import React, { useEffect, useState } from 'react'
import { FormBox, InputBox } from '../../../components/AntdAddons'
import { tokens } from '../../../requests/PageRequest'
import { Button, Col, Form, PageHeader, Row } from 'antd'
import { TokenBreadcrumb } from '../../../config/BreadcrumbConfig'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import useStore from '../../../store'
import Unauthorized from '../../errors/Unauthorized'
import { observer } from 'mobx-react-lite'
import { Notification, validateFields } from '../../../config/Global'
import { CONSTANT } from '../../../config/Constant'

const Tokens = observer(() => {
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [saving, setSaving] = useState<boolean>(false)
	const { AUTH, TOKENS, ROOT } = useStore()

	const handleChange = () => {
		validateFields(form, setDisabled);
	}

	useEffect(() => {

		TOKENS.readtoken()

	}, [TOKENS])

	useEffect(() => {
		form.setFieldsValue({
			allocated_tokens: TOKENS.tokenValues?.allocated_tokens,
			serving_tokens: TOKENS.tokenValues?.serving_tokens
		})
	}, [form, TOKENS.tokenValues])

	const handleSubmit = (data: any) => {
		setSaving(true)
		TOKENS.updateToken(data).then((data: any) => {
			Notification.success({
				message: data.data
			})
			setDisabled(true)
		}).catch((e: any) => {
			ROOT.assignErrorToInput(form, e?.data);
		}).finally(() => {
			setSaving(false)
		})
	}

	return (
		<div>
			{(AUTH.checkPermission(10, 'add') && [CONSTANT.Company,CONSTANT.Employee].includes(AUTH.user?.users_roles_id)) ?
				<PageHeader title={TokenBreadcrumb.title}
					tags={<BreadcrumbComponent items={TokenBreadcrumb.path} />}
				>
					<FormBox form={form} onFinish={handleSubmit} className='tokenLayout'>
						<Row gutter={15}>
							<Col span={6}>
							<InputBox.Text required rules={tokens.allocated_tokens} onChange={handleChange} label='Allocated tokens' name={'allocated_tokens'}></InputBox.Text>
							</Col>
							<Col span={6}>
							<InputBox.Text required rules={tokens.serving_tokens} onChange={handleChange} label='Serving tokens' name={'serving_tokens'}></InputBox.Text>
							</Col>
						</Row>
						
						<Button disabled={disabled} type='primary' htmlType='submit' loading={saving}>Save</Button>
					</FormBox>
				</PageHeader> : <Unauthorized />}
		</div>
	)
})

export default Tokens