import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../../../store";
import { dateComparator } from "../../../../../config/Global";
import { Button } from "antd";
import { ReactComponent as ViewIcon } from "../../../../../assets/images/icon/eye.svg";

// import { agDateColumnFilter } from "../../../../../config/Global";

const ListComponent: React.FC<any> = observer((props: any) => {
  const { ANALYTICS } = useStore();

  const ActionRenderer: React.FC = (prop: any) => {
    return (
      <div className="action-column">
       {"level" in prop.node && (
          <Button
            type="text"
            title={"View"}
            onClick={() => props.openLDrawer(prop.data)}
          >
            <ViewIcon />
          </Button>
        )}
      </div>
    );
  };

  const columnSetup = () => {
    return [
      <AgGridColumn
      key="layout_id"
      field="layout_id"
      headerName="# ID"
      filter={"agNumberColumnFilter"}
      tooltipField="id"
      maxWidth={80}
    />,

    <AgGridColumn
      key="layout_name"
      field="layout_name"
      tooltipField="layout_name"
      headerName="Layout Name"
      // maxWidth={250}
      minWidth={250}
      width={250}
      cellEditor={"customEditor"}
    />,
      <AgGridColumn
        key="start_datetime"
        field="start_datetime"
        headerName="Start Date & Time"
        filter={"agDateColumnFilter"}
        filterParams={dateComparator}
        tooltipField="Start Date & Time"
        minWidth={100}
      />,
      <AgGridColumn
        key="end_datetime"
        field="end_datetime"
        headerName="End Date & Time"
        filter={"agDateColumnFilter"}
        filterParams={dateComparator}
        tooltipField="End Date & Time"
        minWidth={100}
      />,

      <AgGridColumn
        key="duration"
        field="duration"
        headerName="Duration"
        tooltipField="Duration"
        minWidth={100}
      />,
      <AgGridColumn
        key="action"
        headerName="Actions"
        type="actionColumn"
        sortable={false}
        filter={false}
        maxWidth={250}
        minWidth={100}
        resizable={true}
        pinned="right"
        pinnedRowCellRenderer={(params:any) => {
          return params.data ? "hii1" : "hii";
        }}
      />,
    ];
  };

  useEffect(() => {
    ANALYTICS.fetchListLayout();
  }, []);

  return (
    <AgGridWrapper
      onGridReady={ANALYTICS.setupGrid}
      components={{ ActionRenderer }}
      rowData={ANALYTICS.list_data_layout}
      // onColumnResized={ANALYTICS.onGridChangedL}
      // onColumnMoved={ANALYTICS.onGridChangedL}
      pinnedBottomRowData={ANALYTICS.pinnedBottomRowDataLayout}
    >
      {columnSetup()}
    </AgGridWrapper>
  );
});

export default ListComponent;
