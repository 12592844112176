import axios from "axios";
import { makeAutoObservable } from "mobx";
import Config from "../../config/Config";
import { RoleDataProps } from "./RoleInterface";
import API_URL from "../../config/ApiUrl";
import { isActiveStatus } from "../../config/Global";

export default class RoleStore {
	agGrid: any = null;
	per_page = Config.grid.local.gridOptions?.paginationPageSize;
	public list_data?: RoleDataProps[];
	readValues: any = null

	constructor() {
		makeAutoObservable(this);
	}

	// Setup grid and set column size to autosize
	setupGrid = (params: any) => {
		const columnState = localStorage.getItem("role_token")
		if (columnState) {
			params.columnApi.applyColumnState({ state: JSON.parse(columnState) });

		}
		this.agGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(Number(page));
		}
	};

	setReadValues = (data: any) => {
		this.readValues = data
	};
	onGridChanged = (params: any) => {
		localStorage.setItem('role_token', JSON.stringify(params.columnApi.getColumnState()))
	}

	// API Functions
	public fetchList = async (): Promise<any> => {
		this.list_data = [];
		return await axios.post(API_URL.ROLE.LIST).then(({ data }) => {
			data.data && data.data.forEach((item: any) => {
				item.is_active = item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
				if(item.created_by_user){
					item.created_by_user = item.created_by_user.username
				}
			})
			this.list_data = data.data
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	public PermissionList = async (): Promise<any> => {
		return await axios.get(API_URL.ROLE.PERMISSION).then(({ data }) => {
			return data
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	AddData = (formData: any) => {
		return axios.post(API_URL.ROLE.ADD, formData).then(({ data }) => {
			this.fetchList();
			return data
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	DeleteData = () => {
		return axios.post(API_URL.ROLE.DELETE + `/${this.readValues.id}`).then(({ data }) => {
			this.fetchList();
			return data
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	EditData = (formData: any) => {
		return axios.post(API_URL.ROLE.EDIT + "/" + this.readValues.id, formData).then(({ data }) => {
			this.fetchList();
			return data
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	ActiveStatus = (Id: number, status: number) => {
		return axios.post(API_URL.ROLE.STATUS + `/${Id}`, { "status": status }).then(({ data }) => {
			this.fetchList()
			return data
		}).catch(({ response }) => {
			return Promise.reject(response)
		})
	}
}
