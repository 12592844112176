import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Form } from 'antd'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import { EmergencyPopup } from '../../../../requests/PageRequest'
import { Regex } from '../../../../config/Validators'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { Notification, validateFields } from '../../../../config/Global'

const FormComponent = observer(() => {
	const { SETTNG_STORE, AUTH, ROOT } = useStore()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [form] = Form.useForm()
	const [saving, setSaving] = useState<boolean>(false)

	const handleChange = () => {
		var phone = form.getFieldValue('contact_number')
		form.setFieldValue('contact_number', phone && phone.replace(Regex.mobilespace, '$1 $2 $3'))
		validateFields(form, setDisabled);
	}
	const handleSubmit = (data: any) => {
		setSaving(true)
		SETTNG_STORE.updateSetting(data).then((data: any) => {
			Notification.success({
				message: data.data
			})
			setDisabled(true)
		}).catch((e: any) => {
			ROOT.assignErrorToInput(form, e?.data);
		}).finally(() => setSaving(false))
	}

	useEffect(() => {
		SETTNG_STORE.readSetting()
	}, [AUTH])

	useEffect(() => {
		form.setFieldsValue({
			emergency_popup_text: SETTNG_STORE.readValues?.emergency_popup_text,
			display_seconds: SETTNG_STORE.readValues?.display_seconds,
			rate_condition_line: SETTNG_STORE.readValues?.rate_condition_line,
			text_to_display_when_rates_are_hidden: SETTNG_STORE.readValues?.text_to_display_when_rates_are_hidden,
			contact_number: SETTNG_STORE.readValues?.contact_number,
			email_address: SETTNG_STORE.readValues?.email_address
		})
	}, [SETTNG_STORE.readValues])

	return (
		<div>
			<FormBox title='Emergency Popup Settings' form={form} onFinish={handleSubmit} className='commanDrawer popUpSetting'>
				<Row gutter={15}>
					<>
						<Col span={6}>
							<InputBox.Text onChange={handleChange} required label='Emergency popup text' rules={EmergencyPopup.emergency_popup_text} name={'emergency_popup_text'}></InputBox.Text>
						</Col>
						<Col span={6}>
							<InputBox.Text required label='Display seconds' onChange={handleChange} rules={EmergencyPopup.display_seconds} name={'display_seconds'}></InputBox.Text>
						</Col>
						<Col span={6}>
							<InputBox.Text required label='Rate Condition Line' onChange={handleChange} rules={EmergencyPopup.rate} name={'rate_condition_line'}></InputBox.Text>
						</Col>
						<Col span={6}>
							<InputBox.Text required label='Text to display when rates are hidden' onChange={handleChange} rules={EmergencyPopup.text_display} name={'text_to_display_when_rates_are_hidden'}></InputBox.Text>
						</Col>
						<Col span={12} className='settingSaveBtn'>
							<Button type='primary' htmlType='submit' disabled={disabled} loading={saving}
								className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`}>Save</Button>
						</Col>
					</>
				</Row>
			</FormBox>
		</div>
	)
})

export default FormComponent