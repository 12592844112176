import React from "react";
import { useRoutes } from "react-router-dom";
import ForgotPassword from "../views/app_pages/auth/ForgotPassword";
import Login from "../views/app_pages/auth/Login";
import ResetPassword from "../views/app_pages/auth/ResetPassword";
import Dashboard from "../views/app_pages/Dashboard";
import AuthLayout from "../views/layouts/AuthLayout";
import MainLayout from "../views/layouts/MainLayout";
import { CONSTANT } from "./Constant";
import Users from "../views/app_pages/Users";
import Rates from "../views/app_pages/Rates";
import DeviceManagement from "../views/app_pages/Devices";
import SavedLayout from "../views/app_pages/SavedLayout";
import GlobalPopup from "../views/app_pages/GlobalPopup";
import Settings from "../views/app_pages/Settings";
import Tokens from "../views/app_pages/Tokens";
import Schedule from "../views/app_pages/Schedule";
import LayoutManagement from "../views/app_pages/Layout";
import PageNotFound from "../views/errors/PageNotFound";
import Profile from "../views/app_pages/Profile";
import RoleManagement from "../views/app_pages/Role";
import DeviceGroupManagement from "../views/app_pages/DeviceGroup";
import AuditLogManagement from "../views/app_pages/AuditLog";
// const SavedLayout = React.lazy(() => import('../views/app_pages/SavedLayout'))
// const Settings = React.lazy(() => import('../views/app_pages/Settings'))
// const Tokens = React.lazy(() => import('../views/app_pages/Tokens'));
// const GlobalPopup = React.lazy(() => import('../views/app_pages/GlobalPopup'))
// const Login = React.lazy(() => import("../views/app_pages/auth/Login"));
// const ForgotPassword = React.lazy(() => import("../views/app_pages/auth/ForgotPassword"));
// const Dashboard = React.lazy(() => import("../views/app_pages/Dashboard/index"));
// const PageNotFound = React.lazy(() => import("../views/errors/PageNotFound"));
// const Users = React.lazy(() => import("../views/app_pages/Users"))
// const Rates = React.lazy(() => import("../views/app_pages/Rates"))
// const DeviceManagement = React.lazy(() => import("../views/app_pages/Devices"))
// const ResetPassword = React.lazy(() => import('../views/app_pages/auth/ResetPassword'))
// const LayoutManagement = React.lazy(() => import("../views/app_pages/Layout"))
// const Company = React.lazy(() => import('../views/app_pages/Customer'))
// const AuthLayout = React.lazy(() => import("../views/layouts/AuthLayout"));
// const MainLayout = React.lazy(() => import("../views/layouts/MainLayout"));
// const Schedule = React.lazy(() => import('../views/app_pages/Schedule'))
// const Unauthorized = React.lazy(() => import("../views/errors/Unauthorized"));

import MapCheck from "../views/app_pages/MapCheck";
import UpdateAPK from "../views/app_pages/UpdateAPK";
import Subscription from "../views/app_pages/Subscription";
import SubscriptionList from "../views/app_pages/SubscriptionList";
import LayoutAnalytics from "../views/app_pages/Analytics/LayoutAnalytics";
import DeviseAnalytics from "../views/app_pages/Analytics/DeviseAnalytics";
import WebToFrontRedirect from "../views/app_pages/WebToFrontRedirect";

export default function Router() {
  const element = useRoutes(RouterConfig);
  return element;
}

export const RouterConfig = [
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset/:id",
        element: <ResetPassword />,
      },
      {
        path: "/map-check",
        element: <MapCheck />,
      },
      {
        path : "web_to_front",
        element : <WebToFrontRedirect/>
      },
    ],
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: CONSTANT.DASHBOARD_REDIRECT_PATH,
        element: <Dashboard />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/rates",
        element: <Rates />,
      },
      {
        path: "/devices",
        element: <DeviceManagement />,
      },
      {
        path: "/group-devices",
        element: <DeviceGroupManagement />,
      },
      {
        path: "/roles-management",
        element: <RoleManagement />,
      },
      {
        path: "/saved-layout",
        element: <SavedLayout />,
      },
      {
        path: "/global-popup",
        element: <GlobalPopup />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/tokens",
        element: <Tokens />,
      },
      {
        path: "/tokens",
        element: <Tokens />,
      },
      {
        path: "/schedule",
        element: <Schedule />,
      },
      {
        path: "/layouts",
        element: <LayoutManagement />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/plan",
        element: <Subscription />,
      },
      {
        path: "/list",
        element: <SubscriptionList />,
      },
      {
        path: "/apk",
        element: <UpdateAPK />,
      },
      {
        path: "/audit-log",
        element: <AuditLogManagement />,
      },
      {
        path: "/layout/analytics",
        element: <LayoutAnalytics />,
      },
      {
        path: "/device/analytics",
        element: <DeviseAnalytics />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      }, 
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];
