import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import { RoleListProps, UserRoleListProps } from "./UserRoleInterface";
import { displayFormats, isActiveStatus } from "../../config/Global";
import moment from "moment";

export default class UserStore {
	agGrid: any = null;
	per_page = Config.grid.local.gridOptions?.paginationPageSize;
	public list_data?: UserRoleListProps[];
	public roleList?: RoleListProps[];
	viewValues: any = null
	addlicViews: any = null
	dropdown_user_role_list = null;
	country_list: any = null;
	state_list: any = null
	permission_list = null;
	city_list: any = null;
	payment_details: any = null;
	initialValue: any = {
		expiration_date: moment().add(1, 'days')
	}
	userPermissions : any = null
	is_custom:any = null
	selectedDeviceIds:any = []

	constructor() {
		makeAutoObservable(this);
	}

	// Setup grid and set column size to autosize
	setupGrid = (params: any) => {
		const columnState = localStorage.getItem("user_token")
		if (columnState) {
			params.columnApi.applyColumnState({ state: JSON.parse(columnState) });
		}
		this.agGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(Number(page));
		}
	};

	onGridChanged = (params: any) => {
		localStorage.setItem('user_token', JSON.stringify(params.columnApi.getColumnState()))
	}
	getPermissionsList = (formdata: any) => {
		return axios.post('permission/list', formdata).then(({ data }) => {
			this.permission_list = data.data
			this.userPermissions = data.permissson
			return data
		}).catch((response) => {
			this.permission_list = null
			return Promise.reject(response)
		})
	}

	setSelectedDeviceIds = (data:any) => {
		this.selectedDeviceIds = data
	}

	setViewValues = async (id: number | null) => {
		if (id) {
			return await axios.get(API_URL.USER_ROLE.READ + `/${id}`).then(({ data }:any) => {
				if (data.data?.get_company_detail && data.data.get_company_detail.get_state_name) {
					data.data.get_company_detail.get_state_name.name = data.data.get_company_detail ? data.data.get_company_detail.get_state_name.name.charAt(0).toUpperCase() + data.data.get_company_detail.get_state_name.name.slice(1).toLowerCase() : null;
				}
				this.viewValues = data.data;
				this.is_custom = data.is_custom
				return data
			})
		} else {
			this.viewValues = null;
			this.is_custom = null
		}
	};

	setaddViews = async (id: number | null) => {
		if (id) {
			return await axios.get(API_URL.USER_ROLE.READ_LICENSE + `/${id}`).then(({ data }) => {
				this.addlicViews = data.data;
				return data
			})
		} else {
			this.addlicViews = null;
		}
	}

	// Setter Functions
	private setListData = (value?: UserRoleListProps[]): void => {
		this.list_data = value;
	};

	public addLicense = async (data: any): Promise<any> => {
		return await axios.post(API_URL.USER_ROLE.ADD_LICENSE + `/${data.company_id}`, data).then(({ data }) => {
			this.fetchList()
			return data
		}).catch((response) => {
			return Promise.reject(response);
		})
	}

	public editLicense = async (data: any): Promise<any> => {
		return await axios.post(API_URL.USER_ROLE.UPDATE_LICENSE + `/${data.company_id}`, data).then(({ data }) => {
			if (data.data === 'License Update Successfully!') {
				this.fetchList()
			}
			return data
		}).catch((response) => {
			return Promise.reject(response);
		})
	}
	// API Functions
	public fetchList = async (data?: any): Promise<any> => {
		this.setListData([]);
		return await axios.post(API_URL.USER_ROLE.LIST, data).then(({ data }) => {
			const roleSuperAdmin = { id: 1, role_name: 'SuperAdmin' }
			data.data && data.data.forEach((item: any) => {
				item.role = item.role ? item.role : roleSuperAdmin
				item.is_active_status = item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
				item.date = item?.get_company_detail && item?.get_company_detail?.expiry_date ? moment(item?.get_company_detail?.expiry_date).format(displayFormats.DATE_MONTH_YEAR) : 'N/A'
				item.company_name = item?.get_company_detail?.company_name ?? 'N/A'
				item.current_period_start =  item?.subscription_data[0]?.current_period_start ? moment(item?.get_company_detail?.current_period_start).format(displayFormats.DATE_MONTH_YEAR) : 'N/A';
				item.current_period_end =  item?.subscription_data[0]?.current_period_end ? moment(item?.get_company_detail?.current_period_end).format(displayFormats.DATE_MONTH_YEAR) : 'N/A';
				item.plan_name = item.subscription_data[0]?.plan_name ? item.subscription_data[0]?.plan_name : "N/A";
				item.price = item.subscription_data[0]?.price ? item.subscription_data[0]?.price : "N/A"
			})
			this.setListData(data.data);
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	public getRoleList = async (): Promise<any> => {
		return await axios.get(API_URL.USER_ROLE.ROLELIST).then(({ data }) => {
			this.roleList = data
		}).catch((response) => {
			return Promise.reject(response);
		});
	}

	layoutPermission = async (id : any,payload : any): Promise<any> => {
		return await axios.post(`${API_URL.LAYOUT.LAYOUT_PERMISSION}/${id}`,payload).then((data:any) => {
			return data
		}).catch((response) => {
			return Promise.reject(response);
		});
	}

	//USER_PERMISSION

	userPermission = async (id : any,payload : any): Promise<any> => {
		return await axios.post(`${API_URL.USER_ROLE.USER_PERMISSION}/${id}`,payload).then((data:any) => {
			return data
		}).catch((response) => {
			return Promise.reject(response);
		});
	}

	devisePermission = async (id : any,payload : any): Promise<any> => {
		return await axios.post(`${API_URL.DEVICE.DEVISE_PERMISSIONS}/${id}`,payload).then((data:any) => {
			return data
		}).catch((response) => {
			return Promise.reject(response);
		});
	}

	public getCountryList = async (): Promise<any> => {
		return await axios.get(API_URL.USER_ROLE.COUNTRY_LIST).then(({ data }) => {
			this.country_list = data.data
		}).catch((response) => {
			return Promise.reject(response);
		});
	}

	public getStateList = async (id: number): Promise<any> => {
		const country_id: any = { country_id: id }
		return await axios.post(API_URL.USER_ROLE.STATE_LIST, country_id).then(({ data }) => {

			data.data.map((item: any) => {
				item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase();
			})
			this.state_list = data.data
		}).catch((response) => {
			return Promise.reject(response);
		});
	}

	public getCityList = async (id: number): Promise<any> => {
		const state_id: any = { state_id: id }
		return await axios.post(API_URL.USER_ROLE.CITY, state_id).then(({ data }) => {
			this.city_list = data.data
			return data
		}).catch((response) => {
			return Promise.reject(response);
		});
	}

	ActiveStatus = (Id: number, status: number) => {
		return axios.post(API_URL.USER_ROLE.USER_STATUS + `/${Id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
			this.fetchList()
			return data
		}).catch(({ response: { data } }) => {
			var errors: any = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	AddData = (formData: any) => {
		return axios
			.post(API_URL.USER_ROLE.ADD, formData)
			.then((data: any) => {
				this.fetchList();
				return data.data
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	public DeleteData = (data: any) => {
		const id = data.id
		return axios
			.get(API_URL.USER_ROLE.DELETE + `/${id}`)
			.then((data: any) => {
				this.fetchList();
				return data.data
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	EditData = (formData: any) => {
		return axios.post(API_URL.USER_ROLE.EDIT + "/" + this.viewValues?.id, formData).then(({ data }) => {
			this.fetchList();
			return data
		}).catch((response) => {
			return Promise.reject(response);
		});
	};
}
