import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Form, UploadFile, message } from 'antd'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import { updateAPKRequest } from '../../../../requests/PageRequest'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { Notification, createUrl, dataToFormDataConverter, uploadedFileOnPreview, validateFields } from '../../../../config/Global'
import Upload, { RcFile } from 'antd/lib/upload'
import { UploadOutlined } from '@ant-design/icons'

const FormComponent = observer(() => {
	const { UPDATE_APK, AUTH, ROOT } = useStore()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [form] = Form.useForm()
	const [saving, setSaving] = useState<boolean>(false)
	const [fileList, setFileList] = useState<UploadFile[]>([])

	const handleChange = () => {
		validateFields(form, setDisabled);
	}
	const handleSubmit = (data: any) => {
		setSaving(true)
		const formData = dataToFormDataConverter(data);
		UPDATE_APK.update(formData).then((data: any) => {
			Notification.success({
				message: data.message
			})
			setDisabled(true)
		}).catch((e: any) => {
			ROOT.assignErrorToInput(form, e?.data);
		}).finally(() => setSaving(false))
	}

	useEffect(() => {
		UPDATE_APK.read()
	}, [AUTH])

	useEffect(() => {
		if (UPDATE_APK.readValues && UPDATE_APK.readValues?.apk) {
			setFileList([{
				uid: '-1',
				name: UPDATE_APK.readValues?.apk,
				status: 'done',
				url:createUrl("/storage/" + UPDATE_APK.readValues?.apk),
				// url: CONSTANT.BASE_URL + '/storage/' + UPDATE_APK.readValues?.apk,
			}])
		}

		form.setFieldsValue({
			version_code: UPDATE_APK.readValues?.version_code,
			version_name: UPDATE_APK.readValues?.version_name,
			apk: UPDATE_APK.readValues?.apk,
		})
	}, [UPDATE_APK.readValues])

	return (
		<div>
			<FormBox title='Update APK' form={form} onFinish={handleSubmit} className='commanDrawer popUpSetting'>
				<Row gutter={15}>
					<>
						<Col span={6}>
							<InputBox.Text onChange={handleChange} required label='Version Code' rules={updateAPKRequest.version_code} name={'version_code'}></InputBox.Text>
						</Col>
						<Col span={6}>
							<InputBox.Text required label='Version Name' onChange={handleChange} rules={updateAPKRequest.version_name} name={'version_name'}></InputBox.Text>
						</Col>
						<Col span={6}>
							<Form.Item name="apk" className="full-width-dragger" rules={updateAPKRequest.apk}
							label >
								<Upload
									accept=".apk"
									// listType="picture"
									multiple={false}
									fileList={fileList}
									onRemove={() => {
										setFileList([])
										form.resetFields(["apk"])
									}}
									onPreview={uploadedFileOnPreview}
									showUploadList={{ showPreviewIcon: false }}
									onChange={({ fileList }: any) => {
										setFileList(fileList)
										form.setFieldsValue({
											["apk"]: fileList[0]?.originFileObj
										})
										handleChange()
									}}
									beforeUpload={(file: RcFile) => {
										const isJpgOrPng = file.type === 'application/vnd.android.package-archive';
										if (!isJpgOrPng) {
											message.error(`Upload valid file`);
											return Upload.LIST_IGNORE;
										} else {
											setFileList([file])
											return false
										}
									}}
								>
									{fileList.length < 1 && <Button icon={<UploadOutlined />}>Upload</Button>}
								</Upload>
							</Form.Item>
						</Col>
						<Col span={12} className='settingSaveBtn'>
							<Button type='primary' htmlType='submit' disabled={disabled} loading={saving}
								className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`}>Save</Button>
						</Col>
					</>
				</Row>
			</FormBox>
		</div>
	)
})

export default FormComponent