import React, { useState } from "react";
import { Button, PageHeader, Tooltip } from "antd";
import { observer } from "mobx-react";
import RecordPerPage from "../../../components/AgGridWrapper/RecordPerPage";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";
import { DeviceBreadcrumb } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import DeleteComponent from "./components/DeleteComponent";
import LogComponent from "./components/LogComponent";
import Unauthorized from "../../errors/Unauthorized";
import SwitchComponent from "./components/SwitchComponent";
import PublishComponent from "../CommonModals/PublishComponent";
import { Notification } from "../../../config/Global";
import ScheduleoverrideModule from "./components/Scheduleoverridecomponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
// import Dashboard from '../Dashboard';

const DeviceManagement: React.FC = observer(() => {
  const { AUTH, DEVICE } = useStore();
  const [addDrawer, setAddDrawer] = useState<boolean>(false);
  const [editDrawer, setEditDrawer] = useState<boolean>(false);
  const [deleteDrawer, setDeleteDrawer] = useState<boolean>(false);
  const [logDrawer, setLogDrawer] = useState<boolean>(false);
  const [SwitchModalOpen, setSwitchModalOpen] = useState<boolean>(false);
  const [previewScreen, setpreviewscreen] = useState<boolean>(false);
  const [scheduleoverride, setScheduleoverride] = useState<boolean>(false);
  const [filterModal, setFilterModal] = useState<any>({});

  const closeAddDrawer = () => {
    setAddDrawer(false);
  };

  const openLogDrawer = (data: any) => {
    setLogDrawer(data);
  };

  const closeLogDrawer = () => {
    setLogDrawer(false);
  };

  const openEditDrawer = (data: any) => {
    DEVICE.setEditValues(data.id);
    setEditDrawer(true);
  };

  const closeEditDrawer = () => {
    setEditDrawer(false);
  };

  const openDeleteDrawer = (data: any) => {
    DEVICE.setDeleteValues(data);
    setDeleteDrawer(true);
  };

  const closeDeleteDrawer = () => {
    DEVICE.setDeleteValues(null);
    setDeleteDrawer(false);
  };

  const closeSwitchModal = () => {
    setSwitchModalOpen(false);
  };

  const openSwitchModal = (data: any) => {
    setSwitchModalOpen(data);
    DEVICE.setEditValues(data.id);
  };

  const OpenPublishscreen = (data: any) => {
    setpreviewscreen(data);
  };

  const closePreviewScreen = () => {
    setpreviewscreen(false);
  };

  const openPreviewScreen = (data: any) => {
    DEVICE?.previewDevice(data).then((data: any) => {
      if (data && data.data && data.data.pdf_url) {
        window.open("")?.document.write(data.data.pdf_url);
      } else if (data?.success) {
        Notification.error({
          message: data.success,
        });
      } else {
        window.open(data.data?.layout_html_file_url);
      }
    });
  };

  const unInstallDevice = (data: any) => {
    DEVICE?.uninstallDevice(data.id)
      .then((data: any) => {
        Notification.success({
          message: data.data,
        });
      })
      .catch((e: any) => {
        Notification.error({
          message: e.data,
        });
      });
  };
  const ScheduleoverrideDrawer = (data: any) => {
    setScheduleoverride(data);
  };

  const CloseScheduleoverrideDrawer = () => {
    setScheduleoverride(false);
  };
  const restoreDevice = (data: any) => {
    DEVICE.restoreDevice(data.id).then((data: any) => {
      Notification.success({
        message: data.data,
      });
    });
  };

  const resetFilter = () => {
    DEVICE?.agGrid?.api.setFilterModel(null);
    DEVICE?.agGrid?.api.onFilterChanged();
    setTimeout(() => {
      if (filterModal.last_live_time) {
        DEVICE?.agGrid?.api.setFilterModel("last_live_time", null);
      }
    }, 0);
  };

  return (
    <>
      <div className="header">
        {!AUTH.checkPermission(6, "list") ? (
          <Unauthorized />
        ) : (
          <PageHeader
            title={DeviceBreadcrumb.title}
            tags={<BreadcrumbComponent items={DeviceBreadcrumb.path} />}
            extra={[
              Object.keys(filterModal)?.length !== 0 && (
                <Tooltip title={"Clear All Filters"}>
                <Button
                  key="3"
                  type="primary"
                  className="mr-10"
                  onClick={resetFilter}
                >
                  <FontAwesomeIcon icon={faFilterCircleXmark} />
                </Button>
                </Tooltip>
              ),
              AUTH.checkPermission(6, "add") &&
                 (
                  <Button
                    key="1"
                    type="primary"
                    className="mr-10 "
                    onClick={() => setAddDrawer(true)}
                    data-tut="reactour__add_device"
                  >
                    Add New Device
                  </Button>
                ),
              <RecordPerPage
                key="2"
                defaultValue={DEVICE.per_page + " per page"}
                onChange={DEVICE.setPageSize}
              />,
            ]}
          >
            <ListComponent
              openEditModal={openEditDrawer}
              openSwitchModal={openSwitchModal}
              openDeleteModal={openDeleteDrawer}
              openLogeModal={openLogDrawer}
              OpenPublishscreen={OpenPublishscreen}
              openPreviewScreen={openPreviewScreen}
              unInstallDevice={unInstallDevice}
              restoreDevice={restoreDevice}
              ScheduleoverrideDrawer={ScheduleoverrideDrawer}
              setFilterModal={setFilterModal}
            />
            <AddComponent visible={addDrawer} close={closeAddDrawer} />
            <EditComponent visible={editDrawer} close={closeEditDrawer} />
            <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
            <LogComponent visible={logDrawer} close={closeLogDrawer} />
            <SwitchComponent
              visible={SwitchModalOpen}
              close={closeSwitchModal}
            />
            <PublishComponent
              visible={previewScreen}
              close={closePreviewScreen}
              callApi={DEVICE.publishDevice}
            />
            <ScheduleoverrideModule
              visible={scheduleoverride}
              close={CloseScheduleoverrideDrawer}
            />
          </PageHeader>
        )}
      </div>
    </>
  );
});

export default DeviceManagement;
