import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Button, Col, Drawer, FormInstance, Row } from "antd";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import Config from "../../../../config/Config";
import { InputBox } from "../../../../components/AntdAddons";
import useStore from "../../../../store";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  layout_id: any;
  layoutIdFlag?:boolean, 
}

const HtmlLayoutElement: React.FC<formInterface> = observer(
  (props: formInterface) => {
    const { name, form, layout_id, onChange, layoutIdFlag } = props;
    const emailEditorRef = useRef<EditorRef>(null);
    const [showBuilderLayout, setShowBuilderLayout] = useState<any>(false);
    const { LAYOUT } = useStore();

    useEffect(() => {
      if (layout_id && layoutIdFlag) {
        form.setFieldValue(name, "");
      }
    }, [layout_id]);

    const exportHtml = () => {
      const unLayer = emailEditorRef.current?.editor;

      unLayer?.exportHtml((data: any) => {
        const { design, html } = data;
        form.setFieldsValue({
          [name]: html,
          ["json"]: JSON.stringify(design),
        });
        onChange();
        setShowBuilderLayout(false);
      });
    };

    const onReady: EmailEditorProps["onReady"] = (unLayer: any) => {
      const templateJson = form.getFieldValue("json") ? JSON.parse(form.getFieldValue("json")) : {};
      unLayer.loadDesign(templateJson);
      unLayer.setBodyValues({
        contentWidth: "100%",
      });

      unLayer.registerCallback('image', function(file: any, done: any) {
        var data = new FormData()
        data.append('file', file.attachments[0])
      
        LAYOUT.uploadHtmlBuilderFile(data).then((data: any) => {
          // Pass the URL back to Unlayer to mark this upload as completed
          done({ progress: 100, url: data.data })
        })
      });

    };

    const handleClose = () => {
      setShowBuilderLayout(false);
      return false;
    };

    return (
      <>
        <div className="ownLayout">
          <Button onClick={() => setShowBuilderLayout(true)} type="primary">
            Build Your Own Layout
          </Button>
        </div>
        <InputBox.TextArea name={name} style={{ display: "none" }} />
        <InputBox.TextArea name={"json"} style={{ display: "none" }} />
        <Drawer
          open={showBuilderLayout}
          onClose={handleClose}
          width={"100%"}
          className="commanDrawer buildYourOwnLayoutDrawer"
          title={
            <div>
              <span>Build Your Own Layout</span>
              <div className="layoutBtn">
              <Button
                className="orangeBgBtn cancelBtn cancelBt"
                onClick={handleClose}
                style={{ float: "right" }}
              >
                Close
              </Button>
              <Button
                type="primary"
                onClick={exportHtml}
                style={{ float: "right" }}
              >
                Save
              </Button>
              </div>
            </div>
          }
          destroyOnClose={true}
          keyboard={false}
        >
          <Row gutter={Config.gutter}>
            <Col span={24}>
              <div>
                <EmailEditor
                  ref={emailEditorRef}
                  options={{
                    displayMode: "web",
                    tools: {
                      form: {
                        enabled: false,
                      },
                      menu: {
                        enabled: false,
                      },
                      button: {
                        enabled: false
                      },
                    },
                  }}
                  onReady={onReady}
                  style={{ minHeight: "100vh" }}
                />
              </div>
            </Col>
          </Row>
        </Drawer>
      </>
    );
  }
);

export default HtmlLayoutElement;
