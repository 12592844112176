import { Button, Row, Spin, Col, Form, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../../../store";
import { Notification } from "../../../../config/Global";
import { ErrorProps } from "../../../../store/RootStore/RootInterface";

const ProxyConfirmComponent = observer((props: any) => {
	const navigate = useNavigate()
	const { USER, AUTH } = useStore()
	const { visible, close } = props
	const [saving, setSaving] = useState<boolean>(false)
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState(true)

	const handleproxy = () => {
		setSaving(true)
		const id = USER?.viewValues?.id
		AUTH.doProxyLogin(id).then(() => {
			window.open('/dashboard', "_self")
			navigate('/dashboard')
			location.reload()
			close()
		}).catch((e: ErrorProps) => {
			if (e.errors) {
				Notification.error({
					message: e?.errors
				})
			}
		}).finally(() => setSaving(false))
	}
	const handleFieldChange = () => {
		if (form.getFieldsValue().confirm.toLowerCase() === "confirm") {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}

	const handleClose = () => {
		close()
		setDisabled(true)
		form.resetFields()
	}
	return (<>
		<Modal open={visible} onCancel={handleClose}
			title={`Proxy Login ${USER.viewValues?.role?.role_name}`}
			className="commanModal proxyModal"
			footer={[
				<>
					<Button type='primary' htmlType="submit" form="proxy" disabled={disabled}
						className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`}
						loading={saving}>Confirm</Button>
					<Button onClick={handleClose} className="orangeBgBtn cancelBtn" >Cancel</Button>
				</>
			]}>
			<Spin size="large" spinning={!USER.viewValues} tip={"Loading"}>
				<Form form={form} id="proxy" onFinish={handleproxy}>
					{
						<Row align="middle">
							<Col>
								<h3>Are you sure you want to Proxy  login as  <strong>{USER.viewValues?.name}</strong> which role is <strong> {USER.viewValues?.role?.role_name}</strong> ? </h3>
								<Form.Item style={{ marginBottom: 0 }} name="confirm">
									<Input placeholder='Type "CONFIRM" Proxy Login' onChange={handleFieldChange} />
								</Form.Item>
							</Col>
						</Row>
					}
				</Form>
			</Spin>
		</Modal>

	</>)
})

export default ProxyConfirmComponent