import React, { useState } from 'react';
import { Button, PageHeader } from 'antd';
import { observer } from 'mobx-react';
import RecordPerPage from '../../../components/AgGridWrapper/RecordPerPage';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { DeviceGroupBreadcrumb } from '../../../config/BreadcrumbConfig'
import useStore from '../../../store';
import ListComponent from './components/ListComponent';
import AddComponent from './components/AddComponent';
import EditComponent from './components/EditComponent';
import DeleteComponent from './components/DeleteComponent';
import Unauthorized from '../../errors/Unauthorized';
import SwitchComponent from './components/SwitchComponent';
import PublishComponent from '../CommonModals/PublishComponent';

const DeviceGroupManagement: React.FC = observer(() => {
	const { AUTH, DEVICE_GROUP } = useStore()
	const [addDrawer, setAddDrawer] = useState<boolean>(false)
	const [editDrawer, setEditDrawer] = useState<boolean>(false)
	const [deleteDrawer, setDeleteDrawer] = useState<boolean>(false)
	const [switchModalOpen, setSwitchModalOpen] = useState<boolean>(false)
	const [previewScreen, setpreviewscreen] = useState<boolean>(false)

	const closeAddDrawer = () => {
		setAddDrawer(false)
	}

	const openEditDrawer = (data: any) => {
		DEVICE_GROUP.setEditValues(data.id)
		setEditDrawer(true)
	}

	const closeEditDrawer = () => {
		setEditDrawer(false)
	}

	const openDeleteDrawer = (data: any) => {
		DEVICE_GROUP.setDeleteValues(data)
		setDeleteDrawer(true)
	}

	const closeDeleteDrawer = () => {
		DEVICE_GROUP.setDeleteValues(null)
		setDeleteDrawer(false)
	}

	const closeSwitchModal = () => {
		setSwitchModalOpen(false)
	}

	const openSwitchModal = (data: any) => {
		setSwitchModalOpen(data)
		DEVICE_GROUP.setEditValues(data.id)
	}

	const OpenPublishscreen = (data: any) => {
		setpreviewscreen(data)
	}

	const closePreviewScreen = () => {
		setpreviewscreen(false)
	}

	return (
		<>
			<div className='header'>
				{!AUTH.checkPermission(12, 'list') ? (<Unauthorized />) :
					<PageHeader
						title={DeviceGroupBreadcrumb.title}
						tags={
							<BreadcrumbComponent items={DeviceGroupBreadcrumb.path} />}
						extra={[
							(AUTH.checkPermission(12, 'add')) &&
							<Button key='1' type="primary" className='mr-10 ' onClick={() => setAddDrawer(true)}>
								Add New Group</Button>,
							<RecordPerPage key="2" defaultValue={DEVICE_GROUP.per_page + ' per page'} onChange={DEVICE_GROUP.setPageSize} />,
						]}
					>
						<ListComponent
							openEditModal={openEditDrawer}
							openDeleteModal={openDeleteDrawer}
							openSwitchModal={openSwitchModal}
							OpenPublishscreen={OpenPublishscreen}
						/>
						<AddComponent visible={addDrawer} close={closeAddDrawer} />
						<EditComponent visible={editDrawer} close={closeEditDrawer} />
						<DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
						<SwitchComponent visible={switchModalOpen} close={closeSwitchModal} />
						<PublishComponent visible={previewScreen} close={closePreviewScreen} callApi={DEVICE_GROUP.publishDevice} />
					</PageHeader>
				}
			</div>
		</>
	)
})

export default DeviceGroupManagement