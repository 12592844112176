import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Form, Modal, Row } from 'antd'
import useStore from '../../../../store'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import { displayFormats, Notification, validateFields } from '../../../../config/Global'
import { LayoutRequest } from '../../../../requests/PageRequest'
import moment from 'moment'

const SavedLayoutComponent: React.FC<any> = observer((props: any) => {
	const { visible, close } = props
	const [form] = Form.useForm()
	const { LAYOUT, AUTH } = useStore()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState<boolean>(false)

	const handleSubmit = (data: any) => {
		data.id = visible.id
		data.date = moment(data.date).format(displayFormats.TEMP_DATE_FORMAT)
		setSaving(true)
		LAYOUT.savedLayout(data).then((data: any) => {
			Notification.success({
				message: data.data
			})
			form.resetFields()
			close()
			form.resetFields()
			AUTH.fetchAuthUser()
			setDisabled(true)
		}).catch((e: any) => {
			if (e.errors) {
				form.setFields(e.errors)
			}
		}).finally(() => {
			setSaving(false)
		})
	}

	const handleClose = () => {
		close()
		form.resetFields()
		setDisabled(true)
		setDisabled(true)
	}

	const handleChange = () => {
		validateFields(form, setDisabled);
	}
	const resetFormFields = () => {
		form.resetFields()
		close()
	}
	const customFormat = (value: any) => `${value.format("DD-MM-YYYY")}`;
	const dateChange = (date: any, dateString: any) => {
		if (dateString !== null && form.getFieldValue('name') !== null && form.getFieldValue('description')) {
			setDisabled(false)
		}
	}
	return (
		<Modal
			open={visible}
			onCancel={resetFormFields}
			width={500}
			title={`Saved Layout`}
			destroyOnClose={true}
			className="commanModal"
			footer={[
				<div key="1">
					<Button className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} form='SavedLayout' disabled={disabled}
						loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="orangeBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<FormBox form={form} id="SavedLayout" onFinish={handleSubmit} onChange={handleChange}>
				<Row gutter={24}>
					<Col span={24}>
						<InputBox.Text
							name="name"
							label="Name"
							placeholder="Enter Name"
							rules={LayoutRequest.saved_name}
						/>
					</Col>
					<Col span={24}>
						<InputBox.DatePicker
							name="date"
							label="Date"
							placeholder="Enter Date"
							rules={LayoutRequest.saved_date}
							format={customFormat}
							onChange={dateChange}
						/>
					</Col>
					<Col span={24}>
						<InputBox.TextArea
							name="description"
							label="Description"
							placeholder="Enter Description"
							rules={LayoutRequest.saved_description}
						/>
					</Col>
				</Row>
			</FormBox>
		</Modal >
	)
})

export default SavedLayoutComponent