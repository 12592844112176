import React, { useEffect } from 'react'
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../../store";
import Button from "antd/es/button";
import { observer } from 'mobx-react';
import { ReactComponent as EditIcon } from '../../../../assets/images/icon/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icon/delete.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/images/icon/download.svg';
// import { Switch } from 'antd';
import { useLocation } from 'react-router-dom';

const ListComponent: React.FC<any> = observer((prop) => {
	const { openEditModal, openDeleteModal, OpenPublishscreen } = prop // openSwitchModal
	const { DEVICE_GROUP, AUTH } = useStore()
	const loc = useLocation()
	const flag = loc.search.slice(1)
	const cellStyle = {
		textAlign: "center"
	}
	const ActionRenderer: React.FC = (prop: any) => {
		return (
			<div className="action-column">
				{
					AUTH.checkPermission(12, 'publish') && <Button type="text" title={"Publish Device"} onClick={() => OpenPublishscreen(prop.data)} >
						<DownloadIcon />
					</Button>
				}
				{
					AUTH.checkPermission(12, 'edit') && <Button type="text" title={"Edit"} className="editIcon" onClick={() => openEditModal(prop.data)}>
						<EditIcon />
					</Button>
				}
				{
					AUTH.checkPermission(12, 'delete') && <Button type="text" title={"Delete"} onClick={() => openDeleteModal(prop.data)}>
						<DeleteIcon />
					</Button>
				}
			</div >
		);
	}

	useEffect(() => {
		DEVICE_GROUP.fetchList(flag)
	}, [])

	// const cellRenderData = (data: any) => {
	// 	return AUTH.checkPermission(12, 'status') && <Switch checked={data.data.status} onChange={() => openSwitchModal(data.data)} />
	// }

	const columnSetup = () => {
		return ([
			<AgGridColumn
				key='display_id'
				field="display_id"
				cellStyle={cellStyle}
				headerName="# ID"
				tooltipField='display_id'
				maxWidth={80}
				filter={"agNumberColumnFilter"}
				sortable={false}
			/>,
			<AgGridColumn
				key='group_name'
				field="group_name"
				tooltipField='group_name'
				headerName="Group Name"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='company_name'
				field="company_name.company_name"
				tooltipField='company_name'
				headerName="Company Name"
				width={100}
				minWidth={100}
			/>,
			// <AgGridColumn
			// 	key={'status'}
			// 	headerName="Status"
			// 	cellStyle={cellStyle}
			// 	field='status'
			// 	tooltipValueGetter={(params) => params.data.status === 1 ? 'Active' : 'Deactive'}
			// 	sortable={false}
			// 	maxWidth={100}
			// 	filter={'agSetColumnFilter'}
			// 	filterParams={
			// 		{
			// 			defaultToNothingSelected: true,
			// 			values: ["Activated", "Deactivated"]
			// 		}
			// 	}
			// 	cellRendererFramework={cellRenderData}
			// />,
			<AgGridColumn
				key='action'
				headerName="Actions"
				type="actionColumn"
				sortable={false}
				filter={false}
				maxWidth={230}
				minWidth={230}
				width={230}
		        pinned='right'
			/>
		])
	}
	

	return (
		<AgGridWrapper
			onGridReady={DEVICE_GROUP.setupGrid}
			components={{ ActionRenderer }}
			rowData={DEVICE_GROUP.list_data}
			onColumnResized={DEVICE_GROUP.onGridChanged}
			onColumnMoved={DEVICE_GROUP.onGridChanged}
		>
			{columnSetup()}
		</AgGridWrapper>
	)
})

export default ListComponent;