import { AgGridReactProps, AgReactUiProps } from "ag-grid-react";

interface ConfigProps {
  [key: string]: any;
  grid: {
    local: AgGridReactProps | AgReactUiProps;
    server: AgGridReactProps | AgReactUiProps;
  };
}

export const onFilterChanged = (params: any) => {
  const agGrid = params;
  if (agGrid && agGrid.api.getModel().getRowCount() === 0) {
    agGrid.api.showNoRowsOverlay();
  }
  if (agGrid && agGrid.api.getModel().getRowCount() > 0) {
    agGrid.api.hideOverlay();
  }
};

export const setCustomColumnFilter = (params: any) => {
  const returnValue: any = ["empty"];
  params?.map((data: any) => {
    
    return returnValue.push({
      displayKey: data.value,
      displayName: data.value,
      predicate: (_: null, cellValue: string) =>
        cellValue != null && cellValue === data.value,
      numberOfInputs: 0,
    });
  });
  
  return { filterOptions: returnValue };
};
const Config: ConfigProps = {
  gutter: 24,
  sidebar_width: 280,
  dateFormat: "DD/MM/YYYY",
  dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
  orangeBtn: "#FFA500",
  redBtn: "#f10000",
  blueBtn: "#0F01FF",
  greenBtn: "#3cad64",
  themePrimaryBtn: "#ff8a02",
  grid: {
    server: {
      rowModelType: "serverSide",
      serverSideStoreType: "partial",
      gridOptions: {
        animateRows: false,
        pagination: true,
        paginationPageSize: 50,
        rowHeight: 42,
        headerHeight: 40,
        floatingFiltersHeight: 40,
        enableRangeSelection: true,
        cacheBlockSize: 50,
        blockLoadDebounceMillis: 500,
        rowSelection: "multiple",
        overlayNoRowsTemplate: "No Records Found.",
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        cellClass: "text-center",
        filter: "agTextColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
        flex: 1,
        minWidth: 200,
      },
      columnTypes: {
        actionColumn: {
          cellRenderer: "ActionRenderer",
        },
      },
    },
    local: {
      gridOptions: {
        animateRows: false,
        pagination: true,
        paginationPageSize: 50,
        rowHeight: 42,
        headerHeight: 40,
        floatingFiltersHeight: 40,
        enableRangeSelection: true,
        rowSelection: "multiple",
        overlayNoRowsTemplate: "No Records Found.",
        // enableCellTextSelection:true,
        // allowContextMenuWithControlKey:true,
        // getContextMenuItems: () => {
        // 	return ['copy', 'copyWithHeaders', 'csvExport', 'excelExport'];
        //   },
        // suppressContextMenu: true,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        cellClass: "text-center",
        filter: "agTextColumnFilter",
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
        flex: 1,
        minWidth: 200,
      },
      columnTypes: {
        actionColumn: {
          cellRenderer: "ActionRenderer",
        },
      },
      onFilterChanged: onFilterChanged,
      onSortChanged: onFilterChanged,
    },
  },
};

export default Config;
