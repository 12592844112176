import React, { useEffect } from "react";
import LoginBanner from "../../../assets/images/login-image.png";
import useStore from "../../../store";
import LoginForm from "./LoginForm";

const Login: React.FC = () => {
	const { AUTH } = useStore();
	const { customRedirect } = AUTH;

	useEffect(() => {
		customRedirect()
	}, []);
	return (
		<section className="loginSection">
			<div className="loginWrap">
				<div className="loginLogo">
					<div className="loginBanner">
						<img src={LoginBanner} alt="Banner" />
					</div>
				</div>
				<LoginForm />
			</div>
		</section>
	);
};

export default Login;