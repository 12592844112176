import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../config/ApiUrl";


export default class SettingStore {
    readValues: any = null

    constructor() {
        makeAutoObservable(this)
    }

    readSetting = () => {
        return axios.post(API_URL.GLOBAL_POPUP.READ).then(({ data }) => {
            this.readValues = data.data
        }).catch((response) => {
            return Promise.reject(response)
        })
    }

    updateSetting = (data: any) => {
        return axios.post(API_URL.SETTINGS.UPDATE, data).then(({ data }) => {
            return data
        }).catch((e: any) => {
            return Promise.reject(e)
        })
    }


}