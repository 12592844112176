import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Modal } from 'antd';
import FormComponent from './FormComponent';
import { componentProps } from '../../../../store/RateStore/RateInterface';
import useStore from '../../../../store';
import { Notification, validateFields } from '../../../../config/Global';
import { ErrorProps } from '../../../../store/RootStore/RootInterface';

const AddComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [saving, setSaving] = useState<boolean>(false)
	const { RATE, ROOT, AUTH } = useStore()

	const handleChange = () => {
		validateFields(form, setDisabled);
	}
	const handleClose = () => {
		close()
		form.resetFields()
		setDisabled(true)
	}

	const handleSubmit = (data: any) => {
		setSaving(true)
		RATE.AddData(data).then((data: any) => {
			Notification.success({
				message: data.data
			})
			handleClose()
			AUTH.fetchAuthUser()
		}).catch((e: ErrorProps) => {
			ROOT.assignErrorToInput(form, e?.data.STATUS)
		}).finally(() => setSaving(false))
	}

	return (
		<Modal
			open={visible}
			onCancel={handleClose}
			title={`Add Rate`}
			className='commanModal addRate'
			destroyOnClose={true}
			footer={[
				<div key="1">
					<Button disabled={disabled} className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} form='addEditRateForm' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="orangeBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent form={form} handleSubmit={handleSubmit} onChange={handleChange} />
		</Modal>
	)
})

export default AddComponent