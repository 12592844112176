import { Button, Col, Form, Row, Input } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { useState } from 'react'
import { SavedLayoutComponentProps } from '../../../../store/SavedLayoutStore/SavedInterface'
import useStore from '../../../../store'
import { ReactComponent as WarningFilled } from "../../../../assets/images/icon/warning.svg"
import { Notification } from '../../../../config/Global'


const DeleteComponent: React.FC<SavedLayoutComponentProps> = (props: SavedLayoutComponentProps) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState<boolean>(false)
	const { visible, close } = props
	const { SAVED_LAYOUT, AUTH } = useStore()
	const [disabled, setDisabled] = useState(true)

	const handleClose = () => {
		close()
		form.resetFields()
		setDisabled(true)
	}

	const handleSubmit = (data: any) => {
		setSaving(true)
		data.id = SAVED_LAYOUT?.viewValues.id
		SAVED_LAYOUT.deleteData(data).then((data: any) => {
			setSaving(false)
			Notification.success({ message: data.data })
			handleClose()
			AUTH.fetchAuthUser()

		}).catch((e: any) => {
			if (e.errors) {
				form.setFields(e.errors)
			}
		}).finally(() => {
			setSaving(false)
		})
	}

	const handleFieldChange = () => {
		if (form.getFieldsValue().confirm.toLowerCase() === "confirm") {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}

	return (
		<Modal
			open={visible}
			onCancel={handleClose}
			width={'35%'}
			title={`Delete Saved Layout`}
			className="commanModal deleteModal"
			destroyOnClose={true}
			footer={[
				<div key="1">
					<Button className={`${disabled ? 'disableBtn' : 'deleteBtn'}`} form='deleteform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Delete</Button>
					<Button className="redBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled /></Col>
						<Col span={20}>
							<h3>Are you sure you want to Delete <strong>{SAVED_LAYOUT?.viewValues?.name}</strong> Saved Layout ?</h3>
							<Form.Item style={{ marginBottom: 0 }} name="confirm">
								<Input placeholder='Type "CONFIRM" to delete' onChange={handleFieldChange} />
							</Form.Item>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	)
}

export default DeleteComponent