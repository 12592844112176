import React from 'react'
import { observer } from 'mobx-react'
import { Col, FormInstance } from 'antd'
import { InputBox } from '../../../../components/AntdAddons'

interface formInterface {
    form: FormInstance,
    name: string,
    label: string,
    onChange: () => void,
    setShow?: (data: any) => void
}

const TypeElement: React.FC<formInterface> = observer((props: formInterface) => {
    const { name, onChange, label } = props

    return (
        <Col >
            <InputBox.Select
                style={{ marginBottom: 0 }}
                name={name}
                placeholder={label.charAt(0).toUpperCase() + label.slice(1)}
                label={
                    <div>
                        {label.charAt(0).toUpperCase() + label.slice(1)}
                    </div>
                }
                onChange={onChange}
                options={{
                    list: [
                        { id: "1", name: 'Image-' + name.split('-')[1] },
                        { id: "2", name: 'Video-' + name.split('-')[1] },
                        { id: "3", name: 'Youtube-' + name.split('-')[1] },
                    ]
                }}
            />
        </Col>
    )
})

export default TypeElement
