import React, { useEffect, useState } from "react";
import { faLink, faPenNib } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  FormInstance,
  message,
  Modal,
  Popover,
  Upload,
  UploadFile,
} from "antd";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { UploadOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { StyleProps } from "../../../../store/LayoutStore/LayoutInterface";
import { RcFile } from "antd/lib/upload";
import StyleModalComponent from "../components/StyleModal";
import {
  createUrl,
  planMessage,
  uploadedFileOnPreview,
  widgetStyles,
} from "../../../../config/Global";
import useStore from "../../../../store";
import { isArray } from "lodash";
import { CONSTANT } from "../../../../config/Constant";
import GenerateAIImage from "../components/GenerateAIImage";
import { v4 as uuidv4 } from "uuid";
import CropImageUrlElement from "../components/CropImageUrlElement";
// import ImgCrop from "antd-img-crop";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  layout_id: any;
  sliderImg: any;
  setShow?: (data: any) => void;
  layoutIdFlag?: boolean;
}

const ImageSliderElement: React.FC<formInterface> = observer((props) => {
  const {
    form,
    onChange,
    name,
    label,
    sliderImg,
    layout_id,
    setShow,
    layoutIdFlag,
  } = props;
  const [styleProps, setStyleProps] = useState<StyleProps>({
    styles: [],
    type: "",
  });
  const [applyStyleModal, setApplyStyleModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [applyGenerateAIImageModal, setApplyGenerateAIImageModal] =
    useState<boolean>(false);
  const [msg, setMsg] = useState("");
  const [dataV, setDataV] = useState();
  const [visible, setVisible] = useState<boolean>(false);
  const [removeItemData, setRemoveItemData] = useState<any>();
  const [applyImageUrlModal, setApplyImageUrlModal] = useState<boolean>(false);
  // const [aspectRatio, setAspectRatio] = useState<number>(1 / 1);

  const { LAYOUT, AUTH } = useStore();
  const openStyleModal = (data: StyleProps) => {
    setStyleProps(data);
    setApplyStyleModal(true);
  };

  const closeStyleModal = () => {
    setApplyStyleModal(false);
  };

  //For showing modal on file removal :Start
  const toggle = () => {
    setVisible((prev) => !prev);
    return false;
  };

  const deleteItem = (data?: any) => {
    if (!data.originFileObj && !data.ai) {
      const sliderId = LAYOUT.editValues.layout_field.find(
        (data: any) => data.shortcode.split("_")[0] === name
      ).id;
      const payload = {
        file_path: "/storage/media/file/" + data.name,
        layout_field_id: sliderId,
      };
      LAYOUT.RemoveFileApi(LAYOUT.editValues.id, payload);
      toggle();
    }
    const filterFile = fileList.filter((item) => item.name !== data.name);
    setFileList(filterFile);
    onChange();
    form.setFieldsValue({
      [name]: filterFile.map((_x: any) => {
        if (_x.ai) {
          return {
            aiGenerated: {
              type: "aiGenerated",
              name: _x?.name,
              url: _x?.url,
            },
          };
        } else if (_x.originFileObj) {
          return _x.originFileObj;
        } else {
          return _x.name;
        }
      }),
    });
    return true;
  };
  //For showing modal on file removal :End

  // For upload image through web url
  const handleUpload = async (
    croppedImage: string,
    originalFileName: string
  ) => {
    const response = await fetch(croppedImage);
    const blob = await response.blob();

    const fileWithOriginalName = new File([blob], `${originalFileName}.jpeg`, {
      type: blob.type,
    });

    const newFile: any = {
      uid: uuidv4(),
      name: `${originalFileName}.jpeg`,
      status: "done",
      originFileObj: fileWithOriginalName,
    };
    setFileList([...fileList, newFile]);

    const fieldValue = form.getFieldValue(name);

    if (fieldValue && fieldValue?.length !== 0) {
      form.setFieldsValue({
        [name]: [...fieldValue, fileWithOriginalName],
      });
    } else {
      form.setFieldsValue({
        [name]: fileWithOriginalName,
      });
    }

    onChange();
  };

  const eventProps = {
    onChange: ({ fileList: newFileList }: any) => {
      if (newFileList && newFileList.length) {
        setFileList(newFileList);
        form.setFieldsValue({
          [name]: newFileList?.map((_x: any) => {
            if (_x.ai) {
              return {
                aiGenerated: {
                  type: "aiGenerated",
                  name: _x?.name,
                  url: _x?.url,
                },
              };
            } else if (_x.originFileObj) {
              return _x.originFileObj;
            } else {
              return _x.name;
            }
          }),
        });
        onChange();
      }
    },
    fileList,
    beforeUpload: (file: RcFile) => {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt1M = file.size / 1024 / 1024 < 9;
      if (!isJpgOrPng) {
        message.error(`Please upload file should be png, jpg , jpeg or gif`);
        return Upload.LIST_IGNORE;
      } else if (!isLt1M) {
        message.error("File size should not be more than 9 MB");
        return Upload.LIST_IGNORE;
      } else {
        // setFileList([file])
        return false;
      }
    },

    onPreview: uploadedFileOnPreview,
    onRemove: (data: any) => {
      setRemoveItemData(data);
      if (!data.originFileObj && !data.ai) {
        toggle();
      } else {
        deleteItem(data);
      }
      return false;
    },
  };

  useEffect(() => {
    const sliderFieldsValue = form.getFieldValue(`${name}`);
    const SliderImages: any = [];
    if (LAYOUT.editValues && sliderFieldsValue) {
      if (isArray(sliderFieldsValue)) {
        sliderFieldsValue.forEach((item: string) => {
          SliderImages.push({
            uid: uuidv4(),
            name: item,
            status: "done",
            url: createUrl("/storage/media/file/" + item),
          });
        });
        setFileList(SliderImages);
      } else {
        setFileList([
          {
            uid: uuidv4(),
            name: form.getFieldValue(name),
            status: "done",
            url: createUrl("/storage/media/file/" + form.getFieldValue(name)),
          },
        ]);
      }
    }
  }, []);

  useEffect(() => {
    if (LAYOUT.listAiImages && LAYOUT.listAiImages?.field_name === name) {
      const aiImages1 = LAYOUT?.listAiImages?.images?.map((value: number) => {
        const list = LAYOUT?.aiImage?.find((data: number, index: number) => {
          return index === value;
        });
        return list;
      });
      const SliderImages: any = [];

      aiImages1?.map((data: any) => {
        SliderImages.push({
          uid: uuidv4(),
          name: `${data?.name}`,
          status: "done",
          url: data?.image_resource_url,
          ai: "aiGenerated",
        });
      });

      setFileList([...fileList, ...SliderImages]);

      const fieldValue = form.getFieldValue(`${name}`);

      const newImageFiles: any = [];

      aiImages1?.map((data: any) => {
        newImageFiles.push({
          aiGenerated: {
            type: "aiGenerated",
            name: `${data?.name}`,
            url: data?.image_resource_url,
          },
        });
      });

      if (fieldValue && fieldValue?.length !== 0) {
        form.setFieldsValue({
          [name]: [...fieldValue, ...newImageFiles],
        });
      } else {
        form.setFieldsValue({
          [name]: [...newImageFiles],
        });
      }
      onChange();
    }
  }, [LAYOUT.listAiImages]);

  useEffect(() => {
    if (layout_id && layoutIdFlag) {
      setFileList([]);
      form.setFieldsValue({ [name]: null });
    }
  }, [layout_id]);

  useEffect(() => {
    setMsg("");
    // setAspectRatio(1 / 1);
    if (
      ["slider-1", "slider-2", "slider-3", "slider-4"]?.includes(sliderImg) &&
      [22]?.includes(layout_id)
    ) {
      setMsg("Required Image Height = 540 & Width = 960");
      // setAspectRatio(960 / 540);
    } else if (["slider"]?.includes(sliderImg) && [55].includes(layout_id)) {
      setMsg("Required Image Height = 540 & Width = 1280");
      // setAspectRatio(1280 / 540);
    } else if (["slider"]?.includes(sliderImg) && [50]?.includes(layout_id)) {
      setMsg("Required Image/GIF Height = 1150 & Width = 1080");
      // setAspectRatio(1080 / 1150);
    } else if ([2]?.includes(layout_id)) {
      setMsg("Required Image Height = 330 & Width = 1280");
      // setAspectRatio(1280 / 330);
    } else if ([11]?.includes(layout_id)) {
      setMsg("Required Image Height = 1160 & Width = 360");
      // setAspectRatio(360 / 1160);
    } else if ([12]?.includes(layout_id)) {
      setMsg("Required Image Height = 490 & Width = 640");
      // setAspectRatio(640 / 490);
    } else if ([14]?.includes(layout_id)) {
      setMsg("Required Image Height = 540 & Width = 1920");
      // setAspectRatio(1920 / 540);
    } else if ([49]?.includes(layout_id)) {
      setMsg("Required Image Height = 1080 & Width = 1280");
      // setAspectRatio(1280 / 1080);
    } else if (
      ["slider"]?.includes(sliderImg) &&
      [32, 33]?.includes(layout_id)
    ) {
      setMsg("Required Image Height = 1080 & Width = 1920");
      // setAspectRatio(1920 / 1080);
    } else if (["slider"]?.includes(sliderImg) && [60]?.includes(layout_id)) {
      setMsg("Required Image/GIF Height = 1080 & Width = 1280");
      // setAspectRatio(1280 / 1080);
    } else if (
      ["slider-1", "slider-2"]?.includes(sliderImg) &&
      [24]?.includes(layout_id)
    ) {
      setMsg("Required Image Height = 960 & Width = 1080");
      // setAspectRatio(1080 / 960);
    } else if (
      ["slider-1", "slider-2", "slider-3"]?.includes(sliderImg) &&
      [25]?.includes(layout_id)
    ) {
      setMsg("Required Image Height = 640 & Width = 1080");
      // setAspectRatio(1080 / 640);
    } else if (
      ["slider"]?.includes(sliderImg) &&
      ([46]?.includes(layout_id) || [42]?.includes(layout_id))
    ) {
      setMsg("Required Image Height = 1920 & Width = 1080");
      // setAspectRatio(1080 / 1920);
    } else if (["slider"]?.includes(sliderImg) && [54]?.includes(layout_id)) {
      setMsg("Required Image Height = 640 & Width = 1080");
      // setAspectRatio(1080 / 640);
    } else if (["slider"]?.includes(sliderImg) && [52]?.includes(layout_id)) {
      setMsg("Required Image/GIF Height = 1280 & Width = 1080");
      // setAspectRatio(1080 / 1280);
    } else if ([51]?.includes(layout_id) || [60]?.includes(layout_id)) {
      setMsg("Required Image/GIF Height = 1080 & Width = 1280");
      // setAspectRatio(1280 / 1080);
    } else if ([68]?.includes(layout_id)) {
      setMsg("Required Image Height = 1080 & Width = 960");
      // setAspectRatio(960 / 1080);
    }
  }, [layout_id]);

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <Col>
        <Form.Item
          className="full-width-dragger"
          name={name}
          label={
            <div>
              {label.charAt(0).toUpperCase() + label.slice(1)}
              {AUTH.checkPlan(CONSTANT.INTERACTIVE_CONTENT_SUPPORT) ? (
                <>
                  <Button
                    type="primary"
                    title="Apply style"
                    onClick={() =>
                      openStyleModal({
                        styles: widgetStyles.slider(),
                        type: name,
                      })
                    }
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faPenNib} />
                  </Button>
                  {/* <Button
                    type="primary"
                    title="Generate AI Image"
                    onClick={() => setApplyGenerateAIImageModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    Generate AI Image
                  </Button> */}
                  <Button
                    type="primary"
                    title="Image Url"
                    onClick={() => setApplyImageUrlModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </Button>
                </>
              ) : (
                <Popover mouseLeaveDelay={0} title={() => planMessage()}>
                  <Button
                    type="primary"
                    title="Apply style"
                    disabled={true}
                    onClick={() =>
                      openStyleModal({
                        styles: widgetStyles.slider(),
                        type: name,
                      })
                    }
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faPenNib} />
                  </Button>
                  {/* <Button
                    type="primary"
                    title="Generate AI Image"
                    disabled={true}
                    onClick={() => setApplyGenerateAIImageModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    Generate AI Image
                  </Button> */}
                  <Button
                    type="primary"
                    title="Image Url"
                    disabled={true}
                    onClick={() => setApplyImageUrlModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </Button>
                </Popover>
              )}
            </div>
          }
          extra={msg}
          rules={LayoutRequest.image}
        >
          {/* <ImgCrop aspect={aspectRatio}> */}
            <Upload
              customRequest={dummyRequest}
              name={name}
              multiple={true}
              listType="picture"
              accept=".png, .jpg, .jpeg, .gif"
              {...eventProps}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          {/* </ImgCrop> */}
        </Form.Item>

        {applyStyleModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <StyleModalComponent
              visible={applyStyleModal}
              close={closeStyleModal}
              styleProps={styleProps}
              setShow={setShow}
              setDataV={setDataV}
              dataV={dataV}
            />
          </Form.Item>
        )}
        {applyGenerateAIImageModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <GenerateAIImage
              type={"multiple"}
              name={name}
              visible={applyGenerateAIImageModal}
              close={() => {
                setApplyGenerateAIImageModal(false);
              }}
            />
          </Form.Item>
        )}
        {applyImageUrlModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <CropImageUrlElement
              visible={applyImageUrlModal}
              onUpload={handleUpload}
              cancel={() => {
                setApplyImageUrlModal(false);
              }}
              // aspectRatio={aspectRatio}
              flagBackgroundImage={true} //flag to bypass the cropping

            />
          </Form.Item>
        )}
      </Col>
      <Modal
        okText="Yes"
        cancelText="No"
        open={visible}
        onOk={() => deleteItem(removeItemData)}
        onCancel={toggle}
        className="removeUploadModal"
      >
        Are you sure you want to permanently remove this Item ?
      </Modal>
    </>
  );
});

export default ImageSliderElement;
