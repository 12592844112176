import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/dark.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const CustomDatePicker = forwardRef((props:any, ref:any) => {
  //   debugger
  const [date, setDate] = useState<any>(null);
  const [picker, setPicker] = useState<any>(null);
  const refFlatPickr = useRef<any>(null);
  const refInput = useRef<any>(null);
  const onDateChanged = (selectedDates:any) => {
    setDate(selectedDates[0]);
    props?.onDateChanged();
  };

  useEffect(() => {
    setPicker(
      flatpickr(refFlatPickr.current, {
        onChange: onDateChanged,
        wrap: true,
        enableTime: true,
        time_24hr: true,
        position:"auto"
      })
  );
  refInput.current?.setAttribute('placeholder', "yyyy-mm-dd HH:mm");
  }, []);

  useEffect(() => {
    if (picker) {
      picker.calendarContainer?.classList.add('ag-custom-component-popup');
    }
  }, [picker]);

  useEffect(() => {
    if (picker) {
      picker?.setDate(date);
    }
  }, [date, picker]);

  useImperativeHandle(ref, () => ({
    getDate() {
      return date;
    },

    setDate(date:any) {
      setDate(date);
    },

    setInputPlaceholder() {
      if (refInput.current) {
        refInput.current?.setAttribute('placeholder', "yyyy-mm-dd HH:mm");
      } 
    },

    setInputAriaLabel(label:any) {
      if (refInput.current) {
        refInput.current?.setAttribute('aria-label', label);
      }
    },
  }));

  return (
    <div
      className="ag-input-wrapper custom-date-filter"
      role="presentation"
      ref={refFlatPickr}
    >
      <input type="text" ref={refInput} data-input style={{ width: '100%' }} />
      <a className="input-button" title="clear" data-clear>
      <FontAwesomeIcon icon={faCircleXmark} />
      </a>
    </div>
  );
});

// Set the display name for better debugging
CustomDatePicker.displayName = "CustomDatePicker";

export default CustomDatePicker;
