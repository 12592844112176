import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Col, Form, FormInstance, Slider } from 'antd'
import { InputBox } from '../../../../components/AntdAddons'

interface formInterface {
    form: FormInstance,
    name: string,
    label: string,
    onChange: () => void,
    setShow?: (data: any) => void

}

const XYValElement: React.FC<formInterface> = observer((props: formInterface) => {
    const { name, onChange, form } = props
    const [label, setLabel] = useState('');

    const setLabelText = () => {
        setLabel(`X Val (${form.getFieldValue('xval')}%) & Y Val (${form.getFieldValue('yval')}%)`)
    }
    useEffect(()=>{
        setLabelText()
    }, [])

    return (
        <Col >
            <Form.Item
                style={{ marginBottom: 0 }}
                name={name}
                label={<div>{label}</div>}
            >
                <Slider onAfterChange={(val) => {
                    form.setFieldValue('yval', 100 - val)
                    setLabelText()
                    onChange()
                }} />
			</Form.Item>
            <InputBox.Text name="yval" hidden={true} />
        </Col>
    )
})

export default XYValElement
