import { Modal, Button, Form, Image, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import no_image from '../../../assets/images/no_image.jpg'
import { deviceRequest } from '../../../requests/PageRequest'
import { Notification, validateFields } from '../../../config/Global'
import useStore from '../../../store'
import { FormBox, InputBox } from '../../../components/AntdAddons'

export type componentProps = {
	visible: boolean | any,
	close: () => void,
	callApi: (data: any) => any,
}

const PublishComponent: React.FC<componentProps> = observer((props: componentProps) => {
    const { visible, close, callApi } = props
    const [disabled, setDisabled] = useState<boolean>(true)
    const { AUTH, DEVICE, ROOT } = useStore()
    const [form] = Form.useForm()
    const [imageD, setImage] = useState(null)
    const [existingSchedule, setExistingSchedule] = useState<boolean>(false)
    const { assignErrorToInput } = ROOT
    const [saving, setSaving] = useState<boolean>(false)

    const handleClose = () => {
        close()
        setExistingSchedule(false)
        form.resetFields()
        setDisabled(true)
    }

    const handleChange = (data?: any) => {
        const finalImage = DEVICE?.layout_list?.find((_x: any) => _x.id === data)?.['global_layout']
        setImage(finalImage)
        validateFields(form, setDisabled);
    }
    useEffect(() => {
        if (existingSchedule) {
            setDisabled(false)
        }
    }, [existingSchedule])
    useEffect(() => {
        setDisabled(false)
        if (visible) {
            setImage(visible?.image_name)
        }
        form.setFieldsValue({
            layout_id: visible.layout_data && visible?.layout_data.id
        })
        DEVICE.layout_list = [{ id: visible.layout_data && visible.layout_data?.id, name: visible.layout_data && visible.layout_data?.name }]

    }, [visible])
    const handleSubmit = (data: any) => {
        data.id = visible?.id
        if (existingSchedule) {
            data.type = 3
        }
        setSaving(true)
        callApi(data).then((data: any) => {
            Notification.success({
                message: data.data
            })
            close()
            setExistingSchedule(false)
            form.resetFields()
        }).catch((e: any) => {
            assignErrorToInput(form, e?.data);
        }).finally(() => {
            setDisabled(true)
            setSaving(false)
        })
    }


    return (
        <div>
            <Modal
                open={visible}
                centered
                className='commanModal'
                onCancel={handleClose}
                title={'Publish Device'}
                footer={[
                    <div key="1">
                        <Button form='publishform' htmlType="submit" className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} disabled={disabled} type="primary" loading={saving}>Publish</Button>
                        <Button className="orangeBgBtn cancelBtn" onClick={handleClose} >Cancel</Button>
                    </div>
                ]}
            >
                <FormBox id='publishform' form={form} onFinish={handleSubmit}>
                    <InputBox.Select
                        showSearch
                        name='layout_id'
                        required
                        rules={deviceRequest.layout}
                        placeholder='Select Layout'
                        filterOption={(input: any, option: any) =>
                            (option?.children || {}).toLowerCase().includes(input.toLowerCase())
                        }
                        label='Select Layout'
                        onChange={(data: any) => handleChange(data)}
                        onFocus={() => DEVICE?.getLayoutList()}
                        options={{
                            list: DEVICE?.layout_list,
                            valueKey: 'id',
                            textKey: 'name'
                        }}
                    >
                    </InputBox.Select>
                    {AUTH.user.company_id !== 5 && <Checkbox name='override' checked={existingSchedule} onChange={(e: any) => {
                        setExistingSchedule(e.target.checked)
                    }}>Override Existing Schedule</Checkbox>}
                    <Form.Item shouldUpdate={(prevValue, currenValue): boolean => prevValue.layout_id !== currenValue.layout_id}>
                        {() => {
                            return (
                                <Image width={300} src={imageD ? require('../../../assets/images/layouts_default_image/' + imageD["image"]) : no_image} />
                            )
                        }}
                    </Form.Item>
                </FormBox>
            </Modal>

        </div>
    )
})

export default PublishComponent