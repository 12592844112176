import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import moment from "moment";
import { displayFormats } from "../../config/Global";

export default class SubscriptionStore {
  PlanList: Array<any> = [];
  list_data: Array<any> = [];
  agGrid: any = null;
  per_page = Config.grid.local.gridOptions?.paginationPageSize;

  constructor() {
    makeAutoObservable(this);
  }

  // Setup grid and set column size to autosize
  onGridChanged = (params: any) => {
    if (params) {
      localStorage.setItem(
        "subscription_token",
        JSON.stringify(params.columnApi.getColumnState())
      );
    }
  };

  setPlanList = () => {
    this.PlanList = [];
  };

  // Setup grid and set column size to autosize
  setupGrid = (params: any) => {
    const columnState = localStorage.getItem("subscription_token");
    if (columnState) {
      params.columnApi.applyColumnState({ state: JSON.parse(columnState) });
    }
    this.agGrid = params;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(Number(page));
    }
  };

  // API Functions
  fetchList = async (): Promise<any> => {
    return await axios
      .get(API_URL.STRIPE.SUBSCRIPTIONLIST)
      .then(({ data }) => {
        data.data &&
          data.data.forEach((item: any) => {
            item.current_period_start = item.current_period_start
              ? moment(item.current_period_start).format(
                  displayFormats.DATE_TIME_FORMAT
                )
              : "N/A";
            item.current_period_end = item.current_period_end
              ? moment(item.current_period_end).format(
                  displayFormats.DATE_TIME_FORMAT
                )
              : "N/A";
            item.price_id = item.price_id ? item.price_id : "N/A";
            item.customer_id = item.customer_id ? item.customer_id : "N/A";
            item.subscription_id = item.subscription_id
              ? item.subscription_id
              : "N/A";
            item.email = item.email ? item.email : "N/A";
            item.plan_name = item.plan_name ? item.plan_name : "N/A";
            item.price = item.price ? item.price : "N/A";
            item.product = item.product ? item.product : "N/A";
            item.status = item.status ? item.status : "N/A";
          });
        this.list_data = data.data;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  planFetchList = async (): Promise<any> => {
    return await axios
      .get(API_URL.SUBSCRIPTION.PlanList)
      .then(({ data }) => {
        this.PlanList = data.data.reverse();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  createSubscription = async (payload: any): Promise<any> => {
    return await axios
      .post(API_URL.STRIPE.SUBSCRIPTION, payload)
      .then(({ data }) => {
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  cancelSubscription = (payload: any) => {
    return axios
      .post(API_URL.STRIPE.CANCEL, payload)
      .then(({ data }) => {
        this.fetchList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };
}
