import { Button, Col, Form, Input, Modal, Row } from 'antd'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import useStore from '../../../../store'
import { componentProps } from '../../../../store/RateStore/RateInterface'
import { ReactComponent as WarningFilled } from "../../../../assets/images/icon/warningOrange.svg"
import { Notification } from '../../../../config/Global'

const PublishLayoutWithoutMedia: React.FC<componentProps> = observer((props: componentProps) => {
    const { visible, close } = props
    const [disabled, setDisabled] = useState<boolean>(true)
    const [saving, setSaving] = useState<boolean>(false)
    const [form] = Form.useForm()
    const { LAYOUT } = useStore()

    const closeModal = () => {
        setDisabled(true)
        close()
        form.resetFields()
    }

    const handleSubmit = (data: any) => {
        setSaving(true)
        data.id = visible.id
        LAYOUT.publishLayoutWithoutMedia(data).then((data: any) => {
            Notification.success({
                message: data.data
            })
            closeModal()
        }).catch((e: any) => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => {
            setSaving(false)
        })
    }

    const handleFieldChange = () => {
        if (form.getFieldsValue().confirm.toLowerCase() === "confirm") {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }

    return (
        <>
            <Modal
                title="Publish Layout without media"
                centered
                open={visible}
                destroyOnClose
                className="commanModal editLicense"
                onCancel={closeModal}
                footer={[
                  
                    <Button key={1} form="publishform" className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} disabled={disabled} loading={saving} htmlType="submit" type="primary">Publish</Button>,
                    <Button key={2} onClick={closeModal} className="orangeBgBtn cancelBtn">Cancel</Button>,
                ]}
            >
                <Form form={form} id="publishform" onFinish={handleSubmit}>
                    {
                        <Row>
                            <Col span={24} className='warningImg'><WarningFilled /></Col>
                            <Col span={24}>
                                {/* <h3>Are you sure you want to publish {visible.name} layout without media</h3> */}
                                <h3>Please make sure you have not changed any images/video in this layout. This function is only for publishing text updates.
                                     If you have changed image/video and doing this then they will get displayed as broken images.
                                      If you are unsure about this then simply publish instead of this.
                                    Sure to be dont change in media!</h3>
                                <Form.Item style={{ marginBottom: 0 }} name="confirm">
                                    <Input placeholder='Type "CONFIRM" Publish' onChange={handleFieldChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                </Form>
            </Modal>
        </>
    )
})

export default PublishLayoutWithoutMedia