import { BreadcrumbConfigProps } from "./InterfacesAndTypes";
import { CONSTANT } from "./Constant";

const defaultBreadcrumbPath = [
	{ name: "Home", link: CONSTANT.DASHBOARD_REDIRECT_PATH },
];

export const AppMasterBreadcrumb: BreadcrumbConfigProps = {
	title: "App Master",
	path: [
		...defaultBreadcrumbPath,
		{ name: "AppMaster", link: "/appmastercontroller" },
	],
};

export const UserBreadcrumb: BreadcrumbConfigProps = {
	title: 'User Management',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'User', link: '/user' }
	]
}

export const RoleBreadcrumb: BreadcrumbConfigProps = {
	title: 'Roles Management',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Roles', link: '/roles' }
	]
}

export const RateBreadcrumb: BreadcrumbConfigProps = {
	title: 'Rate Management',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Rate', link: '/rate' }
	]
}

export const DeviceBreadcrumb: BreadcrumbConfigProps = {
	title: 'Device Management',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Device', link: '/device' }
	]
}

export const UserRoleBreadcrumb: BreadcrumbConfigProps = {
	title: "User Role",
	path: [
		...defaultBreadcrumbPath,
		{ name: "UserRole", link: "/userrolecontroller" },
	],
};

export const CustomerListBreadcrumb: BreadcrumbConfigProps = {
	title: "Company",
	path: [...defaultBreadcrumbPath, { name: "Company", link: "/company" }],
};

export const LayoutBreadcrumb: BreadcrumbConfigProps = {
	title: "Layouts",
	path: [...defaultBreadcrumbPath, { name: "Layout", link: "/layout" }],
};

export const SavedLayoutBreadcrumb: BreadcrumbConfigProps = {
	title: "Saved Layouts",
	path: [...defaultBreadcrumbPath, { name: "Saved Layout", link: "/saved-layout" }],
};

export const GlobalPopupBreadcrumb: BreadcrumbConfigProps = {
	title: "Global Popup",
	path: [...defaultBreadcrumbPath, { name: "Global Popup", link: "/global-popup" }],
};

export const SettingBreadcrumb: BreadcrumbConfigProps = {
	title: "Emergency Popup Settings",
	path: [...defaultBreadcrumbPath, { name: "Settings", link: "/settings" }],
};

export const TokenBreadcrumb: BreadcrumbConfigProps = {
	title: "Tokens Update Settings",
	path: [...defaultBreadcrumbPath, { name: "Tokens", link: "/tokens" }],
};

export const ScheduleBreadcrumb: BreadcrumbConfigProps = {
	title: "Scheduling Event",
	path: [...defaultBreadcrumbPath, { name: "Schedule", link: "/schedule" }],
};

export const DeviceGroupBreadcrumb: BreadcrumbConfigProps = {
	title: 'Group Devices Management',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Group Devices', link: '/group-devices' }
	]
}

export const AuditLogBreadcrumb: BreadcrumbConfigProps = {
	title: 'Audit Log',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Audit Log', link: '/audit-log' }
	]
}

export const UpdateAPKBreadcrumb: BreadcrumbConfigProps = {
	title: "Update APK",
	path: [...defaultBreadcrumbPath, { name: "Update APK", link: "/apk" }],
};


export const SubscribeBreadcrumb: BreadcrumbConfigProps = {
	title: "Subscribe",
	path: [...defaultBreadcrumbPath, { name: "Subscribe", link: "/plan" }],
};

export const AnalyticsLayoutBreadcrumb: BreadcrumbConfigProps = {
	title: 'Groupe By Layouts',
	path: [
		...defaultBreadcrumbPath,
	]
}
export const AnalyticsGroupBreadcrumb: BreadcrumbConfigProps = {
	title: 'Group By Devices',
	path: [
		...defaultBreadcrumbPath,
	]
}

export const SubscriptionListBreadcrumb: BreadcrumbConfigProps = {
	title: 'Subscription List',
	path: [
		...defaultBreadcrumbPath,
	]
}