import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../../../store";
import { dateComparator } from "../../../../../config/Global";
import { Button } from "antd";
import { ReactComponent as ViewIcon } from "../../../../../assets/images/icon/eye.svg";

const ListComponent: React.FC<any> = observer((props: any) => {
  const { ANALYTICS } = useStore();

  const ActionRenderer: React.FC = (prop: any) => {
    return (
      <div className="action-column">
        {"level" in prop.node && (
          <Button
            type="text"
            title={"View"}
            onClick={() => props.openGDrawer(prop.data)}
          >
            <ViewIcon />
          </Button>
        )}
      </div>
    );
  };

  const columnSetup = () => {
    return [
      <AgGridColumn
        key="device_id"
        field="device_id"
        headerName="# ID"
        filter={"agNumberColumnFilter"}
        tooltipField="id"
        maxWidth={80}
      />,

      <AgGridColumn
        key="device_name"
        field="device_name"
        tooltipField="Device Name"
        headerName="Device Name"
        // maxWidth={250}
        minWidth={250}
        width={250}
      />,
      <AgGridColumn
        key="start_datetime"
        field="start_datetime"
        headerName="Start Date & Time"
        filter={"agDateColumnFilter"}
        filterParams={dateComparator}
        tooltipField="Start Date & Time"
        minWidth={100}
      />,
      <AgGridColumn
        key="end_datetime"
        field="end_datetime"
        headerName="End Date & Time"
        filter={"agDateColumnFilter"}
        filterParams={dateComparator}
        tooltipField="End Date & Time"
        minWidth={100}
      />,

      <AgGridColumn
        key="duration"
        field="duration"
        headerName="Duration"
        // filter={"agDateColumnFilter"}
        // filterParams={agDateColumnFilter}
        tooltipField="Duration"
        minWidth={100}
        // aggFunc = 'sum'
        // aggFunc={myCustomSumFunction}
      />,
      <AgGridColumn
        key="action"
        headerName="Actions"
        type="actionColumn"
        sortable={false}
        filter={false}
        maxWidth={250}
        minWidth={100}
        resizable={true}
        pinned="right"
      />,
    ];
  };

  useEffect(() => {
    ANALYTICS.fetchListDevise();
  }, []);
  return (
    <AgGridWrapper
      onGridReady={ANALYTICS.setupGrid}
      components={{ ActionRenderer }}
      rowData={ANALYTICS.list_data_devices}
      // onColumnResized={ANALYTICS.onGridChangedG}
      // onColumnMoved={ANALYTICS.onGridChangedG}
      pinnedBottomRowData={ANALYTICS.pinnedBottomRowDataGroup}
    >
      {columnSetup()}
    </AgGridWrapper>
  );
});

export default ListComponent;
