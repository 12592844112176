import { createContext, useContext } from "react";
import { Context } from "vm";
import AuthStore from "./AuthStore/AuthStore";
import RootStore from "./RootStore/RootStore";
import DashboardStore from "./DashboardStore/DashboardStore";
import UserStore from "./UserRoleStore/UserRoleStore";
import RateStore from "./RateStore/RateStore";
import DeviceStore from "./DeviceStore/DevicesStore";
import SavedLayout from "./SavedLayoutStore/SavedLayoutStore";
import LayoutStore from "./LayoutStore/LayoutStore";
import GlobalPopupStore from "./GlobalPopupStore/GlobalPopupStore";
import RoleStore from "./Role/RoleStore";
import SettingStore from "./SettingStore/SettingStore";
import TokenStore from "./TokenStore/TokenStore";
import ScheduleStore from "./ScheduleStore/ScheduleStore";
import DeviceGroupStore from "./DeviceGroupStore/DeviceGroupStore";
import AuditLogStore from "./AuditLogStore/AuditLogStore";
import UpdateAPKStore from "./UpdateAPKStore/UpdateAPKStore";
import SubscriptionStore from "./SubscriptionStore/SubscriptionStore";
import AnalyticsStore from "./AnalyticsStore/AnalyticsStore";

const AppContext = createContext({
	ROOT: new RootStore(),
	AUTH: new AuthStore(),
	DASHBOARD_STORE: new DashboardStore(),
	USER: new UserStore(),
	RATE: new RateStore(),
	DEVICE: new DeviceStore(),
	LAYOUT: new LayoutStore(),
	SAVED_LAYOUT: new SavedLayout(),
	GLOBAL_POPUP: new GlobalPopupStore(),
	ROLE: new RoleStore(),
	SETTNG_STORE: new SettingStore(),
	TOKENS: new TokenStore(),
	SCHEDULE: new ScheduleStore(),
	DEVICE_GROUP: new DeviceGroupStore(),
	AUDIT_LOG: new AuditLogStore(),
	UPDATE_APK: new UpdateAPKStore(),
	SUBSCRIPTION : new SubscriptionStore(),
	ANALYTICS : new AnalyticsStore()
});

const useStore = (): Context => useContext(AppContext);

export default useStore;
