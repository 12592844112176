import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
// import '@fullcalendar/core/main.css'
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';
// import '@fullcalendar/list/main.css';
import useStore from "../../../../store";
import moment from "moment";
import { Tooltip } from "antd";
import { ListScheduleProps } from "../../../../store/ScheduleStore/ScheduleInterface";

const Calandercomponent: React.FC<ListScheduleProps> = (
  props: ListScheduleProps
) => {
  const { SCHEDULE } = useStore();
  const { setStartDate, setEndDate, id, events, setEvents } = props;

  function renderEventContent(eventInfo: any) {
    return (
      <div>
        {eventInfo.event.allDay ? (
          <Tooltip
            title={eventInfo.event.extendedProps.description}
          >
            <p>{eventInfo.event.title}</p>
          </Tooltip>
        ) : (
          <Tooltip
            title={eventInfo.event.extendedProps.description}
            placement="top"
          >
            <p>{eventInfo.timeText}</p>
          </Tooltip>
        )}
      </div>
    );
  }

  const datesSet = (data: any) => {
    setStartDate(data.startStr);
    setEndDate(data.endStr);
    const startDate = moment(data.startStr).format("YYYY-MM-DD");
    const endDate = moment(data.endStr).format("YYYY-MM-DD");

    if (id) {
      const payload = {
        device_id: id,
        start_date: startDate,
        end_date: endDate,
      };
      SCHEDULE?.listDeviceSchedule(payload).then((data: any) => {
        setEvents(data);
      });
    }
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin]}
        headerToolbar={{
          left: "title",
          right: "prev,next,today timeGridWeek",
        }}
        initialView="timeGridWeek"
        events={events}
        eventContent={renderEventContent}
        datesSet={datesSet}
      />
    </>
  );
};
export default Calandercomponent;
