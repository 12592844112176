import React from 'react'
import { Col, FormInstance, Row } from 'antd';
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { RateRequest } from '../../../../requests/PageRequest';
import Config from '../../../../config/Config';

interface formInterface {
	form: FormInstance,
	handleSubmit: (data: any) => void,
	onChange: () => void
}

const FormComponent: React.FC<formInterface> = observer((props: formInterface) => {
	const { form, handleSubmit, onChange } = props

	return (
		<FormBox id={'addEditRateForm'} form={form} onFinish={handleSubmit} onChange={onChange}>
			<Row gutter={Config.gutter}>
				<Col span={24}>
					<InputBox.Text
						name="item"
						onChange={onChange}
						label="Item Name"
						placeholder="Enter Item Name"
						rules={RateRequest.item_name}
					/>
				</Col>
				<Col span={24}>
					<InputBox.Text
						name="rates"
						onChange={onChange}
						label="Rate"
						placeholder="Enter Rate"
						rules={RateRequest.rate}
					/>
				</Col>
			</Row>
		</FormBox>
	)
})

export default FormComponent;