import React from "react";
import { observer } from "mobx-react";
import { PageHeader } from "antd";
import { SubscriptionListBreadcrumb } from "../../../config/BreadcrumbConfig";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";
import RecordPerPage from "../../../components/AgGridWrapper/RecordPerPage";
import useStore from "../../../store";
import ListComponent from "./components/ListComponent";

const SubscriptionList: React.FC = observer(() => {
  const { SUBSCRIPTION } = useStore();

  return (
    <>
      <div className="header">
        <PageHeader
          title={SubscriptionListBreadcrumb.title}
          tags={<BreadcrumbComponent items={SubscriptionListBreadcrumb.path} />}
          extra={[
            <RecordPerPage
              key="2"
              defaultValue={SUBSCRIPTION.per_page + " per page"}
              onChange={SUBSCRIPTION.setPageSize}
            />,
          ]}
        >
          <ListComponent />
        </PageHeader>
      </div>
    </>
  );
});

export default SubscriptionList;
