import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../../store";
import Button from "antd/es/button";
import { ReactComponent as EditIcon } from "../../../../assets/images/icon/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/icon/delete.svg";
import { ReactComponent as AddLicenseIcon } from "../../../../assets/images/icon/addLicense.svg";
import { ReactComponent as EditLicenseIcon } from "../../../../assets/images/icon/editLicense.svg";
import { ReactComponent as ProxyLoginIcon } from "../../../../assets/images/icon/ProxyLoginIcon.svg";
import { ReactComponent as LayoutPermission } from "../../../../assets/images/icon/newlayouricon.svg";
import { ReactComponent as ModifyPermission } from "../../../../assets/images/icon/Modify Permission.svg";

//Layout Permission icon

import { observer } from "mobx-react";
import { Popover, Switch, Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import { CONSTANT } from "../../../../config/Constant";
import { planMessage, statusFilter } from "../../../../config/Global";
import { setCustomColumnFilter } from "../../../../config/Config";

const ListComponent: React.FC<any> = observer((props) => {
  const {
    openEditModal,
    openDeleteModal,
    addLicenseD,
    editLicenseD,
    handleProxyLogin,
    openSwitchModal,
    openPermissionD,
    openLayPermissionD,
    openDevisePermissionD,
  } = props;
  const { USER, AUTH } = useStore();
  const location = useLocation();
  const flag = location.search.slice(1);
  const cellStyle = {
    textAlign: "center",
  };
  // const RightSide = {
  //   textAlign: "right",
  // };

  const ActionRenderer: React.FC = (props: any) => {
    return (
      <div className="action-column">
        {props.data.id !== AUTH.user.id &&
          (AUTH.user.company_id
            ? [CONSTANT.Company].includes(props.data.users_roles_id)
              ? false
              : true
            : true) && (
            <>
              {AUTH.checkPermission(CONSTANT.SubAdmin, "edit") && (
                <Button
                  type="text"
                  title={"Edit"}
                  className="editIcon"
                  onClick={() => openEditModal(props.data)}
                >
                  <EditIcon />
                </Button>
              )}
              {AUTH.checkPermission(CONSTANT.SubAdmin, "delete") && (
                <Button
                  type="text"
                  onClick={() => openDeleteModal(props.data)}
                  title={"Delete"}
                >
                  <DeleteIcon />
                </Button>
              )}
              {AUTH.checkPermission(CONSTANT.SubAdmin, "Add License") &&
                [CONSTANT.Company].includes(props.data.users_roles_id) &&
                [CONSTANT.SuperAdmin, CONSTANT.SubAdmin].includes(
                  AUTH.user.users_roles_id
                ) && (
                  <Button
                    className="ant-btn-text"
                    title="Add License"
                    onClick={() => addLicenseD(props.data)}
                  >
                    <AddLicenseIcon />
                  </Button>
                )}
              {[CONSTANT.SuperAdmin, CONSTANT.SubAdmin].includes(
                AUTH.user.users_roles_id
              ) &&
                ![CONSTANT.SuperAdmin].includes(props.data.users_roles_id) && (
                  <Button
                    // disabled={[1].includes(props.data.users_roles_id) ? true : false}
                    title={"Proxy login"}
                    onClick={() => handleProxyLogin(props.data)}
                    type="link"
                  >
                    <ProxyLoginIcon />
                  </Button>
                )}
              {AUTH.checkPermission(CONSTANT.SubAdmin, "Edit License") &&
                [CONSTANT.Company].includes(props.data.users_roles_id) &&
                [CONSTANT.SuperAdmin, CONSTANT.SubAdmin].includes(
                  AUTH.user.users_roles_id
                ) &&
                props.data?.license.length > 0 && (
                  <Button
                    className="ant-btn-text"
                    title="Edit License"
                    onClick={() => editLicenseD(props.data)}
                  >
                    <EditLicenseIcon />
                  </Button>
                )}
              {AUTH.checkPermission(CONSTANT.SubAdmin, "Modify Permission") && (
                <Button
                  title={"Modify Permission"}
                  onClick={() => openPermissionD(props.data)}
                  type="link"
                >
                  <ModifyPermission />
                </Button>
              )}
              {[CONSTANT.Company].includes(props.data.users_roles_id) &&
                !AUTH.user.company_id && (
                  <Button
                    title={"layout Permission"}
                    onClick={() => openLayPermissionD(props.data)}
                    type="link"
                  >
                    <LayoutPermission />
                  </Button>
                )}
              {![
                CONSTANT.SuperAdmin,
                CONSTANT.SubAdmin,
                CONSTANT.Company,
                CONSTANT.Partner,
              ].includes(props.data.users_roles_id) &&
              [CONSTANT.Company].includes(AUTH.user.users_roles_id) ? (
                AUTH.checkPlan(CONSTANT.DEVICES_SPECIFIC_ACCESS) ? (
                  <Button
                    title={"Device Permission"}
                    onClick={() => openDevisePermissionD(props.data)}
                    type="link"
                  >
                    <LayoutPermission />
                  </Button>
                ) : (
                  <Popover title={() => planMessage("Device Permission")}>
                    <Button
                      title={"Device Permission"}
                      onClick={() => openDevisePermissionD(props.data)}
                      type="link"
                    >
                      <LayoutPermission />
                    </Button>
                  </Popover>
                )
              ) : null}
            </>
          )}
      </div>
    );
  };
  const filterParams = {
    defaultToNothingSelected: true,
  };

  const cellRenderData: any = (data: any) => {
    if (AUTH.user.company_id) {
      if ([CONSTANT.Company].includes(AUTH.user.users_roles_id)) {
        return ![CONSTANT.Company].includes(data.data.users_roles_id) &&
          AUTH.checkPermission(CONSTANT.SubAdmin, "status") ? (
          <Tooltip mouseEnterDelay={0} mouseLeaveDelay={0}>
            <Switch
              checked={data.data.status}
              onChange={() => openSwitchModal(data.data)}
            />
          </Tooltip>
        ) : (
          AUTH.checkPermission(CONSTANT.SubAdmin, "status") && (
            <Tooltip
              color="red"
              title={"Sorry, you cannot Deactivate this User"}
            >
              <Switch
                disabled
                checked={data.data.status}
                onChange={() => openSwitchModal(data.data)}
              />
            </Tooltip>
          )
        );
      } else {
        return ![CONSTANT.Company].includes(data.data.users_roles_id) &&
          AUTH.user.id !== data.data.id &&
          AUTH.checkPermission(CONSTANT.SubAdmin, "status") ? (
          <Tooltip mouseEnterDelay={0} mouseLeaveDelay={0}>
            <Switch
              checked={data.data.status}
              onChange={() => openSwitchModal(data.data)}
            />
          </Tooltip>
        ) : (
          AUTH.checkPermission(CONSTANT.SubAdmin, "status") && (
            <Tooltip
              color="red"
              title={"Sorry, you cannot Deactivate this User"}
            >
              <Switch
                disabled
                checked={data.data.status}
                onChange={() => openSwitchModal(data.data)}
              />
            </Tooltip>
          )
        );
      }
    } else {
      return [CONSTANT.SuperAdmin, CONSTANT.Employee].includes(
        data.data.users_roles_id
      ) ||
        ([CONSTANT.SubAdmin, CONSTANT.Company].includes(
          AUTH.user.users_roles_id
        ) &&
          AUTH.checkPermission(CONSTANT.SubAdmin, "status")) ? (
        <Tooltip
          color="red"
          title={
            [CONSTANT.SuperAdmin, CONSTANT.SubAdmin, CONSTANT.Company].includes(
              AUTH.user.users_roles_id
            ) && "Sorry, you cannot Deactivate this User"
          }
        >
          <Switch
            disabled
            checked={data.data.status}
            onChange={() => openSwitchModal(data.data)}
          />
        </Tooltip>
      ) : (
        AUTH.checkPermission(CONSTANT.SubAdmin, "status") && (
          <Tooltip>
            <Switch
              checked={data.data.status}
              onChange={() => openSwitchModal(data.data)}
            />
          </Tooltip>
        )
      );
    }
  };
  const columnSetup = () => {
    return [
      <AgGridColumn
        key="id"
        field="id"
        tooltipField="id"
        headerName="# ID"
        filter={"agNumberColumnFilter"}
        // pinned="left"
        // pinned={width > 769 ? "left" : ""}
        maxWidth={80}
        minWidth={80}
        width={80}
        cellStyle={cellStyle}
      />,
      <AgGridColumn
        key="name"
        field="name"
        tooltipField="name"
        headerName="Name"
        // maxWidth={250}
        minWidth={250}
        width={250}
      />,
      <AgGridColumn
        key="email"
        field="email"
        headerName="Email"
        tooltipField="email"
        // maxWidth={250}
        minWidth={250}
        width={250}
      />,
      <AgGridColumn
        key="Role"
        field="role.role_name"
        headerName="Role"
        tooltipField="role.role_name"
        filter={"agTextColumnFilter"}
        filterParams={filterParams}
        maxWidth={180}
      />,

      <AgGridColumn
        key="company_name"
        field="company_name"
        headerName="Company Name"
        tooltipField="company_name"
        // filter={"agSetColumnFilter"}
        // filterParams={filterParams}
        maxWidth={180}
      />,

      // <AgGridColumn //company_name
      //   key="date"
      //   headerName="Expiration Date"
      //   field="date"
      //   filter={"agDateColumnFilter"}
      //   filterParams={agDateColumnFilter}
      //   cellStyle={RightSide}
      //   maxWidth={180}
      // />,
      // <AgGridColumn
      //   key="plan_name"
      //   field="plan_name"
      //   headerName="Plan Name"
      //   tooltipField="plan_name"
      //   filter={"agSetColumnFilter"}
      //   filterParams={filterParams}
      //   maxWidth={180}
      // />,
      // <AgGridColumn
      //   key="price"
      //   field="price"
      //   headerName="Price"
      //   tooltipField="price"
      //   filter={"agSetColumnFilter"}
      //   filterParams={filterParams}
      //   maxWidth={180}
      // />,
      // <AgGridColumn
      //   key="current_period_start"
      //   headerName="Subs Period Start Date"
      //   field="current_period_start"
      //   filter={"agDateColumnFilter"}
      //   filterParams={agDateColumnFilter}
      //   cellStyle={RightSide}
      //   maxWidth={180}
      // />,
      // <AgGridColumn
      //   key="current_period_end"
      //   headerName="Subs Period End Date"
      //   field="current_period_end"
      //   filter={"agDateColumnFilter"}
      //   filterParams={agDateColumnFilter}
      //   cellStyle={RightSide}
      //   maxWidth={180}
      // />,
      <AgGridColumn
        key={"is_active_status"}
        headerName="Status"
        field="is_active_status"
        sortable={false}
        cellStyle={cellStyle}
        maxWidth={120}
        filter="agTextColumnFilter"
        filterParams={setCustomColumnFilter(statusFilter)}
        cellRendererFramework={cellRenderData}
      />,
      <AgGridColumn
        key="action"
        headerName="Actions"
        type="actionColumn"
        sortable={false}
        filter={false}
        maxWidth={250}
        minWidth={250}
        resizable={true}
        pinned="right"
      />,
    ];
  };

  useEffect(() => {
    USER.fetchList(flag);
  }, []);
  // const gridRef = useRef();
  return (
    <AgGridWrapper
      onGridReady={USER.setupGrid}
      components={{ ActionRenderer }}
      rowData={USER.list_data}
      onColumnResized={USER.onGridChanged}
      onColumnMoved={USER.onGridChanged}
    >
      {columnSetup()}
    </AgGridWrapper>
  );
});

export default ListComponent;
