import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Checkbox, Col, Drawer, Form, Row } from "antd";
import useStore from "../../../../store";
import { FormBox } from "../../../../components/AntdAddons";
import Config from "../../../../config/Config";
import { DevicePermissionsProps } from "../../../../store/CustmerStore/CustomerInterface";
import { Notification } from "../../../../config/Global";
import { ErrorProps } from "../../../../store/RootStore/RootInterface";

const DevisePermissionComponent: React.FC<DevicePermissionsProps> = observer(
  (props: DevicePermissionsProps) => {
    const { USER, ROOT, DEVICE } = useStore();
    const { close, visible, deviceId } = props;
    const [form] = Form.useForm();
    const [saving, setSaving] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [deviceOptions, setDeviceOptions] = useState<any>([]);

    useEffect(() => {
      if (visible) {
        DEVICE.fetchList();
      }
    }, [visible]);

    // for modify data for the options
    useEffect(() => {
      if (visible && DEVICE?.list_data) {
        const optionsData = DEVICE.list_data.map((column: any) => {
          return { name: column.name, id: column.id };
        });
        setDeviceOptions(optionsData);
        if (DEVICE?.list_data.length > 0) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
    }, [DEVICE?.list_data]);

    useEffect(() => {
      if (
        DEVICE.list_data?.length > 0 &&
        USER.selectedDeviceIds?.length > 0 &&
        DEVICE.list_data?.length === USER.selectedDeviceIds?.length
      ) {
        form.setFieldsValue({
          assign_all: true,
          permission: USER.selectedDeviceIds,
          selectLIds: [],
          selectPIds: [],
        });
      } else {
        form.setFieldsValue({
          assign_all: false,
          permission: USER.selectedDeviceIds,
        });
      }
    }, [DEVICE.list_data, USER.selectedDeviceIds]);

    const handleSubmit = (data: any) => {
      delete data.assign_all;

      USER.devisePermission(deviceId, data)
        .then((data: any) => {
          setSaving(false);
          Notification.success({
            message: data.data.message,
          });
          handelClose();
        })
        .catch((e: ErrorProps) => {
          ROOT.assignErrorToInput(form, e?.data.errors);
        })
        .finally(() => setSaving(false));
    };

    const handelClose = () => {
      form.resetFields();
      close();
      USER.setSelectedDeviceIds([]);
      USER.fetchList();
    };

    //for options selections
    const onLayoutCheckAllChange = (e: any) => {
      form.setFieldsValue({
        assign_all: e.target.checked,
        permission: e.target.checked
          ? DEVICE.list_data.map((column: any) => {
              return column.id;
            })
          : [],
      });
    };

    return (
      <Drawer
        open={visible}
        onClose={handelClose}
        width={750}
        title={`Device Permissions`}
        className={`commanDrawer editUser ${DEVICE?.list_data && DEVICE?.list_data.length === 0 ? `alignC` : ``}`}
        destroyOnClose={true}
        footer={[
          <div key="1">
            <Button
              //   className={`${disabled ? "disableBtn" : "blackLineBtn"}`}
              disabled={disabled}
              form="layoutPermission"
              loading={saving}
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
            <Button className="orangeBgBtn cancelBtn" onClick={handelClose}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        {DEVICE?.list_data && DEVICE?.list_data.length === 0 ? (
          <h2>No Devices Found</h2>
        ) : (
          <FormBox id={"layoutPermission"} form={form} onFinish={handleSubmit}>
            <Row gutter={Config.gutter}>
              <Col span={24}>
                <Form.Item
                  name="assign_all"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: "Please check the checkbox",
                    },
                  ]}
                >
                  <Checkbox
                    onChange={(e) => {
                      onLayoutCheckAllChange(e);
                    }}
                  >
                    Assign All Device
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="permission">
                  <Checkbox.Group
                    className="layout_perm"
                    options={
                      deviceOptions &&
                      deviceOptions?.map((item1: any) => {
                        return {
                          label: item1.name,
                          value: item1.id,
                        };
                      })
                    }
                    // disabled={allAssign}
                    onChange={(e) => {
                      if (deviceOptions.length === e.length) {
                        form.setFieldsValue({ assign_all: true });
                      } else {
                        form.setFieldsValue({ assign_all: false });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </FormBox>
        )}
      </Drawer>
    );
  }
);

export default DevisePermissionComponent;
