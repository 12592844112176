import React from "react";
import { Popover, Radio } from "antd";
import {
  defaultOptionKeys,
  defaultSeparator,
  InputWrapper,
  SplitInputWrapperProps,
} from "../../functions";
import { RadioInputBoxProps } from "./interface";
import { planMessage } from "../../../../config/Global";

const RadioInputBox: React.FC<RadioInputBoxProps> = (props) => {
  const { formProps, inputProps } = SplitInputWrapperProps(props);
  const { options, type, buttonStyle = "solid", ...rest } = inputProps;

  const valueKey = options?.valueKey || defaultOptionKeys.value;
  const textKey = options?.textKey || defaultOptionKeys.text;
  const separator = options?.separator || defaultSeparator;
  let textKeys: any[] = [];
  if (Array.isArray(options?.textKey)) {
    textKeys = options?.textKey;
  }

  const renderOptions = options?.list?.map((option: any) => {
    if (
      options?.rejectedValues &&
      options?.rejectedValues.includes(option[valueKey]) &&
      !options?.requiredValues.includes(option[valueKey])
    ) {
      return null;
    }

    let disabled_keys: any = false;
    if (
      options &&
      options.disabled_keys &&
      options.disabled_keys.includes(option.id)
    ) {
      disabled_keys = true;
    } else {
      disabled_keys = false;
    }

    const renderOptionText =
      textKeys.length <= 0
        ? option[textKey]
        : textKeys
            .map((text_key) => option[text_key] || text_key)
            .join(separator);
    if (type === "button") {
      return disabled_keys ? (
        <Popover title={() => planMessage()}>
          <Radio.Button
            key={option[valueKey]}
            value={option[valueKey]}
            disabled={disabled_keys}
          >
            {renderOptionText}
          </Radio.Button>{" "}
        </Popover>
      ) : (
        <Radio.Button
          key={option[valueKey]}
          value={option[valueKey]}
          disabled={disabled_keys}
        >
          {renderOptionText}
        </Radio.Button>
      );
    } else {
      return disabled_keys ? (
        <Popover title={() => planMessage()} >
          <Radio
            key={option[valueKey]}
            value={option[valueKey]}
            disabled={disabled_keys}
          >
            {renderOptionText}
          </Radio>
        </Popover>
      ) : (
        <Radio
          key={option[valueKey]}
          value={option[valueKey]}
          disabled={disabled_keys}
        >
          {renderOptionText}
        </Radio>
      );
    }
  });

  return (
    <InputWrapper {...formProps}>
      <Radio.Group {...rest} buttonStyle={buttonStyle}>
        {renderOptions}
      </Radio.Group>
    </InputWrapper>
  );
};

export default RadioInputBox;
