import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class GlobalPopupStore {
    readData: any = null

    constructor() {
        makeAutoObservable(this)
    }
    getPopupdata = () => {
        return axios.post(API_URL.GLOBAL_POPUP.READ).then(({ data }) => {
            this.readData = data.data
        }).catch((response) => {
            return Promise.reject(response)
        })
    }

    globalPopupupdate = (data: any) => {
        return axios.post(API_URL.GLOBAL_POPUP.UPDATE, data).then(({ data }) => {
            return data
        }).catch((e: any) => {
            return Promise.reject(e)
        })
    }

    globalPopuppublishTiming = () => {
        return axios.post(API_URL.GLOBAL_POPUP.PUBLISH_TIMING).then(({ data }) => {
            return data
        }).catch((e: any) => {
            return Promise.reject(e)
        })
    }

    globalPopuppublishTv = () => {
        return axios.post(API_URL.GLOBAL_POPUP.PUBLISH_ALLTV).then(({ data }) => {
            return data
        }).catch((e: any) => {
            return Promise.reject(e)
        })
    }

}