import React, { useState } from "react"
import { observer } from "mobx-react"
import { Button, PageHeader, Tooltip } from "antd"
import BreadcrumbComponent from "../../../components/BreadcrumbComponent"
import { LayoutBreadcrumb } from "../../../config/BreadcrumbConfig"
import useStore from "../../../store"
import Unauthorized from "../../errors/Unauthorized"
import RecordPerPage from "../../../components/AgGridWrapper/RecordPerPage"
import ListComponent from "./components/ListComponent"
import AddComponent from "./components/AddComponent"
import PublishLayout from "./components/PublishLayout"
import PublishLayoutWithoutMedia from "./components/PublishWithoutMedia"
import EditComponent from "./components/EditComponent"
import DeleteComponent from "./components/DeleteComponent"
import SavedLayoutComponent from "./components/SavedLayoutComponent"
import { CONSTANT } from "../../../config/Constant"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons"

const LayoutManagement: React.FC = observer(() => {
	const { AUTH, LAYOUT } = useStore()
	const [addDrawer, setAddDrawer] = useState<boolean>(false)
	const [editDrawer, setEditDrawer] = useState<boolean>(false)
	const [deleteDrawer, setDeleteDrawer] = useState<boolean>(false)
	const [publishLayout, setPublishLayout] = useState<any>(false)
	const [publishLayoutWithoutMedia, setPublishLayoutWithoutMedia] = useState<boolean>(false)
	const [savedLayout, setSavedLayout] = useState<boolean>(false)
	const [filterModal, setFilterModal] = useState<any>({})


	const closeAddDrawer = () => {
		setAddDrawer(false)
	}

	const openEditDrawer = (data: any) => {
		LAYOUT.ReadData(data.id)
		setEditDrawer(data)
	}

	const closeEditDrawer = () => {
		LAYOUT.setEditValues(null)
		setEditDrawer(false)
	}

	const openPreviewDrawer = (data: any) => {
		LAYOUT.Previewlayout(data.id).then((data: any) => {
			if (data.data.pdf_url) {
				window.open("")?.document.write(data.data.pdf_url)
			} else {
				window.open(data.data.layout_html_file_url)
			}
		})
	}

	const openDeleteDrawer = (data: any) => {
		LAYOUT.ReadData(data.id)
		setDeleteDrawer(true)
	}

	const closeDeleteDrawer = () => {
		setDeleteDrawer(false)
	}

	const openPublishLayout = (data: any) => {
		setPublishLayout(data)
	}

	const closePublishLayout = () => {
		setPublishLayout(false)
	}

	const openPublishLayoutWithoutMedia = (data: any) => {
		setPublishLayoutWithoutMedia(data)
	}

	const closePublishLayoutWithoutMedia = () => {
		setPublishLayoutWithoutMedia(false)
	}

	const openSavedLayout = (data: any) => {
		setSavedLayout(data)
	}

	const closeSavedLayout = () => {
		setSavedLayout(false)
	}

	const resetFilter = () => {
		LAYOUT?.agGrid?.api.setFilterModel(null);
		LAYOUT?.agGrid?.api.onFilterChanged();
	  };

	return (
		<>
			{!AUTH.checkPermission(CONSTANT.Employee, 'list') ? (<Unauthorized />) :
				<PageHeader
					title={LayoutBreadcrumb.title}
					tags={<BreadcrumbComponent items={LayoutBreadcrumb.path} />}
					extra={[
						(Object.keys(filterModal)?.length!==0) &&<Tooltip title={"Clear All Filters"}><Button key='1' type="primary" className='mr-10' 
							onClick={resetFilter}><FontAwesomeIcon icon={faFilterCircleXmark}/></Button></Tooltip>,
						((AUTH.checkPermission(4, 'add') && AUTH?.user?.company_id) && 
						<Button key='2' type="primary" className='mr-10' data-tut="reactour__add_layout" onClick={() => {
							setAddDrawer(true);
							LAYOUT.styleProps = null;
						}}>Add Layout</Button>),
						<RecordPerPage key="3" defaultValue={LAYOUT.per_page + ' per page'} onChange={LAYOUT.setPageSize} />,
					]}
				>
					<ListComponent
						openPreviewDrawer={openPreviewDrawer}
						openEditModal={openEditDrawer}
						openDeleteModal={openDeleteDrawer}
						publishLayout={openPublishLayout}
						publishLayoutWithoutMedia={openPublishLayoutWithoutMedia}
						savedLayout={openSavedLayout}
						setFilterModal ={setFilterModal}
					/>
					<AddComponent visible={addDrawer} close={closeAddDrawer} />
					<EditComponent visible={editDrawer} close={closeEditDrawer} />
					<PublishLayout visible={publishLayout} close={closePublishLayout} />
					<PublishLayoutWithoutMedia visible={publishLayoutWithoutMedia} close={closePublishLayoutWithoutMedia} />
					<DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
					<SavedLayoutComponent visible={savedLayout} close={closeSavedLayout} />
				</PageHeader>
			}
		</>
	)
})

export default LayoutManagement