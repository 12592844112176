import { Button, Col, Drawer, Form, Image, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import Config from "../../../../config/Config";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { observer } from "mobx-react";
import useStore from "../../../../store";
import { validateFields } from "../../../../config/Global";

interface formInterface {
  visible: boolean;
  close: () => void;
  type: string;
  name: string;
}

const GenerateAIImage: React.FC<formInterface> = observer((props) => {
  const { visible, close, type, name } = props;
  const [disabled, setDisabled] = useState<boolean>(true);
  const [disableGenerate, setDisableGenerate] = useState<boolean>(true);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<any>([]);
  const { LAYOUT } = useStore();

  const [form] = Form.useForm();

  const handleSubmit = () => {
    LAYOUT.setListAiImages({ images: [...selectedImages], field_name: name });
    LAYOUT.setNewAiImageList(null);
    form.resetFields();
    close();
  };

  useEffect(() => {
    if (selectedImages.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedImages]);

  const resolutionList = [
    { id: 1, name: "1024x1024" },
    { id: 2, name: "512x512" },
    { id: 3, name: "256x256" },
  ];

  // const providersList = [
  //   { id: 1, value: "replicate", name:"Replicate"  },
  //   { id: 2, value: "openai", name:"Open Ai" },
  //   { id: 3, value: "deepai", name:"Deep Ai" },
  // ];

  const handleDataSubmit = (payload: any) => {
    setImageLoading(true);

    payload["providers"] = "replicate";

    payload["num_images"] = 2;

    const formDataValue = JSON.stringify(payload);

    LAYOUT.LayoutAi(formDataValue).finally(() => {
      setImageLoading(false);
    });
  };

  useEffect(() => {
    //
  }, [LAYOUT.aiImage]);

  const handleDataChange = () => {
    validateFields(form, setDisableGenerate);
    setDisableGenerate(true);
  };

  const onReset = () => {
    form.resetFields();
    handleDataChange();
    LAYOUT.setNewAiImageList(null);
    setSelectedImages([]);
  };

  return (
    <Drawer
      open={visible}
      onClose={() => {
        LAYOUT.setNewAiImageList(null);
        close();
      }}
      width={"40%"}
      title={`Generate AI Image`}
      className="commanDrawer"
      destroyOnClose={true}
      footer={[
        <div key="1">
          <Button
            form="selectedAiImageForm"
            type="primary"
            className={`${disabled ? "disableBtn" : "blackLineBtn"}`}
            disabled={disabled}
            onClick={handleSubmit}
          >
            Apply
          </Button>
          <Button
            className="orangeBgBtn cancelBtn"
            onClick={() => {
              LAYOUT.setNewAiImageList(null);
              close();
            }}
          >
            Cancel
          </Button>
        </div>,
      ]}
    >
      <FormBox
        form={form}
        id="generateAiImageForm"
        onFinish={handleDataSubmit}
        onValuesChange={handleDataChange}
      >
        <>
          <Row gutter={Config.gutter}>
            <Col span={24}>
              <InputBox.Text
                label={"Description"}
                required
                placeholder="Describe the image"
                onChange={handleDataChange}
                name={`text`}
                rules={LayoutRequest.description}
              />
            </Col>
            <Col span={24}>
              <InputBox.Select
                showSearch
                name="resolution"
                required
                rules={LayoutRequest.resolution}
                onChange={handleDataChange}
                placeholder="Select Resolution"
                filterOption={(input: any, option: any) =>
                  (option?.children || {})
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                label="Resolution"
                options={{
                  list: resolutionList,
                  valueKey: "name",
                  textKey: "name",
                }}
              ></InputBox.Select>
            </Col>
            {/* <Col span={24}>
              <InputBox.Select
                showSearch
                name="providers"
                required
                placeholder="Select providers"
                filterOption={(input: any, option: any) =>
                  (option?.children || {})
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                label="Providers"
                options={{
                  list: providersList,
                  valueKey: "value",
                  textKey: "name",
                }}
              ></InputBox.Select>
            </Col> */}
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                form="generateAiImageForm"
                disabled={disableGenerate}
                className={`${disableGenerate ? "disableBtn" : "blackLineBtn"}`}
                title="Generate"
              >
                Generate
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                className={`blackLineBtn`}
                title="Reset"
                onClick={onReset}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </>
      </FormBox>
      <FormBox id="selectedAiImageForm">
        <Row gutter={Config.gutter} className="generatedImages">
          {!imageLoading ? (
            LAYOUT.newAiImageList ? (
              LAYOUT.newAiImageList?.map((data: any, index: number) => {
                return (
                  <Col md={12} sm={24} key={index} className="column">
                    <div className="container">
                      <div className="checkboxAi">
                        <InputBox.Checkbox
                          name="checkboxAi"
                          checked={
                            selectedImages.includes(index) ? true : false
                          }
                          onChange={(e: any) => {
                            const answer = e.target.checked;
                            if (answer) {
                              setDisabled(false);
                              if (type === "single") {
                                setSelectedImages([index]);
                              } else {
                                setSelectedImages([...selectedImages, index]);
                              }
                            } else {
                              const images = [...selectedImages];
                              const newImages = images.filter((item) => {
                                return item !== index;
                              });
                              setSelectedImages([...newImages]);
                            }
                          }}
                        />
                      </div>
                      <div className="imageAI">
                        <Image
                          src={`${data.image_resource_url}`}
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <p>No Images to show</p>
            )
          ) : (
            <div className="spin">
              <Spin />
            </div>
          )}
        </Row>
      </FormBox>
    </Drawer>
  );
});

export default GenerateAIImage;
