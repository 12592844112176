import React from 'react';
import { observer } from 'mobx-react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }: any) => <div>{text}</div>;
interface MarkerData {
	lat: number;
	lng: number;
	text: string;
}

const MapCheck: React.FC = observer(() => {

	const markers: MarkerData[] = [
		{ lat: 37.7749, lng: -122.4194, text: 'Marker 1' },
		{ lat: 37.7913, lng: -122.4374, text: 'Marker 2' },
		{ lat: 37.7732, lng: -122.4653, text: 'Marker 3' },
		// Add more markers as needed
	];

	const calculateCenter = (markers: MarkerData[]) => {
		const totalMarkers = markers.length;
		const centerLat = markers.reduce((sum, marker) => sum + marker.lat, 0) / totalMarkers;
		const centerLng = markers.reduce((sum, marker) => sum + marker.lng, 0) / totalMarkers;
		return { lat: centerLat, lng: centerLng };
	};

	const mapProps = {
		center: calculateCenter(markers),
		zoom: 10, // Adjust the zoom level as needed
	};

	return (
		<>
			<div className='header'>
				<div style={{ height: '500px', width: '100%' }}>
					<GoogleMapReact
						bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY as string }}
						defaultCenter={mapProps.center}
						defaultZoom={mapProps.zoom}
					>
						{markers.map((marker, index) => (
							<AnyReactComponent
								key={index}
								lat={marker.lat}
								lng={marker.lng}
								text={marker.text}
							/>
						))}
					</GoogleMapReact>
				</div>
			</div>
		</>
	)
})

export default MapCheck