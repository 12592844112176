import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "antd";
import RightTick from "../../../../assets/images/icon/blue-tick.png";
import CrossTick from "../../../../assets/images/icon/solid-cross.png";
import useStore from "../../../../store";
import { InfoCircleOutlined } from "@ant-design/icons";

const Pricing: React.FC<any> = observer((props: any) => {
  const { AUTH } = useStore();
  const [selectedIndex, setSelectedIndex] = useState<any>();

  useEffect(() => {
    //for select plan card data
    setSelectedIndex(null);
    props.displayData &&
      props.displayData.map((item: any, id: any) => {
        if (
          item.id === AUTH.subscription?.product &&
          item.price[0].id === AUTH.subscription.price_id
        ) {
          setSelectedIndex(id);
        }
      });
  }, [props.displayData]);

  return (
    <>
      <div className="pricingWrap">
        <div className="pricingRow pricingMainRow">
          <div className="priceBox">
            <div className="firstLevel pricePlans">Price plans</div>
          </div>

          {props.displayData &&
            props.displayData.map((item: any, id: any) => {
              const getTime: string =
                item.price[0]?.interval_count.toString() +
                item.price[0]?.interval;

              const setTime =
                getTime === "1month"
                  ? "Month"
                  : getTime === "6month"
                  ? "Half-yearly"
                  : getTime === "1year"
                  ? "Year"
                  : "";

              // const currencySymbol =
              //   item?.price?.[0].currency === "inr" ? "₹" : "$";

              return (
                    <div
                      className={`priceBox ${
                        selectedIndex === id ? "most_popular" : ""
                      }`}
                      key={id}
                    >
                      <div className="firstLevel">
                        <div className="planNamePrice">
                          <div className="planName">{item.name}</div>
                          <div className="priceText" key={id}>
                            <span style={{ fontSize: "30px" }}>
                              ₹
                            </span>
                            {Math.floor(Number(item?.price?.[0]?.amount) / 100)}
                            <span>
                              / &nbsp;{setTime}
                            </span>
                          </div>
                        </div>
                        {AUTH.subscription?.price_id === item.price[0]?.id ? (
                          <Button
                            className="getStartedBtn"
                            onClick={() => props.openCancelPlanDrawer()}
                          >
                            cancel
                          </Button>
                        ) : AUTH?.subscription?.customer_id &&
                          AUTH?.subscription?.id ? (
                          <Button
                            className="getStartedBtn"
                            onClick={() => {
                              props.setCustomerModel(true),
                                props.setSubData({
                                  ...item.price[0],
                                  plan_name: item.name,
                                });
                            }}
                          >
                            Change Plan
                          </Button>
                        ) : (
                          <Button
                            className="getStartedBtn"
                            onClick={() => {
                              props.setCustomerModel(true),
                                props.setSubData({
                                  ...item.price[0],
                                  plan_name: item.name,
                                });
                            }}
                          >
                            get started
                          </Button>
                        )}
                      </div>
                    </div>
              );
            })}
        </div>

        {AUTH.pricing_plan_list?.map((item: any, id: any) => {
          return (
            <div className="pricingRow" key={id}>
              <div
                className={
                  item?.key == "maximum_devices" || item?.key == "maximum_users"
                    ? "featureData"
                    : "featureHeading"
                }
              >
                <div className="keyFeature">
                  {(item?.key == "maximum_devices" ||
                    item?.key == "maximum_users") && (
                    <p className="subsFontSize">{item.title}</p>
                  )}
                  {item?.key != "maximum_devices" &&
                    item?.key != "maximum_users" &&
                    item.title}
                </div>
                <div
                  className={`featureTick ${
                    selectedIndex === 0 ? "most_popular" : ""
                  } `}
                >
                  {(item?.key == "maximum_devices" ||
                    item?.key == "maximum_users") && (
                    <p className="subsFontSize">{item?.basic}</p>
                  )}
                </div>
                <div
                  className={`featureTick ${
                    selectedIndex === 1 ? "most_popular" : ""
                  } `}
                >
                  {(item?.key == "maximum_devices" ||
                    item?.key == "maximum_users") && (
                    <p className="subsFontSize">{item?.standard}</p>
                  )}
                </div>
                <div
                  className={`featureTick ${
                    selectedIndex === 2 ? "most_popular" : ""
                  } `}
                >
                  {(item?.key == "maximum_devices" ||
                    item?.key == "maximum_users") && (
                    <p className="subsFontSize">{item?.premium}</p>
                  )}
                </div>
              </div>
              {item?.key != "maximum_devices" &&
                item?.key != "maximum_users" &&
                item?.child?.map((planD: any, id: number) => {
                  return (
                    <div className="featureData" key={id}>
                      <div className="keyFeature">
                        {planD.title}
                        {planD.description&&
                        <Tooltip title={planD.description}>
                          <InfoCircleOutlined />
                        </Tooltip>}
                      </div>
                      <div
                        className={`featureTick ${
                          selectedIndex === 0 ? "most_popular" : ""
                        } `}
                      >
                        {typeof planD.basic === "string" ? (
                          <p>{planD.basic}</p>
                        ) : (
                          <img
                            src={planD.basic ? RightTick : CrossTick}
                            alt="Tick Image"
                          />
                        )}
                      </div>
                      <div
                        className={`featureTick ${
                          selectedIndex === 1 ? "most_popular" : ""
                        } `}
                      >
                        {typeof planD.standard === "string" ? (
                          <p>{planD.standard}</p>
                        ) : (
                          <img
                            src={planD.standard ? RightTick : CrossTick}
                            alt="Tick Image"
                          />
                        )}
                      </div>
                      <div
                        className={`featureTick ${
                          selectedIndex === 2 ? "most_popular" : ""
                        } `}
                      >
                        {typeof planD.premium === "string" ? (
                          <p>{planD.premium}</p>
                        ) : (
                          <img
                            src={planD.premium ? RightTick : CrossTick}
                            alt="Tick Image"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    </>
  );
});

export default Pricing;
