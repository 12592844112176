import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Modal } from 'antd';
import FormComponent from './FormComponent';
import useStore from '../../../../store';
import { componentProps } from '../../../../store/RateStore/RateInterface';
import { Notification, validateFields } from '../../../../config/Global';
import { ErrorProps } from '../../../../store/RootStore/RootInterface';

const EditComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const { RATE, ROOT } = useStore()
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [saving, setSaving] = useState<boolean>(false)

	const handleChange = () => {
		validateFields(form, setDisabled);
	}

	const handleSubmit = (data: any) => {
		setSaving(true)
		RATE.EditData(data, RATE.editValues?.id).then((data: any) => {
			Notification.success({
				message: data.data
			})
			handleClose()
		}).catch((e: ErrorProps) => {
			ROOT.assignErrorToInput(form, e?.data.errors)
		}).finally(() => {
			setSaving(false)
		})
	}
	const handleClose = () => {
		close()
		form.resetFields()
		setDisabled(true)
	}

	useEffect(() => {
		form.setFieldsValue(RATE.editValues)
	}, [form, RATE.editValues])

	return (
		<Modal
			open={visible}
			onCancel={handleClose}
			className='commanModal'
			title={`Edit Rate`}
			destroyOnClose={true}
			footer={[
				<div key="1" >
					<Button disabled={disabled} form='addEditRateForm' className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="orangeBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent form={form} handleSubmit={handleSubmit} onChange={handleChange} />
		</Modal>
	)
})

export default EditComponent