import React from "react";
import { InputWrapper, SplitInputWrapperProps } from "../../functions";
import { Colorpicker } from "antd-colorpicker";

const ColorPickerInputBox: React.FC<any> = (props) => {
	const { formProps, inputProps } = SplitInputWrapperProps(props);

	return (
		<InputWrapper {...formProps} className='color-picker-input-design'>
			<Colorpicker popup onColorResult={(color) => color.rgb} {...inputProps} />
		</InputWrapper>
	);
};

export default ColorPickerInputBox;
