import React, { useState } from "react";
import { observer } from "mobx-react";
import { PageHeader } from "antd";
import { AnalyticsGroupBreadcrumb } from "../../../../config/BreadcrumbConfig";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import RecordPerPage from "../../../../components/AgGridWrapper/RecordPerPage";
import useStore from "../../../../store";
import ListComponent from "./components/ListComponent";
import AnalyticsGroupDrawer from "./components/AnalyticsDeviseDrawer";

const GroupedByAnalytics: React.FC = observer(() => {
  const { ANALYTICS } = useStore();

  const [addGroupDrawer, setAddGroupDrawer] = useState<boolean>(false);
  const [viewData,setViewData] = useState<any>(null)


  // this two funcion for drawer group analytics
  const openGDrawer = (data: any) => {
    setViewData(data)
    ANALYTICS.viewDevise(data.device_id);
    setAddGroupDrawer(true);
  };
  const closeGDrawer = () => {
    setAddGroupDrawer(false);
  };

  return (
    <>
      <div className="header">
        <PageHeader
          title={AnalyticsGroupBreadcrumb.title}
          tags={<BreadcrumbComponent items={AnalyticsGroupBreadcrumb.path} />}
          extra={[
            <RecordPerPage
              key="2"
              defaultValue={ANALYTICS.per_page + " per page"}
              onChange={ANALYTICS.setPageSize}
            />,
          ]}
        >
          <ListComponent openGDrawer={openGDrawer} />
          <AnalyticsGroupDrawer visible={addGroupDrawer} close={closeGDrawer} viewData={viewData} />
        </PageHeader>
      </div>
    </>
  );
});

export default GroupedByAnalytics;
