import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import { Regex } from "../config/Validators";
import { mobileValidation } from "../config/Global";
import moment from "moment";
export const UserRequest: RequestProps = {
  name: [
    { required: true, message: Message().required.text("Name") },
    { max: 50, message: Message().max.string(50, "Name") },
  ],
  username: [
    { required: true, message: Message().required.text("Username") },
    { max: 100, message: Message().max.string(100, "Username") },
  ],
  email: [
    { required: true, message: Message().required.text("Email") },
    { type: "email", message: "Invalid email" },
  ],
  password: [{ required: true, message: Message().required.text("Password") }],
  confirm_password: [
    { required: true, message: Message().required.text("Confirm Password") },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject("Confirm Password does not match.");
      },
    }),
  ],
  role: [{ required: true, message: Message().required.text("Role") }],
  permissions: [
    ({ getFieldValue }) => ({
      validator() {
        const permissionsArray: any = getFieldValue("permissions");
        if (permissionsArray) {
          let verified = false;
          permissionsArray.map((item: any) => {
            if (item.actions.length > 0) {
              const is_selected_index: any = item.actions.filter(
                (x: any) => x.is_selected === 1
              );
              if (is_selected_index.length > 0) {
                verified = true;
              }
            }
            return null;
          });
          if (verified) {
            return Promise.resolve();
          } else {
            return Promise.reject("Atleast one permission should be selected.");
          }
        } else {
          return Promise.resolve();
        }
      },
    }),
  ],
};

export const RateRequest: RequestProps = {
  item_name: [
    { required: true, message: Message().required.text("Item Name") },
  ],
  rate: [
    { required: true, message: Message().required.text("Rate") },
    {
      pattern: Regex.numeric,
      message: "Enter valid digit",
    },
  ],
};

export const deviceRequest: RequestProps = {
  pair_code: [
    { required: true, message: Message().required.text("Pair Code") },
    { max: 10, message: Message().max.string(10, "Pair Code") },
  ],
  name_your_device: [
    { required: true, message: Message().required.text("Device Name") },
    { max: 50, message: Message().max.string(50, "Device Name") },
  ],
  hardware_type: [
    { required: true, message: Message().required.text("Hardware Type") },
  ],
  api_path: [
    { required: true, message: Message().required.text("Api Path") },
    {
      pattern:
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/,
      message: "Enter valid URL",
    },
  ],
  scaling: [
    {
      required: true,
      message: Message().between.numeric("scalling", 0.8, 1.2),
    },
  ],
  tv_start_time: [
		{ required: true, message: Message().required.text("Start time") },
		({getFieldValue}) => ({
			validator(value: any, rule: any) {
				if (rule && rule >= getFieldValue("tv_close_time")) {
					return Promise.reject('The tv start time must be before the tv end time.');
				}
				return Promise.resolve();
			}
		})
	],
	tv_end_time: [
		{ required: true, message: Message().required.text("End time") },
		({getFieldValue}) => ({
			validator(value: any, rule: any) {
				if (rule && rule <= getFieldValue("tv_start_time")) {
					return Promise.reject('The tv end time must be after the tv start time.');
				}
				return Promise.resolve();
			}
		})
	],
	tv_restart_time: [
		{ required: true, message: Message().required.text("Re-Start time") },
		({getFieldValue}) => ({
			validator(value: any, rule: any) {
				if (rule && rule > getFieldValue("tv_start_time") && rule < getFieldValue("tv_close_time")) {
					return Promise.resolve();
				}
				return Promise.reject('The tv restart time must be between the tv start time and the tv end time.');
			}
		})
	],
  orientation: [
    { required: true, message: Message().required.text("Oriantation") },
  ],
  desc: [{ max: 200, message: Message().max.string(200, "Description") }],
  layout: [{ required: true, message: Message().required.text("Layout") }],
};

export const CustomerRequest = {
  name: [
    {
      required: true,
      message: Message().required.text("Company"),
    },
    { min: 3, message: Message().min.string(3, "Company") },
    { max: 100, message: Message().max.string(100, "Company") },
  ],
  email: [
    { required: true, message: Message().required.text("Email ID") },
    {
      pattern: Regex.email,
      message: "Invalid email",
    },
  ],
  company_name: [
    { required: true, message: Message().required.text("Company") },
    { min: 3, message: Message().min.string(3, "Company") },
    { max: 100, message: Message().max.string(100, "Company") },
  ],
  address: [
    { required: true, message: Message().required.text("Address") },
    { max: 200, message: Message().max.string(200, "Address") },
    { min: 3, message: "The address must be at least 3 characters" },
  ],
  city: [{ required: true, message: Message().required.text("City") }],
  state: [{ required: true, message: Message().required.text("State") }],
  country: [{ required: true, message: Message().required.text("Country") }],
  Zip: [
    { required: true, message: Message().required.text("Zip Code") },
    { pattern: /^[1-9][0-9]{5}$/, message: "Please Enter Valid Zip Code" },
  ],
  mobile: [
    {
      required: true,
      message: Message().required.text("Mobile"),
    },
    {
      pattern: Regex.mobile,
      message: "Invalid Charcters",
    },
    () => ({
      validator(value: any, rule: any) {
        if (rule && rule.length === 12) {
          const validate: any = mobileValidation(rule);
          if (!validate) {
            return Promise.reject("Invalid Mobile No");
          }
        }
        return Promise.resolve();
      },
    }),
  ],

  dummy_mobile: [
    {
      pattern: Regex.mobile,
      message: "Invalid Charcters",
    },
    () => ({
      validator(value: any, rule: any) {
        if (rule && rule.length === 12) {
          const validate: any = mobileValidation(rule);
          if (!validate) {
            return Promise.reject("Invalid Mobile No");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  privileges: [
    { required: true, message: Message().required.text("Previleges") },
  ],

  maxCompanies: [
    { required: true, message: Message().required.text("Max Companies") },
    {
      pattern: Regex.numeric,
      message: "Enter valid digit",
    },
  ],

  maxDevices: [
    { required: true, message: Message().required.text("Max Devices") },
    {
      pattern: Regex.numeric,
      message: "Enter valid digit",
    },
  ],
  plan: [{ required: true, message: Message().required.text("Plan") }],
  from_date: [
    { required: true, message: Message().required.text("From date"),},
    ({ getFieldValue } :any) => ({
        validator() {
          const startDate = getFieldValue('from_date');
          const endDate = getFieldValue('to_date');
            if (startDate && endDate) {
                if (startDate.diff(endDate, 'days') > 0 || (moment(endDate).isBefore(startDate))) {
                    return Promise.reject("The From Date must be earlier than or the same as the To Date.");
                }
            }
            return Promise.resolve();
        },
    }),
  ],
  to_date: [
    { required: true, message: Message().required.text("To date") },
    ({ getFieldValue } :any) => ({
        validator() {
          const startDate = getFieldValue('from_date');
          const endDate = getFieldValue('to_date');
            if (startDate && endDate) {
                if (endDate.diff(startDate, 'days') < 0 || (moment(endDate).isBefore(startDate))) {
                    return Promise.reject("The To Date must be later than or the same as the From Date.");
                }
            }
            return Promise.resolve();
        },
    }),
  ],
  allow_device: [
    { required: true, message: Message().required.text("Allow device") },
    { pattern: Regex.naturalNumeric, message: "Enter valid digit" },
  ],
  aws_bucket: (title:string)=> [ 
    { required: true, message: Message().required.text(title) },
  ],
};

export const AddLicensevalidation = {
  device: [
    {
      required: true,
      message: Message().required.text("Device"),
    },
  ],

  payment: [
    {
      required: true,
      message: Message().required.text("Payment Detail"),
    },
  ],
  expiration_date: [
    {
      required: true,
      message: Message().required.text("Expiration date"),
    },
  ],
  noOfDevices: [
    {
      required: true,
      message: Message().required.text("Number of devices"),
    },
  ],
  PlanName: [
    {
      required: true,
      message: Message().required.text("Plan Name"),
    },
  ],
  duration: [
    {
      required: true,
      message: Message().required.text("Duration"),
    },
  ],
  quantity: [
    {
      required: true,
      message: Message().required.text("Quantity"),
    },
  ],
  amount: [
    {
      required: true,
      message: Message().required.text("Amount"),
    },
  ],
  planMode: [
    {
      required: true,
      message: Message().required.text("Plan Mode"),
    },
  ],
};

export const globalPopup = {
  start_time: [
    {
      required: true,
      message: Message().required.text("This"),
    },
  ],
  end_time: [
    {
      required: true,
      message: Message().required.text("This"),
    },
  ],
  tiny_data: [
    {
      required: true,
      message: Message().required.text("This"),
    },
  ],
};

export const EmergencyPopup = {
  emergency_popup_text: [
    {
      required: true,
      message: Message().required.text("This"),
    },
    { max: 200, message: Message().max.string(200, "Emergency popup text") },
  ],
  rate: [
    {
      required: true,
      message: Message().required.text("This"),
    },
    { max: 200, message: Message().max.string(200, "Rate Condition Line") },
  ],
  text_display: [
    {
      required: true,
      message: Message().required.text("This"),
    },
    { max: 200, message: Message().max.string(200, "Text To Display") },
  ],
  display_seconds: [
    {
      required: true,
      message: Message().required.text("This"),
    },
    {
      pattern: /^\d+$/,
      message: "Please Enter Digits Only",
    },
  ],
  contact_num: [
    {
      required: true,
      message: Message().required.text("Contact No"),
    },
    {
      pattern: Regex.mobile,
      message: "Invalid Charcters",
    },
    () => ({
      validator(value: any, rule: any) {
        if (rule && rule.length === 12) {
          const validate: any = mobileValidation(rule);
          if (!validate) {
            return Promise.reject("Invalid Contact No");
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  email: [
    {
      required: true,
      message: Message().required.text("This"),
    },
    {
      pattern: Regex.email,
      message: "Invalid Email Id.",
    },
  ],
};

export const tokens = {
  allocated_tokens: [
    {
      required: true,
      message: Message().required.text("This"),
    },
  ],
  serving_tokens: [
    {
      required: true,
      message: Message().required.text("This"),
    },
  ],
};

export const scheduleRules = {
  select_device: [
    {
      required: true,
      message: Message().required.text("This"),
    },
  ],
  select_layout: [
    {
      required: true,
      message: Message().required.text("This"),
    },
  ],
  time_layout: [
    {
      required: true,
      message: Message().required.text("This"),
    },
  ],
};

export const LayoutRequest: RequestProps = {
  layout_name: [
    {
      required: true,
      whitespace: true,
      message: Message().required.text("This"),
    },
    { min: 3, message: Message().min.string(3, "This") },
    { max: 100, message: Message().max.string(100, "This") },
  ],
  global_layout_id: [
    { required: true, message: Message().required.text("This") },
  ],
  description: [
    { min: 3, message: Message().min.string(3, "This") },
    { max: 500, message: Message().max.string(500, "This") },
  ],
  image: [{ required: true, message: Message().required.text("This image") }],
  pdf: [{ required: true, message: Message().required.text("Pdf") }],
  video: [{ required: true, message: Message().required.text("This video") }],
  ticker: [{ whitespace: true, message: "It cannot begin with a space." }],
  text: [
    {
      required: true,
      whitespace: true,
      message: Message().required.text("This"),
    },
  ],
  txt160: [
    { required: true, message: Message().required.text("This") },
    { max: 160, message: Message().max.string(160, "This") },
  ],
  saved_name: [
    { required: true, message: Message().required.text("This") },
    { max: 100, message: Message().max.string(100, "This") },
  ],
  saved_date: [{ required: true, message: Message().required.text("This") }],
  saved_description: [
    { required: true, message: Message().required.text("This") },
    { max: 500, message: Message().max.string(500, "This") },
  ],
  saved_select_tv: [
    { required: true, message: Message().required.text("This") },
  ],
  popup: [
    {
      required: true,
      whitespace: true,
      message: Message().required.text("This"),
    },
  ],
  max20: [
    { required: true, message: Message().required.text("This") },
    { max: 20, message: Message().max.string(20, "This") },
  ],
  max25: [
    { required: true, message: Message().required.text("This") },
    { max: 25, message: Message().max.string(25, "This") },
  ],
  bio: [
    {
      required: true,
      whitespace: true,
      message: Message().required.text("This"),
    },
    { max: 150, message: Message().max.string(150, "This") },
  ],
  from_time: [{ required: true, message: Message().required.text("This") }],
  to_time: [{ required: true, message: Message().required.text("This") }],
  from_date: [{ required: true, message: Message().required.text("This") }],
  to_date: [{ required: true, message: Message().required.text("This") }],
  layout: [{ required: true, message: Message().required.select("Layout") }],
  duration: [{ required: true, message: Message().required.text("Duration") }],
  resolution: [
    { required: true, message: Message().required.select("Resolution") },
  ],
  price_title: [
    {
      required: true,
      whitespace: true,
      message: Message().required.text("This"),
    },
    { min: 5, message: Message().min.string(5, "This") },
    { max: 15, message: Message().max.string(15, "This") },
  ]
};

export const StyleLayoutProps: RequestProps = {
  slide_time_out: [
    { pattern: Regex.numeric, message: Message().regex.numeric() },
    { max: 10, message: "This should contain maximum 10 characters." },
  ],
  scrlling: [
    { required: true, message: Message().required.text("Scolling") },
    {
      pattern: /^([2-9]|10)$/,
      message: Message().between.numeric("Scolling", 2, 10),
    },
  ],
  padding: [
    { pattern: Regex.marginPadding, message: Message().regex.marginPadding() },
  ],
  margin: [
    { pattern: Regex.marginPadding, message: Message().regex.marginPadding() },
  ],
  fontSize: [
    { pattern: Regex.numeric, message: Message().regex.numeric() },
    { max: 3, message: Message().max.numeric(3, "Font size") },
  ],
  borderWidth: [
    { pattern: Regex.marginPadding, message: Message().regex.marginPadding() },
  ],
  postDisplay: [
    { pattern: Regex.numeric, message: Message().regex.numeric() },
    { max: 10, message: "This should contain maximum 10 characters." },
  ],
};

export const layoutPublish: RequestProps = {
  device: [{ required: true, message: Message().required.text("Layout Name") }],
  dateTime: [{ required: true, message: Message().required.text("Time") }],
};

export const profile: RequestProps = {
  name: [
    { required: true, message: Message().required.text("Name") },
    { max: 50, message: Message().max.string(50, "Name") },
  ],
  password: [
    { required: true, message: Message().required.text("Password") },
    {
      pattern: Regex.password,
      message: "Password entry does not meet criteria.",
    },
  ],
  confirmPassword: [
    { required: true, message: "Confirm Password cannot be empty." },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue("new_password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          "New Password and Confirm Password does not match."
        );
      },
    }),
  ],
};

export const role: RequestProps = {
  role_name: [
    { required: true, message: Message().required.text("Role Name") },
    { min: 3, message: Message().min.string(3, "Role Name") },
    { max: 50, message: Message().max.string(50, "Role Name") },
    {
      pattern: Regex.onlyWords,
      message: "Invalid role name.",
    },
  ],
  permissions: [
    ({ getFieldValue }) => ({
      validator() {
        const permissionsArray: any = getFieldValue("permissions");
        if (permissionsArray) {
          let verified = false;
          permissionsArray.map((item: any) => {
            if (item.actions.length > 0) {
              const is_selected_index: any = item.actions.filter(
                (x: any) => x.is_selected === 1
              );
              if (is_selected_index.length > 0) {
                verified = true;
              }
            }
            return null;
          });
          if (verified) {
            return Promise.resolve();
          } else {
            return Promise.reject("Atleast one permission should be selected.");
          }
        } else {
          return Promise.resolve();
        }
      },
    }),
  ],
};

export const deviceGroupRequest: RequestProps = {
  group_name: [
    { required: true, message: Message().required.text("Group Name") },
    { min: 3, message: Message().min.string(3, "Group Name") },
    { max: 50, message: Message().max.string(50, "Group Name") },
  ],
  device_ids: [
    { required: true, message: Message().required.select("Device") },
  ],
};

export const updateAPKRequest = {
  version_code: [
    {
      required: true,
      message: Message().required.text("This"),
    },
  ],
  version_name: [
    {
      required: true,
      message: Message().required.text("This"),
    },
  ],
  apk: [
    {
      required: true,
      message: Message().required.text("This"),
    },
  ],
};

export const SubRequest = {
  email: [
    { required: true, message: Message().required.text("Email ID") },
    {
      pattern: Regex.email,
      message: "Invalid email",
    },
  ],
  name: [{ required: true, message: Message().required.select("Description") }],
};

export const HtmlLayoutRequest: RequestProps = {
  HtmlEditor: [
    {
      required: true,
      whitespace: true,
      message: Message().required.text("This"),
    },
  ],
};
