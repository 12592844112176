import { Button, Modal, Form, Row, Col, notification } from "antd";
import React, { useEffect, useState } from "react";
import { InputBox, FormBox } from "../../../../components/AntdAddons";
import { AddLicensevalidation } from "../../../../requests/PageRequest";
import { CustomerComponentProps } from "../../../../store/CustmerStore/CustomerInterface";
import useStore from "../../../../store";
import { ErrorProps } from "../../../../store/RootStore/RootInterface";
import { validateFields } from "../../../../config/Global";

const AddLicense: React.FC<CustomerComponentProps> = (
  props: CustomerComponentProps
) => {
  const { close, visible } = props;
  const [disabled, setDisabled] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { USER, ROOT, SUBSCRIPTION } = useStore();
  const [planList, setPlanList] = useState<any>([]);
  const [durationList, setDurationList] = useState<any>([]);
  const [intervalCount, setIntervalCount] = useState<number>()

  const closeModal = () => {
    close();
    form.resetFields();
    setDisabled(true);
  };
  const handleSubmit = (data: any) => {
    setSaving(true);
    data.interval_count = intervalCount
    data.company_id = visible?.id;
    USER.addLicense(data)
      .then(() => {
        notification.success({
          message: "License Added Successfully",
          placement: "bottomRight",
        });
        closeModal();
      })
      .catch((e: ErrorProps) => {
        ROOT.assignErrorToInput(form, e?.data);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleChange = () => {
    validateFields(form, setDisabled);
  };

  useEffect(() => {
    if(visible) {
      SUBSCRIPTION.planFetchList();
    }
  }, [visible]);


  const handleSelectList = () => {
    const Plan_name: { id: number; name: string }[] = [];
    const duration_list: { id: number; name: string }[] = [];

    SUBSCRIPTION.PlanList?.forEach((item: any) => {
      Plan_name.push({ id: item.name, name: item.name });
    });
    SUBSCRIPTION.PlanList[0]?.price?.forEach((item: any) => {
      duration_list.push({
        id: item.interval_count.toString() + item.interval,
        name: item.interval_count.toString() + item.interval,
      });
    });
    setPlanList(Plan_name);
    setDurationList(duration_list);
  };

  const handleChangeAmount = () => {
    const duration = form.getFieldValue("duration");
    const qantity = form.getFieldValue("no_of_devices");
    const planName = form.getFieldValue("plan_name");

    let amount: any = 0;
    SUBSCRIPTION.PlanList.forEach((item: any) => {
      if (item.name === planName) {
        item.price.forEach((price: any) => {
          if (price.interval_count.toString() + price.interval === duration) {
            setIntervalCount(price.interval_count)
            amount = price.amount / 100;
          }
        });
      }
    });
    if (duration && Number(qantity) > 0 && planName) {
      form.setFieldValue("amount", amount * Number(qantity));
    }
  };

  return (
    <>
      <Modal
        title="Add License"
        centered
        open={visible}
        destroyOnClose
        onCancel={closeModal}
        className="commanModal addLicense"
        footer={[
          <Button
            key={1}
            form="close"
            disabled={disabled}
            htmlType="submit"
            loading={saving}
            type="primary"
            className={`${disabled ? "disableBtn" : "blackLineBtn"}`}
          >
            Save
          </Button>,
          <Button
            key={2}
            onClick={closeModal}
            className="orangeBgBtn cancelBtn"
          >
            Cancel
          </Button>,
        ]}
      >
        <FormBox
          form={form}
          onFinish={handleSubmit}
          id="close"
          initialValues={USER?.initialValue}
        >
          <Row gutter={15}>
            {/* <Col span={12}>
              <InputBox.Text
                label="No of Devices"
                placeholder="Num of devices"
                name={"no_of_devices"}
                onChange={() => {
                  handleChange();
                  handleChangeAmount();
                }}
                required
                rules={AddLicensevalidation.noOfDevices}
              ></InputBox.Text>
            </Col> */}
            <Col span={12}>
              <InputBox.Select
                required
                label="Plan Name"
                name={"plan_name"}
                placeholder="plan Name"
                showSearch
                onFocus={() => handleSelectList()}
                onChange={() => {
                  handleChange();
                  handleChangeAmount();
                }}
                className="selectDown"
                showArrow={false}
                // extra={
                // 	<DownIcon />
                // }
                options={{
                  list: planList && planList,
                  textKey: "name",
                  valueKey: "id",
                }}
                rules={AddLicensevalidation.PlanName}
              ></InputBox.Select>
            </Col>
            <Col span={12}>
              <InputBox.Select
                required
                label="Duration"
                name={"duration"}
                placeholder="duration"
                showSearch
                onChange={() => {
                  handleChange();
                  handleChangeAmount();
                }}
                onFocus={() => handleSelectList()}
                className="selectDown"
                showArrow={false}
                // extra={
                // 	<DownIcon />
                // }
                options={{
                  list: durationList && durationList,
                  textKey: "name",
                  valueKey: "id",
                }}
                rules={AddLicensevalidation.duration}
              ></InputBox.Select>
            </Col>
            <Col span={12}>
              <InputBox.Number
                label="No of Devices"
                placeholder="Num of devices"
                name={"no_of_devices"}
                onChange={() => {
                  handleChange();
                  handleChangeAmount();
                }}
                min={1}
                required
                rules={AddLicensevalidation.noOfDevices}
              ></InputBox.Number>
            </Col>
            <Col span={12}>
              <InputBox.Text
                label="Amount"
                placeholder="amount"
                name={"amount"}
                onChange={() => handleChange()}
                required
                rules={AddLicensevalidation.amount}
              ></InputBox.Text>
            </Col>
            <Col span={12}>
              <InputBox.Select
                required
                label="Payment Mode"
                name={"plan_mode"}
                placeholder="payment Mode"
                showSearch

                className="selectDown"
                showArrow={false}
                onChange={handleChange}
                // extra={
                // 	<DownIcon />
                // }
                options={{
                  list: [
                    { id: 0, name: "Cash" },
                    { id: 1, name: "Online" },
                  ],
                  textKey: "name",
                  valueKey: "id",
                }}
                rules={AddLicensevalidation.planMode}

              ></InputBox.Select>
            </Col>
            <Col span={24}>
              <InputBox.Text
                required
                label="Payment Description"
                onChange={handleChange}
                rules={AddLicensevalidation.payment}
                name={"payment_details"}
                placeholder="Payment Description"
              ></InputBox.Text>
            </Col>
          </Row>
        </FormBox>
      </Modal>
    </>
  );
};

export default AddLicense;
