import { makeAutoObservable } from "mobx";
import Config from "../../config/Config";
import axios from "axios";
import API_URL from "../../config/ApiUrl";
import { doLoginType } from "../AuthStore/AuthInterface";
import { SavedLayoutCodeDataProps } from "./SavedInterface";
import moment from "moment";
import { displayFormats } from "../../config/Global";

export default class SavedLayout {
	per_page = Config.grid.local.gridOptions?.paginationPageSize
	agGrid: any = null
	viewValues: any = null
	public list_data?: SavedLayoutCodeDataProps[]
	public device_list: any = null

	constructor() {
		makeAutoObservable(this)
	}

	public onGridReady = (data: any) => {
		const { api } = data
		const columnState = localStorage.getItem("savedlayout_token")
		if (columnState) {
			data.columnApi.applyColumnState({ state: JSON.parse(columnState) });

		}
		this.agGrid = api
	}

	public setPageChange = (data: any) => {
		this.agGrid.paginationSetPageSize(Number(data))
		this.per_page = Number(data)
	}

	public setViewValue = (data: any) => {
		this.viewValues = data
	}

	public publishLayout = (data: any) => {
		return axios.post(API_URL.SAVED_LAYOUT.PUBLISH + `/${this.viewValues.id}`, data).then((data) => {
			this.fetchList();
			return data.data
		}).catch((response) => {
			return Promise.reject(response);
		})
	}

	public devicePublishList = async () => {
		return await axios.get(API_URL.LAYOUT.DEVICE).then(({ data }) => {
			this.device_list = data.data
			return data.data
		}).catch((response) => {
			return Promise.reject(response);
		})
	}

	private setListData = (value?: SavedLayoutCodeDataProps[]): void => {
		this.list_data = value
	}

	onGridChanged = (params: any) => {
		if (params) {
			localStorage.setItem('savedlayout_token', JSON.stringify(params.columnApi.getColumnState()))
		}
	}

	public cloneLayout = (data: any) => {
		return axios.post(API_URL.SAVED_LAYOUT.CLONE + `/${data.layout_id}`).then(({ data }) => {
			this.fetchList();
			return data
		}).catch((response) => {
			return Promise.reject(response);
		})
	}

	public PreviewSavedLayout = (data: any) => {
		return axios.post(API_URL.SAVED_LAYOUT.PREVIEW_SAVEDLAYOUT + `/${data.id}`).then(({ data }) => {
			return data
		}).catch((response) => {
			return Promise.reject(response);
		})
	}

	public fetchList = async (): Promise<any> => {
		this.list_data = [];
		return await axios.get(API_URL.SAVED_LAYOUT.LIST).then(({ data }) => {
			data.data.forEach((it: any) => {
				return it.date = moment(it.date).format(displayFormats.DATE_MONTH_YEAR)
			})
			this.setListData(data.data)
		}).catch(({ response }) => {
			return Promise.reject(response);
		});
	};

	public loginAttempt = async (payload: doLoginType): Promise<any> => {
		return await axios.post(API_URL.LOGIN, payload).then(({ data }) => {
			localStorage.setItem("token", data.data.token);
			return data;
		}).catch((data) => {
			return Promise.reject(data);
		})
	}

	public deleteData = (data: any) => {
		return axios.post(API_URL.SAVED_LAYOUT.DELETE + `/${data.id}`).then((data) => {
			this.fetchList();
			return data.data
		}).catch((response) => {
			return Promise.reject(response);
		});
	}

} 