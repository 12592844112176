import React, { useState } from 'react';
import { Button, PageHeader } from 'antd';
import { observer } from 'mobx-react';
import RecordPerPage from '../../../components/AgGridWrapper/RecordPerPage';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { RateBreadcrumb } from '../../../config/BreadcrumbConfig'
import useStore from '../../../store';
import ListComponent from './components/ListComponent';
import AddComponent from './components/AddComponent';
import EditComponent from './components/EditComponent';
import DeleteComponent from './components/DeleteComponent';
import Unauthorized from '../../errors/Unauthorized';
import BulkEditComponent from './components/BulkEditComponent';
import { Notification } from '../../../config/Global';
import { CONSTANT } from '../../../config/Constant';

const Rates: React.FC = observer(() => {
	const { AUTH, RATE } = useStore()
	const [addDrawer, setAddDrawer] = useState<boolean>(false)
	const [editDrawer, setEditDrawer] = useState<boolean>(false)
	const [deleteDrawer, setDeleteDrawer] = useState<boolean>(false)
	const [bulkDrawer, setbulkDrawer] = useState<boolean>(false)

	const closeAddDrawer = () => {
		setAddDrawer(false)
	}

	const openEditDrawer = (data: any) => {
		RATE.setEditValues(data)
		setEditDrawer(data)
	}

	const closeEditDrawer = () => {
		RATE.setEditValues(null)
		setEditDrawer(false)
	}

	const openDeleteDrawer = (data: any) => {
		RATE.setDeleteValues(data)
		setDeleteDrawer(true)
	}

	const closeDeleteDrawer = () => {
		RATE.setDeleteValues(null)
		setDeleteDrawer(false)
	}
	const openbulkDrawer = () => {
		setbulkDrawer(true)
		RATE.setBulkRead()
	}

	const closebulkDrawer = () => {
		setbulkDrawer(false)
	}

	const publishAllTv = () => {
		RATE.publishRate().then((data: any) => {
			Notification.success({
				message: data.data
			})
		}).catch((e: any) => {
			e;
		})
	}
	return (
		<>

			{(!AUTH.checkPermission(7, 'list') && [CONSTANT.SuperAdmin,CONSTANT.SubAdmin].includes(AUTH.user?.users_roles_id)) ? (<Unauthorized />) :
				<PageHeader
					title={RateBreadcrumb.title}
					tags={<BreadcrumbComponent items={RateBreadcrumb.path} />}
					extra={[
						AUTH.checkPermission(7, 'add') && <Button key='1' type="primary" className='mr-10' onClick={() => setAddDrawer(true)}>Add New Rate</Button>,
						AUTH.checkPermission(7, 'Edit bulk') && <Button key='2' onClick={openbulkDrawer} type="primary" className='mr-10'>Edit Bulk Rates</Button>,
						AUTH.checkPermission(7, 'publish') && <Button key='3' type="primary" className='mr-10' onClick={publishAllTv}>Publish Rates to all TVs</Button>,
						<RecordPerPage key="3" defaultValue={RATE.per_page + ' per page'} onChange={RATE.setPageSize} className="" />,
					]}
				>
					<ListComponent openEditModal={openEditDrawer} openDeleteModal={openDeleteDrawer} />
					<AddComponent visible={addDrawer} close={closeAddDrawer} />
					<EditComponent visible={editDrawer} close={closeEditDrawer} />
					<DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
					<BulkEditComponent visible={bulkDrawer} close={closebulkDrawer} />
				</PageHeader>
			}
		</>
	)
})

export default Rates