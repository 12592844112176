import { Button, PageHeader, Form } from "antd";
import React, { useState } from "react";
import RecordPerPage from "../../../components/AgGridWrapper/RecordPerPage";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";
import { UserBreadcrumb } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import { observer } from "mobx-react";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import DeleteComponent from "./components/DeleteComponent";
import Unauthorized from "../../errors/Unauthorized";
import AddLicense from "./components/AddLicense";
import EditLicense from "./components/EditLicense";
import ProxyConfirmComponent from "./components/ProxyConfirmComponent";
import SwitchComponent from "./components/SwitchComponent";
import ModifyPermissionComponent from "./components/ModifyPermissionComponent";
import LayoutPermissionComponent from "./components/LayoutPermissionComponent";
import DevisePermissionComponent from "./components/DevisePermissionComponent";
import { CONSTANT } from "../../../config/Constant";

const Users: React.FC = observer(() => {
  const { USER, AUTH } = useStore();
  const [addDrawer, setAddDrawer] = useState<boolean>(false);
  const [editDrawer, setEditDrawer] = useState<boolean>(false);
  const [deleteDrawer, setDeleteDrawer] = useState<boolean>(false);
  const [addLicense, setAddLicense] = useState<boolean>(false);
  const [editLicense, setEditLicense] = useState<boolean>(false);
  const [viewProxylogin, setviewProxyLogin] = useState<boolean>(false);
  const [SwitchModalOpen, setSwitchModalOpen] = useState<boolean>(false);
  const [permissionDrawer, setPermissionDrawer] = useState<boolean>(false);
  const [layoutPermissionDrawer, setLayoutPermissionDrawer] =
    useState<boolean>(false);
  const [devisePermissionDrawer, setDevisePermissionDrawer] =
    useState<boolean>(false);
  //for select layout permission id
  const [layoutId, setLayoutId] = useState<number>();
  const [deviceId, setDeviceId] = useState<number>();

  const [form] = Form.useForm();
  const closeAddDrawer = () => {
    setAddDrawer(false);
  };

  // do Proxy Login
  const handleProxyLogin = (data: any) => {
    USER.setViewValues(data.id);
    setviewProxyLogin(true);
    form.resetFields();
  };

  const openEditDrawer = (data: any) => {
    USER.setViewValues(data.id);
    setEditDrawer(true);
  };

  const closeEditDrawer = () => {
    USER.setViewValues(null);
    setEditDrawer(false);
  };

  const openDeleteDrawer = (data: any) => {
    USER.setViewValues(data.id);
    setDeleteDrawer(true);
  };

  const closeDeleteDrawer = () => {
    USER.setViewValues(null);
    setDeleteDrawer(false);
  };

  const closeaddLicense = () => {
    setAddLicense(false);
  };

  const closeeditLicense = () => {
    setEditLicense(false);
  };
  const handleClose = () => {
    setviewProxyLogin(false);
    USER.setViewValues(null);
  };
  const addLicenseD = (data: any) => {
    setAddLicense(data);
  };

  const openSwitchModal = (data: any) => {
    setSwitchModalOpen(data);
  };

  const editLicenseD = (data: any) => {
    setEditLicense(data);
    USER.setaddViews(data?.id);
  };

  const closeSwitchModal = () => {
    setSwitchModalOpen(false);
  };

  // for open modify premission drawer
  const openPermissionD = (data: any) => {
    USER.setViewValues(data.id);
    setPermissionDrawer(true);
  };

  // for close modify premission drawer
  const closePermissionD = () => {
    setPermissionDrawer(false);
  };

  // for open layout permission drawer
  const openLayPermissionD = (data: any) => {
    setLayoutId(data.id);
    setLayoutPermissionDrawer(true);
  };

  // for close layout permission drawer
  const closeLayPermissionD = () => {
    setLayoutPermissionDrawer(false);
  };

  // for open devise permission drawer
  const openDevisePermissionD = (data: any) => {
    // convert hash to comaSeparated array
    const arr = data?.device_permission?.split("#").map(Number);
    USER.setSelectedDeviceIds(arr);
    setDeviceId(data.id);
    setDevisePermissionDrawer(true);
  };

  // for close devise permission drawer
  const closedDevisePermissionD = () => {
    setDevisePermissionDrawer(false);
  };

  return (
    <>
      {!AUTH.checkPermission(CONSTANT.SubAdmin, "list") ? (
        <Unauthorized />
      ) : (
        <PageHeader
          title={UserBreadcrumb.title}
          tags={<BreadcrumbComponent items={UserBreadcrumb.path} />}
          extra={[
            // <Button
            //   key="1"
            //   type="primary"
            //   className="mr-10"
            //   onClick={() => {
            //     USER?.agGrid?.api.setFilterModel(null);
            //     USER?.agGrid?.api.onFilterChanged();
            //   }}
            // >
            //   <FontAwesomeIcon icon={faFilterCircleXmark} />
            // </Button>,
            AUTH.checkPermission(CONSTANT.SubAdmin, "add") && (
              <Button
                key="1"
                type="primary"
                className="mr-10"
                onClick={() => setAddDrawer(true)}
              >
                Add User
              </Button>
            ),
            <RecordPerPage
              key="2"
              defaultValue={USER.per_page + " per page"}
              onChange={USER.setPageSize}
            />,
          ]}
        >
          <ListComponent
            openEditModal={openEditDrawer}
            openDeleteModal={openDeleteDrawer}
            addLicenseD={addLicenseD}
            editLicenseD={editLicenseD}
            handleProxyLogin={handleProxyLogin}
            openSwitchModal={openSwitchModal}
            openPermissionD={openPermissionD}
            openLayPermissionD={openLayPermissionD}
            openDevisePermissionD={openDevisePermissionD}
          />

          <AddComponent visible={addDrawer} close={closeAddDrawer} />
          <EditComponent visible={editDrawer} close={closeEditDrawer} />
          <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
          <AddLicense visible={addLicense} close={closeaddLicense} />
          <EditLicense visible={editLicense} close={closeeditLicense} />
          <ProxyConfirmComponent visible={viewProxylogin} close={handleClose} />
          <SwitchComponent visible={SwitchModalOpen} close={closeSwitchModal} />
          <ModifyPermissionComponent
            visible={permissionDrawer}
            close={closePermissionD}
          />
          <LayoutPermissionComponent
            visible={layoutPermissionDrawer}
            close={closeLayPermissionD}
            layoutId={layoutId}
          />
          <DevisePermissionComponent
            visible={devisePermissionDrawer}
            close={closedDevisePermissionD}
            deviceId={deviceId}
          />
        </PageHeader>
      )}
    </>
  );
});

export default Users;
