import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Dropdown, Layout } from "antd";
import Avatar from "../../../../assets/images/user.png";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as DownIcon } from "../../../../assets/images/icon/left-arrow-icon.svg";
import { ReactComponent as TourIcon } from "../../../../assets/images/icon/Tour.svg";
import { ReactComponent as GuideIcon } from "../../../../assets/images/icon/website tour guide.svg";
import { ReactComponent as LeftMenu } from "../../../../assets/images/icon/menu_bar.svg";
import { ReactComponent as DarkMode } from "../../../../assets/images/icon/darkMode.svg";
import { ReactComponent as LightMode } from "../../../../assets/images/icon/lightMode.svg";
import { ReactComponent as LogoutIcon } from "../../../../assets/images/icon/logoutIcon.svg";
import { ReactComponent as ProfileIcon } from "../../../../assets/images/icon/profileIcon.svg";
import { ReactComponent as LogInIcon } from "../../../../assets/images/icon/loginIcon.svg";
import { Notification } from "../../../../config/Global";
import type { MenuProps } from "antd";
import { useTour } from "@reactour/tour";
import { getSteps } from "../Steps";

interface HeaderViewProps {
  collapsed: boolean;
  SetCollapsed: Dispatch<SetStateAction<boolean>>;
}

const AppHeaderView: React.FC<HeaderViewProps> = observer(
  ({ collapsed, SetCollapsed }) => {
    const { AUTH } = useStore();
    const { doLogout, appThemeMode, backToSuperAdmin } = AUTH;
    const navigate = useNavigate();
    const { setIsOpen } = useTour();
    const location = useLocation();
    const [displayTour, setDisplayTour] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState<MenuProps["items"]>([
      {
        label: (
          <Link to="/profile">
            <ProfileIcon />
            <span>Profile</span>
          </Link>
        ),
        key: "profile",
      },
      {
        label: (
          <div onClick={() => logout()}>
            <LogoutIcon />
            <span>Logout</span>
          </div>
        ),
        key: "Logout",
      },
    ]);

    // call logout function
    const logout = () => {
      doLogout().then((data: any) => {
        Notification.success({ message: data.message });
        navigate("/login", { replace: true });
      });
    };

    const superAdminLogin = async () => {
      await backToSuperAdmin();
      window.open("/users", "_self");
      navigate("/users");
      //location.reload()
    };

    useEffect(() => {
      if (localStorage.getItem("privious_token")) {
        setProfileDropdown((prev: any) => [
          ...prev,
          {
            label: (
              <div onClick={superAdminLogin} className="superadmin-menu">
                <LogInIcon />
                <span>Back to User</span>
              </div>
            ),
            key: "BackToSuperAdmin",
          },
        ]);
      }
    }, []);

    useEffect(() => {
      if (AUTH?.user?.company_id) {
        setTimeout(() => {
          const selectorLength = document.querySelectorAll("[data-tut]").length;
          const stepsData = getSteps(location.pathname, selectorLength);

          if (stepsData) {
            setDisplayTour(true);
          } else {
            setDisplayTour(false);
          }
        }, 1000);
      }
    }, [displayTour]);

    return (
      <Layout.Header className="main__page__appheader">
        <div className="headerWrap">
          <div className="appheader__left">
            <div className="menuToggle">
              {collapsed ? (
                <div
                  className="icon right"
                  onClick={() => SetCollapsed(!collapsed)}
                >
                  <LeftMenu />
                </div>
              ) : (
                <div className="icon" onClick={() => SetCollapsed(!collapsed)}>
                  <LeftMenu />
                </div>
              )}
            </div>
          </div>
          <div className="appheader__right" style={{ display: "flex" }}>
            {displayTour && (
              <div
                className="modeIcon"
                style={{ margin: "0 10px" }}
                onClick={() => setIsOpen(true)}
              >
                <TourIcon className="darkModeIcon" />
              </div>
            )}
            <div className="modeIcon" style={{ margin: "0 10px " }}>
              <Link
                rel="noreferrer"
                target="_blank"
                to={
                  "https://signageprod.s3.ap-south-1.amazonaws.com/CloudyFyTV-User-Guide.pdf"
                }
              >
                {/* AUTH?.guideLink */}
                <GuideIcon className="darkModeIcon" />
              </Link>
            </div>
            <div className="userDropdown">
              <Dropdown
                menu={{ items: profileDropdown }}
                trigger={["hover"]}
                overlayClassName="userMenuList"
              >
                <div className="userMenuWrap">
                  <span className="avatar">
                    <img src={Avatar} alt="Avatar" />
                  </span>
                  <span className="userName">{AUTH?.user?.name}</span>
                  <DownIcon className="downIcon" />
                </div>
              </Dropdown>
            </div>
            <div className="modeIcon" onClick={() => AUTH.toggleThemeClick()}>
              {appThemeMode !== 1 ? (
                <DarkMode className="darkModeIcon" />
              ) : (
                <LightMode className="lightModeIcon" />
              )}
            </div>
          </div>
        </div>
      </Layout.Header>
    );
  }
);

export default AppHeaderView;
