import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import { Regex } from "../config/Validators";

export const LoginRequest: RequestProps = {
	emailORUserName: [
		{ required: true, message: Message().required.text("Email ID or Username") },
	],
	email: [
		{ required: true, message: Message().required.text("This") },
		{ type: "email", message: Message().email("") },
	],
	password: [
		{ required: true, message: Message().required.text("Password") },
		{ pattern: Regex.password, message: "Password entry does not meet criteria." },
	],
	confirmPassword: [
		{ required: true, message: "Confirm Password cannot be empty." },
		({ getFieldValue }) => ({
			validator(rule, value) {
				if (!value || getFieldValue('password') === value) {
					return Promise.resolve();
				}
				return Promise.reject('New Password and Confirm Password does not match.');
			},
		}),
	],
	captcha: [
		{ required: true, message: Message().required.text("Captcha") },
		({ getFieldValue }) => ({
			validator(rule, value) {
				if (!value || getFieldValue('captchaGen') === value) {
					return Promise.resolve();
				}
				return Promise.reject('Captcha does not match.');
			},
		}),
	],
};
