import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import moment from "moment";
import { displayFormats } from "../../config/Global";

export default class AnalyticsStore {
  agGrid: any = null;
  per_page = Config.grid.local.gridOptions?.paginationPageSize;
  list_data_devices: Array<any> = [];
  list_data_layout: Array<any> = [];
  agLayoutGrid: any = null;
  viewLayoutList: Array<any> = [];
  agGroupGrid: any = null;
  viewDeviseGroupList: Array<any> = [];
  pinnedBottomRowDataLayout: Array<any> = [];
  pinnedBottomRowDataGroup: Array<any> = [];
  pinnedBottomRowDataViewLayout: Array<any> = [];
  pinnedBottomRowDataViewGroup: Array<any> = [];

  constructor() {
    makeAutoObservable(this);
  }

  // Setup grid and set column size to autosize for list
  setupGrid = (params: any) => {
    const columnState = localStorage.getItem("device_token");
    if (columnState) {
      params.columnApi.applyColumnState({ state: JSON.parse(columnState) });
    }
    this.agGrid = params;
  };
  // Setup grid and set column size to autosize for Layout List
  setupLayoutGrid = (params: any) => {
    const columnState = localStorage.getItem("device_token");
    if (columnState) {
      params.columnApi.applyColumnState({ state: JSON.parse(columnState) });
    }
    this.agLayoutGrid = params;
  };
  // Setup grid and set column size to autosize for Group List
  setupGroupGrid = (params: any) => {
    const columnState = localStorage.getItem("device_token");
    if (columnState) {
      params.columnApi.applyColumnState({ state: JSON.parse(columnState) });
    }
    this.agGroupGrid = params;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize for list
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(Number(page));
    }
  };
  // change page size, default page size is LocalGridConfig.options.paginationPageSize for layout list
  setLayoutPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agLayoutGrid) {
      this.agLayoutGrid.api.paginationSetPageSize(Number(page));
    }
  };
  // change page size, default page size is LocalGridConfig.options.paginationPageSize for Group list
  setGroupPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGroupGrid) {
      this.agGroupGrid.api.paginationSetPageSize(Number(page));
    }
  };

  // API Functions
  public fetchListLayout = async (): Promise<any> => {
    return await axios
      .get(API_URL.ANALYTICS.LAYOUT_ANALYTICS)
      .then(({ data }) => {
        if (data.data.restructuredData.length > 0) {
          const totalnewArray = this.pinnedTotalCountTime(data);
          this.pinnedBottomRowDataLayout = totalnewArray;

          data.data.restructuredData &&
            data.data.restructuredData.forEach((item: any) => {
              item.start_datetime = item.start_datetime
                ? moment(item.start_datetime).format(
                    displayFormats.DATE_TIME_FORMAT
                  )
                : "N/A";
              item.end_datetime = item.end_datetime
                ? moment(item.end_datetime).format(
                    displayFormats.DATE_TIME_FORMAT
                  )
                : "N/A";
            });
        }
        this.list_data_layout = data.data.restructuredData;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  // API Functions
  public fetchListDevise = async (): Promise<any> => {
    return await axios
      .get(API_URL.ANALYTICS.GROUP_ANALYTICS)
      .then(({ data }) => {
        if (data.data.restructuredData.length > 0) {
          const totalnewArray = this.pinnedTotalCountTime(data);
          this.pinnedBottomRowDataGroup = totalnewArray;
          data.data.restructuredData &&
          data.data.restructuredData.forEach((item: any) => {
            item.start_datetime = item.start_datetime
            ? moment(item.start_datetime).format(
              displayFormats.DATE_TIME_FORMAT
              )
              : "N/A";
              item.end_datetime = item.end_datetime
              ? moment(item.end_datetime).format(
                displayFormats.DATE_TIME_FORMAT
                )
                : "N/A"; 
              });
            }
            this.list_data_devices = data.data.restructuredData;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  // API Functions for drawer
  public viewLayout = async (id: number): Promise<any> => {
    return await axios
      .get(`${API_URL.ANALYTICS.LAYOUT_DETAILS}/${id}`)
      .then(({ data }) => {
        if (data.data.length > 0) {
          // const totalnewArray = this.pinnedTotalCountTime(data);
          // this.pinnedBottomRowDataViewLayout = totalnewArray;
          data.data &&
            data.data.forEach((item: any) => {
              item.start_datetime = item.start_datetime
                ? moment(item.start_datetime).format(
                    displayFormats.DATE_TIME_FORMAT
                  )
                : "N/A";
              item.end_datetime = item.end_datetime
                ? moment(item.end_datetime).format(
                    displayFormats.DATE_TIME_FORMAT
                  )
                : "N/A";
            });
        }
        this.viewLayoutList = data.data;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  // API Functions
  public viewDevise = async (id: number): Promise<any> => {
    return await axios
      .get(`${API_URL.ANALYTICS.GROUP_DETAILS}/${id}`)
      .then(({ data }) => {
        if (data.data.length > 0) {
          // const totalnewArray = this.pinnedTotalCountTime(data);
          // this.pinnedBottomRowDataViewGroup = totalnewArray;

          data.data &&
            data.data.forEach((item: any) => {
              item.start_datetime = item.start_datetime
                ? moment(item.start_datetime).format(
                    displayFormats.DATE_TIME_FORMAT
                  )
                : "N/A";
              item.end_datetime = item.end_datetime
                ? moment(item.end_datetime).format(
                    displayFormats.DATE_TIME_FORMAT
                  )
                : "N/A";
            });
        }
        this.viewDeviseGroupList = data.data;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  onGridChangedG = (params: any) => {
    if (params) {
      localStorage.setItem(
        "Analyticsg",
        JSON.stringify(params.columnApi.getColumnState())
      );
    }
  };
  onGridChangedL = (params: any) => {
    if (params) {
      localStorage.setItem(
        "Analyticsl",
        JSON.stringify(params.columnApi.getColumnState())
      );
    }
  };

  // this is for group devise Analytics
  onGridGChanged = (params: any) => {
    if (params) {
      localStorage.setItem(
        "G_Analytics",
        JSON.stringify(params.columnApi.getColumnState())
      );
    }
  };

  // this is for Analytics
  onGridLChanged = (params: any) => {
    if (params) {
      localStorage.setItem(
        "L_Analytics",
        JSON.stringify(params.columnApi.getColumnState())
      );
    }
  };

  pinnedTotalCountTime = (data: any) => {
    // Initialize variables to store the sum of hours, minutes, and seconds
    let totalHours = 0;
    let totalMinutes = 0;
    let totalSeconds = 0;

    // Iterate through the values and parse the time components
    data.data.restructuredData.forEach((value: any) => {
      const timeComponents = value.duration.split(":");
      if (timeComponents.length === 3) {
        // Assuming the format is hh:mm:ss
        totalHours += parseInt(timeComponents[0], 10);
        totalMinutes += parseInt(timeComponents[1], 10);
        totalSeconds += parseInt(timeComponents[2], 10);
      }
    });

    // Calculate the total time in the format "hh:mm:ss"
    totalMinutes += Math.floor(totalSeconds / 60);
    totalSeconds = totalSeconds % 60;
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;

    // Format the result as "hh:mm:ss"
    const formattedResult = `${String(totalHours).padStart(2, "0")}:${String(
      totalMinutes
    ).padStart(2, "0")}:${String(totalSeconds).padStart(2, "0")}`;

    return [
      {
        layout_id: "Total",
        device_id: "Total",
        layout_name: "",
        start_datetime: "",
        end_datetime: "",
        duration: formattedResult,
        action: "",
      },
    ];
  };
}
