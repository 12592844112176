import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import { RateListDataProps } from "./RateInterface";
import moment from "moment";
import { displayFormats } from "../../config/Global";

export default class RateStore {
	agGrid: any = null;
	per_page = Config.grid.local.gridOptions?.paginationPageSize;
	public list_data?: RateListDataProps[];
	editValues = null;
	deleteValues = null;
	dropdown_user_role_list = null;
	bulk_data_list: any = null


	constructor() {
		makeAutoObservable(this);
	}

	// Setup grid and set column size to autosize
	setupGrid = (params: any) => {
		const columnState = localStorage.getItem("rate_token")
		if (columnState) {
			params.columnApi.applyColumnState({ state: JSON.parse(columnState) });

		}
		this.agGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(Number(page));
		}
	};

	setDeleteValues = (data: any) => {
		this.deleteValues = data;
	};

	setEditValues = (data: any) => {
		this.editValues = data;
	};
	onGridChanged = (params: any) => {
		if (params) {
			localStorage.setItem('rate_token', JSON.stringify(params.columnApi.getColumnState()))
		}
	}

	// Setter Functions
	private setListData = (value?: RateListDataProps[]): void => {
		this.list_data = value;
	};

	// API Functions
	public fetchList = async (): Promise<any> => {
		this.setListData([]);
		return await axios.get(API_URL.RATE.LIST).then(({ data }) => {
			data.data.forEach((it: any) => {
				it.date = it.updated_at && moment(it.updated_at).format(displayFormats.DATE_MONTH_YEAR)
			})
			this.setListData(data.data);
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	public publishRate = async () => {
		return axios.post(API_URL.RATE.PUBLISH_RATE).then(({ data }) => {
			return data
		}).catch((response) => {
			return Promise.reject(response)
		})
	}


	AddData = (formData: any) => {
		return axios
			.post(API_URL.RATE.ADD, formData)
			.then(({ data }) => {
				this.fetchList();
				return data
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	DeleteData = (id: number) => {
		return axios
			.post(API_URL.RATE.DELETE + `/${id}`)
			.then(({ data }) => {
				this.fetchList();
				return data

			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	setBulkRead = async () => {
		return await axios.get(API_URL.RATE.BULK_READ).then(({ data }) => {
			this.bulk_data_list = data.data
		}).catch((response) => {
			return Promise.reject(response)
		})
	}

	setBulkUpdate = async (data: any) => {
		const comany: any = {}
		comany.compnayrate = data
		return await axios.post(API_URL.RATE.BULK_EDIT, comany).then(({ data }) => {
			this.fetchList()
			return data
		}).catch((response) => {
			return Promise.reject(response)
		})
	}

	EditData = (formData: any, editId: number) => {
		return axios
			.post(API_URL.RATE.EDIT + "/" + editId, formData)
			.then(({ data }) => {
				this.fetchList();
				return data
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
}
