import { Button, Drawer, PageHeader } from "antd";
import React from "react";
import { AnalyticsLComponentProps } from "../../../../../store/CustmerStore/CustomerInterface";
import { ReactComponent as CloseIcon } from "../../../../../assets/images/icon/close.svg";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import { AgGridColumn } from "ag-grid-react";
import useStore from "../../../../../store";
import { dateComparator } from "../../../../../config/Global";
import { DeviceBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent";
import RecordPerPage from "../../../../../components/AgGridWrapper/RecordPerPage";
import { observer } from "mobx-react";

const AnalyticsLayoutDrawer: React.FC<AnalyticsLComponentProps> = observer(
  (props: AnalyticsLComponentProps) => {
    const { close, visible, viewData } = props;
    const { ANALYTICS } = useStore();

    const closeModal = () => {
      close();
    };
    const columnSetup = () => {
      return [
        <AgGridColumn
          key="device_id"
          field="device_id"
          headerName="# ID"
          filter={"agNumberColumnFilter"}
          tooltipField="id"
          maxWidth={80}
        />,

        <AgGridColumn
          key="device_name"
          field="device_name"
          tooltipField="device_name"
          headerName="Device Name"
          // maxWidth={250}
          minWidth={250}
          width={250}
          cellEditor={"customEditor"}
        />,
        <AgGridColumn
          key="start_datetime"
          field="start_datetime"
          headerName="Start Date & Time"
          filter={"agDateColumnFilter"}
          filterParams={dateComparator}
          tooltipField="Start Date & Time"
          minWidth={100}
        />,
        <AgGridColumn
          key="end_datetime"
          field="end_datetime"
          headerName="End Date & Time"
          filter={"agDateColumnFilter"}
          filterParams={dateComparator}
          tooltipField="End Date & Time"
          minWidth={100}
          // rowGroup={true}
        />,

        <AgGridColumn
          key="duration"
          field="duration"
          headerName="Duration"
          tooltipField="Duration"
          minWidth={100}
        />,
      ];
    };

    return (
      <>
        <Drawer
          open={visible}
          onClose={closeModal}
          width={"60%"}
          closeIcon={<CloseIcon />}
          className="commanDrawer addDeviceModal"
          title={`View Group By Layouts`}
          destroyOnClose={true}
          footer={[
            <div key="1">
              <Button className="orangeBgBtn cancelBtn" onClick={closeModal}>
                Cancel
              </Button>
            </div>,
          ]}
        >
          <div className="header">
            <PageHeader
              title={`${viewData?.layout_name} Analytics`}
              tags={<BreadcrumbComponent items={DeviceBreadcrumb.path} />}
              extra={[
                <RecordPerPage
                  key="2"
                  defaultValue={ANALYTICS.per_page + " per page"}
                  onChange={ANALYTICS.setLayoutPageSize}
                />,
              ]}
            >
              <AgGridWrapper
                onGridReady={ANALYTICS.setupLayoutGrid}
                rowData={ANALYTICS.viewLayoutList}
                // onColumnResized={ANALYTICS.onGridLChanged}
                // onColumnMoved={ANALYTICS.onGridLChanged}
                // pinnedBottomRowData={ANALYTICS.pinnedBottomRowDataViewLayout}
              >
                {columnSetup()}
              </AgGridWrapper>
            </PageHeader>
          </div>
        </Drawer>
      </>
    );
  }
);

export default AnalyticsLayoutDrawer;
