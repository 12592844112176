import { Button, Form } from 'antd';
import React, { useState } from 'react'
import LoginBanner from "../../../assets/images/login-image.png";
import { FormBox, InputBox } from '../../../components/AntdAddons';
import { LoginRequest } from '../../../requests/AuthRequest';
import LoginBG from "../../../assets/images/login-pattern.png";
import { ErrorProps } from '../../../store/RootStore/RootInterface';
import { Link, useNavigate } from 'react-router-dom';
import useStore from '../../../store';
import { Notification } from '../../../config/Global';

const ForgotPassword: React.FC = () => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const navigate = useNavigate();
	const { AUTH, ROOT } = useStore();
	const { forgotPassword } = AUTH;
	const { assignErrorToInput } = ROOT;

	const handleSubmit = (data: any) => {
		setSaving(true);
		forgotPassword(data).then((data: any) => {
			navigate('/login');
			form.resetFields()
			Notification.success({ message: data.message })
		}).catch((e: ErrorProps) => {
			assignErrorToInput(form, e?.data.errors);
			setSaving(false);
		}).finally(() => setSaving(false));
	};

	return (
		<section className="loginSection forgotPassword">
			<div className="loginWrap">
				<div className="loginLogo">
					<div className="loginBanner">
						<img src={LoginBanner} alt="Banner" />
					</div>
				</div>
				<div className="loginForm" style={{ backgroundImage: `url(${LoginBG})` }}>
					<div className="loginFormWrap">
						<div className="formTitle">
							<h2>Forgot Password</h2>
						</div>
						<FormBox form={form} onFinish={handleSubmit}>
							<InputBox.Text
								name="email"
								label="Email ID"
								placeholder="Enter Email"
								rules={LoginRequest.email}
							/>
							<div className="text-center">
								<Button loading={saving} type="primary" htmlType="submit" className="activeBtn">
									Forgot Password
								</Button>
							</div>
							<div className="text-right mt-10 font-medium text-white">
								<Link to="/login">Back to login</Link>
							</div>
						</FormBox>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ForgotPassword;