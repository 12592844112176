import React, { useState } from 'react'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { SavedLayoutBreadcrumb } from '../../../config/BreadcrumbConfig'
import { PageHeader } from 'antd'
import RecordPerPage from '../../../components/AgGridWrapper/RecordPerPage'
import ListComponent from './components/ListComponent'
import { observer } from 'mobx-react'
import PublishComponent from './components/PublishComponent'
import DeleteComponent from './components/DeleteComponent'
import useStore from '../../../store'
import Unauthorized from '../../errors/Unauthorized'
import { Notification } from '../../../config/Global'

const SavedLayout: React.FC<any> = observer(() => {
	const { SAVED_LAYOUT } = useStore()
	const [openPublishmodal, setOpenpublishmodal] = useState<boolean>(false)
	const [deleteModal, setDeleteModal] = useState<boolean>(false)

	const { AUTH } = useStore()

	const openPublishModal = (data: any) => {
		SAVED_LAYOUT.setViewValue(data)
		setOpenpublishmodal(true)
	}

	const closePublishModal = () => {
		setOpenpublishmodal(false)
		SAVED_LAYOUT.setViewValue(null)
	}

	const openPreviewsavedlayout = (data: any) => {
		SAVED_LAYOUT.PreviewSavedLayout(data).then((data: any) => {
			if (data.data.layout_html_file_url) {
				window.open(data.data.layout_html_file_url)
			}
		}).catch((e: any) => {
			Notification.error({
				message: e.data.message
			})
		})
	}


	const openDeleteModal = (data: any) => {
		SAVED_LAYOUT.setViewValue(data)
		setDeleteModal(true)
	}

	const closeDeleteModal = () => {
		SAVED_LAYOUT.setViewValue(null)
		setDeleteModal(false)
	}

	return (
		<div>

			{!AUTH.checkPermission(5, 'list') ? <Unauthorized /> : <PageHeader title={SavedLayoutBreadcrumb.title}
				tags={<BreadcrumbComponent items={SavedLayoutBreadcrumb.path} />}
				extra={[
					<RecordPerPage defaultValue={SAVED_LAYOUT.per_page + ' per page'} key='1' onChange={SAVED_LAYOUT.setPageChange} />
				]}>
				<ListComponent openPublishModal={openPublishModal} openDeleteModal={openDeleteModal} openPreviewsavedlayout={openPreviewsavedlayout} />
				<PublishComponent visible={openPublishmodal} close={closePublishModal} />
				<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
			</PageHeader>}
		</div>
	)
})

export default SavedLayout