import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import SidebarView from "./Sidebar/SidebarView";
import AppHeaderView from "./AppHeader/AppHeaderView";
import AppFooterView from "./AppFooter/AppFooterView";
import useStore from "../../../store";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import { useTour } from "@reactour/tour";

import { getSteps } from "./Steps";

const MainLayout: React.FC = observer(() => {
  const [collapsed, SetCollapsed] = useState<boolean>(
    window.matchMedia("(min-width: 576px)") ? false : true
  );
  const { AUTH, LAYOUT, DEVICE } = useStore();
  const { setIsOpen, setSteps, setCurrentStep } = useTour();
  const location = useLocation();

  const { token, app_loading, subscription } = AUTH;
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  useEffect(() => {
    if (AUTH?.user?.company_id) {
      setCurrentStep(0);

      setTimeout(() => {
        const selectorLength = document.querySelectorAll("[data-tut]").length;

        const stepsData = getSteps(location.pathname, selectorLength);

        if (stepsData) {
          setSteps && setSteps(stepsData?.steps);
          stepsData.display && setIsOpen(true);
        } else {
          setIsOpen(false);
          setSteps && setSteps([]);
        }
      }, 1000);
    }
  }, [location.pathname, LAYOUT.list_data, DEVICE.list_data]);

  return app_loading ? (
    <PageSpinner />
  ) : (
    <>
      {subscription?.type === 1 && (
        <div className="animated-div">{`You are currently on a free trial plan, and you have ${AUTH?.subscription?.free_trail_days} days remaining.`}</div>
      )}
      <Layout
        className={`main__page__wrapper has__appsidebar`}
      >
        <SidebarView collapsed={collapsed}></SidebarView>
        <Layout className="site-layout">
          <AppHeaderView collapsed={collapsed} SetCollapsed={SetCollapsed} />
          <Layout.Content className="main__app__content">
            <Outlet />
          </Layout.Content>
          <AppFooterView />
        </Layout>
      </Layout>
    </>
  );
});

export default MainLayout;
