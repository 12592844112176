import React, { useState } from "react"
import { Button, Col, Form, Input, Modal, Row } from "antd"
import { observer } from "mobx-react"
import { componentProps } from '../../../../store/DeviceStore/DeviceInterface';
import { ReactComponent as WarningFilled } from "../../../../assets/images/icon/warning.svg"
import useStore from "../../../../store"
import { Notification } from "../../../../config/Global";
import { ErrorProps } from "../../../../store/RootStore/RootInterface";

const DeleteComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const [form] = Form.useForm()
	const { DEVICE, AUTH } = useStore()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState<boolean>(false)

	const handleSubmit = (data: any) => {
		setSaving(true)
		data.id = DEVICE?.deleteValues.id
		DEVICE.DeleteData(data.id).then((data: any) => {
			Notification.success({
				message: data.data
			})
			AUTH.fetchAuthUser()
			handleClose()
		}).catch((e: ErrorProps) => {
			e;
		}).finally(() => {
			setSaving(false)
		})
	}

	const handleClose = () => {
		close()
		form.resetFields()
		setDisabled(true)
	}

	const handleFieldChange = () => {
		if (form.getFieldsValue().confirm.toLowerCase() === "confirm") {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}

	return (
		<Modal
			open={visible}
			onCancel={handleClose}
			width={'35%'}
			title={`Delete Device`}
			className="commanModal deleteModal"
			destroyOnClose={true}
			footer={[
				<div key="1">
					<Button className={`${disabled ? 'disableBtn' : 'deleteBtn'}`} form='deleteform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Delete</Button>
					<Button className="redBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						{/* <Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col> */}
						<Col span={4}><WarningFilled /></Col>
						<Col span={20}>
							<h3>Are you sure you want to Delete <strong>{DEVICE.deleteValues?.name}</strong>  Device ?</h3>
							<Form.Item style={{ marginBottom: 0 }} name="confirm">
								<Input placeholder='Type "CONFIRM" to delete' onChange={handleFieldChange} />
							</Form.Item>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	)
})

export default DeleteComponent