import React from "react";
import { observer } from "mobx-react";
import { Modal } from "antd";
import { CustomerProps } from "../../../../store/RateStore/RateInterface";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CustomerCreateForm from "./CustomerCreateForm";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_TEST_KEY || ""}`);
const CustomerCreate: React.FC<CustomerProps> = observer(
  (props: CustomerProps) => {
    return (
      <Modal
        open={props.visible}
        onCancel={props.close}
        title={`Customer Price Modal`}
        className="commanModal addRate"
        destroyOnClose={true}
        footer={false}
        width={"540px"}
      >
        <Elements stripe={stripePromise} >
          <CustomerCreateForm subData={props.subData} close={props.close}/>
        </Elements>
      </Modal>
    );
  }
);

export default CustomerCreate;
