import React, { useState } from "react"
import { Button, Col, Form, Input, Modal, Row, Spin } from "antd"
import { observer } from "mobx-react"
import { componentProps } from "../../../../store/UserRoleStore/UserRoleInterface"
import useStore from "../../../../store"
import { Notification } from "../../../../config/Global"
import { ReactComponent as WarningFilled } from "../../../../assets/images/icon/warning.svg"

const DeleteComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const [form] = Form.useForm()
	const { USER, AUTH } = useStore()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState<boolean>(false)

	const handleSubmit = (data: any) => {
		setSaving(true)
		data.id = USER?.viewValues.id
		USER.DeleteData(data).then((data: any) => {
			Notification.success({
				message: data.message
			})
			handleClose()
			AUTH.fetchAuthUser()

		}).catch((e: any) => {
			if (e.errors) {
				form.setFields(e.errors)
			}
		}).finally(() => {
			setSaving(false)
		})
	}

	const handleClose = () => {
		close()
		form.resetFields()
		setDisabled(true)
	}

	const handleFieldChange = () => {
		if (form.getFieldsValue().confirm.toLowerCase() === "confirm") {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}

	return (
		<Modal
			open={visible}
			onCancel={handleClose}
			width={700}
			title={`Delete User`}
			destroyOnClose={true}
			className="commanModal deleteModal"
			footer={[
				<div key="1">
					<Button className={`${disabled ? 'disableBtn' : 'deleteBtn'}`} form='deleteform' disabled={disabled}
						loading={saving} htmlType="submit" type="primary">Delete</Button>
					<Button className="redBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={!USER.viewValues} tip={"Loading"}>
				<Form form={form} id="deleteform" onFinish={handleSubmit}>
					{
						<Row>
							<Col span={4}><WarningFilled /></Col>
							<Col span={20}>
								<h3>Are you sure you want to delete
									{/* user name  */}
									<strong> {USER.viewValues?.name} </strong> which role is <strong>{USER.viewValues?.role?.role_name}</strong> ?</h3>
								<Form.Item style={{ marginBottom: 0 }} name="confirm">
									<Input placeholder='Type "CONFIRM" to delete' onChange={handleFieldChange} />
								</Form.Item>
							</Col>
						</Row>
					}
				</Form>
			</Spin>
		</Modal>
	)
})

export default DeleteComponent