import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Spin } from 'antd';
import { componentProps } from '../../../../store/RateStore/RateInterface';
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper';
import { AgGridColumn } from 'ag-grid-react';
import useStore from '../../../../store';
import { InputBox } from '../../../../components/AntdAddons';
import moment from 'moment';
import { Notification, agDateColumnFilter, displayFormats } from '../../../../config/Global';

const LogComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const { DEVICE } = useStore()
	const [loading, setLoading] = useState(false)

	const columnSetup = () => {
		return ([
			<AgGridColumn
				key='layout_id'
				field="layout_id"
				headerName="# ID"
				// pinned="left"
				width={80}
				minWidth={80}
			/>,
			<AgGridColumn
				key='device_name'
				field="device_name"
				headerName="Device name"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='layout_name'
				field="layout_name"
				headerName="Layout"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='date'
				field="date"
				headerName="Date"
				filter={'agDateColumnFilter'}
				filterParams={agDateColumnFilter}
				width={100}
				minWidth={100}
			/>,
		])
	}
	const handleClose = () => {
		close();
		// DEVICE.logAgGrid.api.destroy();
		DEVICE.log_list = null;
		// DEVICE.setupGrid(DEVICE.agGrid);
	}

	const handleChange = (e: any) => {
		setLoading(true)
		const start_date = e && moment(e[0]).format('YYYY-MM-DD')
		const end_date = e && moment(e[1]).format('YYYY-MM-DD')
		const device_id = visible.id
		const payload = {
			start_date: start_date,
			end_date: end_date,
			device_id: device_id
		}
		DEVICE?.setLogtList(payload).then((data: any) => {
			if (!Array.isArray(data.data)) {
				Notification.error({
					message: data.data
				})
				DEVICE.log_list = null
			}

		}).catch((e: any) => {
			Notification.error({
				message: e.data
			})
		}).finally(() => {
			setLoading(false)
		})
	}

	return (
		<Drawer
			open={visible}
			onClose={handleClose}
			width={'750px'}
			title={`Manage Your Device Log`}
			className='commanDrawer viewDeviceDrawer'
			destroyOnClose={true}
			footer={[
				<div key="1" className="centerBtn">
					<Button onClick={handleClose} className='orangeBgBtn'>Close</Button>
				</div>
			]}
		>
			<InputBox.DateRangePicker onChange={handleChange}
				allowClear={false}
				format={displayFormats.DATE_MONTH_YEAR}
				ranges={{
					Today: [moment(), moment()],
					'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
					"Last 7 Days": [moment().add(-7, 'd'), moment()],
					"Last 30 Days": [moment().add(-30, 'd'), moment()],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					"Last Month": [moment().add(-1, 'months'), moment()],
					"Last Year": [moment().subtract(1, 'year'), moment()]
				}}
			></InputBox.DateRangePicker>
			{
				DEVICE.log_list ?
					<AgGridWrapper onGridReady={DEVICE.logSetupGrid} rowData={DEVICE?.log_list} className='gridCommon'>
						{columnSetup()}
					</AgGridWrapper> :
					<Spin spinning={loading} />
			}
		</Drawer>
	)
})

export default LogComponent