import { Button, Modal, Row, Col } from 'antd'
import React from 'react'
import { ReactComponent as WarningFilledOrange } from '../../../../assets/images/icon/warningOrange.svg'
import { Notification } from '../../../../config/Global'
import { observer } from 'mobx-react'
import useStore from '../../../../store'

const SwitchComponent = observer((props: any) => {
    const { visible, close } = props
    const { USER } = useStore()

    const handleSwitch = () => {
        USER.ActiveStatus(visible.id, visible.status).then((data: any) => {
            Notification.success({ message: data.message })
        })
        close()
    }
    return (
        <div>
            <Modal title={visible.status === 0 ? 'Active User' : 'Deactivate User'} open={visible} onCancel={close}
                className='commanModal deactivateModal'
                footer={[
                    <Button key={1} onClick={handleSwitch} className='blackLineBtn'>
                        Yes
                    </Button>,
                    <Button onClick={close} key={2} className="orangeBgBtn cancelBtn">
                        No
                    </Button>,
                ]}>
                <Row>
                    <Col className='deactiveCol' span={4}>
                        <WarningFilledOrange />
                    </Col>
                    <Col span={20}>
                        <h3>
                            Would you like to{' '}
                            <span>   {visible.status === 0 ? 'Activate' : 'Deactivate'} {visible.name}</span> ?
                        </h3>
                    </Col>
                </Row>
            </Modal>

        </div>
    )
})

export default SwitchComponent