import {
  Button,
  Col,
  Form,
  FormInstance,
  message,
  Modal,
  Row,
  Upload,
} from "antd";
import { RcFile, UploadFile } from "antd/lib/upload";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import useStore from "../../../../store";
import { createUrl } from "../../../../config/Global";
import { InputBox } from "../../../../components/AntdAddons";

interface formInterface {
  form: FormInstance;
  name: string;
  global_layout_id: any;
  onChange: () => void;
  layoutIdFlag?:boolean;
}

const BackgroundMusicElement: React.FC<formInterface> = observer((props) => {
  const { form, onChange, name, global_layout_id, layoutIdFlag } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [visible, setVisible] = useState<boolean>(false);

  const { LAYOUT } = useStore();

  useEffect(() => {
    if (LAYOUT.editValues && form.getFieldValue(name)) {
      setFileList([
        {
          uid: "-1",
          name: form.getFieldValue(name),
          status: "done",
          url: createUrl("/storage/media/file/" + form.getFieldValue(name)),
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (global_layout_id && layoutIdFlag) {
      setFileList([]);
      form.setFieldsValue({ [name] : null })
    }
  }, [global_layout_id]);

  const onPreview = (file: any) => {
    const url = file.url ? file.url : URL.createObjectURL(file?.originFileObj);
    window.open(url);
  };
  useEffect(() => {
    if (!form.getFieldValue("audio_status")) {
      form.setFieldsValue({ audio_status: "0" });
    }
  }, [form, global_layout_id]);

  //For showing modal on file removal :Start
  const toggle = () => {
    setVisible((prev) => !prev);
    return false;
  };

  const deleteItem = () => {
    if (typeof form.getFieldValue(name) !== "object") {
      const filePath = "/storage/media/file/" + form.getFieldValue(name);
      LAYOUT.RemoveFileApi(LAYOUT.editValues.id, filePath);
      toggle();
    }
    setFileList([]);
    form.resetFields([name]);
    onChange();
    return true;
  };
  //For showing modal on file removal :End

  return (
    <>
      <Row gutter={48} className="layoutOptions">
        <Col span={12}>
          <Form.Item
            name={name}
            className="full-width-dragger"
            label={<div>Background Music</div>}
            extra={"You can select mp3 music file for this widget"}
          >
            <Upload
              accept=".mp3"
              listType="text"
              multiple={false}
              fileList={fileList}
              onRemove={
                typeof form.getFieldValue(name) !== "object"
                  ? toggle
                  : deleteItem
              }
              onPreview={onPreview}
              showUploadList={{ showPreviewIcon: false }}
              onChange={({ fileList }: any) => {
                setFileList(fileList);
                form.setFieldsValue({
                  [name]: fileList[0]?.originFileObj,
                });
                onChange();
              }}
              beforeUpload={(file: RcFile) => {
                const isJpgOrPng = file.type === "audio/mpeg";
                const isLt1M = file.size / 1024 / 1024 < 5;

                if (!isJpgOrPng) {
                  message.error(`Please upload file should be mp3`);
                  return Upload.LIST_IGNORE;
                } else if (!isLt1M) {
                  message.error("File size should not be more than 5 MB");
                  return Upload.LIST_IGNORE;
                } else {
                  setFileList([file]);
                  return false;
                }
              }}
            >
              {fileList.length < 1 && (
                <Button icon={<UploadOutlined />}>Upload</Button>
              )}
            </Upload>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="full-width-dragger"
            shouldUpdate={(prevValues, curValues) =>
              prevValues.bg_music !== curValues.bg_music
            }
          >
            {() => {
              return (
                form &&
                form.getFieldValue("bg_music") && (
                  <InputBox.Radio
                    name={"audio_status"}
                    label={<div>Background Music Status</div>}
                    onChange={onChange}
                    options={{
                      list: [
                        { id: "1", name: "enable", label: "Enable" },
                        { id: "0", name: "disable", label: "Disable" },
                      ],
                      valueKey: "id",
                      textKey: "label",
                    }}
                  />
                )
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Modal
        okText="Yes"
        cancelText="No"
        open={visible}
        onOk={deleteItem}
        onCancel={toggle}
        className="removeUploadModal"
      >
        Are you sure you want to permanently remove this Item ?
      </Modal>
    </>
  );
});

export default BackgroundMusicElement;
