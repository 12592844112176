import { Button, Checkbox, Col, Drawer, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import Config from "../../../../config/Config";
import { StyleLayoutProps } from "../../../../requests/PageRequest";
import useStore from "../../../../store";
import { objectToRGB, validateFields } from "../../../../config/Global";

const StyleModalComponent: React.FC<any> = (props) => {
  const {
    visible,
    close,
    styleProps,
    styleProps: { styles, type },
    setDataV,
    dataV,
    name,
    setShow,
  } = props;
  const [disabled, setDisabled] = useState<boolean>(true);
  const [applyBorder, setApplyBorder] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { LAYOUT } = useStore();
  const colorStyle = [
    {
      name: "1",
      label: "Sample 1",
      backgroundColor: "rgba(247, 242, 242, 1)",
      titleBgColor: "rgba(2, 173, 139, 1)",
      titleFontColor: "rgba(247, 242, 242, 1)",
      oddRowBgColor: "rgba(247, 242, 242, 1)",
      oddRowFontColor: "rgba(0, 0, 0, 1)",
      evenRowBgColor: "rgba(237, 237, 237, 1)",
      evenRowFontColor: "rgba(0, 0, 0, 1)",
    },
    {
      name: "2",
      label: "Sample 2",
      backgroundColor: "rgba(247, 242, 242, 1)",
      titleBgColor: "rgba(162, 27, 184, 1)",
      titleFontColor: "rgba(255, 255, 255, 1)",
      oddRowBgColor: "rgba(229, 221, 250, 1)",
      oddRowFontColor: "rgba(0, 0, 0, 1)",
      evenRowBgColor: "rgba(142, 124, 255, 1)",
      evenRowFontColor: "rgba(255, 255, 255, 1)",
    },
    {
      name: "3",
      label: "Sample 3",
      backgroundColor: "rgba(247, 242, 242, 1)",
      titleBgColor: "rgba(70, 130, 180, 1)",
      titleFontColor: "rgba(247, 242, 242, 1)",
      oddRowBgColor: "rgba(211, 211, 211, 1)",
      oddRowFontColor: "rgba(0, 0, 0, 1)",
      evenRowBgColor: "rgba(173, 216, 230, 1)",
      evenRowFontColor: "rgba(0, 0, 0, 1)",
    },
    {
      name: "4",
      label: "Sample 4",
      backgroundColor: "rgba(247, 242, 242, 1)",
      titleBgColor: "rgba(162, 27, 184, 1)",
      titleFontColor: "rgba(255, 255, 255, 1)",
      oddRowBgColor: "rgba(229, 221, 250, 1)",
      oddRowFontColor: "rgba(0, 0, 0, 1)",
      evenRowBgColor: "rgba(142, 124, 255, 1)",
      evenRowFontColor: "rgba(255, 255, 255, 1)",
    },
  ];
  const handleSubmit = (data: any) => {
    const copyStyleProps = { ...LAYOUT.styleProps };
    copyStyleProps[type] = data;
    LAYOUT.styleProps = copyStyleProps;
    form.resetFields();
    close();
    if (applyBorder) {
      setDataV(true);
    }
    if (data) {
      setShow(true);
    }
  };

  const handleChange = () => {
    validateFields(form, setDisabled);
  };
  useEffect(() => {
    if (dataV) {
      setApplyBorder(true);
    } else {
      setApplyBorder(false);
    }
  }, [dataV]);
  useEffect(() => {
    if (LAYOUT.styleProps) {
      const TempObj: any = {};
      if (LAYOUT.styleProps[type]) {
        if (LAYOUT.styleProps[type] && LAYOUT.styleProps[type].length) {
          LAYOUT.styleProps[type].map((_x: any) => {
            TempObj[_x.attribute] = _x.value;

            if (_x.attribute === "apply_border" && _x.value == "1") {
              setApplyBorder(true);
            } else if (_x.attribute === "apply_border") {
              setApplyBorder(false);
            }
          });
          form.setFieldsValue(TempObj);
        } else {
          form.setFieldsValue(LAYOUT.styleProps[type]);
        }
      }
    }
  }, []);

  return (
    <Drawer
      open={visible}
      onClose={close}
      width={"40%"}
      title={`Add Style`}
      className="commanDrawer"
      destroyOnClose={true}
      footer={[
        <div key="1">
          <Button
            form="addEditStyleLayoutForm"
            htmlType="submit"
            type="primary"
            className={`${disabled ? "disableBtn" : "blackLineBtn"}`}
            disabled={disabled}
          >
            Apply
          </Button>
          <Button className="orangeBgBtn cancelBtn" onClick={close}>
            Cancel
          </Button>
        </div>,
      ]}
    >
      <FormBox
        form={form}
        id="addEditStyleLayoutForm"
        onFinish={handleSubmit}
        onChange={handleChange}
        initialValues={LAYOUT.initialValues}
      >
        {styleProps && styles && type ? (
          <>
            <Row gutter={Config.gutter}>
              {styles.includes("sliderTimeOut") &&
                ![
                  "twitt_post-right-top",
                  "twitt_post-left-bottom",
                  "twitt_post",
                ].includes(name) && (
                  <Col span={12}>
                    <InputBox.Text
                      label={"Image Slide Timeout [in Sec.]"}
                      name={`timeout`}
                      rules={StyleLayoutProps.slide_time_out}
                    />
                  </Col>
                )}
              {styles.includes("applyFormat") && (
                <Col span={12}>
                  <InputBox.Radio
                    label={"Apply Format"}
                    className="formatRadioBtn"
                    name={`format`}
                    options={{
                      list: [
                        { name: "1", label: "Normal" },
                        { name: "2", label: "Rate With Row" },
                      ],
                      valueKey: "name",
                      textKey: "label",
                    }}
                  />
                </Col>
              )}
              {styles.includes("orderBy") && (
                <Col span={12}>
                  <InputBox.Radio
                    label={"Order By"}
                    name={`forderBy`}
                    options={{
                      list: [
                        { name: "item", label: "Name" },
                        { name: "price", label: "Price" },
                      ],
                      valueKey: "name",
                      textKey: "label",
                    }}
                  />
                </Col>
              )}
              {styles.includes("orderDirectory") && (
                <Col span={12}>
                  <InputBox.Radio
                    label={"Order Directory"}
                    name={`orderDir`}
                    options={{
                      list: [
                        { name: "item", label: "ASC" },
                        { name: "DESC", label: "Desc" },
                      ],
                      valueKey: "name",
                      textKey: "label",
                    }}
                  />
                </Col>
              )}
              {styles.includes("applyScroll") && (
                <Col span={12}>
                  <InputBox.Radio
                    label={"Apply Auto Scroll"}
                    name={`scrollable`}
                    options={{
                      list: [
                        { name: "1", label: "Yes" },
                        { name: "2", label: "No" },
                      ],
                      valueKey: "name",
                      textKey: "label",
                    }}
                  />
                </Col>
              )}
              {styles.includes("colorStyle") && (
                <Col span={12}>
                  <InputBox.Select
                    label={"Color Style"}
                    onChange={(selectedColor: any) => {
                      handleChange();
                      const selected = colorStyle.filter(
                        (item: any) => selectedColor === item.name
                      );
                      form.setFieldsValue({
                        "background-color": selected[0].backgroundColor,
                        "title-background-color": selected[0].titleBgColor,
                        "title-color": selected[0].titleFontColor,
                        "odd-background-color": selected[0].oddRowBgColor,
                        "odd-color": selected[0].oddRowFontColor,
                        "even-background-color": selected[0].evenRowBgColor,
                        "even-color": selected[0].evenRowFontColor,
                      });
                    }}
                    name={`color_format`}
                    options={{
                      list: colorStyle,
                      valueKey: "name",
                      textKey: "label",
                    }}
                  />
                </Col>
              )}
              {styles.includes("titleBgColor") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Title Bg Color"}
                    name={`title-background-color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue(
                          "title-background-color",
                          objectToRGB(color)
                        );
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("iconColor") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Event Icon Color"}
                    name={`Icon Color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue("Icon Color", objectToRGB(color));
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("first_event_color") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"First Event Color"}
                    name={`First Event Color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue(
                          "First Event Color",
                          objectToRGB(color)
                        );
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("second_event_color") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Second Event Color"}
                    name={`Second Event Color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue(
                          "Second Event Color",
                          objectToRGB(color)
                        );
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("third_event_color") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Third Event Color"}
                    name={`Third Event Color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue(
                          "Third Event Color",
                          objectToRGB(color)
                        );
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("four_event_color") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Fourth Event Color"}
                    name={`Four Event Color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue(
                          "Four Event Color",
                          objectToRGB(color)
                        );
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("titleFontColor") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Title Font Color"}
                    name={`title-color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue("title-color", objectToRGB(color));
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("oddRowBgColor") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Odd Row Bg Color"}
                    name={`odd-background-color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue(
                          "odd-background-color",
                          objectToRGB(color)
                        );
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("oddRowFontColor") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Odd Row Font Color"}
                    name={`odd-color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue("odd-color", objectToRGB(color));
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("evenRowBgColor") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Even Row Bg Color"}
                    name={`even-background-color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue(
                          "even-background-color",
                          objectToRGB(color)
                        );
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("evenRowFontColor") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Even Row Font Color"}
                    name={`even-color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue("even-color", objectToRGB(color));
                      }, 0);
                    }}
                  />
                </Col>
              )}

              {styles.includes("bgColor") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Background Color"}
                    name={`background-color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue(
                          "background-color",
                          objectToRGB(color)
                        );
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("objectFit") && (
                <Col span={12}>
                  <InputBox.Select
                    name="object-fit"
                    placeholder="Select Object Fit"
                    label="Object Fit"
                    onChange={handleChange}
                    allowClear
                    options={{
                      list: [
                        { id: "cover", name: "cover" },
                        { id: "contain", name: "contain" },
                      ],
                    }}
                  />
                </Col>
              )}
              {styles.includes("textColor") && (
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Text Color"}
                    name={`color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue("color", objectToRGB(color));
                      }, 0);
                    }}
                  />
                </Col>
              )}
              {styles.includes("fontWeight") && (
                <Col span={12}>
                  <InputBox.Select
                    name="font-weight"
                    placeholder="Select font weight"
                    label="Font weight"
                    onChange={handleChange}
                    allowClear
                    options={{
                      list: [
                        { id: 100, name: "100" },
                        { id: 200, name: "200" },
                        { id: 400, name: "400" },
                        { id: 700, name: "700" },
                        { id: 900, name: "900" },
                      ],
                    }}
                  />
                </Col>
              )}
              {styles.includes("textFontWeight") && (
                <Col span={12}>
                  <InputBox.Select
                    name="text-font-weight"
                    placeholder="Select text font weight"
                    label="Text Font Weight"
                    onChange={handleChange}
                    options={{
                      list: [
                        { id: "Normal", name: "Normal" },
                        { id: "Bold", name: "Bold" },
                      ],
                    }}
                  />
                </Col>
              )}
              {styles.includes("rateFontWeight") && (
                <Col span={12}>
                  <InputBox.Select
                    name="rate-font-weight"
                    placeholder="Select rate font weight"
                    label="Rate Font Weight"
                    onChange={handleChange}
                    options={{
                      list: [
                        { id: "Normal", name: "Normal" },
                        { id: "Bold", name: "Bold" },
                      ],
                    }}
                  />
                </Col>
              )}
              {styles.includes("margin") && (
                <Col span={12}>
                  <InputBox.Text
                    name="margin"
                    placeholder="Enter margin"
                    label="Margin"
                    rules={StyleLayoutProps.margin}
                  />
                </Col>
              )}
              {styles.includes("padding") && (
                <Col span={12}>
                  <InputBox.Text
                    name="padding"
                    placeholder="Enter padding"
                    label="Padding"
                    rules={StyleLayoutProps.padding}
                  />
                </Col>
              )}
              {styles.includes("fontFamily") && (
                <Col span={12}>
                  <InputBox.Select
                    showSearch
                    filterOption={(input: any, option: any) =>
                      (option?.children || "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    name="font-family"
                    placeholder="Select font family"
                    label="Font family"
                    onChange={handleChange}
                    allowClear
                    options={{
                      list: [
                        { id: 1, name: 'Times New Roman' }, { id: 2, name: 'Georgia' }, { id: 3, name: 'Serif' }, { id: 4, name: 'Brandon Grotesque' }, { id: 5, name: 'Roboto' }], valueKey: "name" }
                        // { 
                        //   id: 1,
                        //   name: "Roboto",
                        //   value: `"Roboto", sans-serif`,
                        // },
                        // {
                        //   id: 2,
                        //   name: "Noto sans",
                        //   value: `"Noto Sans", sans-serif`,
                        // },
                        // {
                        //   id: 3,
                        //   name: "Open sans",
                        //   value: `"Open Sans", sans-serif`,
                        // },
                        // {
                        //   id: 4,
                        //   name: "Montserrat",
                        //   value: `"Montserrat", sans-serif`,
                        // },
                        // {
                        //   id: 5,
                        //   name: "Poppins",
                        //   value: `"Poppins", sans-serif`,
                        // },
                        // { id: 6, 
                        //   name: "Lato", 
                        //   value: `"Lato", sans-serif`,
                        // },
                        // {
                        //   id: 7,
                        //   name: "Oswald",
                        //   value: `"Oswald", sans-serif`,
                        // },
                        // {
                        //   id: 8,
                        //   name: "Nunito Sans",
                        //   value: `"Nunito Sans", sans-serif`,
                        // },
                        // {
                        //   id: 9,
                        //   name: "PT sans",
                        //   value: `"PT Sans", sans-serif`,
                        // },
                        // {
                        //   id: 10,
                        //   name: "Barlow",
                        //   value: `"Barlow", sans-serif`,
                        // },
                      // ],
                      // valueKey: "value",
                    // }
                  }
                  />
                </Col>
              )}
              {styles.includes("textAlign") && (
                <Col span={12}>
                  <InputBox.Select
                    name="text-align"
                    placeholder="Select Text alignment"
                    label="Text Alignment"
                    onChange={handleChange}
                    allowClear
                    options={{
                      list: [
                        { id: 1, name: "Left" },
                        { id: 2, name: "Right" },
                        { id: 3, name: "Center" },
                      ],
                      valueKey: "name",
                    }}
                  />
                </Col>
              )}
              {styles.includes("ScollingSpeed") && (
                <Col span={12}>
                  <InputBox.Text
                    name="scolling_speed"
                    onChange={handleChange}
                    placeholder="Enter Scolling Speed"
                    label="Scolling Speed"
                    rules={StyleLayoutProps.scrlling}
                  />
                </Col>
              )}
              {styles.includes("ScollingDelay") && (
                <Col span={12}>
                  <InputBox.Text
                    name="scolling_delay"
                    onChange={handleChange}
                    placeholder="Enter Scolling Delay"
                    label="Scolling Delay"
                    rules={StyleLayoutProps.scrlling}
                  />
                </Col>
              )}
              {styles.includes("ScollingInterval") && (
                <Col span={12}>
                  <InputBox.Text
                    name="scolling_interval"
                    onChange={handleChange}
                    placeholder="Enter Scolling Inteval"
                    label="Scolling InterVal"
                    rules={StyleLayoutProps.scrlling}
                  />
                </Col>
              )}
              {styles.includes("fontSize") && (
                <Col span={12}>
                  <InputBox.Text
                    name="font-size"
                    placeholder="Enter Font Size"
                    label="Font size"
                    rules={StyleLayoutProps.fontSize}
                  />
                </Col>
              )}
              {styles.includes("iconFontSize") && (
                <Col span={12}>
                  <InputBox.Text
                    name="icon-font-size"
                    placeholder="Enter Icon Font Size"
                    label="Icon Font size"
                    rules={StyleLayoutProps.fontSize}
                  />
                </Col>
              )}
              {styles.includes("twittPost") && (
                <Col span={12}>
                  <InputBox.Text
                    name="twitt-post"
                    placeholder="How many twitt you would like to display"
                    label="How many twitt you would like to display"
                    rules={StyleLayoutProps.postDisplay}
                    required
                  />
                </Col>
              )}
              {styles.includes("applyBorder") && (
                <Col span={12}>
                  <Form.Item name="apply_border">
                    <Checkbox
                      checked={applyBorder}
                      onChange={(e: any) => {
                        setApplyBorder(e.target.checked);
                        form.setFieldValue(
                          "apply_border",
                          e.target.checked ? 1 : 0
                        );
                        !e.target.checked &&
                          form.resetFields([
                            "border-color",
                            "border-style",
                            "border-width",
                          ]);
                        handleChange();
                      }}
                    >
                      Apply border
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
            </Row>
            {applyBorder && (
              <Row gutter={Config.gutter}>
                <Col span={12}>
                  <InputBox.ColorPicker
                    label={"Choose Border Color"}
                    name={`border-color`}
                    onChangeComplete={(color: any) => {
                      handleChange();
                      setTimeout(() => {
                        form.setFieldValue("border-color", objectToRGB(color));
                      }, 0);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <InputBox.Select
                    name="border-style"
                    placeholder="Select border style"
                    label="Border style"
                    onChange={handleChange}
                    allowClear
                    options={{
                      list: [
                        { id: "solid", name: "Solid" },
                        { id: "dashed", name: "Dashed" },
                      ],
                    }}
                  />
                </Col>
                <Col span={12}>
                  <InputBox.Text
                    name="border-width"
                    placeholder="Enter border width"
                    label="Border width"
                    rules={StyleLayoutProps.borderWidth}
                    allowClear
                  />
                </Col>
              </Row>
            )}
          </>
        ) : null}
      </FormBox>
    </Drawer>
  );
};

export default StyleModalComponent;
