import React, { useLayoutEffect } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react";
import useStore from "../../../store";

const AuthLayout: React.FC = observer(() => {
	const { AUTH } = useStore();
	const { token, customRedirect } = AUTH;
	useLayoutEffect(() => {
		if (token) {
			customRedirect()
		}
	}, [token]);
	return (
		<Layout className={`main__page__wrapper has__header`}>
			{/* <HeaderView /> */}
			<Layout.Content className="main__page__content">
				<Outlet />
			</Layout.Content>
		</Layout>
	);
});

export default AuthLayout;
