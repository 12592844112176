import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";

export default class DeviceGroupStore {
	agGrid: any = null;
	logAgGrid: any = null
	per_page = Config.grid.local.gridOptions?.paginationPageSize;
	list_data?: any[];
	editValues = null;
	deleteValues = null;
	deviceGroupDropdownList = null;
	initialFormValues = {
		// scaling: 1,
		oriantation: 0
	}

	constructor() {
		makeAutoObservable(this);
	}

	// Setup grid and set column size to autosize
	setupGrid = (params: any) => {
		const columnState = localStorage.getItem("device_token")
		if (columnState) {
			params.columnApi.applyColumnState({ state: JSON.parse(columnState) });
		}
		this.agGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(Number(page));
		}
	};

	logSetupGrid = (params: any) => {
		this.logAgGrid = params;
	};

	setDeleteValues = (data: any) => {
		this.deleteValues = data;
	};

	setEditValues = (id: number) => {
		return axios.get(API_URL.DEVICE_GROUP.READ + `/${id}`).then(({ data }) => {
			this.editValues = data.data
			return data
		}).catch((response) => {
			return Promise.reject(response);
		})
	};

	// API Functions
	fetchList = async (): Promise<any> => {
		return await axios.get(API_URL.DEVICE_GROUP.LIST).then(({ data }) => {
			data.data.forEach((item: any, index: number) => {
				item.display_id = data.data.length > 0 ? data.data.length - index : 0
			})
			this.list_data = data.data
		}).catch((response) => {
			return Promise.reject(response);
		});
	};
	
	ActiveStatus = (Id: number, status: number) => {
		return axios.post(API_URL.DEVICE_GROUP.CHANGE_STATUS + `/${Id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
			this.fetchList()
			return data
		}).catch(({ response: { data } }) => {
			var errors: any = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	AddData = (formData: any) => {
		return axios
			.post(API_URL.DEVICE_GROUP.ADD, formData)
			.then(({ data }) => {
				this.fetchList();
				return data
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	DeleteData = (id: number) => {
		return axios
			.post(API_URL.DEVICE_GROUP.DELETE + `/${id}`)
			.then(({ data }) => {
				this.fetchList();
				return data
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};


	EditData = (formData: any, editId: number) => {
		return axios
			.post(API_URL.DEVICE_GROUP.EDIT + "/" + editId, formData)
			.then(({ data }) => {
				this.fetchList();
				return data
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	getDeviceGroupDropdownList = async () => {
		return await axios.get(API_URL.LAYOUT.DEVICE).then(({ data }) => {
			this.deviceGroupDropdownList = data.data
			return data.data
		}).catch((response) => {
			return Promise.reject(response);
		})
	}

	public publishDevice = (data: any) => {
		const id = data.id
		return axios.post(API_URL.DEVICE_GROUP.PUBLISH_DEVICE + `/${id}`, data).then(({ data }) => {
			this.fetchList();
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}
}
