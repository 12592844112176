import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

const AppFooterView: React.FC = () => {
    return (
        <Layout.Footer>
            <div>
                {moment().format("Y")} © CloudyFy TV Powered by <Link to="https://www.siliconithub.com/" target="_blank" className="footerLink"> <b> Silicon IT Hub PVT Ltd</b></Link>
            </div>
            <div className="otherLink">
                <Link to="https://cloudyfytv.com/terms-conditions" target="_blank">Terms of Use</Link> | <Link to="https://cloudyfytv.com/privacy-policy" target="_blank"> Privacy Policy</Link>
            </div>
        </Layout.Footer>
    );
};

export default AppFooterView;
