import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form } from 'antd';
import FormComponent from './FormComponent';
import { componentProps } from '../../../../store/UserRoleStore/UserRoleInterface';
import useStore from '../../../../store';
import { ErrorProps } from '../../../../store/RootStore/RootInterface';
import { Notification, displayFormats, validateFields } from '../../../../config/Global';
import { Regex } from '../../../../config/Validators';
import moment from 'moment';


const AddComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [saving, setSaving] = useState<boolean>(false)
	const { USER, ROOT, AUTH } = useStore()
	const handleChange = (data: any) => {
		var data = form.getFieldsValue()
		form.setFieldsValue({
			phone1: data.phone1 && data.phone1.replace(Regex.mobilespace, '$1 $2 $3'),
			phone2: data.phone2 && data.phone2.replace(Regex.mobilespace, '$1 $2 $3')
		})
		validateFields(form, setDisabled);
	}

	const handleClose = () => {
		close()
		setDisabled(true)
		form.resetFields()
	}

	const handleSubmit = (data: any) => {
		data.from_date=moment(data.from_date).format(displayFormats.TEMP_DATE_FORMAT)
		data.to_date=moment(data.to_date).format(displayFormats.TEMP_DATE_FORMAT)
		setSaving(true)
		USER.AddData(data).then((data: any) => {
			Notification.success({
				message: data.message
			})
			AUTH.fetchAuthUser()
			handleClose()
		}).catch((e: ErrorProps) => {
			ROOT.assignErrorToInput(form, e?.data.STATUS);
		}).finally(() => setSaving(false));
	}

	return (
		<Drawer
			open={visible}
			onClose={handleClose}
			width={750}
			title={`Add New User`}
			className='commanDrawer editUser'
			destroyOnClose={true}
			footer={[
				<div key="1">
					<Button className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`}
						disabled={disabled} form='addeditform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="orangeBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent form={form} handleSubmit={handleSubmit} onChange={handleChange} />
		</Drawer>
	)
})

export default AddComponent