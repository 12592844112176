import React, { useState, useEffect } from 'react'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import { componentProps } from '../../../../store/LayoutStore/LayoutInterface'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { Button, Form, Col, Row, Drawer } from 'antd'
import { RateRequest } from '../../../../requests/PageRequest'
import { Notification, validateFields } from '../../../../config/Global'

const BulkEditComponent: React.FC<componentProps> = observer((props: componentProps) => {
    const { RATE, ROOT } = useStore()
    const { visible, close } = props
    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState<boolean>(true)

    const handleclose = () => {
        close()
        form.resetFields()
        setDisabled(true)
    }
    useEffect(() => {
        setDisabled(true)
        RATE?.bulk_data_list && RATE?.bulk_data_list.map((item: any) => {
            form.setFieldValue(item.id, item.rates)
        })
    }, [form, RATE?.bulk_data_list])

    const handleChange = () => {
        validateFields(form, setDisabled);
    }
    const handleSubmit = (data: any) => {
        RATE.setBulkUpdate(data).then((data: any) => {
            Notification.success({
                message: data.data
            })
            handleclose()
        }).catch((e: any) => {
            ROOT.assignErrorToInput(form, e?.data)
        })
    }
    return (
        <>
            <Drawer open={visible}
                onClose={handleclose}
                width={750}
                title={`Edit Bulk Rates`}
                className='commanDrawer addRate'
                destroyOnClose={true}
                footer={[
                    <Button key={2} disabled={disabled} className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} form="bulkedit" htmlType="submit" type="primary"
                    >Save</Button>,
                    <Button key={1} onClick={handleclose} className="orangeBgBtn cancelBtn">Cancel</Button>,
                ]}
            >

                <FormBox form={form} id='bulkedit' onFinish={handleSubmit}>
                    <Row gutter={15}>
                        {RATE?.bulk_data_list && RATE?.bulk_data_list.map((item: any) => {
                            return (<>

                                <Col span={12}>
                                    <InputBox.Text
                                        name={item.id}
                                        onChange={handleChange}
                                        label={`${item.item} Rate`}
                                        placeholder="Enter Rate"
                                        rules={RateRequest.rate}
                                    />
                                </Col>

                            </>)
                        })
                        }
                    </Row>
                </FormBox>
            </Drawer >
        </>
    )
}
)
export default BulkEditComponent