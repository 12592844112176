import { PageHeader } from 'antd'
import React from 'react'
import { SettingBreadcrumb } from '../../../config/BreadcrumbConfig'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import FormComponent from './component/FormComponent'
import { observer } from 'mobx-react'
import useStore from '../../../store'
import Unauthorized from '../../errors/Unauthorized'

const Settings = observer(() => {
	const { AUTH } = useStore()

	return (
		<div className='settingContainer'>
			{AUTH.checkPermission(9, 'add') ?
				<PageHeader title={SettingBreadcrumb.title}
					tags={<BreadcrumbComponent items={SettingBreadcrumb.path} />}
				>
					<FormComponent />
				</PageHeader> : <Unauthorized />}
		</div>
	)
})

export default Settings