import React, { useEffect } from 'react'
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../../store";
import Button from "antd/es/button";
import { observer } from 'mobx-react';
import { ReactComponent as ViewIcon } from '../../../../assets/images/icon/eye.svg';
import { useLocation } from 'react-router-dom';
import { agDateColumnFilter } from '../../../../config/Global';

const ListComponent: React.FC<any> = observer((prop) => {
	const { openDetailViewModal} = prop
	const { AUDIT_LOG } = useStore()
	const loc = useLocation()
	const flag = loc.search.slice(1)
	const cellStyle = {
		textAlign: "center"
	}
	const ActionRenderer: React.FC = (prop: any) => {
		return (
			<div className="action-column">
				{<Button type="text" title={"View"} onClick={() => openDetailViewModal(prop.data)}>
					<ViewIcon />
				</Button>
				}
			</div >
		);
	}

	useEffect(() => {
		AUDIT_LOG.fetchList(flag)
	}, [])

	const columnSetup = () => {
		return ([
			<AgGridColumn
				key='id'
				field="id"
				cellStyle={cellStyle}
				headerName="# ID"
				tooltipField='id'
				maxWidth={80}
				filter={"agNumberColumnFilter"}

			/>,
			<AgGridColumn
				key='user_type'
				field="user_type"
				tooltipField='user_type'
				headerName="User Type"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='user_name.name'
				field="user_name.name"
				tooltipField='user_name.name'
				headerName="User Name"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='event'
				field="event"
				tooltipField='event'
				headerName="Event"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='auditable_type'
				field="auditable_type"
				tooltipField='auditable_type'
				headerName="Auditable Type"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='auditable_id'
				field="auditable_id"
				tooltipField='auditable_id'
				headerName="Auditable ID"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='url'
				field="url"
				tooltipField='url'
				headerName="URL"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='ip_address'
				field="ip_address"
				tooltipField='ip_address'
				headerName="IP Address"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='user_agent'
				field="user_agent"
				tooltipField='user_agent'
				headerName="User Agent"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='tags'
				field="tags"
				tooltipField='tags'
				headerName="Tags"
				width={100}
				minWidth={100}
			/>,
			<AgGridColumn
				key='updated_at'
				field="updated_at"
				tooltipField='updated_at'
				headerName="Last Updated"
				width={100}
				minWidth={100}
				filter={'agDateColumnFilter'}
				filterParams={agDateColumnFilter}
			/>,
			<AgGridColumn
				key='action'
				headerName="Actions"
				type="actionColumn"
				sortable={false}
				filter={false}
				maxWidth={100}
				minWidth={100}
				width={100}
		        pinned='right'
			/>
		])
	}
	

	return (
		<AgGridWrapper
			onGridReady={AUDIT_LOG.setupGrid}
			components={{ ActionRenderer }}
			rowData={AUDIT_LOG.list_data}
			onColumnResized={AUDIT_LOG.onGridChanged}
			onColumnMoved={AUDIT_LOG.onGridChanged}
		>
			{columnSetup()}
		</AgGridWrapper>
	)
})

export default ListComponent;