import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Checkbox, Col, Form, FormInstance, Popover } from "antd";
import { planMessage } from "../../../../config/Global";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  layout_id: any;
  setShow?: (data: any) => void;
  maxChar?: number;
  disable?: boolean;
  layoutIdFlag?:boolean;
}

const CheckBoxElememt: React.FC<formInterface> = observer(
  (props: formInterface) => {
    const { name, label, layout_id, form, disable, layoutIdFlag } = props;

    useEffect(() => {
      if (layout_id && layoutIdFlag) {
        form.setFieldValue(name, false);
      } 
    }, [layout_id]);

    return (
      <Col>
        <Form.Item
          name={name}
          label={
            <div>
              {label
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}
            </div>
          }
        >
          {disable ? (
            <Popover title={()=>planMessage()}>
              <Checkbox
                disabled={disable}
                defaultChecked={
                  form.getFieldValue(name) === "true" ||
                  form.getFieldValue(name) === true
                }
                onChange={(e) => form.setFieldValue(name, e.target.checked)}
              >
                {label
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase())}
              </Checkbox>{" "}
            </Popover>
          ) : (
            <Checkbox
              disabled={disable}
              defaultChecked={
                form.getFieldValue(name) === "true" ||
                form.getFieldValue(name) === true
              }
              onChange={(e) => form.setFieldValue(name, e.target.checked)}
            >
              {label
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}
            </Checkbox>
          )}
        </Form.Item>
      </Col>
    );
  }
);

export default CheckBoxElememt;
