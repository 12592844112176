import React, { useEffect } from "react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { AgGridColumn } from "ag-grid-react";
import { Button } from "antd";
import { observer } from "mobx-react";
import useStore from "../../../../store";
import { SavedLayoutListProps } from "../../../../store/SavedLayoutStore/SavedInterface";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/icon/delete.svg";
import { ReactComponent as PublishIcon } from "../../../../assets/images/icon/publish.svg";
import { ReactComponent as PreviewIcon } from "../../../../assets/images/icon/privewScreen.svg";
import { ReactComponent as CloneIcon } from "../../../../assets/images/icon/clone.svg";
import { agDateColumnFilter, Notification } from "../../../../config/Global";

const ListComponent: React.FC<SavedLayoutListProps> = observer(
  (props: SavedLayoutListProps) => {
    const { SAVED_LAYOUT, AUTH } = useStore();
    const cellStyle = {
      textAlign: "center",
    };
    const RightSide = {
      textAlign: "right",
    };
    const { openPublishModal, openDeleteModal, openPreviewsavedlayout } = props;

    const cloneLayout = (data: any) => {
      SAVED_LAYOUT.cloneLayout(data)
        .then((data: any) => {
          Notification.success({ message: data.data });
        })
        .catch((e: any) => {
          return Promise.reject(e);
        });
    };

    const ActionRenderer: React.FC = (prop: any) => {
      return (
        <div className="action-column">
          {AUTH.checkPermission(5, "preview") &&
          (
            <Button
              key={1}
              className="ant-btn-text"
              title="Preview Screen"
              onClick={() => openPreviewsavedlayout(prop.data)}
            >
              <PreviewIcon />
            </Button>
          )}
          {AUTH.checkPermission(5, "publish") &&
            // [CONSTANT.Company, CONSTANT.Employee].includes(
            //   AUTH?.user?.company_id
            // ) && 
            (
              <Button
                key={2}
                className="ant-btn-text"
                title="Publish"
                onClick={() => openPublishModal(prop.data)}
              >
                <PublishIcon />
              </Button>
            )}
          {AUTH.checkPermission(5, "clone") &&
            // [CONSTANT.Company, CONSTANT.Employee].includes(
            //   AUTH.user.users_roles_id
            // ) && 
            (
              <Button
                key={3}
                className="ant-btn-text"
                title="Clone"
                onClick={() => cloneLayout(prop.data)}
              >
                <CloneIcon />
              </Button>
            )}
          {AUTH.checkPermission(5, "delete") &&
            // [CONSTANT.Company, CONSTANT.Employee].includes(
            //   AUTH.user.users_roles_id
            // ) && 
            (
              <Button
                key={4}
                className="ant-btn-text"
                title="Delete"
                onClick={() => openDeleteModal(prop.data)}
              >
                <DeleteIcon />
              </Button>
            )}
        </div>
      );
    };

    const columnSetup = () => {
      return [
        <AgGridColumn
          key="id"
          field="id"
          tooltipField="id"
          headerName="# ID"
          filter={"agNumberColumnFilter"}
          maxWidth={80}
          cellStyle={cellStyle}
        />,
        <AgGridColumn
          key="name"
          field="name"
          tooltipField="name"
          headerName="Layout Name"
          width={280}
          minWidth={280}
          maxWidth={280}
        />,
        <AgGridColumn
          key="description"
          field="description"
          tooltipField="description"
          headerName="Description"
          width={320}
          minWidth={320}
        />,
        <AgGridColumn
          key="Date"
          headerName="Date"
          field="date"
          tooltipField="date"
          cellStyle={RightSide}
          filter={"agDateColumnFilter"}
          filterParams={agDateColumnFilter}
          maxWidth={150}
        />,
        <AgGridColumn
          key="usernameBy"
          field="users.username"
          headerName="Created by"
          tooltipField="users.username"
          width={220}
          minWidth={220}
          maxWidth={220}
        />,
        <AgGridColumn
          key="action"
          headerName="Actions"
          type="actionColumn"
          sortable={false}
          filter={false}
          maxWidth={200}
          pinned="right"
        />,
      ];
    };

    useEffect(() => {
      SAVED_LAYOUT.fetchList();
    }, []);

    return (
      <div>
        <AgGridWrapper
          onGridReady={SAVED_LAYOUT.onGridReady}
          components={{ ActionRenderer }}
          rowData={SAVED_LAYOUT.list_data}
          onColumnResized={SAVED_LAYOUT.onGridChanged}
          onColumnMoved={SAVED_LAYOUT.onGridChanged}
        >
          {columnSetup()}
        </AgGridWrapper>
      </div>
    );
  }
);

export default ListComponent;
