import React, { useEffect } from "react";

import { observer } from "mobx-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useStore from "../../../store";

const WebToFrontRedirect: React.FC = observer(() => {
  const [params]: any = useSearchParams();
  const navigate = useNavigate();

  const { AUTH } = useStore();

  useEffect(() => {
    const token = params.get("t");

    if (token) {
      AUTH.setupHeaders(token);

      navigate("/dashboard", { replace: true });
    } else {
      window.location.href = `${process.env.REACT_APP_WEB_URL}`;
    }
  }, []);
  return <></>;
});

export default WebToFrontRedirect;
