import { Button, Modal, Row, Col, Form } from 'antd'
import React, { useState } from 'react'
import { ReactComponent as WarningFilledOrange } from '../../../../assets/images/icon/warningOrange.svg'
import { Notification } from '../../../../config/Global'
import { observer } from 'mobx-react'
import useStore from '../../../../store'

const SwitchComponent = observer((props: any) => {
    const [form] = Form.useForm()
    const { visible, close } = props
    const { ROLE } = useStore()
    const [saving, setSaving] = useState<boolean>(false)

    const handleSubmit = () => {
        ROLE.ActiveStatus(visible.id, visible.is_active === 'Activated' ? 0 : 1).then((data: any) => {
            Notification.success({ message: data.message })
            handleClose()
        }).catch((data: any) => {
            Notification.error({ message: data.message })
        })
    }

    const handleClose = () => {
        setSaving(false)
        form.resetFields()
        close()
    }


    return (
        <div>
            <Modal title={visible.is_active !== 'Activated' ? 'Active role?' : 'Deactivate role'} open={visible} onCancel={handleClose}
                className='commanModal deactivateModal'
                footer={[
                    <Button key='1' form='SwitchForm' loading={saving} htmlType="submit" type="primary">Yes</Button>,
                    <Button onClick={handleClose} key={2} className="orangeBgBtn cancelBtn">No</Button>,
                ]}>
                <Form form={form} id="SwitchForm" onFinish={handleSubmit}>
                    <Row align="middle">
                        <Col span={4}><WarningFilledOrange /></Col>
                        <Col span={20}>
                            <h3>Are you sure you want to {visible.is_active !== 'Activated' ? 'active this role?' : 'deactivate this role'}?</h3>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </div>
    )
})

export default SwitchComponent