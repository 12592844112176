import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { Spin } from "antd";

// import { Editor } from '@tinymce/tinymce-react'

// const TinymceComponent = ({ initValue, editorChange, onBlur,disabled }: any) => {
// 	// const cloudName = 'nonenone25251325zz';

// 	const handleEditorChange = (e: any) => {
// 		if (editorChange) {
// 			editorChange(e);
// 		}
// 	}

// 	return (
// 		<div>
// 			<Editor apiKey={'kl3bge05eerrgdvpxzkg6yutfg9zre8qhgdyncxpva3xslz7'}
// 				onBlur={onBlur}
// 				initialValue={initValue}
// 				onEditorChange={handleEditorChange}
// 				disabled={disabled}
// 				init={{
// 					menubar: false,
// 					plugins: 'lists image fullscreen wordcount code table',
// 					toolbar: `bold italic underline forecolor removeformat | fontsize | numlist bullist alignleft aligncenter alignright alignjustify outdent indent | lineheight image fullscreen | code | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol`,
// 					font_size_formats: "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt",
// 					line_height_formats: "1 1.2 1.4 1.5 1.6 1.8 2.0 3.0",
// 					content_style: "body {font-size: 14pt;}",
// 					image_dimensions: true,
// 					images_upload_credentials: true,
// 					automatic_uploads: true,
// 					file_picker_types: 'image',
// 					images_file_types: 'png,jpg,svg',
// 					images_upload_handler: async (blobInfo) => {
// 						return new Promise((resolve, reject) => {
// 							fileRader.readAsDataURL(blobInfo.blob())
// 							const fileRader: any = new FileReader()
// 							fileRader.onload = () => {
// 								resolve(fileRader.result)
// 							}
// 							fileRader.onerror = (e: any) => {
// 								reject(e)
// 							}
// 						})
// 					}
// 				}} />

// 		</div >
// 	)
// }

// export default TinymceComponent

const CkEditor = ({ onChange, editorLoaded, onBlur, value, disabled }: any) => {
  return (
    <div>
      {editorLoaded ? (
        <CKEditor
          editor={ClassicEditor}
          data={value??""}
          disabled={disabled}
          onBlur={onBlur}
          config={{
            toolbar: {
              items: [
              'selectAll', '|',
              'heading', '|',
              'bold', 'italic',
              'numberedList', 'bulletedList', '|',
              'blockQuote', '|',
			        'undo', 'redo',
              ],
            },
          }}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            onChange(data);
          }}
        />
      ) : (
        <div>
          <Spin />
        </div>
      )}
    </div>
  );
};

export default CkEditor;
