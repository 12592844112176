import React, { useState } from "react";
import { observer } from "mobx-react";
import { PageHeader } from "antd";
import { AnalyticsLayoutBreadcrumb } from "../../../../config/BreadcrumbConfig";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import RecordPerPage from "../../../../components/AgGridWrapper/RecordPerPage";
import useStore from "../../../../store";
import ListComponent from "./components/ListComponent";
import AnalyticsLayoutDrawer from "./components/AnalyticsLayoutDrawer";

const LayoutAnalytics: React.FC = observer(() => {
  const { ANALYTICS } = useStore();
	const [addLayoutDrawer, setAddLayoutDrawer] = useState<boolean>(false)
  const [viewData,setViewData] = useState<any>(null)


  // this two funcion for drawer layout analytics
  const openLDrawer = (data: any) => {
    setViewData(data)
    ANALYTICS.viewLayout(data.layout_id)
		setAddLayoutDrawer(true)
	}
	const closeLDrawer = () => {
		setAddLayoutDrawer(false)
	}


  return (
    <>
      <div className="header">
        <PageHeader
          title={AnalyticsLayoutBreadcrumb.title}
          tags={<BreadcrumbComponent items={AnalyticsLayoutBreadcrumb.path} />}
          extra={[
            <RecordPerPage
              key="2"
              defaultValue={ANALYTICS.per_page + " per page"}
              onChange={ANALYTICS.setPageSize}
            />,
          ]}
        >
          <ListComponent openLDrawer={openLDrawer} />
          <AnalyticsLayoutDrawer visible={addLayoutDrawer} close={closeLDrawer} viewData={viewData}/>
        </PageHeader>
      </div>
    </>
  );
});

export default LayoutAnalytics;
