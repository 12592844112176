import { Button, Result, Space, } from "antd";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import useStore from "../../store";

const Unauthorized: React.FC = observer(() => {
	const navigate = useNavigate()
	const { AUTH } = useStore();
	let isUnauthorise = AUTH.isUnauthorized();

	useEffect(() => {
		isUnauthorise = AUTH.isUnauthorized();
	}, [isUnauthorise]);

	return (
		<section className="section unauthorized__section">
			<Result status="403" title="403" subTitle="Sorry, you are not authorized to access this page." extra={
				<Space size="large">
					<Button
						size="large"
						type="primary"
						onClick={() => navigate(-1)}
					>
						Go Back
					</Button>
					<Button size="large" type="primary">
						<Link to="/">Back Home</Link>
					</Button>
				</Space>
			}
			/>
		</section>
	);
});

export default Unauthorized;
