import React, { useLayoutEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../../store";
import { Button, Switch, Tooltip } from "antd";
import { observer } from "mobx-react";
import { ReactComponent as EditIcon } from "../../../../assets/images/icon/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/icon/delete.svg";
import { CONSTANT } from "../../../../config/Constant";
import { setCustomColumnFilter } from "../../../../config/Config";
import { statusFilter } from "../../../../config/Global";

const ListComponent: React.FC<any> = observer((props) => {
  const { openEditModal, openDeleteModal, openSwitchModal } = props;
  const { ROLE, AUTH } = useStore();
  // const cellRightStyle = {
  //   textAlign: "right",
  //   paddingRight: "30px",
  // };
  const cellStyle = {
    textAlign: "center",
  };

  const ActionRenderer: React.FC = (prop: any) => {
    return (
      <div className="action-column">
        {AUTH.checkPermission(CONSTANT.Company, "edit") &&
          ([CONSTANT.SuperAdmin].includes(AUTH.user.users_roles_id) &&
          ![AUTH.user.users_roles_id].includes(prop.data.id)
            ? true
            : ![
                CONSTANT.SuperAdmin,
                CONSTANT.SubAdmin,
                CONSTANT.Company,
                CONSTANT.Employee,
                CONSTANT.Partner,
                AUTH.user.users_roles_id,
              ].includes(prop.data.id)
            ? true
            : false) && (
            <Button
              type="text"
              title={"Edit"}
              className="editIcon"
              onClick={() => openEditModal(prop.data)}
            >
              <EditIcon />
            </Button>
          )}
        {AUTH.checkPermission(CONSTANT.Company, "delete") &&
          ([CONSTANT.SuperAdmin].includes(AUTH.user.users_roles_id) &&
          ![AUTH.user.users_roles_id].includes(prop.data.id)
            ? true
            : ![
                CONSTANT.SuperAdmin,
                CONSTANT.SubAdmin,
                CONSTANT.Company,
                CONSTANT.Employee,
                CONSTANT.Partner,
                AUTH.user.users_roles_id,
              ].includes(prop.data.id)
            ? true
            : false) &&
          !prop.data.total_user_count && (
            <Button
              type="text"
              title={"Delete"}
              onClick={() => openDeleteModal(prop.data)}
            >
              <DeleteIcon />
            </Button>
          )}
      </div>
    );
  };

  const cellRenderData: any = (data: any) => {
    return data.data.total_user_count &&
      AUTH.checkPermission(CONSTANT.Company, "status") ? (
      <Tooltip
        color="red"
        title={
          "Sorry, You cannot change status as User has assigned this role."
        }
      >
        <Switch
          disabled
          checked={data.data.is_active === "Activated" ? true : false}
          onChange={() => openSwitchModal(data.data)}
        />
      </Tooltip>
    ) : (
      AUTH.checkPermission(CONSTANT.Company, "status") && (
        <Tooltip>
          <Switch
            checked={data.data.is_active === "Activated" ? true : false}
            onChange={() => openSwitchModal(data.data)}
          />
        </Tooltip>
      )
    );
  };

  const columnSetup = () => {
    return [
      <AgGridColumn
        key="id"
        field="id"
        filter={"agNumberColumnFilter"}
        headerName="# ID"
        // pinned="left"
        tooltipField="id"
        width={80}
        maxWidth={80}
        cellStyle={cellStyle}
      />,
      <AgGridColumn
        key="role_name"
        field="role_name"
        tooltipField="role_name"
        headerName="Role Name"
        // width={250}
        // maxWidth={250}
      />,
      AUTH.user.users_roles_id === 1 && (
        <AgGridColumn
          key="user_count"
          field="user_count"
          tooltipField="user_count"
          headerName="Active User"
          filter={"agNumberColumnFilter"}
          // width={300}
          // cellStyle={cellRightStyle}
          // maxWidth={120}
          // minWidth={300}
        />
      ),
      AUTH.user.users_roles_id === 1 && (
        <AgGridColumn
          key="created_by_user"
          field="created_by_user"
          tooltipField="created_by_user"
          headerName="Created By"
          // width={300}
          // cellStyle={cellRightStyle}
          // maxWidth={120}
          // minWidth={300}
        />
      ),
      <AgGridColumn
        key={"is_active"}
        headerName="Status"
        field="is_active"
        sortable={false}
        // maxWidth={120}
        filter="agTextColumnFilter"
        filterParams={setCustomColumnFilter(statusFilter)}
        cellRendererFramework={cellRenderData}
      />,
      <AgGridColumn
        key="action"
        headerName="Actions"
        type="actionColumn"
        sortable={false}
        filter={false}
        maxWidth={150}
        pinned="right"
      />,
    ];
  };

  useLayoutEffect(() => {
    ROLE.fetchList();
  }, []);

  return (
    <AgGridWrapper
      onGridReady={ROLE.setupGrid}
      components={{ ActionRenderer }}
      rowData={ROLE.list_data}
      onColumnResized={ROLE.onGridChanged}
      onColumnMoved={ROLE.onGridChanged}
    >
      {columnSetup()}
    </AgGridWrapper>
  );
});

export default ListComponent;
