import React from 'react'
import { Col, FormInstance, Row } from 'antd';
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { deviceGroupRequest } from '../../../../requests/PageRequest';
import Config from '../../../../config/Config';
import useStore from '../../../../store';
// import { ReactComponent as DownIcon } from '../../../../assets/images/icon/left-arrow-icon.svg';
// import { ReactComponent as DownIcon } from '../../../../assets/images/icon/downIcon.svg';

interface formInterface {
	form: FormInstance
	handleSubmit: (data: any) => void
	onChange: () => void
	type?: any
}

const FormComponent: React.FC<formInterface> = observer((props: formInterface) => {
	const { DEVICE_GROUP } = useStore()
	const { form, handleSubmit, onChange } = props

	return (
		<FormBox id={'addEditDeviceForm'} form={form} onFinish={handleSubmit} onChange={onChange} initialValues={DEVICE_GROUP.initialFormValues}>
			<Row gutter={Config.gutter}>
				<Col span={12}>
					<InputBox.Text
						required
						name="group_name"
						label="Group Name"
						placeholder="Enter Group name"
						rules={deviceGroupRequest.group_name}
					/>
				</Col>
				<Col span={12}>
					<InputBox.Select
						name="device_ids"
						label="Device"
						mode="multiple"
						required
						allowClear
						onFocus={async () => {
							await DEVICE_GROUP?.getDeviceGroupDropdownList()
							const deviceGroupDropdownList = [...DEVICE_GROUP.deviceGroupDropdownList ?? [], ...DEVICE_GROUP.editValues?.device ?? []];
							const ids = deviceGroupDropdownList.map(({ id }) => id);
							DEVICE_GROUP.deviceGroupDropdownList = deviceGroupDropdownList.filter(({id}, index: number) => !ids.includes(id, index + 1));
						}}
						placeholder='Select Device'
						options={{
							list: DEVICE_GROUP.deviceGroupDropdownList && DEVICE_GROUP.deviceGroupDropdownList?.length > 0 ? [...[{ 'id': '', 'name': 'Select All' }], ...DEVICE_GROUP.deviceGroupDropdownList] : [],
							valueKey: 'id',
							textKey: 'name'
						}}
						onChange={(e: any) => {
							if (e && e.length > 0 && e.includes("")) {
								e = DEVICE_GROUP?.deviceGroupDropdownList.map((obj: any) => obj.id);
							}

							form.setFieldsValue({
								device_ids: e
							})
							onChange()
						}}
						maxTagCount={2}
						showSearch
						filterOption={(input: any, option: any) =>
							(option?.children || "").toLowerCase().includes(input.toLowerCase())
						}
						rules={deviceGroupRequest.device_ids}
					/>
				</Col>
			</Row>
		</FormBox>
	)
})

export default FormComponent;