import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../../store";
import { dateComparator } from "../../../../config/Global";

const ListComponent: React.FC<any> = observer(() => {
  const { SUBSCRIPTION } = useStore();

  const ActionRenderer: React.FC = () => {
    return <div className="action-column"></div>;
  };

  const columnSetup = () => {
    return [
      <AgGridColumn
        key="id"
        field="id"
        headerName="# ID"
        filter={"agNumberColumnFilter"}
        tooltipField="id"
        maxWidth={80}
      />,
      <AgGridColumn
        key="price_id"
        field="price_id"
        headerName="Price Id"
        tooltipField="Price Id"
        minWidth={100}
      />,
      <AgGridColumn
        key="customer_id"
        field="customer_id"
        headerName="Customer Id"
        tooltipField="customer_id"
        minWidth={100}
      />,
      <AgGridColumn
        key="subscription_id"
        field="subscription_id"
        headerName="Subscription Id"
        tooltipField="subscription_id"
        minWidth={100}
      />,
      <AgGridColumn
        key="email"
        field="email"
        headerName="Email"
        tooltipField="email"
        minWidth={100}
      />,
      <AgGridColumn
        key="plan_name"
        field="plan_name"
        headerName="Plan Name"
        tooltipField="plan_name"
        minWidth={100}
      />,
      <AgGridColumn
        key="price"
        field="price"
        filter={"agNumberColumnFilter"}
        headerName="Price"
        tooltipField="price"
        minWidth={100}
      />,
      <AgGridColumn
        key="product"
        field="product"
        headerName="Product Id"
        tooltipField="product"
        minWidth={100}
      />,
      <AgGridColumn
        key="current_period_start"
        field="current_period_start"
        headerName="Start Period"
        filter={"agDateColumnFilter"}
        filterParams={dateComparator}
        tooltipField="current_period_start"
        minWidth={100}
      />,
      <AgGridColumn
        key="current_period_end"
        field="current_period_end"
        headerName="End Period"
        filter={"agDateColumnFilter"}
        filterParams={dateComparator}
        tooltipField="current_period_end"
        minWidth={100}
      />,
      <AgGridColumn
        key="status"
        field="status"
        headerName="Status"
        tooltipField="status"
        minWidth={100}
      />,
      <AgGridColumn
        key="action"
        headerName="Actions"
        type="actionColumn"
        sortable={false}
        filter={false}
        maxWidth={200}
        pinned="right"
      />,
    ];
  };
  useEffect(() => {
    SUBSCRIPTION.fetchList();
  }, []);
  return (
    <AgGridWrapper
      onGridReady={SUBSCRIPTION.setupGrid}
      components={{ ActionRenderer }}
      rowData={SUBSCRIPTION.list_data}
      onColumnResized={SUBSCRIPTION.onGridChanged}
      onColumnMoved={SUBSCRIPTION.onGridChanged}
    >
      {columnSetup()}
    </AgGridWrapper>
  );
});

export default ListComponent;
