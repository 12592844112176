import React from "react";
// import LOGO_IMAGE from "../../../assets/images/company_logo.png";
// import Logo  from "../../../assets/images/CompanyLogo.png";
import Logo from "../../../assets/images/Cloudyfy TV.svg";
import CollapseLogo from "../../../assets/images/company_logo.png";
import { useNavigate } from "react-router-dom";

const LogoComponent: React.FC<any> = ({ collapsed }) => {
	const navigate = useNavigate();
	return (
		<div className="logo__wrapper" onClick={()=>{ navigate("/dashboard")}}>{collapsed ? <img src={CollapseLogo} alt="LOGO" /> : <img src={Logo} alt="LOGO" />}</div>
	);
};

export default LogoComponent;
