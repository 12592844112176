import React, { useState } from 'react'
import { Button, Form, Popover } from 'antd';
import { FormBox, InputBox } from '../../../components/AntdAddons';
import LoginBanner from "../../../assets/images/login-image.png";
import LoginBG from "../../../assets/images/login-pattern.png";
import { LoginRequest } from '../../../requests/AuthRequest';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useStore from '../../../store';
import { Notification, passwordTooltip } from '../../../config/Global';
import { ErrorProps } from '../../../store/RootStore/RootInterface';

const ResetPassword: React.FC = () => {
	const router = useLocation()
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [tooltip, setTooltip] = useState(false)
	const { AUTH, ROOT } = useStore()
	const navigate = useNavigate();

	const resetPassword = (data: any) => {
		localStorage.removeItem('token')
		localStorage.clear()
		setSaving(true)
		AUTH.updatePassword(data).then(() => {
			Notification.success({
				message: 'Password has been updated successfully.'
			})
			navigate('/login');
		}).catch((errors: ErrorProps) => {
			form.resetFields()
			ROOT.assignErrorToInput(form, errors?.data.message);
		}).finally(() => setSaving(false))
	}

	const onChange = () => {
		form.validateFields(['password']).then(() => {
			setTooltip(false)
		}).catch(() => {
			setTooltip(true)
		})
	}

	return (
		<section className="loginSection">
			<div className="loginWrap">
				<div className="loginLogo">
					<div className="loginBanner">
						<img src={LoginBanner} alt="Banner" />
					</div>
				</div>
				<div className="loginForm" style={{ backgroundImage: `url(${LoginBG})` }}>
					<div className="loginFormWrap">
						<div className="formTitle">
							<h2>Reset Password</h2>
						</div>
						<FormBox form={form} onFinish={resetPassword} onChange={onChange}>
							<InputBox.Text
								name="key" initialValue={router.pathname.split('/')[2]} style={{ display: 'none' }}
							/>
							<Popover placement="topRight" content={passwordTooltip} open={tooltip}>
								<InputBox.Password
									name="password"
									label="Password"
									placeholder="xxxxxxx"
									rules={LoginRequest.password}
								/>
							</Popover>
							<InputBox.Password
								name="confirm_password"
								label="Confirm Password"
								placeholder="xxxxxxx"
								rules={LoginRequest.confirmPassword}
							/>
							<div className="text-center resetPassword">
								<Button loading={saving} type="primary" htmlType="submit">Reset Password</Button>
							</div>
							<div className="text-right mt-10 font-medium text-white">
								<Link to="/login">Back to login</Link>
							</div>
						</FormBox>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ResetPassword