import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Button, Drawer, Form } from "antd";
import FormComponent from "./FormComponent";
import { componentProps } from "../../../../store/DeviceStore/DeviceInterface";
import useStore from "../../../../store";
import { Notification, validateFields } from "../../../../config/Global";
import { ErrorProps } from "../../../../store/RootStore/RootInterface";
import moment from "moment";

const AddComponent: React.FC<componentProps> = observer(
  (props: componentProps) => {
    const { visible, close } = props;
    const { LAYOUT, ROOT, AUTH } = useStore();
    const { assignErrorToInput } = ROOT;
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [show, setShow] = useState<any>(false);

    const handleClose = () => {
      form.resetFields();
      setDisabled(true);
      setSaving(false);
      close();
      setShow(false);
    };

    useEffect(() => {
      if (show) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }, [show]);

    const handleChange = () => {
      validateFields(form, setDisabled);
      checkPopupField();
    };
    // useEffect(() => {
    // 	if (show) {
    // 		setDisabled(false)
    // 	} else {
    // 		setDisabled(true)
    // 	}
    // }, [show])
    const checkPopupField = () => {
      for (const item in form.getFieldsValue()) {
        if (
          item.split("-")[0] === "twitt_ticker" ||
          item === "twitt_image" ||
          item === "twitt-left-top" ||
          item === "twitt_post-right-top" ||
          item === "twitt-right-bottom" ||
          item === "twitt-right-bottom" ||
          item === "twitt_post-left-bottom" ||
          item === "twitt_ticker-bottom" ||
          item === "twitt_post" ||
          item === "pdf"
        ) {
          if (LAYOUT.styleProps) {
            if (LAYOUT.styleProps[item]) {
              if (
                LAYOUT.styleProps[item]["twitt-post"] &&
                LAYOUT.styleProps[item]["twitt-post"] !== ""
              ) {
                form.setFields([{ name: item, errors: [] }]);
              } else if (
                LAYOUT.styleProps[item]["scolling_delay"] !== "" ||
                LAYOUT.styleProps[item]["scolling_interval"] !== "" ||
                LAYOUT.styleProps[item]["scolling_speed"] !== ""
              ) {
                form.setFields([{ name: item, errors: [] }]);
              } else {
                setDisabled(true);
                form.getFieldError(item).length === 0 &&
                  form.setFields([
                    {
                      name: item,
                      errors: ["Please fill the required fields in pop-up"],
                    },
                  ]);
              }
            } else {
              setDisabled(true);
              form.getFieldError(item).length === 0 &&
                form.setFields([
                  {
                    name: item,
                    errors: ["Please fill the required fields in pop-up"],
                  },
                ]);
            }
          } else {
            setDisabled(true);
            form.getFieldError(item).length === 0 &&
              form.setFields([
                {
                  name: item,
                  errors: ["Please fill the required fields in pop-up"],
                },
              ]);
          }
        }
      }
    };

    const handleSubmit = (data: any) => {
      setSaving(true);
      var finalPayload: any;
      
      if (data["description"] == undefined ||
        data["description"] == "undefined" ||
        data["description"] == null ||
        data["description"] == "null" ||
        data["description"]?.length === 0
      ) {
        delete data["description"];
      }
      const components: any = { component: {} };
      Object.keys(data)
        .filter(
          (_x) =>
            ![
              "layout_mode",
              "name",
              "global_layout_id",
              "description",
              "oriantation",
            ].includes(_x)
        )
        .forEach((_x: any) => {
          if (
            data[_x] == undefined ||
            data[_x] == "undefined"
          ) {
            if (_x === "ticker") {
              components.component[_x] = "";
              delete data[_x];
            } else {
              delete data[_x];
              delete components.component[_x];
            }
          } else {
            components.component[_x] = data[_x];
            delete data[_x];
          }
        });
      finalPayload = { ...data, ...components };

      if (finalPayload.component.professor_schedule) {
        var professor_schedule = finalPayload.component.professor_schedule;

        Object.entries(professor_schedule.schedule).forEach(
          ([key, value]: any) => {
            if (value) {
              value?.forEach((val: any, index: number) => {
                professor_schedule.schedule[key][index].from_time = moment(
                  val.from_time
                ).format("HH:mm");
                professor_schedule.schedule[key][index].to_time = moment(
                  val.to_time
                ).format("HH:mm");
              });
            }
          }
        );

        professor_schedule.holiday?.forEach((val: any, index: number) => {
          professor_schedule.holiday[index].from_date = moment(
            val.from_date
          ).format("YYYY-MM-DD");
          professor_schedule.holiday[index].to_date = moment(
            val.to_date
          ).format("YYYY-MM-DD");
        });
        finalPayload.component.professor_schedule = JSON.stringify(
          finalPayload.component.professor_schedule
        );
      }

      if (finalPayload.component.layout_name) {
        finalPayload.component.layout_name = JSON.stringify(
          finalPayload.component.layout_name
        );
      }

      if (finalPayload.component) {
        Object.keys(finalPayload.component).forEach((_x: any) => {
          if (
            _x.split("-")[0] === "bg_image" ||
            _x.split("-")[0] === "image" ||
            _x.split("-")[0] === "logo"
          ) {
            if (Object.hasOwn(finalPayload.component[_x], "aiGenerated")) {
              finalPayload.component[_x] = JSON.stringify(
                finalPayload.component[_x].aiGenerated
              );
            }
          }
          if (_x.split("-")[0] === "slider") {
            const newPayload: any = [];
            finalPayload?.component[_x].forEach((data: any) => {
              if (data.aiGenerated) {
                newPayload.push(JSON.stringify(data.aiGenerated));
              } else {
                newPayload.push(data);
              }
            });
            finalPayload.component[_x] = newPayload;
          }
        });
      }

      const formDataValue = LAYOUT.JsonToFormData(finalPayload);

      LAYOUT.AddData(formDataValue)
        .then((data: any) => {
          Notification.success({ message: data.data });
          close();
          LAYOUT.styleProps = null;
          form.resetFields();
          AUTH.fetchAuthUser();
        })
        .catch((e: ErrorProps) => {
          assignErrorToInput(form, e.data.STATUS);
        })
        .finally(() => setSaving(false));
    };

    return (
      <Drawer
        open={visible}
        onClose={handleClose}
        width={"70%"}
        className="commanDrawer layoutDrawer"
        title={`Add Layout`}
        destroyOnClose={true}
        footer={[
          <div key="1">
            <Button
              disabled={disabled}
              form="addEditLayoutForm"
              loading={saving}
              htmlType="submit"
              type="primary"
              className={`${disabled ? "disableBtn" : "blackLineBtn"}`}
            >
              Save
            </Button>
            <Button className="orangeBgBtn cancelBtn" onClick={handleClose}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <FormComponent
          id="addEditLayoutForm"
          form={form}
          handleSubmit={handleSubmit}
          onChange={handleChange}
          setShow={setShow}
        />
      </Drawer>
    );
  }
);

export default AddComponent;
