import { makeAutoObservable } from "mobx";
import axios from "axios";
import { doLoginType, forgetType } from "./AuthInterface";
import RootStore from "../RootStore/RootStore";
import API_URL from "../../config/ApiUrl";
import { CONSTANT } from "../../config/Constant";
import { Notification } from "../../config/Global";
export default class AuthStore {
	// Variables
	public guideLink?: any;
	public user?: any;
	public subscription?: any;
	public token?: string;
	public app_loading: boolean;
	private rootStore: RootStore;
	public accessMenu?: any;
	public permission: any;
	public menuCount: any;
	public appThemeMode: any = "light";
	public menu_count: any;
	public expiry_note: any = null
	public haspassword: any = null
	pricing_plan_list: any
	// Setter Functions
	public setUser = (value?: any): void => {
		this.user = value;
	};
	public setSubscription = (value?: any): void => {
		this.subscription = value;
	};
	public setToken = (value?: string): void => {
		this.token = value;
	};
	public setAppLoading = (value: boolean): void => {
		this.app_loading = value;
	};
	public setAccessMenu = (value: any): void => {
		this.accessMenu = value;
	};

	constructor() {
		this.app_loading = true;
		this.rootStore = new RootStore();
		makeAutoObservable(this);
		window.addEventListener("storage", this.handleInvalidToken);
	}

	// Initiate Application Functions
	public InitializeApp = () => {
		this.rootStore.setAxiosBaseUrl();
		this.rootStore.setAxiosInterceptor();
		this.setupHeaders();
	};

	public handleInvalidToken = (e: any) => {
		if (e.key === "token") {
			if ((e.oldValue && !e.newValue) || e.oldValue !== e.newValue) {
				window.location.href = "/";
			} else if (!e.oldValue && e.newValue) {
				window.location.reload();
			}
		}
	};

	public toggleThemeClick = async (): Promise<void> => {
		this.appThemeMode = this.appThemeMode === 1 ? 0 : 1;
		const payload = { theme: this.appThemeMode };
		return axios
			.post(API_URL.THEME, payload)
			.then(() => {
				if (payload.theme === 1) {
					document.body.classList.remove("light-mode", "light__layout");
					document.body.classList.add("dark-mode", "dark__layout");
				} else {
					document.body.classList.remove("dark-mode", "dark__layout");
					document.body.classList.add("light-mode", "light__layout");
				}
			})
			.catch((data: any) => {
				Notification.error({ message: data.data.message });
				return Promise.reject(data);
			});
	};

	public isUnauthorized = () => {
		return this.rootStore.isUnauthorise;
	};

	// Check User Privileges
	checkPermission = (module_id: number, permission_type: string) => {
		if (this.user && this.user.id === CONSTANT.SuperAdmin) {
			return true;
		} else {
			if (
				this.permission &&
				this.permission[module_id] &&
				this.permission[module_id].includes(permission_type)
			) {
				return true;
			} else {
				return false;
			}
		}
	};

	public customRedirect = (redirect = "", timeout = 0) => {
		// if (this.rootStore.login_redirect_timer) {
		// 	clearTimeout(this.rootStore.login_redirect_timer);
		// }
		this.setAppLoading(true);
		const token = localStorage.getItem("token");
		const without_login_page = CONSTANT.WITHOUT_LOGIN_PAGE;
		const dashboard_redirect_path = CONSTANT.DASHBOARD_REDIRECT_PATH;
		const login_redirect_path = CONSTANT.LOGIN_REDIRECT_PATH;

		setTimeout(() => {
			const currentPath = window.location.pathname;
			if (token && without_login_page.includes(currentPath)) {
				redirect = dashboard_redirect_path;
			} else if (!token && !without_login_page.includes(currentPath)) {
				redirect = login_redirect_path;
			}
			if (redirect) {
				if (redirect == "reload") {
					window.location.reload();
				} else if (currentPath !== redirect) {
					window.location.href = redirect;
				}
			}
			this.setAppLoading(false);
		}, timeout);
	};

	public fetchAuthUser = async (loading?: string): Promise<void> => {
		this.setAppLoading(loading ? false : true);
		return await axios
			.post(API_URL.ME)
			.then(({ data }) => {
				this.pricingPlan()
				this.haspassword = data.hasPassword
				if (data.user) {
					this.expiry_note = data?.exp_licence

					if (data?.exp_licence?.is_expired) {
						Notification.error({
							// eslint-disable-next-line react/react-in-jsx-scope
							message: <><div dangerouslySetInnerHTML={{ __html: data?.exp_licence?.message }}></div></>,
						});
						if (!localStorage.getItem("privious_token")) {
							setTimeout(() => {
								if (localStorage.getItem('privious_token')) {
									this.backToSuperAdmin();
									this.customRedirect('/users');
								} else {
									this.rootStore.resetStore();
									this.setToken();
									this.customRedirect();
								}
							}, 500);
						}
					}
					this.guideLink = data.document_tour
					this.setUser(data.user);
					this.setSubscription(data.subscription);

					const new_menu: any = {};
					Object.values(data.menu).forEach((it: any) => {
						if ([CONSTANT.SIDEBAR.Tokens].includes(it.id)) { // to hide menus
							return
						}
						if ([CONSTANT.SuperAdmin, CONSTANT.SubAdmin, CONSTANT.Partner].includes(data.user?.users_roles_id)) {
							if (![CONSTANT.SIDEBAR.Rates,
							CONSTANT.SIDEBAR.GlobalPopup,
							CONSTANT.SIDEBAR.Settings,
							CONSTANT.SIDEBAR.Tokens,
							CONSTANT.SIDEBAR.Schedule,
							CONSTANT.SIDEBAR.Subscription,
							].includes(it.id)) {
								new_menu[it.id] = it;
							}
						}
						// else if ([CONSTANT.Employee].includes(data.user?.users_roles_id)) {
						// 	if (![CONSTANT.SubAdmin, 8, 9].includes(it.id)) {
						// 		new_menu[it.id] = it;
						// 	}
						// } 
						else {
							new_menu[it.id] = it;
						}
					});
					this.setAccessMenu(new_menu);
					this.permission = data.permissions;
					this.menu_count = data.menu_count;
					this.appThemeMode = data.user.theme;
					if (data.user.theme === 1) {
						document.body.classList.add("dark-mode", "dark__layout");
					} else {
						document.body.classList.add("light-mode", "light__layout");
					}
					return data;
				}
			})
			.catch(() => {
				Notification.error({
					message: "Token is expired. Login again please.",
				});
				this.rootStore.resetStore();
				this.setToken()
				this.customRedirect();

			})
			.finally(() => this.setAppLoading(false));
	};

	public setupHeaders = async (access_token?: string): Promise<void> => {
		this.setAppLoading(true);
		this.rootStore
			.setAxiosHeaders(access_token)
			.then(async (token: string) => {
				this.setToken(token);
				await this.fetchAuthUser();
				// await this.pricingPlan()
			})
			.catch(() => {
				this.setToken();
			})
			.finally(() => this.setAppLoading(false));
	};

	// API Functions
	public doLogin = async (payload: doLoginType): Promise<any> => {
		return await axios
			.post(API_URL.LOGIN, payload)
			.then(({ data }) => {
				this.setupHeaders(data.data.token);
				this.rootStore.setIsUnauthorise(false);
				return data;
			})
			.catch((data) => {
				this.setToken();
				this.setUser();
				return Promise.reject(data);
			});
	};

	doProxyLogin = (id: any) => {
		return axios
			.get(API_URL.USER_ROLE.PROXY_LOGIN + `/${id}`)
			.then(({ data }) => {
				const privious_token: any = localStorage.getItem("token");
				localStorage.setItem("privious_token", privious_token);
				// localStorage.setItem('token', data.access_token)
				this.setupHeaders(data.access_token);
				this.rootStore.setIsUnauthorise(false);
				return data;
			})
			.catch(({ response: { data } }) => {
				return Promise.reject(data);
			});
	};

	backToSuperAdmin = () => {
		const privious_token: any = localStorage.getItem("privious_token");
		localStorage.removeItem("privious_token");
		this.setupHeaders(privious_token);
		return true;
	};

	public forgotPassword = (payload: forgetType): Promise<any> => {
		return axios.post(API_URL.FORGET_PASSWORD, payload).then(({ data }) => {
			return data;
		});
	};

	public updatePassword = (payload: forgetType): Promise<any> => {
		localStorage.clear();
		return axios.post(API_URL.UPDATE_PASSWORD, payload).then(({ data }) => {
			return data;
		});
	};
	public pricingPlan = () => {
		return axios
			.get(API_URL.SUBSCRIPTION.PRICING_PLAN)
			.then(({ data }) => {
				this.pricing_plan_list = data.pricingPlan
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	updateProfile = (formData: any) => {
		return axios
			.post(API_URL.UPDATE_PROFILE, formData)
			.then(({ data }) => {
				this.fetchAuthUser("loading");
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	public changePassword = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.CHANGE_PASSWORD, payload)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	public doLogout = async (): Promise<any> => {
		return await axios
			.post(API_URL.LOGOUT)
			.then((data: any) => {
				this.setAppLoading(true);
				this.rootStore.resetStore();
				this.setToken();
				this.setUser();
				document.body.classList.remove("dark-mode", "dark__layout");
				document.body.classList.remove("light-mode", "light__layout");
				return data.data
			})
			.catch((data) => {
				this.setAppLoading(false);
				return Promise.reject(data);
			})
			.finally(() => this.setAppLoading(false));
	};
	public SocialLogin = async (payload: any): Promise<any> => {
		return await axios
			.post(API_URL.SOCIAL.LOGIN, payload)
			.then(({ data }: any) => {
				this.setupHeaders(data.data.token);
				this.rootStore.setIsUnauthorise(false);
				return data
			})
			.catch((data) => {
				this.setToken();
				this.setUser();
				return Promise.reject(data);
			});
	};

	// common function for the subscription Plan wise check functionality
	checkPlan = (PlanName: string) => {
		return this.pricing_plan_list?.some((item1: any) =>
			item1.child.some((childItem: any) => {
				if (
					childItem.key == PlanName &&
					this.subscription.plan_name === "Basic" &&
					childItem.basic
				) {
					return true;
				} else if (
					childItem.key == PlanName &&
					this.subscription.plan_name === "Standard" &&
					childItem.standard
				) {
					return true;
				} else if (
					childItem.key == PlanName &&
					this.subscription.plan_name === "Premium" &&
					childItem.premium
				) {
					return true;
				} else {
					return false;
				}
			})
		);
	};

	checkPlanWObj = (PlanName: string) => {
		// debugger
		const result: any = this.pricing_plan_list?.map((item1: any) =>
			item1.child.map((childItem: any) => {
				if (
					childItem.key === PlanName &&
					this.subscription.plan_name === "Basic" &&
					childItem.basic
				) {
					return { type: "Basic", size: 100, data: childItem };
				} else if (
					childItem.key === PlanName &&
					this.subscription.plan_name === (null || "free") &&
					childItem.basic
				) {
					return { type: "Basic", size: 100, data: childItem };
				} else if (
					childItem.key === PlanName &&
					this.subscription.plan_name === "Standard" &&
					childItem.standard
				) {
					return { type: "Standard", size: 250, data: childItem };
				} else if (
					childItem.key === PlanName &&
					this.subscription.plan_name === "Premium" &&
					childItem.premium
				) {
					return { type: "Premium", size: 500, data: childItem };
				} else {
					return null;
				}
			})
		);

		const flattenedResult = result.flat().filter((item: any) => item !== null);

		return flattenedResult
	};

}
