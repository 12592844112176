import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Checkbox, Col, Drawer, Form, Row, Segmented } from "antd";
import useStore from "../../../../store";
import { FormBox } from "../../../../components/AntdAddons";
import Config from "../../../../config/Config";
import { LayoutPermissionsProps } from "../../../../store/CustmerStore/CustomerInterface";
import { Notification } from "../../../../config/Global";
import { ErrorProps } from "../../../../store/RootStore/RootInterface";

const LayoutPermissionComponent: React.FC<LayoutPermissionsProps> = observer(
  (props: LayoutPermissionsProps) => {
    const { USER, LAYOUT, ROOT } = useStore();
    const { close, visible, layoutId } = props;
    const [form] = Form.useForm();
    const [saving, setSaving] = useState<boolean>(false);
    const [allAssign, setAllAssign] = useState<boolean>(false);
    const [layoutOptions, setLayoutOptions] = useState<any>([]);

    // this is for segmented
    const options = ["LandScape", "Portrait"];
    const [selectedSegment, setSelectedSegment] = useState<number>(0);

    useEffect(() => {
      if (visible) {
        setAllAssign(false);
        form.setFieldValue("assign_all", false);
        LAYOUT.getLayout(layoutId);
      }
    }, [visible]);

    // for modify data for the options
    useEffect(() => {
      if (visible && LAYOUT?.layoutList) {
        const optionsData = LAYOUT?.layoutList?.global_layout_list?.filter(
          (column: any) => {
            if (column.oriantation === selectedSegment) {
              return { name: column.name, id: column.id };
            }
          }
        );
        setLayoutOptions(optionsData);
      }
    }, [LAYOUT?.layoutList, selectedSegment]);

    useEffect(() => {
      const selectLIds: any = [];
      const selectPIds: any = [];
      const layoutIds: any = LAYOUT?.layoutList?.data?.map((item: any) => {
        item.oriantation === 1
          ? selectPIds.push(item.id)
          : selectLIds.push(item.id);
        return item.id;
      });

      if (
        LAYOUT?.layoutList?.global_layout_list?.length ===
        LAYOUT?.layoutList?.data?.length
      ) {
        setAllAssign(true);
        form.setFieldsValue({
          assign_all: true,
          layout_ids: layoutIds,
          selectLIds: [],
          selectPIds: [],
        });
      } else {
        form.setFieldsValue({
          assign_all: false,
          layout_ids: layoutIds,
          selectLIds,
          selectPIds,
        });
      }
    }, [LAYOUT?.layoutList]);

    const handleSegmentChange = (newSegment: any) => {
      if (newSegment === "LandScape") {
        setSelectedSegment(0);
      } else {
        setSelectedSegment(1);
      }
    };

    const handleSubmit = (data: any) => {
      const finalPermissionsIds = [...new Set(data?.layout_ids)];
      let payload: any;
      if (finalPermissionsIds.length > 0 && data.assign_all === false) {
        payload = { permission: finalPermissionsIds };
      } else {
        payload = { permission: null };
      }

      if (data.assign_all === true || finalPermissionsIds.length > 0) {
        USER.layoutPermission(layoutId, payload)
          .then((data: any) => {
            setSaving(false);
            Notification.success({
              message: data.data.message,
            });
            handelClose();
          })
          .catch((e: ErrorProps) => {
            ROOT.assignErrorToInput(form, e?.data.errors);
          })
          .finally(() => setSaving(false));
      } else {
        Notification.error({
          message: 'Please Select at least one ',
        });
      }
    };

    const handelClose = () => {
      form.resetFields();
      close();
      setSelectedSegment(0);
    };

    //for options selections
    const onLayoutChange = (list: any) => {
      if (selectedSegment === 0) {
        const field: any = form.getFieldValue("selectPIds")
          ? form.getFieldValue("selectPIds")
          : [];
        const myArray = [...field, ...list];
        const uniqueArray = [...new Set(myArray)];
        form.setFieldsValue({ selectLIds: list, layout_ids: uniqueArray });
      } else {
        const field: any = form.getFieldValue("selectLIds")
          ? form.getFieldValue("selectLIds")
          : [];
        const myArray = [...field, ...list];
        const uniqueArray = [...new Set(myArray)];
        form.setFieldsValue({ selectPIds: list, layout_ids: uniqueArray });
      }
    };

    //for options selections
    const onLayoutCheckAllChange = (e: any) => {
      form.setFieldsValue({
        assign_all: e.target.checked,
        layout_ids: e.target.checked
          ? LAYOUT?.layoutList?.global_layout_list?.map((column: any) => {
              return column.id;
            })
          : [],
      });
    };

    return (
      <Drawer
        open={visible}
        onClose={handelClose}
        width={750}
        title={`Layout Permissions`}
        className="commanDrawer editUser"
        destroyOnClose={true}
        footer={[
          <div key="1">
            <Button
              //   className={`${disabled ? "disableBtn" : "blackLineBtn"}`}
              //   disabled={disabled}
              form="layoutPermission"
              loading={saving}
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
            <Button className="orangeBgBtn cancelBtn" onClick={handelClose}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <FormBox id={"layoutPermission"} form={form} onFinish={handleSubmit}>
          <Row gutter={Config.gutter}>
            <Col span={24}>
              <Form.Item
                name="assign_all"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "Please check the checkbox",
                  },
                ]}
              >
                <Checkbox
                  onChange={(e) => {
                    setAllAssign(e.target.checked);
                    onLayoutCheckAllChange(e);
                  }}
                >
                  Assign All Layout
                </Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Segmented options={options} onChange={handleSegmentChange} />
            </Col>
            <Col span={24}>
              <Form.Item name="layout_ids">
                <Checkbox.Group
                  className="layout_perm"
                  options={
                    layoutOptions &&
                    layoutOptions?.map((item1: any) => {
                      // Find a matching item in data2 based on global_layout_id
                      const match = LAYOUT?.layoutList?.is_used.find(
                        (item2: any) => item2.global_layout_id === item1.id
                      );

                      if (match) {
                        // If a match is found, return a new object with disabled set to false
                        const ids = form.getFieldValue("layout_ids");
                        ids?.push(item1.id ? item1.id : []);
                        form.setFieldValue("layout_ids", ids);
                        return {
                          label: item1.name,
                          value: item1.id,
                          disabled: true,
                          checked: true,
                        };
                      } else {
                        // If no match is found, return a new object with disabled set to true
                        return {
                          label: item1.name,
                          value: item1.id,
                          disabled: false,
                          checked: false,
                        };
                      }
                    })
                  }
                  disabled={allAssign}
                  onChange={onLayoutChange}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item hidden name="selectLIds" />
              <Form.Item hidden name="selectPIds" />
            </Col>
          </Row>
        </FormBox>
      </Drawer>
    );
  }
);

export default LayoutPermissionComponent;
