import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Tabs } from "antd";
import useStore from "../../../store";
import CustomerCreate from "./components/CustomerCreate";
import type { TabsProps } from "antd";
import Pricing from "./components/Pricing";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import CancelPlanComponent from "./components/CancelPlanComponent";

const Subscription: React.FC = observer(() => {
  const { SUBSCRIPTION, AUTH } = useStore();
  const [customerModel, setCustomerModel] = useState<boolean>(false);
  const [planTime, setPlanTime] = useState<any>(null);
  const [subData, setSubData] = useState<any>();
  const [displayData, setDisplayData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [cancelPlanDrawer, setCancelPlanDrawer] = useState<boolean>(false);

  useEffect(() => {
    SUBSCRIPTION.planFetchList()
      .then(() => {
        setLoading(true);
      })
      .catch(() => {
        setLoading(false);
      });
    return SUBSCRIPTION.setPlanList();
  }, []);

  const CustomercloseModel = () => {
    setCustomerModel(false);
  };

  const openCancelPlanDrawer = () => {
    setCancelPlanDrawer(true);
  };

  const closeCancelPlanDrawer = () => {
    setCancelPlanDrawer(false);
  };

  useEffect(() => {
    // initial select tab
    if (
      AUTH.subscription?.interval !== "days" &&
      AUTH.subscription?.interval !== null &&
      AUTH.subscription?.id
    ) {
      setPlanTime(
        AUTH?.subscription?.interval_count.toString() + AUTH?.subscription?.interval
      );
    } else {
      setPlanTime("1month");
    }
  }, [AUTH.subscription]);

  useEffect(() => {
    // modify price response
    if (SUBSCRIPTION.PlanList) {
      const priceList = SUBSCRIPTION.PlanList.map((item: any) => {
        const filteredPrices = item?.price?.filter((pData: any) => {
          const time = pData.interval_count.toString() + pData.interval;
          return time === planTime;
        });
        return { ...item, price: filteredPrices };
      });
      setDisplayData(priceList);
    }
  }, [SUBSCRIPTION.PlanList, planTime]);

  const items: TabsProps["items"] = [
    {
      key: "1month",
      label: "Monthly",
    },
    {
      key: "6month",
      label: "Half-yearly",
    },
    {
      key: "1year",
      label: "Annually",
    },
  ];

  const handleTabChange = (key: any) => {
    setPlanTime(key);
  };

  const titleSection = (
    <div className="titleSection">
      <h1>Get Started with our new plans</h1>
      <p>
        Our digital signage solution can transform visual experience of your
        customers in a cost-effective way!
      </p>
    </div>
  );

  return (
    <>
      {!loading && planTime !== null ? (
        <PageSpinner />
      ) : (
        <>
          <div className="pricingPage">
            <Tabs
              tabBarExtraContent={titleSection}
              defaultActiveKey={planTime}
              items={items}
              onChange={handleTabChange}
            />
            <Pricing
              planTime={planTime}
              displayData={displayData}
              setCustomerModel={setCustomerModel}
              setSubData={setSubData}
              openCancelPlanDrawer={openCancelPlanDrawer}
            />
          </div>
          <CustomerCreate
            visible={customerModel}
            close={CustomercloseModel}
            subData={subData}
          />
          <CancelPlanComponent
            visible={cancelPlanDrawer}
            close={closeCancelPlanDrawer}
          />
        </>
      )}
    </>
  );
});

export default Subscription;
