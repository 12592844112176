import React, { useState } from "react"
import { Button, Col, Form, Input, Modal, Row } from "antd"
import { observer } from "mobx-react"
import { componentProps } from "../../../../store/RateStore/RateInterface"
import useStore from "../../../../store"
import { Notification } from "../../../../config/Global"
import { ReactComponent as WarningFilled } from "../../../../assets/images/icon/warning.svg"

const DeleteComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const [form] = Form.useForm()
	const { RATE, AUTH } = useStore()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState<boolean>(false)

	const handleSubmit = (/* data: any */) => {
		setSaving(true)
		const id = RATE.deleteValues.id
		RATE.DeleteData(id).then((data: any) => {
			Notification.success({
				message: data.data
			})
			handleClose()
			AUTH.fetchAuthUser()

		}).catch((e: any) => {
			Notification.error({
				message: e?.data
			})
		}).finally(() => {
			setSaving(false)
		})
	}
	const handleClose = () => {
		close()
		setDisabled(true)
		form.resetFields()
	}
	const handleFieldChange = () => {
		if (form.getFieldsValue().confirm.toLowerCase() === "confirm") {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}

	return (
		<Modal
			open={visible}
			onCancel={handleClose}
			title={`Delete Rate`}
			destroyOnClose={true}
			className="commanModal deleteModal"
			footer={[
				<div key="1">
					<Button className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} form='deleteform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Delete</Button>
					<Button className="redBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						{/* <Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col> */}
						<Col span={4}><WarningFilled /></Col>
						<Col span={20}>
							<h3>Are you sure you want to Delete Rate { } ?</h3>
							<Form.Item style={{ marginBottom: 0 }} name="confirm">
								<Input placeholder='Type "CONFIRM" to delete' onChange={handleFieldChange} />
							</Form.Item>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	)
})

export default DeleteComponent