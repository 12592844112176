import React, { useState } from 'react';
import { PageHeader } from 'antd';
import { observer } from 'mobx-react';
import RecordPerPage from '../../../components/AgGridWrapper/RecordPerPage';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { AuditLogBreadcrumb } from '../../../config/BreadcrumbConfig'
import useStore from '../../../store';
import ListComponent from './components/ListComponent';
import DetailViewComponent from './components/DetailViewComponent';
import Unauthorized from '../../errors/Unauthorized';

const AuditLogManagement: React.FC = observer(() => {
	const { AUTH, AUDIT_LOG } = useStore()
	const [detailViewDrawer, setDetailViewDrawer] = useState<boolean>(false)

	const openDetailViewModal = (data: any) => {
		AUDIT_LOG.setEditValues(data.id)
		setDetailViewDrawer(true)
	}

	const closeDetailViewModal = () => {
		AUDIT_LOG.editValues = null
		setDetailViewDrawer(false)
	}

	return (
		<>
			<div className='header'>
				{!AUTH.checkPermission(13, 'list') ? (<Unauthorized />) :
					<PageHeader
						title={AuditLogBreadcrumb.title}
						tags={
							<BreadcrumbComponent items={AuditLogBreadcrumb.path} />}
						extra={[
							<RecordPerPage key="2" defaultValue={AUDIT_LOG.per_page + ' per page'} onChange={AUDIT_LOG.setPageSize} />,
						]}
					>
						<ListComponent
							openDetailViewModal={openDetailViewModal}
						/>
						<DetailViewComponent visible={detailViewDrawer} close={closeDetailViewModal} />
					</PageHeader>
				}
			</div>
		</>
	)
})

export default AuditLogManagement