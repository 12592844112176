import React, { useState } from "react";
import { observer } from "mobx-react";
import { InputBox } from "../../../../components/AntdAddons";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { Button, Col, Form, FormInstance, Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenNib } from "@fortawesome/free-solid-svg-icons";
import { StyleProps } from "../../../../store/LayoutStore/LayoutInterface";
import StyleModalComponent from "../components/StyleModal";
import { planMessage, widgetStyles } from "../../../../config/Global";
import { CONSTANT } from "../../../../config/Constant";
import useStore from "../../../../store";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  setShow?: (data: any) => void;
}

const EventElement: React.FC<formInterface> = observer(
  (props: formInterface) => {
    const { name, label, onChange, setShow } = props;
    const [styleProps, setStyleProps] = useState<StyleProps>({
      styles: [],
      type: "",
    });
    const [applyStyleModal, setApplyStyleModal] = useState<boolean>(false);
    const [dataV, setDataV] = useState();
    const { AUTH } = useStore();

    const openStyleModal = (data: StyleProps) => {
      setStyleProps(data);
      setApplyStyleModal(true);
    };

    const closeStyleModal = () => {
      setApplyStyleModal(false);
    };

    return (
      <Col>
        <InputBox.Text
          name={name}
          label={
            <div>
              {label.charAt(0).toUpperCase() + label.slice(1)}
              {AUTH.checkPlan(CONSTANT.INTERACTIVE_CONTENT_SUPPORT) ? (
                <Button
                  type="primary"
                  title="Apply style"
                  onClick={() =>
                    openStyleModal({ styles: widgetStyles.event(), type: name })
                  }
                  className="ml-5 form-button-icon"
                >
                  <FontAwesomeIcon icon={faPenNib} />
                </Button>
              ) : (
                <Popover
                title={() => planMessage()}
                >
                  <Button
                    type="primary"
                    disabled={true}
                    title="Apply style"
                    onClick={() =>
                      openStyleModal({
                        styles: widgetStyles.event(),
                        type: name,
                      })
                    }
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faPenNib} />
                  </Button>
                </Popover>
              )}
            </div>
          }
          placeholder={`Enter ${
            label.charAt(0).toUpperCase() + label.slice(1)
          }`}
          rules={LayoutRequest.text}
          onChange={onChange}
          extra="Please Enter Your Calendar Id to display Your event"
        />
        {applyStyleModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >       
            <StyleModalComponent
              setShow={setShow}
              setDataV={setDataV}
              dataV={dataV}
              visible={applyStyleModal}
              close={closeStyleModal}
              styleProps={styleProps}
            />
          </Form.Item>
        )}
      </Col>
    );
  }
);

export default EventElement;
