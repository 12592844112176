import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Button, Checkbox, Col, Form, Row } from "antd";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { Notification, validateFields } from "../../../../config/Global";
import { CustomerPropsForm } from "../../../../store/RateStore/RateInterface";
import useStore from "../../../../store";
import { SubRequest } from "../../../../requests/PageRequest";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const useOptions = () => {
  const options = useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          "::placeholder": {
            color: "#7c7c7c",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const CustomerCreateForm: React.FC<CustomerPropsForm> = observer(
  (props: CustomerPropsForm) => {
    const { SUBSCRIPTION, AUTH } = useStore();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    // const [error, setError] = useState<string | null>(null);
    const [showField, setShowField] = useState(false);
    const options = useOptions();
    const stripe: any = useStripe();
    const elements: any = useElements();
    const [form] = Form.useForm();

    const handleChange = () => {
      validateFields(form, setDisabled);
    };

    useEffect(() => {
      // initial check PriceId and product
      if (
        (AUTH?.subscription?.price === null ||
          AUTH?.subscription?.price === "00") &&
        !AUTH?.subscription?.product
      ) {
        setShowField(true);
      } else {
        setShowField(false);
      }
    }, [AUTH]);

    // for handle submit form
    const handleSubmit = async () => {
      if (showField) {
        if (!stripe || !elements) {
          return;
        }
        setLoading(true);
        const result = await stripe.createToken(
          elements.getElement(CardElement)
        );
        if (result.error) {
          Notification.error({
            message: result.error.message,
          });
          setLoading(false);
        } else {
          const subPayload: any = {
            source: showField ? result.token.id : "",
            price: props.subData.id,
            email: form.getFieldValue("email"),
            description: form.getFieldValue("description")?? "",
            name: form.getFieldValue("name"),
            amount: Math.floor(props.subData.amount / 100),
            type: "0",
            interval_count: props.subData.interval_count,
            duration: props.subData.interval,
            plan_name: props.subData.plan_name,
          };
          await SUBSCRIPTION.createSubscription(subPayload)
            .then(() => {
              props.close();
              Notification.success({
                message: `Your Subscription has been Activated`,
              });
              AUTH.fetchAuthUser();
              setLoading(false);
            })
            .catch((e: any) => {
              if(e.data.data) {
                setLoading(false);
                Notification.error({
                  message: `${e.data.data}`,
                });
              }
            });
        }
      } else {
        const subPayload = {
          email: form.getFieldValue("email")
            ? form.getFieldValue("email")
            : AUTH?.user?.email,
          price: props.subData.id,
          amount: Math.floor(props.subData.amount / 100),
          type: "0",
          name: form.getFieldValue("name"),
          description: form.getFieldValue("description")?? "",
          interval_count: props.subData.interval_count,
          duration: props.subData.interval,
          plan_name: props.subData.plan_name,
        };
        await SUBSCRIPTION.createSubscription(subPayload)
          .then((data: any) => {
            Notification.success({
              message: `${data.data}`,
            });
            AUTH.fetchAuthUser();
            setLoading(false);
            props.close();
          })
          .catch((e: any) => {
            if(e.data.data) {
              Notification.error({
                message: `${e.data.data}`,
              });
              setLoading(false);
            }
          });
      }
    };

    // const handleSubmit = async () => {
    //   setLoading(true);

    //   try {
    //     if (showField) {
    //       if (!stripe || !elements) {
    //         throw new Error("Stripe or elements not available");
    //       }

    //       const result = await stripe.createToken(
    //         elements.getElement(CardElement)
    //       );

    //       if (result.error) {
    //         throw new Error(result.error.message);
    //       }

    //       const subPayload = {
    //         source: result.token.id,
    //         price: props.subData.id,
    //         email: form.getFieldValue("email"),
    //         description: form.getFieldValue("description"),
    //         amount: Math.floor(props.subData.amount / 100),
    //         type: "0",
    //         interval_count: props.subData.interval_count,
    //         duration: props.subData.interval,
    //         plan_name: props.subData.plan_name,
    //       };

    //       await SUBSCRIPTION.createSubscription(subPayload);
    //     } else {
    //       const subPayload = {
    //         email: form.getFieldValue("email") || AUTH?.user?.email || "",
    //         price: props.subData.id,
    //         amount: Math.floor(props.subData.amount / 100),
    //         type: "0",
    //         description: form.getFieldValue("description"),
    //         interval_count: props.subData.interval_count,
    //         duration: props.subData.interval,
    //         plan_name: props.subData.plan_name,
    //       };

    //       await SUBSCRIPTION.createSubscription(subPayload);
    //     }

    //     props.close();
    //     Notification.success({
    //       message: `Your Subscription has been Activated`,
    //     });
    //     AUTH.fetchAuthUser();
    //   } catch (error:any) {
    //     Notification.error({
    //       message: error.data.data || "An error occurred",
    //     });
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    const handleTitle = () => {
      switch (props.subData.product) {
        case `${process.env.REACT_APP_PROD_PREMIUM}`:
          return "Premium";
        case `${process.env.REACT_APP_PROD_STANDARD}`:
          return "Standard";
        default:
          return "Basic";
      }
    };

    const currencySymbol = () => {
      return props.subData.currency === "usd" ? "$" : "₹";
    };

    return (
      <>
        <FormBox
          id={"addEditRateForm"}
          onFinish={handleSubmit}
          form={form}
          onChange={handleChange}
          className="paymentDetailForm"
        >
          <div className="topPlanDetail">
            <h1>Your subscription</h1>
            <div className="planName">
              <span>{handleTitle()}</span>
              <span>
                {Math.floor(props.subData.amount / 100)}
                {currencySymbol()}
              </span>
            </div>
            <div className="planTotalAmt">
              <span>Total</span>
              <span>
                {Math.floor(props.subData.amount / 100)}
                {currencySymbol()}
              </span>
            </div>
          </div>
          {AUTH?.subscription?.price !== "00" &&
            AUTH?.subscription?.price !== null &&
            AUTH?.subscription?.product && (
              <Row className="mb-20">
                <Col
                  xs={24}
                  sm={24}
                  lg={24}
                  md={24}
                  className="inputIcon paymentCardDetail"
                >
                  <Form.Item name="upgrade" valuePropName="checked">
                    <Checkbox
                      defaultChecked={showField}
                      onChange={(e: CheckboxChangeEvent) =>
                        setShowField(e.target.checked)
                      }
                    >
                      Upgrade
                    </Checkbox>
                  </Form.Item>
                  {/* {error && <div className="error">{error}</div>} */}
                </Col>
              </Row>
            )}
          {showField && (
            <>
              <Row className="mb-20">
                <Col
                  xs={24}
                  sm={24}
                  lg={24}
                  md={24}
                  className="inputIcon paymentCardDetail"
                >
                  <label className="mb-10">
                    <span>*</span>Credit Card
                  </label>
                  <CardElement className={"stripePayment"} options={options} />
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} lg={24} md={24} className="inputIcon">
                  <label>
                    <span>*</span>Card Holder Name
                  </label>
                  <InputBox.Text
                    name="name"
                    placeholder="Card Holder Name"
                    required
                    rules={SubRequest.name}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} lg={24} md={24} className="inputIcon">
                  <label>
                    <span>*</span>Email
                  </label>
                  <InputBox.Text
                    name="email"
                    placeholder="Email"
                    required
                    rules={SubRequest.email}
                  />
                </Col>
              </Row>
            </>
          )}
          <Button
            disabled={disabled}
            loading={loading}
            className="ant-button btn_primary form_submit_button"
            type="primary"
            htmlType="submit"
          >
            Pay Now
          </Button>
        </FormBox>
      </>
    );
  }
);

export default CustomerCreateForm;
