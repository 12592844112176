import React, { useState } from 'react';
import { Button, PageHeader } from 'antd';
import { observer } from 'mobx-react';
import RecordPerPage from '../../../components/AgGridWrapper/RecordPerPage';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { RoleBreadcrumb } from '../../../config/BreadcrumbConfig'
import useStore from '../../../store';
import ListComponent from './component/ListComponent';
import Unauthorized from '../../errors/Unauthorized';
import AddComponent from './component/AddComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import SwitchComponent from './component/SwitchComponent';
import { CONSTANT } from '../../../config/Constant';


const RoleManagement: React.FC = observer(() => {
	const { AUTH, ROLE } = useStore()
	const [addDrawer, setAddDrawer] = useState<boolean>(false)
	const [editDrawer, setEditDrawer] = useState<boolean>(false)
	const [deleteDrawer, setDeleteDrawer] = useState<boolean>(false)
	const [SwitchModalOpen, setSwitchModalOpen] = useState<boolean>(false)

	const closeAddDrawer = () => {
		setAddDrawer(false)
	}

	const openEditDrawer = (data: any) => {
		ROLE.setReadValues(data)
		setEditDrawer(true)
	}

	const closeEditDrawer = () => {
		setEditDrawer(false)
		ROLE.setReadValues(null)
	}

	const openDeleteDrawer = (data: any) => {
		ROLE.setReadValues(data)
		setDeleteDrawer(true)
	}

	const closeDeleteDrawer = () => {
		setDeleteDrawer(false)
		ROLE.setReadValues(null)
	}

	const openSwitchModal = (data: any) => {
		setSwitchModalOpen(data)
	}

	const closeSwitchModal = () => {
		setSwitchModalOpen(false)
	}

	return (
		<>
			<div className='header'>
				{!AUTH.checkPermission(CONSTANT.Company, 'list') ? (<Unauthorized />) :
					<PageHeader
						title={RoleBreadcrumb.title}
						tags={<BreadcrumbComponent items={RoleBreadcrumb.path} />}
						extra={[
							AUTH.checkPermission(CONSTANT.Company, 'add') && <Button key='1' type="primary" className='mr-10 ' onClick={() => setAddDrawer(true)}>Add Role</Button>,
							<RecordPerPage key="2" defaultValue={ROLE.per_page + ' per page'} onChange={ROLE.setPageSize} />,
						]}
					>
						<ListComponent openEditModal={openEditDrawer} openDeleteModal={openDeleteDrawer} openSwitchModal={openSwitchModal} />
						<AddComponent visible={addDrawer} close={closeAddDrawer} />
						<EditComponent visible={editDrawer} close={closeEditDrawer} />
						<DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
						<SwitchComponent visible={SwitchModalOpen} close={closeSwitchModal} />
					</PageHeader>
				}
			</div>
		</>
	)
})

export default RoleManagement