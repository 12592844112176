import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { GlobalPopupBreadcrumb } from '../../../config/BreadcrumbConfig'
import { Button, PageHeader, Form, Row, Col } from 'antd'
import useStore from '../../../store'
import Unauthorized from '../../errors/Unauthorized'
import { FormBox, InputBox } from '../../../components/AntdAddons'
import { globalPopup } from '../../../requests/PageRequest'
import moment from 'moment'
import { Notification, validateFields } from '../../../config/Global'
import CkEditor from '../../../components/CkEditorComponent'

const GlobalPopup = observer(() => {
	const [disabled, setDisabled] = useState<boolean>(true)
	const [content, setContent] = useState<string>('')
	const [saving, setSaving] = useState<boolean>(false)
    const [editorLoaded, setEditorLoaded] = useState<boolean>(false);

	const { AUTH, ROOT } = useStore()
	const [form] = Form.useForm()
	const { GLOBAL_POPUP } = useStore()
	
	const handleChange = () => {
		validateFields(form, setDisabled);
	}
	useEffect(() => {
		GLOBAL_POPUP.getPopupdata()
	}, [AUTH])

	useEffect(() => {
		form.setFieldsValue({
			auto_popup_start_time: GLOBAL_POPUP.readData?.auto_popup_start_time && moment(GLOBAL_POPUP.readData?.auto_popup_start_time, 'hh:mm:ss'),
			auto_popup_end_time: GLOBAL_POPUP.readData?.auto_popup_end_time && moment(GLOBAL_POPUP.readData?.auto_popup_end_time, 'hh:mm:ss'),
			global_popup_text: GLOBAL_POPUP.readData && GLOBAL_POPUP.readData?.global_popup_text,
		})
		setContent(GLOBAL_POPUP.readData && GLOBAL_POPUP.readData?.global_popup_text)
	}, [GLOBAL_POPUP.readData])

	const handleSubmit = (data: any) => {
		setSaving(true)
		data.auto_popup_start_time = data.auto_popup_start_time && moment(data.auto_popup_start_time).format('HH:mm:ss')
		data.auto_popup_end_time = data.auto_popup_end_time && moment(data.auto_popup_end_time).format('HH:mm:ss')
		GLOBAL_POPUP.globalPopupupdate(data).then((data: any) => {
			Notification.success({
				message: data.data
			})
			setDisabled(true)
		}).catch((e: any) => {
			ROOT.assignErrorToInput(form, e?.data);
		}).finally(() => {
			setSaving(false)
		})
	}

	const publishTimingAllTv = () => {
		GLOBAL_POPUP.globalPopuppublishTiming().then((data: any) => {
			Notification.success({
				message: data.data
			})
		}).catch((e: any) => {
			e;
		})
	}

	const publishPopupAllTv = () => {
		GLOBAL_POPUP.globalPopuppublishTv().then((data: any) => {
			Notification.success({
				message: data.data
			})
		}).catch((e: any) => {
			e;
		})
	}

	const editorChange = (value?: any) => {
		form.setFieldsValue({
			global_popup_text: value
		})
	}

	useEffect(() => {
		setEditorLoaded(true);
	}, []);

	return (
		<div className='globalTimeSelect'>

			{(!AUTH.checkPermission(8, 'add')) ? <Unauthorized /> : <PageHeader key={1}
				tags={<BreadcrumbComponent items={GlobalPopupBreadcrumb.path} />}
				title={GlobalPopupBreadcrumb.title}
				extra={
					<>
						{AUTH.checkPermission(8, 'Publish timings') && <Button type='primary' title='Publish Timings' onClick={publishTimingAllTv}>Publish Timings to all TVs</Button>}
						{AUTH.checkPermission(8, 'Publish popup') && <Button type='primary' title='Publish Popup Text and Timings' onClick={publishPopupAllTv}>Publish Popup to all TVs</Button>}
					</>
				}>

				{/* <AddComponent visible={addDrawer} close={handleClose} /> */}
				<FormBox form={form} id='addData' onFinish={handleSubmit} className='globalPopUpForm commanDrawer'>
					<Row gutter={15}>
						<Col span={4}>
							<InputBox.TimePicker onChange={handleChange} rules={globalPopup.start_time} name={'auto_popup_start_time'} label='Start Time'></InputBox.TimePicker>
						</Col>
						<Col span={4}>
							<InputBox.TimePicker name={'auto_popup_end_time'} onChange={handleChange} label='End Time' rules={globalPopup.end_time} ></InputBox.TimePicker>
						</Col>
						<Col span={24}>
							<Form.Item name={'global_popup_text'}>
								<CkEditor
                					value ={content}
									onChange={(data:any) => {
										handleChange();
										editorChange(data);
									  }}
                					editorLoaded={editorLoaded}
              					/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Button type='primary' loading={saving} disabled={disabled} htmlType='submit' form='addData'
								className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`}>Save</Button>
						</Col>
					</Row>
				</FormBox>
			</PageHeader>}
		</div>
	)
})

export default GlobalPopup