import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../config/ApiUrl";

export default class UpdateAPKStore {
    readValues: any = null

    constructor() {
        makeAutoObservable(this)
    }

    read = () => {
        return axios.get(API_URL.UPDATE_APK.READ).then(({ data }) => {
            this.readValues = data.data
        }).catch((response) => {
            return Promise.reject(response)
        })
    }

    update = (data: any) => {
        return axios.post(API_URL.UPDATE_APK.UPLOAD, data).then(({ data }) => {
            return data
        }).catch((e: any) => {
            return Promise.reject(e)
        })
    }
}