export const dashboardSteps = () => {
  const initialSteps: any[] = [
    {
      selector: '[data-tut="reactour__online_device_count"]',
      content: `Online device count.`,
    },
    {
      selector: '[data-tut="reactour__offline_device_count"]',
      content: `Offline device count.`,
    },
    {
      selector: '[data-tut="reactour__fire_tv"]',
      content: `Display FireTv devices count.`,
    },
    {
      selector: '[data-tut="reactour__android_device"]',
      content: `Display Android devices count.`,
    },
  ];

  const otherSteps: any[] = [];

  return { initialSteps, otherSteps };
};

export const devicesSteps = () => {
  const initialSteps: any[] = [
    {
      selector: '[data-tut="reactour__add_device"]',
      content: `You can add a device by clicking here.`,
    },
    {
      selector: '[data-tut="reactour__list_is_live"]',
      content: `This icon indicates whether the device is online or offline.`,
    },
  ];

  const otherSteps: any[] = [
    {
      selector: '[data-tut="reactour__list_publish_device"]',
      content: `Use this feature to Publish device.`,
    },
  ];

  return { initialSteps, otherSteps };
};

export const layoutsSteps = () => {
  const initialSteps: any[] = [
    {
      selector: '[data-tut="reactour__add_layout"]',
      content: `Create a new layout by clicking here.`,
    },
  ];

  const otherSteps: any[] = [
    {
      selector: '[data-tut="reactour__list_preview"]',
      content: `You can preview your layout by clicking here.`,
    },
    {
      selector: '[data-tut="reactour__list_publish"]',
      content: `Publish your layout to make it live by clicking here.`,
    },
    {
      selector: '[data-tut="reactour__list_edit"]',
      content: `Edit your layout easily by clicking here.`,
    },
  ];

  return { initialSteps, otherSteps };
};

export const getSteps = (path: string, selectorLength: number) => {
  const tourData: any = JSON.parse(localStorage.getItem("tourData") ?? "{}");
  const key = path.replaceAll("/", "_");

  let steps: any[] = [];
  let stepData: any;
  if (key === "_dashboard") {
    stepData = dashboardSteps();
  }
  if (key === "_devices") {
    stepData = devicesSteps();
  }
  if (key === "_layouts") {
    stepData = layoutsSteps();
  }

  if (stepData) {
    const localStorageData = tourData[key];
    if (selectorLength == stepData.initialSteps.length) {
      steps = stepData.initialSteps;
    } else if (localStorageData && localStorageData.count == stepData.initialSteps.length && selectorLength > stepData.initialSteps.length) {
      steps = stepData.otherSteps;
    } else {
      steps = [...stepData.initialSteps, ...stepData.otherSteps];
    }

    const tourDataNew: any = {
      ...tourData,
      ...{ [key]: { path: path, count: steps.length } },
    };

    const display = (!localStorageData || (localStorageData && localStorageData.count < steps.length));
    
    if (display) {
        localStorage.setItem("tourData", JSON.stringify(tourDataNew));
    }

    return { steps, display };
  }
  return null;
};
