import { Col, Row, Typography } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { ReactComponent as FireTvIcon } from "../../../../assets/images/icon/amazon.svg";
import { ReactComponent as AndroidDeviceIcon } from "../../../../assets/images/icon/android.svg";
// import { ReactComponent as ChromeCast } from "../../../../assets/images/icon/chrome.svg";
// import { ReactComponent as MappinOnline } from "../../../../assets/images/icon/mappinOnline.svg";
// import { ReactComponent as mappinOfline } from "../../../../assets/images/icon/mappinOfline.svg";
import useStore from "../../../../store";
import { NavLink, Link } from "react-router-dom";
import { CONSTANT } from "../../../../config/Constant";
// import GoogleMapReact from 'google-map-react';

// const Popup: React.FC<MarkerData> = ({ deviceName, companyName }: any) => (

// 	<div className="popup">
// 		<p>{`${deviceName} (${companyName})`}</p>
// 	</div>
// );

interface MarkerData {
	lat: number;
	lng: number;
	deviceName: string;
	companyName: string;
}
// const mapMarkercontent = (deviceName : any, companyName :any) => {
// 	return(
// 		<ul>
// 			<li>Company Name : <strong>{companyName}</strong></li>
// 			<li>Device Name : <strong>{deviceName}</strong></li>
// 			<li>Status : <strong>Online</strong></li> 
// 		</ul>
// 	)
// }
// const AnyReactComponent = ({ deviceName, companyName }: any) => <div>
// 	{/* {`${deviceName} (${companyName})`} */}
// 	<div className="mapMarker">
// 		<Popover
// 			content={mapMarkercontent(deviceName, companyName)}		
// 		>
// 			<MappinOnline />
// 		</Popover>

// 	</div>
// </div>;

const DevicesLayout: React.FC = observer(() => {
	const { DASHBOARD_STORE, AUTH, USER } = useStore();
	const { user } = AUTH
	const { Title } = Typography
	// const [selectedMarker, setSelectedMarker] = useState<MarkerData | null>(null);
	const [markers, setMarkers] = useState<MarkerData[] | null>(null);
	const [mapProps, setMapProps] = useState<any>();

	const calculateCenter = (markers: MarkerData[]) => {
		const totalMarkers = markers.length;
		const centerLat = markers.reduce((sum, marker) => sum + marker.lat, 0) / totalMarkers;
		const centerLng = markers.reduce((sum, marker) => sum + marker.lng, 0) / totalMarkers;
		return { lat: centerLat, lng: centerLng };
	};

	useEffect(() => {
		DASHBOARD_STORE.dashboardCount();
	}, [])

	useEffect(() => {
		const markersData: MarkerData[] = [];
		DASHBOARD_STORE.dashboard_count?.device_data?.length > 0 && DASHBOARD_STORE.dashboard_count.device_data.map((item: any) => {
			markersData.push({
				lat: parseFloat(item.latitude),
				lng: parseFloat(item.longitude),
				deviceName: item.name ?? "",
				companyName: item.company_data?.company_name ?? "",
			})
		})
		setMarkers(markersData);
		markersData.length > 0 && setMapProps({
			center: calculateCenter(markersData),
			zoom: 11, // Adjust the zoom level as needed
		})
	}, [DASHBOARD_STORE.dashboard_count])

	useEffect(() => {
		if (AUTH.checkPermission(CONSTANT.SubAdmin, 'list')) {
			USER.fetchList({ "status": DASHBOARD_STORE.dashboard_count?.total_users })
		}
	}, [])

	useEffect(() => {
		//
	}, [markers, mapProps])

	return (
		<div className="DevicesLayoutWrap">
			{(AUTH.expiry_note?.message && user.company_id) && <>
				{<Title level={4}>{AUTH.expiry_note?.is_expired ? "Account is expired, Contact for renewal license" : "Renewal notice for license"}</Title>}
				<div dangerouslySetInnerHTML={{ __html: AUTH.expiry_note?.message }} className="deviceAlert"></div>
			</>}
			{
				[CONSTANT.SuperAdmin, CONSTANT.SubAdmin,CONSTANT.Partner].includes(AUTH.user.users_roles_id) ? <Row gutter={30}>
					<Col className="col-6">
						<NavLink to={'/users?licence=7'}>
							<div className="cardContainer">
								<div className="cardContent">
									<div className="deviceNumber green">
										<h1>{DASHBOARD_STORE.dashboard_count?.total_company}</h1>
									</div>
									<div className="cardHeading">
										<h2>All Companies</h2>
									</div>
								</div>
							</div>
						</NavLink>
					</Col>
					<Col className="col-6">
						<Link to={'/users?licence=0'}>
							<div className="cardContainer">
								<div className="cardContent">
									<div className="deviceNumber blue">
										<h1>{DASHBOARD_STORE.dashboard_count?.with_license}</h1>
									</div>
									<div className="cardHeading">
										<h2>Companies With License</h2>
									</div>
								</div>
							</div>
						</Link>
					</Col>
					<Col className="col-6" >
						<Link to={'/users?licence=1'}>
							<div className="cardContainer">
								<div className="cardContent">
									<div className="deviceNumber purple">
										<h1>{DASHBOARD_STORE.dashboard_count?.without_license}</h1>
									</div>
									<div className="cardHeading">
										<h2>Companies Without License</h2>
									</div>
								</div>
							</div>
						</Link>
					</Col>
					<Col className="col-6">
						<Link to={'/users?licence=2'}>
							<div className="cardContainer">
								<div className="cardContent">
									<div className="deviceNumber pink">
										<h1>{DASHBOARD_STORE.dashboard_count?.expire_license}</h1>
									</div>
									<div className="cardHeading">
										<h2>Companies Expired License</h2>
									</div>
								</div>
							</div>
						</Link>
					</Col>
				</Row> : ""
			}


			<Row gutter={30}>
					{ // [CONSTANT.Company, CONSTANT.Employee].includes(user?.users_roles_id)
					user.company_id ? <Col className="col-6">
						<Link to={'/devices?status=1'}>
							<div className="cardContainer">
								<div className="cardContent">
									<div className="deviceNumber green">
										<h1>
											{DASHBOARD_STORE.dashboard_count?.allow_device}
										</h1>
									</div>
									<div className="cardHeading">
										<h2>Allowed Devices</h2>
									</div>
								</div>
							</div>
						</Link>
					</Col> : ![CONSTANT.Partner].includes(user?.users_roles_id) && <Col className="col-6">
						{/* <Link to={'/devices?status=3'}> */}
							<div className="cardContainer">
								<div className="cardContent">
									<div className="deviceNumber blue">
										<h1>{DASHBOARD_STORE.dashboard_count?.not_registered_device}</h1>
									</div>
									<div className="cardHeading">
										<h2>Not Registered</h2>
									</div>
								</div>
							</div>
						{/* </Link> */}
					</Col>
				}
				{
					// [CONSTANT.Company, CONSTANT.Employee].includes(user?.users_roles_id)
					user.company_id
					 ? <Col className="col-6">
						<Link to={'/devices'}>
							<div className="cardContainer">
								<div className="cardContent">
									<div className="deviceNumber blue">
										<h1>{DASHBOARD_STORE.dashboard_count?.total_device}</h1>
									</div>
									<div className="cardHeading">
										<h2>All Devices</h2>
									</div>
								</div>
							</div>
						</Link>
					</Col> : <Col className="col-6">
						<Link to={'/devices?status=2'}>
							<div className="cardContainer">
								<div className="cardContent">
									<div className="deviceNumber pink">
										<h1>
											{DASHBOARD_STORE.dashboard_count?.expired_device
												? DASHBOARD_STORE.dashboard_count?.expired_device
												: 0}
										</h1>
									</div>
									<div className="cardHeading">
										<h2>Expired Devices</h2>
									</div>
								</div>
							</div>
						</Link>
					</Col>
				}
				<Col className="col-6" data-tut="reactour__online_device_count">
					<Link to={'/devices?status=7'}>
						<div className="cardContainer">
							<div className="cardContent">
								<div className="deviceNumber green">
									<h1>{`${DASHBOARD_STORE.dashboard_count?.online_offline_device_count?.online ? DASHBOARD_STORE.dashboard_count?.online_offline_device_count?.online : 0}`}</h1>
								</div>
								<div className="cardHeading">
									<h2>Online Devices</h2>
								</div>
							</div>
						</div>
					</Link>
				</Col>
				<Col className="col-6" data-tut="reactour__offline_device_count">
					<Link to={'/devices?status=8'}>
						<div className="cardContainer">
							<div className="cardContent">
								<div className="deviceNumber pink">
									<h1>{`${DASHBOARD_STORE.dashboard_count?.online_offline_device_count?.offline ? DASHBOARD_STORE.dashboard_count?.online_offline_device_count?.offline : 0}`}</h1>
								</div>
								<div className="cardHeading">
									<h2>Offline Devices</h2>
								</div>
							</div>
						</div>
					</Link>
				</Col>
				<Col className="col-6">
					<Link to={'/devices?status=1'}>
						<div className="cardContainer">
							<div className="cardContent">
								<div className="deviceNumber purple">
									<h1>{DASHBOARD_STORE.dashboard_count?.active_device}</h1>
								</div>
								<div className="cardHeading">
									<h2>Active Devices</h2>
								</div>
							</div>
						</div>
					</Link>
				</Col>
				<Col className="col-6" >
					<Link to={'/devices?status=0'}>
						<div className="cardContainer">
							<div className="cardContent">
								<div className="deviceNumber pink">
									<h1>{DASHBOARD_STORE.dashboard_count?.deactive_device}</h1>
								</div>
								<div className="cardHeading">
									<h2>Inactive Devices</h2>
								</div>
							</div>
						</div>
					</Link>
				</Col>
				{ DASHBOARD_STORE.dashboard_count?.data.map((item: any, index: number) => {
						return (<Col key={`device-count-${index}`} className="col-6" >
							<div className="cardContainer">
								<div className="cardContent">
									<div className="deviceNumber pink">
										<h1>{`${item.active_device}/${item.total_device}`}</h1>
									</div>
									<div className="cardHeading">
										<h2>{`${item.group_name} ${item?.company_name?.company_name ? `(${item?.company_name?.company_name})` : ``}`}</h2>
									</div>
								</div>
							</div>
						</Col>)
					})
				}{[CONSTANT.Partner].includes(AUTH.user.users_roles_id) && <><Col className="col-6" >
						<div className="cardContainer">
							<div className="cardContent">
								<div className="deviceNumber pink">
									<h1>{DASHBOARD_STORE.dashboard_count?.max_device ?? 0}</h1>
								</div>
								<div className="cardHeading">
									<h2>Maximum Devices</h2>
								</div>
							</div>
						</div>
				</Col>
				<Col className="col-6" >
						<div className="cardContainer">
							<div className="cardContent">
								<div className="deviceNumber pink">
									<h1>{DASHBOARD_STORE.dashboard_count?.max_company ?? 0}</h1>
								</div>
								<div className="cardHeading">
									<h2>Maximum Company</h2>
								</div>
							</div>
						</div>
				</Col>
				</>}
			</Row>

			

			<Row className="dashboardSecond" gutter={30}>
				<Col className="col-4" data-tut="reactour__fire_tv">
					<Link to={'/devices?status=4'}>
						<div className="cardContainer">
							<div className="CardContent">
								<div className="cardIcon darkYellow">
									<FireTvIcon />
								</div>
								<div className="CardHeading">
									<h2>Fire TV</h2>
								</div>
								<div className="deviceNumber blue">
									<h1>{DASHBOARD_STORE.dashboard_count?.fire_tv}</h1>
								</div>
							</div>
						</div>
					</Link>
				</Col>
				<Col className="col-4" data-tut="reactour__android_device">
					<Link to={'/devices?status=5'}>
						<div className="cardContainer">
							<div className="CardContent">
								<div className="cardIcon green">
									<AndroidDeviceIcon />
								</div>
								<div className="CardHeading">
									<h2>Android Device</h2>
								</div>
								<div className="deviceNumber purple">
									<h1>{DASHBOARD_STORE.dashboard_count?.android_device}</h1>
								</div>
							</div>
						</div>
					</Link>
				</Col>
				{/* <Col className="col-4">
					<Link to={'/devices?status=6'}>
						<div className="cardContainer">
							<div className="CardContent">
								<div className="cardIcon lightYellow ">
									<ChromeCast />
								</div>
								<div className="CardHeading">
									<h2>Chromecast</h2>
								</div>
								<div className="deviceNumber pink">
									<h1>{DASHBOARD_STORE.dashboard_count?.chromecast}</h1>
								</div>
							</div>
						</div>
					</Link>
				</Col> */}
			</Row>

			{/* <Row className="dashboardSecond" gutter={30}>
				<Col className="col-12" style={{ width: "100%", height: "100%" }}>
					<div style={{ height: '500px', width: '100%' }}>
						{mapProps && markers && markers.length > 0 && <GoogleMapReact
							bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY as string }}
							defaultCenter={mapProps.center}
							defaultZoom={mapProps.zoom}
						>
							{markers.map((marker, index) => (
								<AnyReactComponent
									key={index}
									lat={marker?.lat}
									lng={marker?.lng}
									deviceName={marker?.deviceName}
									companyName={marker?.companyName}
									onClick={() => setSelectedMarker(marker)}
								/>
							))}

							{selectedMarker && (
								<Popup
									lat={selectedMarker?.lat}
									lng={selectedMarker?.lng}
									deviceName={selectedMarker?.deviceName}
									companyName={selectedMarker?.companyName}
								/>
							)}
						</GoogleMapReact>}
					</div>
				</Col>
			</Row> */}
		</div>
	);
});

export default DevicesLayout;
