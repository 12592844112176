import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Card, Checkbox, Col, Divider, Drawer, Form, Row } from "antd";
import useStore from "../../../../store";
import { FormBox } from "../../../../components/AntdAddons";
import Config from "../../../../config/Config";
import { UserRequest } from "../../../../requests/PageRequest";
import { ModifyPermissionsProps } from "../../../../store/CustmerStore/CustomerInterface";
import { ErrorProps } from "../../../../store/RootStore/RootInterface";
import { Notification } from "../../../../config/Global";
import { CONSTANT } from "../../../../config/Constant";


const ModifyPermissionComponent: React.FC<ModifyPermissionsProps> = observer(
  (props: ModifyPermissionsProps) => {
    const { USER, ROOT } = useStore();
    const { close, visible } = props;
    const [form] = Form.useForm();
    const [saving, setSaving] = useState<boolean>(false);
    const [hidePermissionTable, setHidePermissionTable] =
      useState<boolean>(false);

      useEffect(() => {
        //for set initial states
        if(USER?.is_custom) {
          form.setFieldValue("myCheckbox", true);
          setHidePermissionTable(true);
        }else {
          form.setFieldValue("myCheckbox", false);
          setHidePermissionTable(false);
        }
      if (visible && USER?.viewValues) {
        const payload = {
          user_id: USER?.viewValues?.id,
        };
        USER.getPermissionsList(payload)
          .then((data: any) => {
            form.setFieldsValue({
              permissions: data.data,
            });
          })
          .catch((e: any) => {
            if (e.errors) {
              form.setFields(e.errors);
            }
          });
      }
    }, [USER?.viewValues]);

    const handelClose = () => {
      setHidePermissionTable(false);
      close();
    };

    const handleSubmit = (data: any) => {
      setSaving(true);
      var actions: any = {};
      if (data.permissions) {
        data.permissions.map((item: any) => {
          item.actions.map((action: any) => {
            if (action.is_selected === 1) {
              if (!actions[item.id]) {
                actions[item.id] = [];
              }
              actions[item.id].push(action.id);
            }
            return null;
          });
          return null;
        });
        data.permissions = actions;
      } else {
        form.getFieldValue("permissions") &&
          form.getFieldValue("permissions").map((item: any) => {
            item.actions.map((action: any) => {
              if (action.is_selected === 1) {
                if (!actions[item.id]) {
                  actions[item.id] = [];
                }
                actions[item.id].push(action.id);
              }
              return null;
            });
            return null;
          });
        data.permissions = actions;
      }
      delete data.myCheckbox;
      if (hidePermissionTable === false) {
        data.permissions = USER?.userPermissions;
        data.is_custom = CONSTANT.USERPERMISSION.is_custom0
      }else {
        data.is_custom = CONSTANT.USERPERMISSION.is_custom1
      }
      const id = USER?.viewValues?.id;
      data.role_id = USER?.viewValues?.role.id;

      USER.userPermission(id, data)
        .then((data: any) => {
          setSaving(false);
          Notification.success({
            message: data.data.message,
          });
          handelClose();
        })
        .catch((e: ErrorProps) => {
          ROOT.assignErrorToInput(form, e?.data.errors);
        })
        .finally(() => setSaving(false));
    };

    return (
      <Drawer
        open={visible}
        onClose={handelClose}
        width={750}
        title={`Modify Permissions`}
        className="commanDrawer editUser"
        destroyOnClose={true}
        footer={[
          <div key="1">
            <Button
              //   className={`${disabled ? "disableBtn" : "blackLineBtn"}`}
              //   disabled={disabled}
              form="modifyPermission"
              loading={saving}
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
            <Button className="orangeBgBtn cancelBtn" onClick={close}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <FormBox
          id={"modifyPermission"}
          form={form}
          onFinish={handleSubmit}
          //   onChange={onChange}
        >
          <Row gutter={Config.gutter}>
            <Col span={24}>
              <Form.Item
                name="myCheckbox"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "Please check the checkbox",
                  },
                ]}
              >
                <Checkbox
                  //   checked={hidePermissionTable}
                  onChange={(e) => setHidePermissionTable(e.target.checked)}
                >
                  Modify Permission
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <>
                <Row className="permisionRow">
                  <Col span={6}>Features</Col>
                  <Col span={12}>Actions</Col>
                </Row>
                <Form.Item
                  name="permissions"
                  rules={UserRequest.permissions}
                  className="permissions_form_item"
                >
                  <Form.List name="permissions">
                    {(fields) => {
                      return (
                        fields &&
                        fields.map((field) => {
                          var value =
                            form.getFieldValue("permissions")[field.key];
                          var isDisabled = false;
                          value.actions &&
                            value.actions.map((action: any) => {
                              if (
                                action.action_name === "list" &&
                                action.is_selected !== 1
                              ) {
                                isDisabled = true;
                              }
                              return null;
                            });
                          var all_checked = 0;
                          value.actions.map((action: any) => {
                            if (action.is_selected === 1) {
                              all_checked++;
                            }
                            return null;
                          });

                          !hidePermissionTable &&
                            value.actions &&
                            value.actions.map(() => {
                              isDisabled = true;
                              return null;
                            });

                          return (
                            <div key={field.key} className="permissionCard">
                              {value.actions.length > 0 && (
                                <Card className="mt-10">
                                  <Row gutter={24}>
                                    <Col
                                      span={5}
                                      style={{
                                        borderRight: "1px solid #f0f0f0",
                                      }}
                                    >
                                      <b>{value.title}</b>
                                    </Col>
                                    <Col span={19}>
                                      <Checkbox
                                        disabled={
                                          !hidePermissionTable ||
                                          (hidePermissionTable &&
                                            [1].includes(
                                              USER.viewValues?.users_roles_id
                                            ))
                                        }
                                        checked={
                                          all_checked === value.actions.length
                                        }
                                        onChange={(e) => {
                                          var temp =
                                            form.getFieldValue("permissions");
                                          temp[field.key].actions.map(
                                            (action: any, index: number) => {
                                              temp[field.key].actions[
                                                index
                                              ].is_selected = e.target.checked
                                                ? 1
                                                : 0;
                                              return null;
                                            }
                                          );
                                          form.setFieldsValue({
                                            permissions: temp,
                                          });
                                        }}
                                      >
                                        Check all
                                      </Checkbox>
                                      <Divider type="vertical" />
                                      {value.actions.map(
                                        (action: any, index: number) => {
                                          var temp = action.action_name.replace(
                                            /_/g,
                                            " "
                                          );
                                          return (
                                            <div
                                              key={index}
                                              style={{ display: "inline" }}
                                            >
                                              <Form.Item
                                                noStyle
                                                name={[
                                                  field.name,
                                                  "actions",
                                                  index,
                                                  "is_selected",
                                                ]}
                                              >
                                                <Checkbox
                                                  disabled={
                                                    !hidePermissionTable ||
                                                    (hidePermissionTable &&
                                                      isDisabled &&
                                                      action.action_name !==
                                                        "list")
                                                  }
                                                  checked={
                                                    action.is_selected === 1
                                                      ? true
                                                      : false
                                                  }
                                                  value={action.id}
                                                  onChange={(e) => {
                                                    var temp =
                                                      form.getFieldValue(
                                                        "permissions"
                                                      );
                                                    temp[field.key].actions[
                                                      index
                                                    ].is_selected = e.target
                                                      .checked
                                                      ? 1
                                                      : 0;
                                                    if (
                                                      temp[field.key].actions[
                                                        index
                                                      ].action_name ===
                                                        "list" &&
                                                      !e.target.checked
                                                    ) {
                                                      temp[
                                                        field.key
                                                      ].actions.map(
                                                        (
                                                          _: any,
                                                          index: number
                                                        ) => {
                                                          temp[
                                                            field.key
                                                          ].actions[
                                                            index
                                                          ].is_selected = 0;
                                                          return null;
                                                        }
                                                      );
                                                    }
                                                    form.setFieldsValue({
                                                      permissions: temp,
                                                    });
                                                  }}
                                                >
                                                  {temp
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    temp.slice(1)}
                                                </Checkbox>
                                              </Form.Item>
                                            </div>
                                          );
                                        }
                                      )}
                                    </Col>
                                  </Row>
                                </Card>
                              )}
                            </div>
                          );
                        })
                      );
                    }}
                  </Form.List>
                </Form.Item>
              </>
            </Col>
          </Row>
        </FormBox>
      </Drawer>
    );
  }
);

export default ModifyPermissionComponent;
