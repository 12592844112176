import { faLink, faPenNib } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  FormInstance,
  message,
  Modal,
  Popover,
  Upload,
} from "antd";
import { RcFile, UploadFile } from "antd/lib/upload";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { StyleProps } from "../../../../store/LayoutStore/LayoutInterface";
import { UploadOutlined } from "@ant-design/icons";
import StyleModalComponent from "../components/StyleModal";
import {
  createUrl,
  planMessage,
  uploadedFileOnPreview,
  widgetStyles,
} from "../../../../config/Global";
import useStore from "../../../../store";
// import ImgCrop from "antd-img-crop";
import { CONSTANT } from "../../../../config/Constant";
import GenerateAIImage from "../components/GenerateAIImage";
import CropImageUrlElement from "../components/CropImageUrlElement";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  images: any;
  layout_id: any;
  setShow?: (data: any) => void;
  layoutIdFlag?: boolean;
}

const ImageElement: React.FC<formInterface> = observer((props) => {
  const {
    form,
    onChange,
    name,
    label,
    images,
    layout_id,
    setShow,
    layoutIdFlag,
  } = props;
  const [styleProps, setStyleProps] = useState<StyleProps>({
    styles: [],
    type: "",
  });
  const [applyStyleModal, setApplyStyleModal] = useState<boolean>(false);
  const [applyGenerateAIImageModal, setApplyGenerateAIImageModal] =
    useState<boolean>(false);
  const [applyImageUrlModal, setApplyImageUrlModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [msg, setMsg] = useState("");
  const { LAYOUT, AUTH } = useStore();
  const [dataV, setDataV] = useState();
  // const [aspectRatio, setAspectRatio] = useState<number>(1 / 1);
  const [visible, setVisible] = useState<boolean>(false);

  const openStyleModal = (data: StyleProps) => {
    setStyleProps(data);
    setApplyStyleModal(true);
  };

  const closeStyleModal = () => {
    setApplyStyleModal(false);
  };

  useEffect(() => {
    if (LAYOUT.editValues && form.getFieldValue(name)) {
      setFileList([
        {
          uid: "-1",
          name: form.getFieldValue(name),
          status: "done",
          url: createUrl("/storage/media/file/" + form.getFieldValue(name)),
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (LAYOUT.listAiImages && LAYOUT.listAiImages?.field_name === name) {
      const aiImages1 = LAYOUT?.listAiImages?.images?.map((value: number) => {
        const list = LAYOUT?.aiImage?.find((data: number, index: number) => {
          return index === value;
        });
        return list;
      });

      const fileList = aiImages1?.map((data: any) => ({
        uid: "-1",
        name: `${data?.name}`,
        status: "done",
        url: data?.image_resource_url,
      }));
      setFileList(fileList);

      form.setFieldsValue({
        [name]: {
          aiGenerated: {
            type: "aiGenerated",
            name: fileList[0]?.name,
            url: fileList[0]?.url,
          },
        },
      });
      onChange();
    }
  }, [LAYOUT.listAiImages]);

  useEffect(() => {
    if (layout_id && layoutIdFlag) {
      setFileList([]);
      form.setFieldsValue({ [name]: null });
    }
  }, [layout_id]);

  // For upload image through web url
  const handleUpload = async (
    croppedImage: string,
    originalFileName: string
  ) => {
    const response = await fetch(croppedImage);
    const blob = await response.blob();
    
    const newFile: any = [
      {
        uid: "-1",
        name: `${originalFileName}.jpeg`,
        status: "done",
        url: croppedImage,
      },
    ];
    setFileList(newFile);

    const fileWithOriginalName = new File(
      [blob],
      `${originalFileName}.jpeg`,
      { type: blob.type }
    );
    form.setFieldsValue({
      [name]: fileWithOriginalName,
    });
    onChange();
  };

  useEffect(() => {
    setMsg("");
    // setAspectRatio(1 / 1);
    if (
      [
        "image-GIF-top-left",
        "image-GIF-top-right",
        "image-GIF-bottom-left",
        "image-GIF-bottom-right",
      ]?.includes(images) &&
      [21]?.includes(layout_id)
    ) {
      setMsg("Required Image/GIF Height = 540 & Width = 960");
      // setAspectRatio(960 / 540);
    } else if ([7]?.includes(layout_id)) {
      if (["image-1"]?.includes(images)) {
        setMsg("Required Image Height = 648 & Width = 1120");
        // setAspectRatio(1120 / 648);
      } else if (["image-2", "image-3"]?.includes(images)) {
        setMsg("Required Image Height = 270 & Width = 560");
        // setAspectRatio(560 / 270);
      } else {
        setMsg("Required Image Height = 270 & Width = 800");
        // setAspectRatio(800 / 270);
      }
    } else if ([8]?.includes(layout_id)) {
      if (["image-1"]?.includes(images)) {
        setMsg("Required Image Height = 650 & Width = 1120");
        // setAspectRatio(1120 / 650);
      } else {
        setMsg("Required Image Height = 270 & Width = 560");
        // setAspectRatio(560 / 270);
      } 
    } else if (["image"]?.includes(images) && [55]?.includes(layout_id)) {
      setMsg("Required Image Height = 540 & Width = 1280");
      // setAspectRatio(1280 / 540);
    } else if (
      ["image-1", "image-2"]?.includes(images) &&
      [6]?.includes(layout_id)
    ) {
      setMsg("Required Image Height = 270 & Width = 560");
      // setAspectRatio(560 / 270);
    } else if (
      ["image-leftbottom", "image-rightbottom"]?.includes(images) &&
      [18]?.includes(layout_id)
    ) {
      setMsg("Required Image Height = 270 & Width = 480");
      // setAspectRatio(480 / 270);
    } else if (
      ["image-1", "image-2", "image-3"]?.includes(images) &&
      [41]?.includes(layout_id)
    ) {
      setMsg("Required Image Height = 540 & Width = 640");
      // setAspectRatio(640 / 540);
    } else if (
      ["image-1", "image-2", "image-3", "image-4"]?.includes(images) &&
      [5]?.includes(layout_id)
    ) {
      setMsg("Required Image Height = 270 & Width = 580");
      // setAspectRatio(580 / 270);
    } else if (
      (["image"]?.includes(images) && [35]?.includes(layout_id)) ||
      [36]?.includes(layout_id)
    ) {
      setMsg("Required Image Height = 1080 & Width = 960");
      // setAspectRatio(960 / 1080);
    } else if (
      ["image"]?.includes(images) &&
      ( [11]?.includes(layout_id))
    ) {
      setMsg("Required Image Height = 290 & Width = 600");
      // setAspectRatio(600 / 290);
    } else if (
      ["image"]?.includes(images) &&
      ([39]?.includes(layout_id))
    ) {
      setMsg("You can select jpg or png or gif image file for this widget");
    } else if (
      ["image-GIF-middle", "image-GIF-top"]?.includes(images) &&
      [43]?.includes(layout_id)
    ) {
      setMsg("Required Image/GIF Height = 640 & Width = 1080");
      // setAspectRatio(1080 / 640);
    } else if (
      ["image-GIF-bottom", "image-GIF-top"]?.includes(images) &&
      [23]?.includes(layout_id)
    ) {
      setMsg("Required Image/GIF Height = 960 & Width = 1080");
      // setAspectRatio(1080 / 960);
    } else if (
      ["image-GIF-middle", "image-GIF-top", "image-GIF-bottom"]?.includes(
        images
      ) &&
      [26]?.includes(layout_id)
    ) {
      setMsg("Required Image/GIF Height = 640 & Width = 1080");
      // setAspectRatio(1080 / 640);
    } else if (
      [
        "image-1",
        "image-left-top",
        "image-left-bottom",
        "image-right-top",
        "image-right-bottom",
      ]?.includes(images) &&
      [16]?.includes(layout_id)
    ) {
      if ([
        "image-1"
      ]?.includes(images)){
        setMsg("Required Image Height = 770 & Width = 190");
        // setAspectRatio(190 / 770);  
      }
      else{
        setMsg("Required Image Height = 490 & Width = 360");
        // setAspectRatio(360 / 490);  
      }
    } else if (["image-1"]?.includes(images) && [9]?.includes(layout_id)) {
      setMsg("Required Image Height = 970 & Width = 630");
      // setAspectRatio(630 / 970);
    } else if (
      ["image-2", "image-3"]?.includes(images) &&
      [9]?.includes(layout_id)
    ) {
      setMsg("Required Image Height = 490 & Width = 310");
      // setAspectRatio(310 / 490);
    } else if (["image"]?.includes(images) && [31]?.includes(layout_id)) {
      setMsg("Required Image Height = 1920 & Width = 1080");
      // setAspectRatio(1080 / 1920);
    } else if (["image"]?.includes(images) && [54]?.includes(layout_id)) {
      setMsg("Required Image Height = 640 & Width = 1080");
      // setAspectRatio(1080 / 640);
    } else if (
      [45]?.includes(layout_id) &&
      ["image-GIF-top"]?.includes(images)
    ) {
      setMsg("Required Image/GIF Height = 1080 & Width = 1280");
      // setAspectRatio(1080 / 1080);
    } else if ([10]?.includes(layout_id)) {
      setMsg("Required Image Height = 960 & Width = 450");
      // setAspectRatio(450 / 960);
    } else if ([38, 39, 40]?.includes(layout_id)) {
      setMsg("Required Image Height = 540 & Width = 960");
      // setAspectRatio(960 / 540);
    } else if ([3]?.includes(layout_id)) {
      setMsg("Required Image Height = 330 & Width = 640");
      // setAspectRatio(640 / 330);
    } else if ([4]?.includes(layout_id)) {
      setMsg("Required Image Height = 380 & Width = 640");
      // setAspectRatio(640 / 380);
    } else if ([78]?.includes(layout_id)) {
      setMsg("Required Image Height = 420 & Width = 420");
      // setAspectRatio(420 / 420);
    } else if ([56]?.includes(layout_id)) {
      setMsg("Required Image Height = 768 & Width = 1080");
      // setAspectRatio(1080 / 768);
    } else if ([14]?.includes(layout_id)) {
      setMsg("Required Image Height = 380 & Width = 480");
      // setAspectRatio(480 / 380);
    } else if ([34]?.includes(layout_id)) {
      setMsg("Required Image Height = 1080 & Width = 1920");
      // setAspectRatio(1920 / 1080);
    } else if ([44 ,84].includes(layout_id)) {
      setMsg("Required Image/GIF Height = 1280 & Width = 1080");
      // setAspectRatio(1080 / 1280);
    } else if ([30, 34]?.includes(layout_id)) {
      setMsg("Required Image Height = 1080 & Width = 1920");
      // setAspectRatio(1920 / 1080);
    } else if ([67]?.includes(layout_id)) {
      if (["image-GIF-top-1"]?.includes(images)) {
        setMsg("Required Image/GIF Height = 540 & Width = 960");
        // setAspectRatio(960 / 540);
      } else {
        setMsg("You can select jpg or png or gif image file for this widget");
        // setAspectRatio(960 / 540);
      }
    } else if ([66]?.includes(layout_id)) {
      if (["image-GIF-top-left"]?.includes(images)) {
        setMsg("Required Image/GIF Height = 540 & Width = 960");
        // setAspectRatio(960 / 540);
      } else {
        setMsg("You can select jpg or png or gif image file for this widget");
        // setAspectRatio(960 / 540);
      }
    }
  }, [layout_id]);

  //For showing modal on file removal :Start
  const toggle = () => {
    setVisible((prev) => !prev);
    return false;
  };

  const deleteItem = () => {
    if (typeof form.getFieldValue(name) !== "object") {
      const imageFieldId = LAYOUT.editValues.layout_field.find(
        (data: any) => data.shortcode.split("_")[0] === name
      ).id;
      const payload = {
        file_path: "/storage/media/file/" + form.getFieldValue(name),
        layout_field_id: imageFieldId,
      };
      LAYOUT.RemoveFileApi(LAYOUT.editValues.id, payload);
      toggle();
    }
    setFileList([]);
    form.resetFields([name]);
    onChange();
    return true;
  };
  //For showing modal on file removal :End

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <Col>
        <Form.Item
          name={name}
          className="full-width-dragger"
          label={
            <div>
              {label.charAt(0).toUpperCase() + label.slice(1)}
              {AUTH.checkPlan(CONSTANT.INTERACTIVE_CONTENT_SUPPORT) ? (
                <>
                  <Button
                    type="primary"
                    title="Apply style"
                    onClick={() =>
                      openStyleModal({
                        styles: widgetStyles.ImageElement(),
                        type: name,
                      })
                    }
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faPenNib} />
                  </Button>
                  {/* <Button
                    type="primary"
                    title="Generate AI Image"
                    onClick={() => setApplyGenerateAIImageModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    Generate AI Image
                  </Button> */}
                  <Button
                    type="primary"
                    title="Image Url"
                    onClick={() => setApplyImageUrlModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </Button>
                </>
              ) : (
                <Popover title={() => planMessage()}>
                  <Button
                    type="primary"
                    title="Apply style"
                    disabled={true}
                    onClick={() =>
                      openStyleModal({
                        styles: widgetStyles.ImageElement(),
                        type: name,
                      })
                    }
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faPenNib} />
                  </Button>
                  {/* <Button
                    type="primary"
                    title="Generate AI Image"
                    disabled={true}
                    onClick={() => setApplyGenerateAIImageModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    Generate AI Image
                  </Button> */}
                  <Button
                    type="primary"
                    title="Image Url"
                    disabled={true}
                    onClick={() => setApplyImageUrlModal(true)}
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </Button>
                </Popover>
              )}
            </div>
          }
          extra={msg}
          rules={LayoutRequest.image}
        >
          {/* <ImgCrop aspect={aspectRatio}> */}
            <Upload
              customRequest={dummyRequest}
              accept=".png, .jpg, .jpeg, .gif"
              listType="picture"
              multiple={false}
              fileList={fileList}
              onRemove={
                typeof form.getFieldValue(name) !== "object"
                  ? toggle
                  : deleteItem
              }
              onPreview={uploadedFileOnPreview}
              showUploadList={{ showPreviewIcon: false }}
              onChange={({ fileList }: any) => {
                if (fileList.status === "error") {
                  if (fileList.error) {
                    fileList.error.message = "error";
                  } else {
                    fileList.error = {
                      message: "error",
                    };
                  }
                }
                setFileList(fileList);
                form.setFieldsValue({
                  [name]: fileList[0]?.originFileObj,
                });
                onChange();
              }}
              beforeUpload={(file: RcFile) => {
                const isJpgOrPng =
                  file.type === "image/jpeg" ||
                  file.type === "image/jpg" ||
                  file.type === "image/png" ||
                  file.type === "image/gif";
                const isLt1M = file.size / 1024 / 1024 < 9;

                if (!isJpgOrPng) {
                  message.error(
                    `Please upload file should be png, jpg , jpeg or gif`
                  );
                  return Upload.LIST_IGNORE;
                } else if (!isLt1M) {
                  message.error("File size should not be more than 9 MB");
                  return Upload.LIST_IGNORE;
                } else {
                  setFileList([file]);
                  return false;
                }
              }}
            >
              {fileList.length < 1 && (
                <Button icon={<UploadOutlined />}>Upload</Button>
              )}
            </Upload>
          {/* </ImgCrop> */}
        </Form.Item>
        {applyStyleModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <StyleModalComponent
              visible={applyStyleModal}
              close={closeStyleModal}
              styleProps={styleProps}
              setShow={setShow}
              setDataV={setDataV}
              dataV={dataV}
            />
          </Form.Item>
        )}
        {applyGenerateAIImageModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <GenerateAIImage
              type={"single"}
              name={name}
              visible={applyGenerateAIImageModal}
              close={() => {
                setApplyGenerateAIImageModal(false);
              }}
            />
          </Form.Item>
        )}
        {applyImageUrlModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <CropImageUrlElement
              visible={applyImageUrlModal}
              onUpload={
                handleUpload
              }
              cancel={() => {
                setApplyImageUrlModal(false);
              }}
              // aspectRatio={aspectRatio}
              flagBackgroundImage={true} //flag to bypass the cropping

            />
          </Form.Item>
        )}
      </Col>
      <Modal
        okText="Yes"
        cancelText="No"
        open={visible}
        onOk={deleteItem}
        onCancel={toggle}
        className="removeUploadModal"
      >
        Are you sure you want to permanently remove this Item ?
      </Modal>
    </>
  );
});

export default ImageElement;
