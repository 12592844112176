import React, { useEffect, useState } from "react";
import { Alert, Button, Checkbox, Col, Form, Popover, Row } from "antd";
import { LoginRequest } from "../../../requests/AuthRequest";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import LoginBG from "../../../assets/images/login-pattern.png";
import { CONSTANT } from "../../../config/Constant";
import { Notification, passwordTooltip, validateFields } from "../../../config/Global";
import { ReactComponent as Reload } from "../../../assets/images/icon/reload.svg";
import {
  IResolveParams,
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialLinkedin,
  LoginSocialApple,
} from "reactjs-social-login";
import {
  AppleLoginButton,
  FacebookLoginButton,
  GoogleLoginButton,
  LinkedInLoginButton,
} from "react-social-login-buttons";

const LoginForm: React.FC = () => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [commonError, setCommonError] = useState<any>(null);
  const [tooltip, setTooltip] = useState(false);
  const navigate = useNavigate();
  const { AUTH, ROOT } = useStore();
  const { doLogin } = AUTH;
  const { assignErrorToInput } = ROOT;

  const handleSubmit = (data: any) => {
    setSaving(true);

    doLogin(data)
      .then((response: any) => {
        if (rememberMe) {
          delete data?.captchaGen;
          delete data?.captcha;
          var encoded = window.btoa(JSON.stringify(data));
          localStorage.setItem("jwt", encoded);
        } else {
          localStorage.removeItem("jwt");
        }
        Notification.success({ message: response.message });
        navigate(CONSTANT.DASHBOARD_REDIRECT_PATH);
      })
      .catch((e: ErrorProps) => {
        if (e && e.data.message) {
          setCommonError(e.data.message);
        }
        // if (e && e.data.errors) { setCommonError(e?.data.errors?.both[0]) }
        assignErrorToInput(form, e?.data.message);
        setSaving(false);
      })
      .finally(() => setSaving(false));
  };

  const handleChange = () => {
   try{
    validateFields(form, setDisabled)
    setTooltip(false)
   }
   catch(error:any){
    setTooltip(true)
   }
  };

  function generateString(length: number) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  useEffect(() => {
    const checkRemomberAuth = localStorage.getItem("jwt");
    if (checkRemomberAuth) {
      var decoded = JSON.parse(window.atob(checkRemomberAuth));
      form.setFieldsValue(decoded);
      form.setFieldsValue({ remember_me: true });
      form.setFieldsValue({ captcha: "" });
      setRememberMe(true);
      setDisabled(false);
    }
  }, []);

  useEffect(() => {
    const string = generateString(5);
    form.setFieldValue("captchaGen", string);
  }, [form]);

  //this is for backend api
  const socialLogin = (provider: any, data: any) => {
    AUTH.setAppLoading(true);
    let social_id: any = null;
    if (
      provider === "google" ||
      provider === "linkedin" ||
      provider === "apple"
    ) {
      social_id = data.sub;
    } else {
      social_id = data.userID;
    }

    const payload = {
      email: data.email,
      social_login_type: provider,
      social_id,
      mobile: data.mobile ?? "",
      name: data.name ?? "",
    };
    AUTH.SocialLogin(payload)
      .then((response: any) => {
        navigate(CONSTANT.DASHBOARD_REDIRECT_PATH);
        Notification.success({ message: response.message });
      })
      .catch((e: ErrorProps) => {
        Notification.error({ message: e.data.message });
        assignErrorToInput(form, e?.data?.message);
        setSaving(false);
        AUTH.setAppLoading(false);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const REDIRECT_URI = `${window.location.href}`;
  // const REDIRECT_URI = `https://app.cloudyfytv.com/login`;

  // this is convert to jwt token to user data
  const parseJwt = (token: string) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  return (
    <div className="loginForm" style={{ backgroundImage: `url(${LoginBG})` }}>
      <div className="loginFormWrap">
        <div className="formTitle">
          <h2>Welcome to CloudyFy TV</h2>
        </div>
        <div className="socialLoginWrap">
          <LoginSocialFacebook
            appId={process.env.REACT_APP_FB_APP_ID || ""}
            fieldsProfile={
              "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
            }
            redirect_uri={REDIRECT_URI}
            // onLoginStart={() => onLoginStart("")}
            onResolve={({ provider, data }: IResolveParams) => {
              socialLogin(provider, data);
            }}
            onReject={() => {
              AUTH.setAppLoading(false);
              // Notification.error({ message: "Popup window closed" });
            }}
          >
            <FacebookLoginButton> Continue with Facebook </FacebookLoginButton>
          </LoginSocialFacebook>
          <LoginSocialGoogle
            client_id={process.env.REACT_APP_GG_APP_ID || ""}
            redirect_uri={REDIRECT_URI}
            scope="openid profile email"
            ux_mode="popup"
            onResolve={({ provider, data }) => {
              socialLogin(provider, data);
            }}
            onReject={() => {
              AUTH.setAppLoading(false);
            }}
          >
            <GoogleLoginButton> Continue with Google </GoogleLoginButton>
          </LoginSocialGoogle>
          <LoginSocialLinkedin
            client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ""}
            client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ""}
            redirect_uri={REDIRECT_URI}
            // onLoginStart={() => onLoginStart("linkedin")}
            scope="openid profile w_member_social email"
            onResolve={({ provider, data }: IResolveParams) => {
              AUTH.setAppLoading(true);
              if (data) {
                socialLogin(provider, parseJwt(data.id_token));
              }
            }}
            onReject={() => {
              AUTH.setAppLoading(false);
            }}
          >
            <LinkedInLoginButton> Continue with LinkedIn </LinkedInLoginButton>
          </LoginSocialLinkedin>
          <LoginSocialApple
            client_id={process.env.REACT_APP_APPLE_ID || ""}
            scope={"name email"}
            redirect_uri={REDIRECT_URI}
            // onLoginStart={() => onLoginStart("")}
            onResolve={({ provider, data }: IResolveParams) => {
              if (data?.authorization) {
                socialLogin(provider, parseJwt(data?.authorization.id_token));
              }
            }}
            onReject={() => {
              AUTH.setAppLoading(false);
            }}
          >
            <AppleLoginButton> Continue with Apple</AppleLoginButton>
          </LoginSocialApple>
        </div>
        <div className="or">
          <span>or</span>
        </div>
        <FormBox form={form} onFinish={handleSubmit} onChange={handleChange}>
          {commonError && (
            <Alert
              style={{ marginBottom: 40 }}
              message={
                <div dangerouslySetInnerHTML={{ __html: commonError }}></div>
              }
              type="error"
              showIcon
            />
          )}
          <InputBox.Text
            name="username"
            label="Username or Email ID"
            placeholder="Enter Username or Email"
            rules={LoginRequest.emailORUserName}
          />
          <Popover
            placement="topRight"
            content={passwordTooltip}
            open={tooltip}
          >
            <InputBox.Password
              name="password"
              label="Password"
              placeholder="xxxxxxx"
              onChange={() => {
                form
                  .validateFields(["password"])
                  .then(() => {
                    setTooltip(false);
                  })
                  .catch((err) => {
                    if (err.errorFields && err.errorFields.length > 0) {
                      setTooltip(true);
                    } else {
                      setTooltip(false);
                    }
                  });
              }}
              rules={LoginRequest.password}
            />
          </Popover>
          <div className="captchaInput">
            <Row gutter={10}>
              <Col span={12}>
                <InputBox.Text
                  type="button"
                  name="captchaGen"
                  className="captchaGen"
                />
                <div className="reloadIcon">
                  <Reload
                    onClick={() => {
                      const string = generateString(5);
                      form.setFieldValue("captchaGen", string);
                    }}
                  />
                </div>
              </Col>
              <Col span={12}>
                <InputBox.Text
                  name="captcha"
                  label="Captcha"
                  placeholder="captcha"
                  rules={LoginRequest.captcha}
                />
              </Col>
            </Row>
          </div>
          <div className="text-center">
            <Button
              className={`${disabled ? "disableBtn" : "activeBtn"}`}
              disabled={disabled}
              loading={saving}
              type="primary"
              htmlType="submit"
            >
              Login
            </Button>
          </div>
          <div
            className="mt-10 font-medium login_extra_fields"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Checkbox
              name="remember_me"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            >
              Remember me
            </Checkbox>
            <Link to="/forgot-password">Forgot Password?</Link>
            <Form.Item hidden name="user_provider" />
          </div>
        </FormBox>
      </div>
    </div>
  );
};

export default LoginForm;
