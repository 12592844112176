import { observer } from "mobx-react-lite";
import React from "react";
import DevicesLayout from "./Components/DevicesLayout";
import useStore from "../../../store";
import Unauthorized from "../../errors/Unauthorized";
import { CONSTANT } from "../../../config/Constant";

const Dashboard: React.FC = observer(() => {
	const { AUTH } = useStore()
	return (
		<>
			{AUTH.checkPermission(CONSTANT.SuperAdmin, 'device_count') ? <div className="dashBoardWrap">
				<h1>Dashboard</h1>
				<DevicesLayout />
			</div> : <Unauthorized />}
		</>
	);
});

export default Dashboard;
