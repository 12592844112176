import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form } from 'antd';
import FormComponent from './FormComponent';
import { componentProps } from '../../../../store/DeviceStore/DeviceInterface';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icon/close.svg';
import moment from 'moment';
import useStore from '../../../../store';
import { Notification, validateFields } from '../../../../config/Global';
import { ErrorProps } from '../../../../store/RootStore/RootInterface';

const AddComponent: React.FC<componentProps> = observer((props: componentProps) => {

	const { visible, close } = props
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [saving, setSaving] = useState<boolean>(false)
	const { DEVICE, ROOT, AUTH } = useStore()
	const handleChange = () => {
		validateFields(form, setDisabled);
	}
	const handleClose = () => {
		close()
		form.resetFields()
		setDisabled(true)
	}

	const handleSubmit = (data: any) => {
		setSaving(true)
		data.tv_start_time = moment(data.tv_start_time).format('HH:mm')
		data.tv_close_time = moment(data.tv_close_time).format('HH:mm')
		data.app_restart_time = moment(data.app_restart_time).format('HH:mm')
		data.company_id = AUTH.user?.company_id ? AUTH.user?.company_id : ''
		DEVICE.AddData(data).then((data: any) => {
			Notification.success({ message: data.data })
			handleClose()
			AUTH.fetchAuthUser()
		}).catch((e: ErrorProps) => {
			ROOT.assignErrorToInput(form, e?.data);
		}).finally(() => setSaving(false))
	}

	useEffect(() => {
		const API_URL = process.env.REACT_APP_API_URL ?? "";
		form.setFieldsValue({
			api_path: (API_URL.slice(-1) === '/') ? API_URL + "api" : API_URL + "/api",
			tv_start_time: moment("07:00:00", "HH:mm"),
			tv_close_time: moment("23:00:00", "HH:mm"),
			app_restart_time: moment("22:00:00", "HH:mm"),
			scaling:1
		})
	}, [visible])

	return (
		<Drawer
			open={visible}
			onClose={handleClose}
			width={'50%'}
			closeIcon={
				<CloseIcon />
			}
			className='commanDrawer addDeviceModal'
			title={`Add Device`}
			destroyOnClose={true}
			footer={[
				<div key="1">
					<Button
						form='addEditDeviceForm' className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} disabled={disabled}
						loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="orangeBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent form={form} handleSubmit={handleSubmit} onChange={handleChange} />
		</Drawer>
	)
})

export default AddComponent