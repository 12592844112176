import React, { useState } from "react";
import { Layout, Menu, Popover } from "antd";
import LogoComponent from "../../Components/LogoComponent";
import Config from "../../../../config/Config";
import { Link, useLocation } from "react-router-dom";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as DashBoardIcon } from "../../../../assets/images/icon/DashBoard.svg";
// import { ReactComponent as DashBoardIcon } from "../../../../assets/images/icon/layout.svg";
// import { ReactComponent as LeftIcon } from "../../../../assets/images/icon/left-arrow.svg";
import { ReactComponent as UserIcon } from "../../../../assets/images/icon/profileIcon.svg";
// import { ReactComponent as CompanyIcon } from '../../../../assets/images/icon/Company.svg';
import { ReactComponent as LayoutIcon } from "../../../../assets/images/icon/layout.svg";
import { ReactComponent as DeviceIcon } from "../../../../assets/images/icon/device.svg";
import { ReactComponent as SaveLayoutIcon } from "../../../../assets/images/icon/save_layout.svg";
import { ReactComponent as RateIcon } from "../../../../assets/images/icon/rates.svg";
import { ReactComponent as GlobalPopupIcon } from "../../../../assets/images/icon/globlePopup.svg";
import { ReactComponent as SettingIcon } from "../../../../assets/images/icon/setting.svg";
import { ReactComponent as TokenIcon } from "../../../../assets/images/icon/token.svg";
import { ReactComponent as ScheduleIcon } from "../../../../assets/images/icon/schedule.svg";
import { ReactComponent as RoleManegmentIcon } from "../../../../assets/images/icon/rolemanagment.svg";
import { ReactComponent as AuditLogs } from "../../../../assets/images/icon/AuditLogs.svg";
import { ReactComponent as UpdateAPK } from "../../../../assets/images/icon/UpdateAPK.svg";
import { ReactComponent as GroupDevices } from "../../../../assets/images/icon/GroupDevices.svg";
import { ReactComponent as SubscriptionPlan } from "../../../../assets/images/icon/subscription_plan.svg";
import { ReactComponent as SubscriptionList } from "../../../../assets/images/icon/subscription_list.svg";
import { ReactComponent as Analytics } from "../../../../assets/images/icon/analytics.svg";
import { ReactComponent as GroupAnalytics } from "../../../../assets/images/icon/group of analytics.svg";
import { ReactComponent as LayoutAnalytics } from "../../../../assets/images/icon/layout analytics.svg";
import { planMessage } from "../../../../config/Global";

//analytics

interface AppSidebarViewProps {
  collapsed?: boolean;
}

const SidebarView: React.FC<AppSidebarViewProps> = observer(({ collapsed }) => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState([]);

  const { AUTH } = useStore();
  const { accessMenu, menu_count } = AUTH;
  let menukey = 1;
  const icons: any = {
    // 1: faTachometerAlt,
    // 2: faUser,
    // 3: faChair,
    // 4: faTable,
    // 5: faTable,
    // 6: faDesktop,
    // 7: faCoins,
    // 9: faGear,
    // 10: faCoins,
    // 11: faUserGear,
  };

  const svgIcons: any = {
    1: <DashBoardIcon />,
    2: <UserIcon />,
    3: <RoleManegmentIcon />,
    4: <LayoutIcon />,
    5: <SaveLayoutIcon />,
    6: <DeviceIcon />,
    7: <RateIcon />,
    8: <GlobalPopupIcon />,
    9: <SettingIcon />,
    10: <TokenIcon />,
    11: <ScheduleIcon />,
    12: <GroupDevices />,
    13: <AuditLogs />,
    14: <UpdateAPK />,
    15: <SubscriptionPlan />,
    16: <SubscriptionList />,
    17: <Analytics />,
    18: <GroupAnalytics />,
    19: <LayoutAnalytics />,
  };

  const AppMenu = (menu_item: any, open: any = []) => {
    return menu_item
      ? Object.keys(menu_item)
          .sort((a, b) =>
            menu_item[a].sequence_no > menu_item[b].sequence_no
              ? 1
              : menu_item[b].sequence_no > menu_item[a].sequence_no
              ? -1
              : 0
          )
          .map((key) => {
            const item: any = menu_item[key];
            if (item.submenu) {
              return (
                <Menu.SubMenu
                  key={item.title + menukey}
                  icon={
                    icons[item.id] ? (
                      <FontAwesomeIcon icon={icons[item.id]} />
                    ) : (
                      <Analytics />
                    )
                  }
                  title={item.title}
                >
                  {AppMenu(item.submenu, [...open, item.title + menukey++])}
                </Menu.SubMenu>
              );
            } else {
              if (
                location.pathname.includes(item.path) &&
                openMenu.length <= 0
              ) {
                if (open.length === 0) {
                  open.push(item.path);
                }
                setOpenMenu(open);
              }

              return "tooltip" in item ? (
                <Popover content={() => planMessage()} placement="rightBottom" key={item.path}>
                  <Menu.Item
                    icon={
                      icons[item.id] ? (
                        <FontAwesomeIcon
                          icon={icons[item.id] ? icons[item.id] : faCircle}
                          size="xs"
                          className="mr-10"
                        />
                      ) : (
                        svgIcons[item.id]
                      )
                    }
                  >
                    <span>
                      {item.title}
                      <div className="extracontent">
                        {menu_count[item.id] ? (
                          <span className="counter">{menu_count[item.id]}</span>
                        ) : null}
                        {/* <span className="sidebarArrowIcon">  <RightArrow /></span> */}
                      </div>
                    </span>
                    <Link to={item.tooltip_path} />
                  </Menu.Item>
                </Popover>
              ) : (
                <Menu.Item
                  key={item.path}
                  icon={
                    icons[item.id] ? (
                      <FontAwesomeIcon
                        icon={icons[item.id] ? icons[item.id] : faCircle}
                        size="xs"
                        className="mr-10"
                      />
                    ) : (
                      svgIcons[item.id]
                    )
                  }
                >
                  <span>
                    {item.title}
                    <div className="extracontent">
                      {menu_count[item.id] ? (
                        <span className="counter">{menu_count[item.id]}</span>
                      ) : null}
                      {/* <span className="sidebarArrowIcon">  <RightArrow /></span> */}
                    </div>
                  </span>
                  <Link to={item.path} />
                </Menu.Item>
              );
            }
          })
      : null;
  };

  return (
    <Layout.Sider
      collapsed={collapsed}
      width={Config.sidebar_width}
      theme="dark"
      className="main__page__appsidebar"
    >
      <LogoComponent collapsed={collapsed}/>
      <Menu mode="inline" theme="dark" activeKey={location.pathname}>
        {AppMenu(accessMenu)}
      </Menu>
    </Layout.Sider>
  );
});

export default SidebarView;
