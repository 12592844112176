import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { ListDataScheduleProps } from "./ScheduleInterface";

export default class ScheduleStore {
	initialValue = {
		type: 1
	}
	layout_list: any = null
	public list_data?: ListDataScheduleProps[]
	schedule_layout_list: any = null

	constructor() {
		makeAutoObservable(this)
	}


	addSchedule = (data: any) => {
		return axios.post(API_URL.SCHEDULE.ADD_SCHEDULE, data).then(({ data }) => {
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

	listSchedule = async () => {
		return await axios.get(API_URL.SCHEDULE.LIST_LAYOUT).then(({ data }) => {
			this.layout_list = data.data
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

	listDeviceSchedule = (data: any) => {
		return axios.post(API_URL.SCHEDULE.LIST_DEVICE, data).then(({ data }) => {
			data.forEach((it: any) => {
				if (it.type === 3) {
					it['start'] = it.start_date
					delete it.start_date
					it['backgroundColor'] = '#ffc107'
				} else {
					it['start'] = it.start_date
					it['end'] = it.end_date
					delete it.start_date
					delete it.end_date
					it['backgroundColor'] = it.type === 1 ? '#5867dd' : '#36a3f7'
				}
			})
			this.list_data = data

			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

	listLayoutSchedule = async () => {
		return await axios.get(API_URL.SCHEDULE.DEVICE_LIST).then(({ data }) => {
			this.schedule_layout_list = data.data
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}

}


