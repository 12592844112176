import React, { useEffect } from 'react'
import { Card, Checkbox, Col, Divider, Form, FormInstance, Row } from 'antd';
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { role } from '../../../../requests/PageRequest';
import Config from '../../../../config/Config';
import useStore from '../../../../store';

interface formInterface {
	form: FormInstance,
	handleSubmit: (data: any) => void,
	onChange: () => void,
	type: string
}
const FormComponent: React.FC<formInterface> = observer((props: formInterface) => {
	const { form, handleSubmit, onChange, type } = props
	const { ROLE, USER } = useStore()

	useEffect(() => {
		if (type === 'add') {
			ROLE.PermissionList().then((data: any) => {
				form.setFieldsValue({
					permissions: data
				})
			})
		} else {
			USER.getPermissionsList({ role_id: ROLE.readValues && ROLE.readValues.id }).then((data: any) => {
				form.setFieldsValue({
					permissions: data.data
				})
			}).catch((e: any) => {
				if (e.errors) { form.setFields(e.errors) }
			})
		}
	}, [form, ROLE.readValues])

	return (
		<FormBox id={'roleForm'} form={form} onFinish={handleSubmit} onChange={onChange}>
			<Row gutter={Config.gutter}>
				<Col span={24}>
					<InputBox.Text
						required
						name="role_name"
						label="Role Name"
						placeholder="Enter role name"
						rules={role.role_name}
					/>
				</Col>
				<Col span={5}>Features</Col>
				<Col span={12}>Actions</Col>
				<Form.Item name="permissions" rules={role.permissions} className='permissions_form_item'>
					<Form.List name="permissions">
						{(fields => {
							return (
								fields && fields.map(field => {
									var value = form.getFieldValue('permissions')[field.key]
									var isDisabled = false
									value.actions && value.actions.map((action: any) => {
										if (action.action_name === 'list' && action.is_selected !== 1) {
											isDisabled = true
										}
										return null
									})
									var all_checked = 0
									value.actions.map((action: any) => {
										if (action.is_selected === 1) {
											all_checked++
										}
										return null
									})
									return (
										<div key={field.key} className='permissionCard'>
											{value.actions.length > 0 && (
												<Card className="mt-10">
													<Row gutter={24}>
														<Col span={5} style={{ borderRight: '1px solid #f0f0f0' }}><b>{value.title}</b></Col>
														<Col span={19}>
															<Checkbox
																checked={all_checked === value.actions.length}
																onChange={(e) => {
																	var temp = form.getFieldValue('permissions')
																	temp[field.key].actions.map((action: any, index: number) => {
																		temp[field.key].actions[index].is_selected = e.target.checked ? 1 : 0
																		return null
																	})
																	form.setFieldsValue({
																		permissions: temp
																	})
																}}>Check all</Checkbox>
															<Divider type='vertical' />
															{value.actions.map((action: any, index: number) => {
																var temp = action.action_name.replace(/_/g, ' ')
																return (
																	<div key={index} style={{ display: "inline" }}>
																		<Form.Item
																			noStyle
																			name={[field.name, 'actions', index, 'is_selected']}
																		>
																			<Checkbox disabled={isDisabled && action.action_name !== 'list'} checked={action.is_selected === 1 ? true : false} value={action.id} onChange={(e) => {
																				var temp = form.getFieldValue('permissions')
																				temp[field.key].actions[index].is_selected = e.target.checked ? 1 : 0
																				if (temp[field.key].actions[index].action_name === 'list' && !e.target.checked) {
																					temp[field.key].actions.map((_: any, index: number) => {
																						temp[field.key].actions[index].is_selected = 0
																						return null
																					})
																				}
																				form.setFieldsValue({
																					permissions: temp
																				})
																			}}>{temp.charAt(0).toUpperCase() + temp.slice(1)}</Checkbox>
																		</Form.Item>
																	</div>
																)
															})}

														</Col>
													</Row>
												</Card>
											)
											}
										</div>
									)
								})
							)
						})
						}
					</Form.List>
				</Form.Item>

			</Row>
		</FormBox>
	)
})

export default FormComponent