import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { InputBox } from "../../../../components/AntdAddons";
import { Button, Col, Form, FormInstance, Row, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { displayFormats } from "../../../../config/Global";
import { LayoutRequest } from "../../../../requests/PageRequest";
import moment from "moment";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  layout_id: any;
  setShow?: (data: any) => void;
  layoutIdFlag?:boolean; 
}

const ScheduleElement: React.FC<formInterface> = observer(
  (props: formInterface) => {
    const { name, onChange, layout_id, form, layoutIdFlag } = props;

    useEffect(() => {
      if (layout_id && layoutIdFlag) {
        form.setFieldValue(name, "");
      }
    }, [layout_id]);

    const date_name = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    return (
      <>
        <Col>
          {date_name.map((item: any, id) => {
            return (
              <Form.List key={id} name={[name, "schedule", `${item}`]}>
                {(subFields, subOpt) => (
                  <Row className="AddBtnWrap">
                    {item} :
                    {subFields.map((subField: any) => {
                      return (
                        <Space key={subField.key}>
                          <InputBox.TimePicker
                            name={[subField.name, "from_time"]}
                            label="From Time"
                            placeholder="From Time"
                            rules={LayoutRequest.from_time}
                            onChange={(val: any) => {
                              if(val) {
                              const newVal = moment(val);
                                form.setFieldValue(
                                  [
                                    name,
                                    "schedule",
                                    `${item}`,
                                    subField.name,
                                    "to_time",
                                  ],
                                  newVal.add(1, "h")
                                );
                              }
                              onChange();
                            }}
                          />
                          <InputBox.TimePicker
                            name={[subField.name, "to_time"]}
                            label="To Time"
                            placeholder="To Time"
                            rules={LayoutRequest.to_time}
                            onChange={onChange}
                          />
                          <CloseOutlined
                            onClick={() => {
                              subOpt.remove(subField.name);
                              onChange()
                            }}
                          />
                        </Space>
                      );
                    })}
                    <Button type="dashed" onClick={() => subOpt.add()}>
                      +
                    </Button>
                  </Row>
                )}
              </Form.List>
            );
          })}
        </Col>
        <Col>
          <label>Holiday</label>
          <Form.List name={[name, "holiday"]}>
            {(subFields, subOpt) => (
              <Row className="AddBtnWrap">
                {subFields.map((subField: any) => {
                  return (
                    <Space key={subField.key}>
                      <InputBox.DatePicker
                        name={[subField.name, "from_date"]}
                        label="From Date"
                        placeholder="From Date"
                        rules={LayoutRequest.from_date}
                        format={displayFormats.DATE_MONTH_YEAR}
                        onChange={onChange}
                      />
                      <InputBox.DatePicker
                        name={[subField.name, "to_date"]}
                        label="To Date"
                        placeholder="To Date"
                        rules={LayoutRequest.to_date}
                        format={displayFormats.DATE_MONTH_YEAR}
                        onChange={onChange}
                      />
                      <CloseOutlined
                        onClick={() => {
                          subOpt.remove(subField.name);
                          onChange()
                        }}
                      />
                    </Space>
                  );
                })}
                <Button type="dashed" onClick={() => subOpt.add()} block>
                  +
                </Button>
              </Row>
            )}
          </Form.List>
        </Col>
      </>
    );
  }
);

export default ScheduleElement;
