import { InputNumber } from "antd";
import React from "react";
import { InputWrapper, SplitInputWrapperProps } from "../../functions";
import { NumberInputBoxProps } from "./interface";
import { numericField } from "../../../../config/Global";

const NumberInputBox: React.FC<NumberInputBoxProps> = (props) => {
	const { formProps, inputProps } = SplitInputWrapperProps(props);
	return (
		<InputWrapper  onKeyDown={numericField}{...formProps}>
			<InputNumber {...inputProps}  />
		</InputWrapper>
	);
};

export default NumberInputBox;
