import React, { useEffect } from 'react'
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../../store";
import Button from "antd/es/button";
import { ReactComponent as EditIcon } from '../../../../assets/images/icon/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icon/delete.svg';
import { observer } from 'mobx-react';
import { agDateColumnFilter } from '../../../../config/Global';
import { RateListProps } from '../../../../store/RateStore/RateInterface';

const ListComponent: React.FC<RateListProps> = observer((props: RateListProps) => {
	const { openEditModal, openDeleteModal } = props
	const { RATE, AUTH } = useStore()
	const cellStyle = {
		textAlign: "center"
	}
	const cellRightStyle = {
		textAlign: "right",
		paddingRight: "30px"
	}

	useEffect(() => {
		RATE.fetchList()
	}, [RATE])

	const ActionRenderer: React.FC = (props: any) => {
		return (
			<div className="action-column">
				{AUTH.checkPermission(7, 'edit') && <Button type="text" title={"Edit"} className="editIcon" onClick={() => openEditModal(props.data)}>
					<EditIcon />
				</Button>}
				{AUTH.checkPermission(7, 'delete') &&
					<Button type="text" title={"Delete"} className="deleteIcon" onClick={() => openDeleteModal(props.data)}>
						<DeleteIcon />
					</Button>}
			</div>
		);
	};


	const columnSetup = () => {
		return ([
			<AgGridColumn
				key='id'
				field="id"
				headerName="# ID"
				filter={"agNumberColumnFilter"}
				maxWidth={80}
				cellStyle={cellStyle}
			/>,
			<AgGridColumn
				key='item'
				field="item"
				headerName="Rate Item Name"
				minWidth={200}
				maxWidth={250}
			/>,
			<AgGridColumn
				key='rates'
				field="rates"
				headerName="Rate"
				filter={"agNumberColumnFilter"}
				cellStyle={cellRightStyle}
				// cellStyle={cellStyle}
				maxWidth={150}
			/>,
			<AgGridColumn
				key='date'
				field="date"
				cellStyle={cellRightStyle}
				filter={'agDateColumnFilter'}
				filterParams={agDateColumnFilter}
				headerName="Last Updated"

				maxWidth={150}
			/>,
			<AgGridColumn
				key='action'
				headerName="Actions"
				type="actionColumn"
				sortable={false}
				filter={false}
				pinned={'right'}
				maxWidth={200}

			/>
		])
	}

	return (
		<AgGridWrapper onGridReady={RATE.setupGrid} components={{ ActionRenderer }} rowData={RATE?.list_data} onColumnResized={RATE.onGridChanged} onColumnMoved={RATE.onGridChanged}>
			{columnSetup()}
		</AgGridWrapper>
	)
})

export default ListComponent;