import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { InputBox } from "../../../../components/AntdAddons";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { Col, FormInstance } from "antd";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  layout_id: any;
  setShow?: (data: any) => void;
  maxChar?: number;
  layoutIdFlag?: boolean;
}

const TextFieldElement: React.FC<formInterface> = observer(
  (props: formInterface) => {
    const { name, label, onChange, layout_id, form, maxChar, layoutIdFlag} =
      props;

    useEffect(() => {
      if (layout_id && layoutIdFlag) {
        form.setFieldValue(name, "");
      }
    }, [layout_id]);

    return (
      <Col>
        <InputBox.Text
          name={name}
          label={
            <div>
              {label
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}
            </div>
          }
          placeholder={`Enter ${label
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase())}`}
          rules={
            maxChar === 20
              ? LayoutRequest.max20
              : maxChar === 25
              ? LayoutRequest.max25
              : LayoutRequest.text
          }
          onChange={onChange}
        />
      </Col>
    );
  }
);

export default TextFieldElement;
