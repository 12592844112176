import React, { useEffect, useState } from "react"
import { Button, Col, Modal, Row } from "antd"
import { observer } from "mobx-react"
import { componentProps } from '../../../../store/DeviceStore/DeviceInterface';
import useStore from "../../../../store"
import moment from "moment";

const DetailViewComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const { AUDIT_LOG } = useStore()
	const [logData, setLogData] = useState<any>()
	const handleClose = () => {
		close()
	}

	useEffect(() => {
		AUDIT_LOG.editValues && setLogData(AUDIT_LOG.editValues[0])
	}, [AUDIT_LOG.editValues])

	const AuditLogData = (data: any, item: any, pervious = ""): any => {
		var key = item.split('_').join(" ")?.charAt(0)?.toUpperCase() + item.split('_')?.join(" ")?.slice(1)

		if (data[item] && typeof data[item] === 'object') {
			return Object.keys(data[item]).map((dataItem: any) => {
				if (dataItem !== 'id') {
					return AuditLogData(data[item], dataItem, `${key} `)
				} else {
					return ""
				}
			})
		}

		return (
			<>
				<Row className="logRow">
					<Col span={12}>
						<label>{`${pervious}${key}`}</label>
					</Col>
					<Col span={12}>
						<span>{["created_by", "updated_by", "user_id", "sender_id", "completed_by"].includes(item) ? (data[item]) : item === "start_date" ? data[item] && moment(data[item]).format('DD-MM-YYYY') : item === "date_of_birth" ? data[item] && moment(data[item]).format('DD-MM-YYYY') : data[item]}</span>
					</Col>
				</Row>
			</>
		)
	};

	return (
		<Modal
			open={visible}
			onCancel={handleClose}
			width={'50%'}
			title={`Audit Log`}
			className="commanModal"
			destroyOnClose={true}
			footer={[
				<div key="1">
					<Button className="redBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			{logData && <Row className="infoModalTable">
				<Col xl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }}>

					<div className="infoData oldVales">
					<div className="logTableTitle">
						<h4>Old Values</h4>

					<span>Updated At {logData.updated_date ? moment(logData.updated_date, false).format('DD-MM-YYYY') : null}</span>
					</div>
								{
									logData?.old_values && Object.keys(logData?.old_values).map((item: any) => {
										return AuditLogData(logData?.old_values, item)
									})
								}
							</div>
				</Col>
				<Col xl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }}>

					<div className="infoData newVales">
					<div className="logTableTitle">
						<h4>New Values</h4>
					<span>Updated At {logData.updated_date ? moment(logData.updated_date, false).format('DD-MM-YYYY') : null}</span>
					</div>
								{
									logData?.new_values && Object.keys(logData?.new_values).map((item: any) => {
										return AuditLogData(logData?.new_values, item)
									})
								}
							</div>
				</Col>
				
			</Row>}
		</Modal>
	)
})

export default DetailViewComponent