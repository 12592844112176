import React, { useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import { observer } from "mobx-react";
import { componentProps } from "../../../../store/UserRoleStore/UserRoleInterface";
import useStore from "../../../../store";
import { Notification } from "../../../../config/Global";
import { ReactComponent as WarningFilled } from "../../../../assets/images/icon/warning.svg";

const CancelPlanComponent: React.FC<componentProps> = observer(
  (props: componentProps) => {
    const { visible, close } = props;
    const { AUTH, SUBSCRIPTION } = useStore();
    const [saving, setSaving] = useState<boolean>(false);

    const handleSubmit = () => {
      setSaving(true);
      const sId = {
        sub_id: AUTH?.subscription?.id,
      };
      SUBSCRIPTION.cancelSubscription(sId)
        .then(({ message }: any) => {
          Notification.success({
            message: `${message}`,
          });
          AUTH.fetchAuthUser();
        })
        .catch((error: any) => {
          Notification.error({
            message: `${error.data.error}`,
          });
        })
        .finally(() => {
          setSaving(false);
        });
    };

    return (
      <Modal
        open={visible}
        onCancel={close}
        width={700}
        title={`Plan Cancellation`}
        destroyOnClose={true}
        className="commanModal deleteModal"
        footer={[
          <div key="1">
            <Button
              //   className={`${disabled ? "disableBtn" : "deleteBtn"}`}
              className="redBgBtn cancelBtn"
              //   disabled={disabled}
              loading={saving}
              htmlType="submit"
              type="primary"
              onClick={handleSubmit}
            >
              Ok
            </Button>
            <Button className="redBgBtn cancelBtn" onClick={close}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <Row>
          <Col span={4}>
            <WarningFilled />
          </Col>
          <Col span={20}>
            <h3>
              Are you sure you want to Cancellation
              <strong> {AUTH.subscription?.plan_name} </strong>
              Subscription Plan?
            </h3>
          </Col>
        </Row>
      </Modal>
    );
  }
);

export default CancelPlanComponent;
