import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { InputBox } from '../../../../components/AntdAddons'
import { LayoutRequest } from '../../../../requests/PageRequest'
import { Col, FormInstance } from 'antd'


interface formInterface {
	form: FormInstance,
	name: string,
	label: string,
	onChange: () => void
	layout_id: any,
	setShow?: (data: any) => void;
	layoutIdFlag?:boolean; 
}

const TextAreaElement: React.FC<formInterface> = observer((props: formInterface) => {
	const { name, label, onChange, layout_id, form, layoutIdFlag } = props

	useEffect(() => {
		if (layout_id && layoutIdFlag) {
			form.setFieldValue(name, "")
		} 
	  }, [layout_id]);
	  
	return (
		<Col>
			<InputBox.TextArea
				name={name}
				label={<div>
					{label.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
				</div>}
				placeholder={`Enter ${label.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}`}
				rules={name === "bio" ?LayoutRequest.bio :LayoutRequest.text}
				onChange={onChange}
			/>
		</Col>
	)
})

export default TextAreaElement