import React, { useState } from 'react'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { ScheduleBreadcrumb } from '../../../config/BreadcrumbConfig'
import { Button, Col, PageHeader, Row, Form } from 'antd'
import { observer } from 'mobx-react'
import AddComponent from './components/AddComponent'
import { FormBox, InputBox } from '../../../components/AntdAddons'
import useStore from '../../../store'
// import { Notification } from '../../../config/Global'
import Calandercomponent from './components/Calandercomponent'
import moment from 'moment'
import Unauthorized from '../../errors/Unauthorized'
// import { ReactComponent as DownIcon } from '../../../assets/images/icon/downIcon.svg';

const Schedule: React.FC = observer(() => {
	const [addmodal, setaddModal] = useState<boolean>(false)
	const { SCHEDULE, AUTH } = useStore()
	const [id, setId]: any = useState()
	const [startDate, setStartDate] = useState()
	const [events, setEvents] = useState('')
	const [endDate, setEndDate] = useState()
	const [form] = Form.useForm()

	const handleChange = (e: number) => {
		setId(e)
		const startdate = startDate && moment(startDate).format('YYYY-MM-DD')
		const enddate = endDate && moment(endDate).format('YYYY-MM-DD')
		const payload = {
			device_id: e,
			start_date: startdate,
			end_date: enddate,
		}
		
		SCHEDULE?.listDeviceSchedule(payload).then((data: any) => {
			setEvents(data)
		}).catch(() => {
			// Notification.error({
			// 	message: e?.data
			// })
		})
	}
	const handleClose = () => {
		setEvents('')
		setaddModal(false)
		form.resetFields()
	}

	return (
		<div className='scheduleLayout'>
			{AUTH.checkPermission(11, 'list') ?
				<PageHeader title={ScheduleBreadcrumb.title}
					tags={<BreadcrumbComponent items={ScheduleBreadcrumb.path} />}
					extra={[
						AUTH.checkPermission(11, 'add') && <Button key={1} type='primary' onClick={() => { setaddModal(true) }}>
							+ Add Event
						</Button>
					]}>
					<FormBox className='scheduleForm' form={form}>
						<Row gutter={15}>
							<Col span={12} xs={8}>
								<InputBox.Select
									showSearch
									placeholder='Select Device'
									label='Select Device'
									name={'device_id'}
									// className='selectDown'
									// showArrow={false}
									// extra={
									//     <DownIcon/>
									// }
									filterOption={(input: any, option: any) =>
										(option?.children || {}).toLowerCase().includes(input.toLowerCase())
									}
									onFocus={() => SCHEDULE?.listLayoutSchedule()}
									onChange={handleChange}
									options={{
										list: SCHEDULE?.schedule_layout_list,
										valueKey: 'id',
										textKey: 'name'
									}}
								></InputBox.Select>
							</Col>
						</Row>
					</FormBox>
					<AddComponent visible={addmodal} close={handleClose} />
					<Calandercomponent setStartDate={setStartDate} setEndDate={setEndDate} id={id} events={events} setEvents={setEvents} />
				</PageHeader>
				: <Unauthorized />}
		</div>
	)
})

export default Schedule