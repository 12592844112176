import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";

export default class AuditLogStore {
	agGrid: any = null;
	logAgGrid: any = null
	per_page = Config.grid.local.gridOptions?.paginationPageSize;
	list_data?: any[];
	editValues = null;

	constructor() {
		makeAutoObservable(this);
	}

	// Setup grid and set column size to autosize
	setupGrid = (params: any) => {
		const columnState = localStorage.getItem("device_token")
		if (columnState) {
			params.columnApi.applyColumnState({ state: JSON.parse(columnState) });
		}
		this.agGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(Number(page));
		}
	};

	logSetupGrid = (params: any) => {
		this.logAgGrid = params;
	};

	setEditValues = (id: number) => {
		return axios.get(API_URL.AUDIT_LOG.READ + `/${id}`).then(({ data }) => {
			this.editValues = data.data
			return data
		}).catch((response) => {
			return Promise.reject(response);
		})
	};

	// API Functions
	fetchList = async (payload: any): Promise<any> => {
		return await axios.post(API_URL.AUDIT_LOG.LIST, payload).then(({ data }) => {
			this.list_data = data.data
		}).catch((response) => {
			return Promise.reject(response);
		});
	};
}
