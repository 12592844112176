import React, { useState } from "react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { Button, Col, Form, Row, Table, Drawer } from "antd";
import { observer } from "mobx-react";
import { scheduleRules } from "../../../../requests/PageRequest";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import useStore from "../../../../store";
import { Notification, displayFormats, validateFields } from "../../../../config/Global";
import { ErrorProps } from "../../../../store/RootStore/RootInterface";
// import { ReactComponent as DownIcon } from '../../../../assets/images/icon/downIcon.svg';
import {
	FadeInSectionProps,
	DataType,
} from "../../../../store/ScheduleStore/ScheduleInterface";

const AddComponent: React.FC<FadeInSectionProps> = observer(
	(props: FadeInSectionProps) => {
		const { visible, close } = props;
		const [form] = Form.useForm();
		const [disabled, setDisabled] = useState(true);
		const [tableData, setTableData] = useState<any>(null);
		const { SCHEDULE, ROOT } = useStore();
		const [show, setShow] = useState(true);

		const rowSelection = {
			onChange: (selectedRowKeys: any, selectedRows: DataType[]) => {
				selectedRows;
				setTableData([...selectedRowKeys]);
				if (selectedRows.length > 0) {
					setDisabled(false)
				} else {
					setDisabled(true)
				}
			},
			columnTitle: <></>,
		};
		const columns: ColumnsType<DataType> = [
			{
				title: "Days",
				dataIndex: "days",
				render: (text: string) => <a>{text}</a>,
			},
			{
				title: "Start Time",
				dataIndex: "start_time",
			},
			{
				title: "End Time",
				dataIndex: "end_time",
			},
		];

		const handleTimezonChange = () => {
			setDisabled(true)
			if (form.getFieldValue('type') === 1) {
				setTableData([]);
				form.resetFields(['mon_start_time', 'mon_end_time', 'tue_start_time', 'tue_end_time', 'wed_start_time', "wed_end_time", 'thu_start_time', 'thu_end_time', 'fri_start_time', 'fri_end_time', 'sat_start_time', 'sat_end_time', "sun_start_time", 'sun_start_time'])
			} else {
				form.resetFields(['start_date', 'end_date'])
			}
		};

		const handleClose = () => {
			close()
			form.resetFields()
			setDisabled(true)
			setShow(true)
		}
		const disableMonStartDate = (current: any) => {
			return current && current <= moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT));
		}
		const disableMonEndDate = (current: any) => {
			return (form.getFieldValue('mon_start_time') ? current && current <= moment(form.getFieldValue('mon_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT)));
		}

		const disableTueEndDate = (current: any) => {
			return (form.getFieldValue('tue_start_time') ? current && current <= moment(form.getFieldValue('tue_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT)));
		}

		const disableWedEndDate = (current: any) => {
			return (form.getFieldValue('wed_start_time') ? current && current <= moment(form.getFieldValue('wed_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT)));
		}

		const disableThuEndDate = (current: any) => {
			return (form.getFieldValue('thu_start_time') ? current && current <= moment(form.getFieldValue('thu_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT)));
		}
		const disableFriEndDate = (current: any) => {
			return (form.getFieldValue('fri_start_time') ? current && current <= moment(form.getFieldValue('fri_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT)));
		}
		const disablesatEndDate = (current: any) => {
			return (form.getFieldValue('sat_start_time') ? current && current <= moment(form.getFieldValue('sat_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT)));
		}
		const disablesunEndDate = (current: any) => {
			return (form.getFieldValue('sun_start_time') ? current && current <= moment(form.getFieldValue('sun_start_time')) : current && current < moment(moment().add(60, 'minute').format(displayFormats.TIME_FORMAT)));
		}

		const handleChange = () => {
			validateFields(form, setDisabled);
		}

		const data: DataType[] = [
			{
				key: '0',
				days: 'MON',
				start_time: tableData && tableData.includes('0') ? <InputBox.TimePicker allowClear={false} rules={scheduleRules.time_layout}
					onChange={(e: any) => {
						{ form.setFieldsValue({ 'mon_start_time': moment(e), 'mon_end_time': moment(e.add(10, 'minute')) }) }
					}} disabledDate={disableMonStartDate} name={'mon_start_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
				end_time: tableData && tableData.includes('0') ? <InputBox.TimePicker allowClear={false} rules={scheduleRules.time_layout}
					name={'mon_end_time'} format={displayFormats.TIME_FORMAT} disabledDate={disableMonEndDate}></InputBox.TimePicker> : null
			},
			{
				key: '1',
				days: 'TUE',
				start_time: tableData && tableData.includes('1') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
					(e: any) => {
						{ form.setFieldsValue({ 'tue_start_time': moment(e), 'tue_end_time': moment(e.add(10, 'minute')) }) }
					}
				} name={'tue_start_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
				end_time: tableData && tableData.includes('1') ? <InputBox.TimePicker allowClear={false} disabledDate={disableTueEndDate} rules={scheduleRules.time_layout} name={'tue_end_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
			},
			{
				key: '2',
				days: 'WED',
				start_time: tableData && tableData.includes('2') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
					(e: any) => {
						{ form.setFieldsValue({ 'wed_start_time': moment(e), 'wed_end_time': moment(e.add(10, 'minute')) }) }
					}
				} name={'wed_start_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
				end_time: tableData && tableData.includes('2') ? <InputBox.TimePicker allowClear={false} disabledDate={disableWedEndDate} rules={scheduleRules.time_layout} name={'wed_end_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
			},
			{
				key: '3',
				days: 'THU',
				start_time: tableData && tableData.includes('3') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
					(e: any) => {
						{ form.setFieldsValue({ 'thu_start_time': moment(e), 'thu_end_time': moment(e.add(10, 'minute')) }) }
					}
				} name={'thu_start_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
				end_time: tableData && tableData.includes('3') ? <InputBox.TimePicker allowClear={false} disabledDate={disableThuEndDate} rules={scheduleRules.time_layout} name={'thu_end_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
			},
			{
				key: '4',
				days: 'FRI',
				start_time: tableData && tableData.includes('4') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
					(e: any) => {
						{ form.setFieldsValue({ 'fri_start_time': moment(e), 'fri_end_time': moment(e.add(10, 'minute')) }) }
					}
				} name={'fri_start_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
				end_time: tableData && tableData.includes('4') ? <InputBox.TimePicker allowClear={false} disabledDate={disableFriEndDate} rules={scheduleRules.time_layout} name={'fri_end_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
			}, {
				key: '5',
				days: 'SAT',
				start_time: tableData && tableData.includes('5') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
					(e: any) => {
						{ form.setFieldsValue({ 'sat_start_time': moment(e), 'sat_end_time': moment(e.add(10, 'minute')) }) }
					}
				} name={'sat_start_time'} format={displayFormats.TIME_FORMAT} ></InputBox.TimePicker> : null,
				end_time: tableData && tableData.includes('5') ? <InputBox.TimePicker allowClear={false} disabledDate={disablesatEndDate} rules={scheduleRules.time_layout} name={'sat_end_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
			}, {
				key: '6',
				days: 'SUN',
				start_time: tableData && tableData.includes('6') ? <InputBox.TimePicker disabledDate={disableMonStartDate} allowClear={false} rules={scheduleRules.time_layout} onChange={
					(e: any) => {
						{ form.setFieldsValue({ 'sun_start_time': moment(e), 'sun_end_time': moment(e.add(10, 'minute')) }) }
					}
				} name={'sun_start_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
				end_time: tableData && tableData.includes('6') ? <InputBox.TimePicker allowClear={false} disabledDate={disablesunEndDate} rules={scheduleRules.time_layout} name={'sun_end_time'} format={displayFormats.TIME_FORMAT}></InputBox.TimePicker> : null,
			},
		];
		const handleSubmit = (data: any) => {
			if (show) {
				data.start_date = moment(data.start_date).format('YYYY-MM-DD HH:mm:ss')
				data.end_date = moment(data.end_date).format('YYYY-MM-DD HH:mm:ss')
				data.start_time = moment(data.start_date).format('HH:mm:ss')
				data.end_time = moment(data.end_date).format('HH:mm:ss')

			} else {
				data.week_day = tableData
				const start_time: any = []
				const end_time: any = []
				Object.keys(data).map((it: any) => {
					if (!['device_id', 'layout_id', 'type', 'week_day'].includes(it)) {
						if (it.split("_")[1] === 'start') {
							start_time.push(moment(data[it]).format('HH:mm:ss'))
							delete data[it]
						}
						if (it.split("_")[1] === 'end') {
							end_time.push(moment(data[it]).format('HH:mm:ss'))
							delete data[it]
						}
					}
				})
				data.start_time = start_time
				data.end_time = end_time
			}
			SCHEDULE.addSchedule(data).then((data: any) => {
				Notification.success({
					message: data.data
				})
				handleClose()
			}).catch((e: ErrorProps) => {
				ROOT.assignErrorToInput(form, e?.data)
			})
		}

		const handleDateChange = (e: any) => {
			setDisabled(false)
			form.setFieldsValue({ 'end_date': moment(e).add(10, 'minutes') })
		}

		const disableStartDate = (current: any) => {
			return current && current < moment(moment().add(24, 'hours').subtract(1, 'day').format("YYYY-MM-DD HH:mm"));
		}

		const disableEndDate = (current: any) => {
			return (form.getFieldValue('start_date') ? current && current <= moment(form.getFieldValue('start_date')) : current && current < moment(moment().add(24, 'hours').format("YYYY-MM-DD HH:mm")));
		}

		return (
			<div>
				<Drawer open={visible} title='Schedule Layout' width={700} destroyOnClose onClose={handleClose}
					className='commanDrawer scheduleDrawer'
					footer={
						[
							<>
								<Button key={2} type='primary' disabled={disabled} form='addData' className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} htmlType='submit'>Save</Button>
								<Button key={1} type='primary' onClick={handleClose} className="orangeBgBtn cancelBtn">Cancel</Button>
							</>
						]
					}>
					<FormBox form={form} id='addData' onFinish={handleSubmit} initialValues={SCHEDULE.initialValue}>
						<Row gutter={15}>
							<Col span={12}>
								<InputBox.Select
									showSearch
									required
									// className='selectDown'
									// 			showArrow={false}
									// 			extra={
									// 				<DownIcon/>
									// 			}
									filterOption={(input: any, option: any) =>
										(option?.children || {}).toLowerCase().includes(input.toLowerCase())
									}
									rules={scheduleRules.select_device}
									placeholder='Select Device'
									label='Select Device'
									name={'device_id'}
									onFocus={() => SCHEDULE?.listLayoutSchedule()}
									onChange={handleChange}
									options={{
										list: SCHEDULE?.schedule_layout_list,
										valueKey: 'id',
										textKey: 'name'
									}}
								></InputBox.Select>
							</Col>
							<Col span={12}>
								<InputBox.Select
									required
									showSearch
									// className='selectDown'
									// 			showArrow={false}
									// 			extra={
									// 				<DownIcon/>
									// 			}
									placeholder='Select Layout'
									label='Select Layout'
									name={'layout_id'}
									filterOption={(input: any, option: any) =>
										(option?.children || {}).toLowerCase().includes(input.toLowerCase())
									}
									onFocus={() => SCHEDULE?.listSchedule()}
									rules={scheduleRules.select_layout}
									onChange={handleChange}
									options={{
										list: SCHEDULE?.layout_list,
										valueKey: 'id',
										textKey: 'name'
									}}
								></InputBox.Select>
							</Col>
							<Col span={6}>
								<InputBox.Radio
									required
									label='Select TimeZone'
									// rules={scheduleRules.select_layout}
									buttonStyle='solid'
									onChange={
										(data: any) => {
											handleTimezonChange()
											setShow(data.target.value === 1 ? true : false)
										}
									}
									optionType='button'
									options={{
										list: [{ id: 1, name: 'Once' }, { id: 2, name: 'Repeat' }],
										valueKey: 'id',
										textKey: 'name'
									}}
									name={'type'}
								></InputBox.Radio>
							</Col>
							{show ? (
								<>
									<Col span={8}>
										<InputBox.DateTimePicker
											required
											rules={scheduleRules.select_layout}
											format={displayFormats.DATE_TIME_FORMAT}
											onChange={(e: any) => {
												// handleChange()
												handleDateChange(e)
												// form.setFieldsValue({ 'end_date': moment(e).add(10, 'minutes') })
											}}
											allowClear={false}
											label='Start Date & Time'
											disabledDate={disableStartDate}
											name={'start_date'}
										>
										</InputBox.DateTimePicker>
									</Col>
									<Col span={8}>
										<InputBox.DateTimePicker
											required
											allowClear={false}
											rules={scheduleRules.select_layout}
											onChange={handleChange}
											format={displayFormats.DATE_TIME_FORMAT}
											label='End Date & Time'
											disabledDate={disableEndDate}
											name={'end_date'}
										>
										</InputBox.DateTimePicker>
									</Col>
								</>)
								:
								<Col span={24}>
									<Table
										rowSelection={rowSelection}
										pagination={false}
										columns={columns}
										dataSource={data}
									/>
								</Col>}
						</Row>
					</FormBox>
				</Drawer>
			</div>
		)
	})
export default AddComponent
