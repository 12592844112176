import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Col, Form, FormInstance, Row, Spin } from "antd";
import { observer } from "mobx-react";
import { InputBox } from "../../../../components/AntdAddons";
import React, { useEffect, useState } from "react";
import useStore from "../../../../store";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  layout_id: any;
  layoutIdFlag?: boolean;
}

const MixLayout: React.FC<formInterface> = observer((props: formInterface) => {
  const { name, onChange, layout_id, form, layoutIdFlag } = props;
  const [layoutNameLoading, setLayoutNameLoading] = useState<boolean>(true);
  const { LAYOUT } = useStore();

  useEffect(() => {
    const globalLayoutId = form.getFieldValue(["oriantation"]);
    LAYOUT.LayoutName(globalLayoutId).then(() => {
      setLayoutNameLoading(true);
    });
  }, []);

  useEffect(() => {
    //
  }, [LAYOUT.layout_name]);

  useEffect(() => {
    if (layout_id && layoutIdFlag) {
      form.setFieldValue(name, "");
      form.setFieldValue("layout_name", "");
    }
  }, [layout_id]);

  const handleSequenceChange = (result: any) => {
    if (result.source?.index >= 0 && result.destination?.index >= 0) {
      const getFieldValue = form.getFieldValue(["layout_name"]);
      const prevCategoryListCopy = JSON.parse(JSON.stringify(getFieldValue));
      const dragged = prevCategoryListCopy[result.source.index];
      prevCategoryListCopy.splice(result.source.index, 1);
      prevCategoryListCopy.splice(result.destination.index, 0, dragged);
      form.setFieldsValue({ layout_name: prevCategoryListCopy });
      onChange();
    }
  };
  return (
    <>
      <DragDropContext onDragEnd={handleSequenceChange}>
        <Droppable droppableId="sequence">
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Form.List name="layout_name">
                {(fields, { add, remove }) => {
                  const myFields = fields?.length
                    ? fields
                    : [
                        {
                          name: 0,
                          key: 0,
                          isListField: true,
                          fieldKey: 0,
                        },
                      ];
                  return (
                    <>
                      {myFields.map(({ key, name, ...restField }, index) => (
                        <Draggable
                          key={key}
                          draggableId={key.toString()}
                          index={index}
                        >
                          {(innerProvided: any) => (
                            <div
                              ref={innerProvided.innerRef}
                              {...innerProvided.draggableProps}
                              key={`sequence-${key}`}
                            >
                              <Row gutter={15} className="mixLayout">
                                <Col
                                  xs={{ span: 24 }}
                                  md={{ span: 2 }}
                                  lg={{ span: 2 }}
                                  className="layoutNameIcon"
                                  style={
                                    form.getFieldValue(["layout_name"])
                                      ?.length > 1
                                      ? { display: "flex" }
                                      : { display: "none" }
                                  }
                                  {...innerProvided.dragHandleProps}
                                >
                                  <FontAwesomeIcon
                                    icon={faGripVertical}
                                    size="xl"
                                  />{" "}
                                </Col>
                                <Col
                                  xs={{ span: 24 }}
                                  md={{ span: 10 }}
                                  lg={{ span: 10 }}
                                  className="layoutName"
                                >
                                  <InputBox.Select
                                    name={[name, `layout`]}
                                    label={`Layout`}
                                    optionFilterProp="children"
                                    {...restField}
                                    required
                                    showSearch
                                    notFoundContent={
                                      !layoutNameLoading ? (
                                        <Spin />
                                      ) : (
                                        "No content found"
                                      )
                                    }
                                    placeholder={"search Layout"}
                                    filterOption={(input, option: any) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={onChange}
                                    options={{
                                      list: LAYOUT?.layout_name,
                                      valueKey: "id",
                                      textKey: "name",
                                    }}
                                    rules={LayoutRequest.layout}
                                  />
                                </Col>
                                <Col
                                  xs={{ span: 24 }}
                                  md={{ span: 6 }}
                                  lg={{ span: 6 }}
                                  className="layoutName"
                                >
                                  <InputBox.Number
                                    name={[name, `duration`]}
                                    label={`Duration (In seconds)`}
                                    type="number"
                                    placeholder="Enter Duration"
                                    {...restField}
                                    min={1}
                                    onChange={onChange}
                                    required
                                    rules={LayoutRequest.duration}
                                  ></InputBox.Number>
                                </Col>
                                <Col
                                  xs={{ span: 24 }}
                                  md={{ span: 2 }}
                                  lg={{ span: 2 }}
                                  className="addRemove"
                                >
                                  <MinusCircleOutlined
                                    onClick={() => {
                                      remove(name);
                                      onChange();
                                    }}
                                  />
                                  {myFields?.length - 1 === name && (
                                    <PlusCircleOutlined
                                      onClick={() => {
                                        add();
                                        onChange();
                                      }}
                                    />
                                  )}
                                </Col>
                                {myFields?.length - 1 !== name && <hr />}
                              </Row>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </>
                  );
                }}
              </Form.List>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
});

export default MixLayout;
