import { Button, Form, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { useState } from 'react'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import { observer } from 'mobx-react'
import { SavedLayoutComponentProps } from '../../../../store/SavedLayoutStore/SavedInterface'
import useStore from '../../../../store'
import { LayoutRequest } from '../../../../requests/PageRequest'
import { Notification, validateFields } from '../../../../config/Global'

const PublishComponent: React.FC<SavedLayoutComponentProps> = observer((props: SavedLayoutComponentProps) => {
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [saving, setSaving] = useState<boolean>(false)
	const [deviceList, setDeviceList] = useState<boolean>(true)
	const { visible, close } = props
	const { SAVED_LAYOUT } = useStore()

	const handleChange = () => {
		validateFields(form, setDisabled);
	}

	const handleClose = () => {
		close()
		form.resetFields()
	}

	const handleSubmit = (data: any) => {
		setSaving(true)
		SAVED_LAYOUT.publishLayout(data).then((data: any) => {
			setSaving(false)
			Notification.success({ message: data.data })
			handleClose()
		}).catch((e: any) => {
			setSaving(false)
			return Promise.reject(e)
		})
	}


	return (
		<div>
			<Modal
				title='Publish Layout'
				open={visible}
				destroyOnClose
				className='commanModal publishModal'
				centered
				onCancel={handleClose}
				footer={[
					<>
						<Button key={1} type='primary' disabled={disabled} htmlType='submit' loading={saving} className={`${disabled ? 'disableBtn' : 'orangeBgBtnBtn'}`} form='publish'>Publish</Button>
						<Button key='2' className="cancelBtn" onClick={handleClose}>Cancel</Button>
					</>
				]}
			>
				<FormBox form={form} id='publish' onFinish={handleSubmit}>
					<InputBox.Select
						name='device_id'
						label='Select TV'
						placeholder='Select TV'
						filterOption={(input: any, option: any) =>
							(option?.children || {}).toLowerCase().includes(input.toLowerCase())}
						mode='multiple'
						onFocus={() => deviceList && SAVED_LAYOUT.devicePublishList().then(() => setDeviceList(false))}
						notFoundContent={deviceList ? <Spin /> : 'No data found'}
						options={{ list: SAVED_LAYOUT?.device_list }}
						rules={LayoutRequest.saved_select_tv}
						onChange={handleChange}
						maxTagCount={4}
					>
					</InputBox.Select>
				</FormBox>
			</Modal>
		</div>

	)
})

export default PublishComponent