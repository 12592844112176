import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form } from 'antd';
import FormComponent from './FormComponent';
import { componentProps } from '../../../../store/RateStore/RateInterface';
import useStore from '../../../../store';
import { Notification, validateFields } from '../../../../config/Global';
import { ErrorProps } from '../../../../store/RootStore/RootInterface';

const EditComponent: React.FC<componentProps> = observer((props: componentProps) => {
    const { visible, close } = props
    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState<boolean>(true)
    const { ROLE, ROOT } = useStore()
    const [saving, setSaving] = useState<boolean>(false)

    const handleChange = () => {
        validateFields(form, setDisabled);
    }

    const handleClose = () => {
        setSaving(false)
        setDisabled(true)
        form.resetFields()
        close()
    }

    const handleSubmit = (data: any) => {
        setSaving(true)
        var actions: any = {}
        if (data.permissions) {
            data.permissions.map((item: any) => {
                item.actions.map((action: any) => {
                    if (action.is_selected === 1) {
                        if (!actions[item.id]) { actions[item.id] = [] }
                        actions[item.id].push(action.id)
                    }
                    return null
                })
                return null
            })
            data.permissions = actions
        } else {
            data.permissions = null
        }
        ROLE.EditData(data).then((data: any) => {
            Notification.success({ message: data.message })
            close()
            form.resetFields()
            setDisabled(true)
        }).catch((e: ErrorProps) => {
            ROOT.assignErrorToInput(form, e?.data?.STATUS);
        }).finally(() => setSaving(false))
    }

    useEffect(() => {
        form.setFieldsValue({
            role_name: ROLE.readValues && ROLE.readValues.role_name
        })
    }, [form, ROLE.readValues])

    return (
        <Drawer
            open={visible}
            onClose={handleClose}
            width={750}
            className='commanDrawer addDeviceModal'
            title={`Edit Role`}
            destroyOnClose={true}
            footer={[
                <div key="1">
                    <Button form='roleForm' disabled={disabled} loading={saving} htmlType="submit" type="primary">Save</Button>
                    <Button className="orangeBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
                </div>
            ]}
        >
            <FormComponent form={form} handleSubmit={handleSubmit} onChange={handleChange} type='edit' />
        </Drawer>
    )
})

export default EditComponent