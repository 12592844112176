import React from "react";
import { TimePicker } from "antd";
import { DatePickerInputBoxProps } from "./interface";
import { SplitInputWrapperProps, InputWrapper } from "../../functions";

const TimePickerInputBox: React.FC<DatePickerInputBoxProps> = (props) => {
    const { formProps, inputProps } = SplitInputWrapperProps(props);
    return (
        <InputWrapper {...formProps}>
            <TimePicker format="HH:mm" showTime  {...inputProps} />
        </InputWrapper>
    );
};

export default TimePickerInputBox;
