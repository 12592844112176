import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import {
  LayoutListDataProps,
  LayoutListProps,
  MainLayoutListDP,
} from "./LayoutInterface";
import { isArray } from "lodash";
import { Notification, snake_case_string } from "../../config/Global";

export default class LayoutStore {
  agGrid: any = null;
  per_page = Config.grid.local.gridOptions?.paginationPageSize;
  public list_data?: LayoutListProps[];
  DeviceData?: any = null;
  public deviceFilterList:any = null;
  editValues: any = null;
  deleteValues = null;
  styleProps = null;
  public layout_name?: LayoutListProps[];
  public layoutList?: MainLayoutListDP[];
  public preViewData: any = null;
  public removeFile: any =null;
  public aiImage:any = null;
  public listAiImages:any = null;
  public newAiImageList:any = null;
  initialValues = {
    oriantation: "0",
    "border-style": "solid",
    "background-color": "rgba(255, 255, 255, 1)",
    "xval": 30,
    "yval": 70,
    "type-1": "1",
    "type-2": "1",
    img_top_left_slider: [
      {
        img_top_left: null,
      },
    ],
    img_top_right_slider: [
      {
        img_top_right: null,
      },
    ],
  };

  constructor() {
    makeAutoObservable(this);
  }

  onGridChanged = (params: any) => {
    if (params) {
      localStorage.setItem(
        "layout_token",
        JSON.stringify(params.columnApi.getColumnState())
      );
    //   params.api.addEventListener('filterOpened', (event:any) => {
    //     if (event.column.colId === 'device_name') {
    //       this.deviceFilter();
    //     }
    // });
    }
  };

  // Setup grid and set column size to autosize
  setupGrid = (params: any) => {
    const columnState = localStorage.getItem("layout_token");
    if (columnState) {
      params.columnApi.applyColumnState({ state: JSON.parse(columnState) });
      this.refreshFilter()
    }
    this.agGrid = params;
  };
  refreshFilter = () => {
    if (this.agGrid) {
        this.agGrid.api.getFilterInstance('device_name', (filterInstance:any) => {
            filterInstance.onNewRowsLoaded(); // Force the filter to recognize new rows
            filterInstance.applyModel(); // Apply the new filter model
            this.agGrid.api.onFilterChanged(); // Inform the grid that the filter has changed
            this.agGrid.api.refreshCells(); // Refresh the grid to ensure the UI updates
        });
    }
};

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(Number(page));
    }
  };

  setDeviceFilterList = (data: any) => {
    this.deviceFilterList = data;
  };

  setDeleteValues = (data: any) => {
    this.deleteValues = data;
  };

  setEditValues = (data: any) => {
    this.editValues = data;
  };

  setLayoutList = (data: any) => {
    this.layoutList = data;
  };

  setLayoutName = (data: any) => {
    this.layout_name = data;
  };

  setListData = (data: any) => {
    this.list_data = data;
  };
  
  setAiImage = (data: any) => {
    this.aiImage = data;
  };

  setListAiImages = (data: any) => {
    this.listAiImages = data;
  };

  setNewAiImageList = (data: any) => {
    this.newAiImageList = data;
  };

  setRemoveFile = (data:any) =>{
    this.removeFile = data;
  }

  // API Functions
  public fetchList = async (): Promise<any> => {
    return await axios
      .get(API_URL.LAYOUT.LIST)
      .then(({ data }) => {
        if (data.data) {
          data.data.forEach((_x: LayoutListDataProps) => {
            _x.oriantation = _x.oriantation === 0 ? "Landscape" : "Portrait";
          });
        }
        data.data &&
          data.data.forEach((item: any) => {
            item.device_name = item?.device?.map((d: any) => d.name).toString();
          });
        this.setListData(data.data);  
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

   deviceFilter = async (): Promise<any> => {
    
    return await axios
      .get(API_URL.LAYOUT.DEVICE_FILTER_LIST)
      .then(({ data }) => {
        this.setDeviceFilterList(data.devices)
        return data.devices;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public getLayout = async (id: number): Promise<any> => {
    const payload = {
      user_id: id,
    };
    return await axios
      .post(API_URL.LAYOUT.LAYOUTLIST, payload)
      .then(({ data }) => {
        this.setLayoutList(data);
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public getDevice = async (): Promise<any> => {
    return await axios
      .get(API_URL.LAYOUT.DEVICE)
      .then(({ data }) => {
        this.DeviceData = [...this.DeviceData, ...data.data];
        const uniqueObjects = new Map();
        const uniqueArray = this.DeviceData.filter((obj: any) => {
          const key = JSON.stringify(obj);
          if (!uniqueObjects.has(key)) {
            uniqueObjects.set(key, true);
            return true;
          }
          return false;
        });
        const temp = uniqueArray.map((obj: any) => ({
          id: obj.id,
          name: obj.name,
        }));
        this.DeviceData = temp;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public Previewlayout = async (id: any): Promise<any> => {
    return await axios
      .post(API_URL.LAYOUT.PREVIEW_LAYOUT + `/${id}`)
      .then(({ data }) => {
        return data;
        // this.DeviceData = data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public RemoveFileApi = async (id:any,data: any) => {
    return await axios
      .post(`${API_URL.LAYOUT.REMOVE_FILE}/${id}`, data)
      .then((data: any) => {
        return data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public LayoutName = async (orientation:number): Promise<any> => {
    return await axios
      .get(`${API_URL.LAYOUT.LAYOUT_NAME}/${orientation}`)
      .then(({ data }) => {
        this.setLayoutName(data.data);
        return data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  public LayoutAi = async (payload:any): Promise<any> => {
  
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiM2Y5ZmU2NjMtNTlhNS00MTViLWJlMTctMWI2MGZlMmY5NTZiIiwidHlwZSI6ImFwaV90b2tlbiJ9.9tmX10qa8W9Xmd71KY8dxwGnyUXG0PGfZjJJHbM3KjQ"

    const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        referrer: "no-referrer",
        body: payload
    };  
      await fetch (API_URL.LAYOUT.LAYOUT_AI,config).then((data)=>{
        return data.json();
      }).then((data)=>{
        if(data.replicate.status==="fail"){
        return Promise.reject(data.replicate.error.message)
        }
        const newImages:any =[];
        data.replicate.items.forEach((data:any,index:number)=>{
          const fetchFormat = data.image_resource_url.split('.').pop()
          newImages.push({name:`${snake_case_string(JSON.parse(payload)?.["text"])}_${index}.${fetchFormat}`,
          image_resource_url:data.image_resource_url})
        })
          this.setAiImage(newImages);
          this.setNewAiImageList(newImages);
        return Promise.resolve(newImages)
      }).catch ((error)=> {
          Notification.error({
            message: error
          });
        return Promise.reject(error)
      })
  };




  AddData = (formData: any) => {
    return axios
      .post(API_URL.LAYOUT.ADD, formData)
      .then((data: any) => {
        this.fetchList();
        return data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  ReadData = (id: number) => {
    return axios
      .get(API_URL.LAYOUT.READ + `/${id}`)
      .then(({ data }) => {
        this.setEditValues(data[0]);
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  DeleteData = (id: number) => {
    return axios
      .post(API_URL.LAYOUT.DELETE + `/${id}`)
      .then(({ data }) => {
        this.fetchList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  EditData = (formData: any) => {
    return axios
      .post(API_URL.LAYOUT.EDIT + "/" + this.editValues?.id, formData)
      .then(({ data }) => {
        this.fetchList();
        this.editValues = null;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  publishLayout = (data: any) => {
    return axios
      .post(API_URL.LAYOUT.PUBLISH + `/${data.id}`, data)
      .then(({ data }) => {
        this.fetchList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  publishLayoutWithoutMedia = (data: any) => {
    return axios
      .post(API_URL.LAYOUT.PUBLISH_LAYOUT_WITHOUT_MEDIA + `/${data.id}`)
      .then(({ data }) => {
        this.fetchList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  savedLayout = (data: any) => {
    return axios
      .post(API_URL.LAYOUT.SAVEDLAYOUT + `/${data.id}`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  cloneLayout = (data: any) => {
    return axios
      .post(API_URL.LAYOUT.CLONE + `/${data.id}`)
      .then(({ data }) => {
        this.fetchList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  JsonToFormData = (data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((ObjKeys) => {
      if (ObjKeys === "component") {
        const extraFields = data[ObjKeys];
        Object.keys(extraFields).forEach((layoutFields: any) => {
          var newArray: any = this.styleProps?.[layoutFields];
          var copyStyleProps: any = {};
          if (isArray(newArray)) {
            newArray.forEach((a: any) => {
              return (copyStyleProps[a.attribute] = a.value);
            });
            copyStyleProps = JSON.stringify(copyStyleProps);
          } else {
            copyStyleProps = JSON.stringify(this.styleProps?.[layoutFields]);
          }
          if (
            ["logo", "image", "video", "slider", "bg_image", "bg_music","pdf"].includes(
              layoutFields.split("-")[0]
            )
          ) {
            if (
              isArray(extraFields[layoutFields]) &&
              extraFields[layoutFields].length > 0
            ) {
              for (let i = 0; i < extraFields[layoutFields].length; i++) {
                formData.append(
                  `component[${layoutFields.toLowerCase()}][files][${i}]`,
                  extraFields[layoutFields][i]
                );
              }
            } else {
              formData.append(
                `component[${layoutFields.toLowerCase()}][files]`,
                extraFields[layoutFields]
              );
            }
            if (this.styleProps?.[layoutFields]) {
              formData.append(
                `component[style][${layoutFields.toLowerCase()}]`,
                copyStyleProps
              );
            }
          } else {
            formData.append(
              `component[${layoutFields.toLowerCase()}]`,
              extraFields[layoutFields]
            );
            if (this.styleProps?.[layoutFields]) {
              formData.append(
                `component[style][${layoutFields.toLowerCase()}]`,
                copyStyleProps
              );
            }
          }
        });
      } else {
        formData.append(ObjKeys, data[ObjKeys]);
      }
    });
    return formData;
  };
  // JsonToFormData = (data: any) => {
  // 	const formData = new FormData();
  // 	if(data.component){
  // 		Object.keys(data).forEach((ObjKeys) => {
  // 			if (ObjKeys === 'component') {

  // 				if (['logo', 'image', 'video', 'slider', 'bg_image'].includes(ObjKeys.split('-')[0])) {
  // 					if (['slider'].includes(ObjKeys.split('-')[0])) {
  // 						// formData.append(`${ObjKeys}-${[index]}`, item);
  // 					} else {
  // 						formData.append(`${ObjKeys}[file]}`, data[ObjKeys]);
  // 				}
  // 				// if (['slider'].includes(ObjKeys.split('-')[0])) {
  // 				// 	data[ObjKeys].forEach((item: any, index: number) => {
  // 				// 		// formData.append(`${ObjKeys}-${[index]}_url`, item);
  // 				// 		formData.append(`${ObjKeys}-${[index]}`, item);
  // 				// 	})
  // 				// } else {
  // 				// 	// formData.append(`${ObjKeys}_url`, (data[ObjKeys] !== undefined && data[ObjKeys] !== null) ? data[ObjKeys] : '')
  // 				// 	formData.append(`${ObjKeys}`, (data[ObjKeys] !== undefined && data[ObjKeys] !== null) ? data[ObjKeys] : '')
  // 				// }
  // 				// } else {
  // 				// 	formData.append(ObjKeys, data[ObjKeys])
  // 				// }
  // 			} else {
  // 				formData.append(ObjKeys, data[ObjKeys])
  // 			}
  // 		})
  // 	}else{
  // 		formData.append(data, data[ObjKeys])
  // 	}
  // }

  uploadHtmlBuilderFile = (data: any) => {
    return axios
      .post(API_URL.LAYOUT.FILE_UPLOAD, data)
      .then(({ data }) => {
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };
}
