import { makeAutoObservable } from "mobx";
import axios from "axios";
import API_URL from "../../config/ApiUrl";
// import Config from "../../config/Config";

export default class DashboardStore {
	dashboard_count: any = null

	constructor() {
		makeAutoObservable(this);
	}

	dashboardCount = () => {
		return axios.get(API_URL.DASHBOARD_COUNT).then(({ data }) => {
			this.dashboard_count = data.data
			return data
		})
	}
}
