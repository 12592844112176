import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form } from 'antd';
import FormComponent from './FormComponent';
import useStore from '../../../../store';
import { componentProps } from '../../../../store/DeviceStore/DeviceInterface';
import { Notification, validateFields } from '../../../../config/Global';
import { ErrorProps } from '../../../../store/RootStore/RootInterface';

const EditComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const { DEVICE_GROUP, ROOT } = useStore()

	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [saving, setSaving] = useState<boolean>(false)

	const handleChange = () => {
		validateFields(form, setDisabled);
	}

	const handleSubmit = (data: any) => {
		setSaving(true)
		DEVICE_GROUP.EditData(data, DEVICE_GROUP.editValues?.id).then((data: any) => {
			Notification.success({ message: data.data })
			handleClose()
			setDisabled(true)
		}).catch((e: ErrorProps) => {
			ROOT.assignErrorToInput(form, e?.data);
		}).finally(() => setSaving(false))
	}
	const handleClose = () => {
		close()
		form.resetFields()
		setDisabled(true)
	}

	useEffect(() => {
		form.setFieldsValue({
			group_name: DEVICE_GROUP.editValues?.group_name,
			device_ids: DEVICE_GROUP.editValues?.device_ids.slice(1).slice(0, -1).split("#").map((item: string) => parseInt(item)),
		})
		DEVICE_GROUP.deviceGroupDropdownList = DEVICE_GROUP.editValues?.device
	}, [form, DEVICE_GROUP.editValues])

	return (
		<Drawer
			open={visible}
			onClose={handleClose}
			width={'40%'}
			className='commanDrawer'
			title={`Edit Device Group`}
			destroyOnClose={true}
			footer={[
				<div key="1">
					<Button className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`}
						disabled={disabled}
						form='addEditDeviceForm' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="orangeBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent form={form} handleSubmit={handleSubmit} onChange={handleChange} type='edit' />
		</Drawer>
	)
})

export default EditComponent