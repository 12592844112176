import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form } from 'antd';
import FormComponent from './FormComponent';
import { componentProps } from '../../../../store/RateStore/RateInterface';
import useStore from '../../../../store';
import { Notification, validateFields } from '../../../../config/Global';
import { ErrorProps } from '../../../../store/RootStore/RootInterface';

const AddComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState<boolean>(true)
	const { ROLE, ROOT } = useStore()
	const [saving, setSaving] = useState<boolean>(false)

	const handleChange = () => {
		validateFields(form, setDisabled);
	}

	const handleClose = () => {
		setSaving(false)
		setDisabled(true)
		form.resetFields()
		close()
	}

	const handleSubmit = (data: any) => {
		setSaving(true)
		var actions: any = {}
		if (data.permissions) {
			data.permissions.map((item: any) => {
				item.actions.map((action: any) => {
					if (action.is_selected === 1) {
						if (!actions[item.id]) { actions[item.id] = [] }
						actions[item.id].push(action.id)
					}
					return null
				})
				return null
			})
			data.permissions = actions
		} else {
			data.permissions = null
		}
		ROLE.AddData(data).then((data: any) => {
			Notification.success({ message: data.message })
			close()
			form.resetFields()
		}).catch((e: ErrorProps) => {
			ROOT.assignErrorToInput(form, e?.data?.STATUS);
		}).finally(() => setSaving(false))
	}

	return (
		<Drawer
			open={visible}
			onClose={handleClose}
			width={750}
			className='commanDrawer addDeviceModal'
			title={`Add Role`}
			destroyOnClose={true}
			footer={[
				<div key="1">
					<Button form='roleForm' className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`} disabled={disabled} loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="orangeBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent form={form} handleSubmit={handleSubmit} onChange={handleChange} type='add' />
		</Drawer>
	)
})

export default AddComponent